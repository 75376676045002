import { capitalize } from '@/utils/functions'
import UserPopover from '@/components/UserPopover'
import { Container } from './styles'

const PlaygroundForkInfo = ({ playground }) => {
  if (!playground?.copied_from) return <></>

  return (
    <Container className="playground-fork-info">
      <span>
        Copied from{' '}
        <UserPopover user={playground?.copied_from?.user}>
          <span className="link">
            {capitalize(playground?.copied_from?.user?.first_name)}{' '}
            {capitalize(playground?.copied_from?.user?.last_name)}
          </span>
        </UserPopover>
      </span>
    </Container>
  )
}

export default PlaygroundForkInfo
