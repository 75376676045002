'use client'

import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoginForm from '../LoginForm'
import SignUpForm from '../SignUpForm'
import ForgotPasswordForm from '../ForgotPasswordForm'
import ResetPasswordForm from '../ResetPasswordForm'
import { toggleAuthModal } from '@/store/users/actions'
import AuthBg from '@/assets/images/auth/auth-bg.png'
import PlatformPreview from '@/assets/images/platform-preview.png'
import Logo from '@/assets/images/logo-sm-white.svg?react'
import { Modal } from './styles'

const AuthModal = () => {
  const dispatch = useDispatch()

  const { isAuthenticated, isAuthModalOpen } = useSelector((state) => state.users)

  const [formMode, setFormMode] = useState('login')

  const handleCallback = () => {
    dispatch(toggleAuthModal(false))
  }

  if (isAuthenticated) return <></>

  return (
    <Modal
      className="auth-modal"
      open={isAuthModalOpen}
      onCancel={() => dispatch(toggleAuthModal(false))}
      footer={null}
    >
      <div className="left-content" style={{ backgroundImage: `url(${AuthBg})` }}>
        <div className="content">
          <Logo className="logo" />

          <h4 className="title">Practice Data Science with Real Life projects</h4>

          <img className="image" src={PlatformPreview} alt="datawars.io" />

          <p className="description">
            Tired of just watching videos?
            <br />
            Our collection of +100 of projects will help you put your skills to a test.
          </p>
        </div>
      </div>

      <div className="right-content">
        <div className="content">
          {formMode === 'login' && <LoginForm toggleMode={setFormMode} callback={handleCallback} />}
        </div>

        <div className="content">
          {formMode === 'signup' && <SignUpForm toggleMode={setFormMode} callback={handleCallback} />}
        </div>

        <div className="content">
          {formMode === 'forgotPassword' && <ForgotPasswordForm toggleMode={setFormMode} />}
        </div>

        <div className="content">{formMode === 'resetPassword' && <ResetPasswordForm toggleMode={setFormMode} />}</div>
      </div>
    </Modal>
  )
}

export default AuthModal
