import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tooltip } from 'antd'
import { Timer24Regular, Eye24Regular, EyeOff24Regular } from '@fluentui/react-icons'
import {
  stopModuleAttempt,
  setAttemptRemainingTime,
  setModuleTimerModalAutoOpened,
  setModuleTimerModalOpen,
} from '@/store/modules/actions'
import { setInactivityModalOpen } from '@/store/labs/actions'
import { Container } from './styles'

const ModuleTimer = ({ isDisabled, isLarge }) => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { currentModule, moduleAttemptRemainingTime, isModuleTimerModalAutoOpened } = useSelector(
    (state) => state.modules,
  )

  const isPro = userProfile?.is_pro || userProfile?.groups?.includes('Pro')
  const moduleTimeOutModalInMinutes = (userProfile?.config?.module_time_out_modal_in_seconds || 300) / 60

  const moduleAttempt = currentModule?.user_status?.last_module_attempt
  const isAttemptFinished = moduleAttempt?.status === 'finished'
  const isAttemptActive = moduleAttempt?.is_active

  const min = Math.floor(moduleAttemptRemainingTime / 60)
  const sec = Math.floor(moduleAttemptRemainingTime % 60)

  const [status, setStatus] = useState('info')
  const [minutes, setMinutes] = useState(min < 10 ? `0${min}` : min)
  const [seconds, setSeconds] = useState(sec < 10 ? `0${sec}` : sec)
  const [isVisible, setIsVisible] = useState(true)

  const shouldOpenTimerModal =
    !isPro &&
    !!currentModule?.expiration_time &&
    !isAttemptFinished &&
    isAttemptActive &&
    // FIXME: it only supports whole minutes. Support seconds too
    min < moduleTimeOutModalInMinutes

  useEffect(() => {
    let interval

    if (isPro || isAttemptFinished) return

    setStatus(minutes < moduleTimeOutModalInMinutes ? 'danger' : 'info')

    if (!isModuleTimerModalAutoOpened && shouldOpenTimerModal && !isDisabled) {
      dispatch(setModuleTimerModalOpen(true))
      dispatch(setModuleTimerModalAutoOpened(true))
    }

    if (moduleAttemptRemainingTime && isAttemptActive) {
      const min = Math.floor(moduleAttemptRemainingTime / 60)
      setMinutes(min < 10 ? `0${min}` : min)

      const sec = Math.floor(moduleAttemptRemainingTime % 60)
      setSeconds(sec < 10 ? `0${sec}` : sec)

      interval = setInterval(() => {
        dispatch(setAttemptRemainingTime(moduleAttemptRemainingTime - 1))
      }, 1000)
    }

    if (moduleAttemptRemainingTime !== null && moduleAttemptRemainingTime <= 0) {
      setMinutes('00')
      setSeconds('00')
      clearInterval(interval)

      if (isAttemptActive && !isDisabled) {
        dispatch(stopModuleAttempt(currentModule?.id, moduleAttempt?.id, { reason: 'timed_out' }))

        dispatch(setModuleTimerModalOpen(false))
        dispatch(setModuleTimerModalAutoOpened(false))

        dispatch(setInactivityModalOpen(false))
      }
    }

    return () => clearInterval(interval)
  }, [isAttemptFinished, isAttemptActive, moduleAttemptRemainingTime])

  return (
    <Container id="module-timer" $status={status} $isLarge={isLarge}>
      <Timer24Regular className="timer-icon" />

      <p
        className={`time ${shouldOpenTimerModal ? 'clickeable' : ''}`}
        onClick={() => shouldOpenTimerModal && dispatch(setModuleTimerModalOpen(true))}
      >
        {isVisible ? `${minutes}:${seconds}` : '--:--'}
      </p>

      <Tooltip title={isVisible ? 'Hide timer' : 'Show timer'}>
        <span className="visibility-button" onClick={() => setIsVisible(!isVisible)}>
          {isVisible ? <Eye24Regular /> : <EyeOff24Regular />}
        </span>
      </Tooltip>
    </Container>
  )
}

export default ModuleTimer
