import { SET_LAUNCH_LOADING, LAUNCH_DATA_UPDATED, SET_LAUNCH_ERROR } from '@/store/launch'
import { getLaunch } from '@/services/launch'

const fetchLaunch = (launchId, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch(SET_LAUNCH_LOADING(true))

    const launchData = await getLaunch(launchId)
    dispatch(LAUNCH_DATA_UPDATED(launchData))

    if (onSuccess) {
      onSuccess(launchData)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_LAUNCH_ERROR(message))

    if (onError) {
      onError()
    }
  } finally {
    dispatch(SET_LAUNCH_LOADING(false))
  }
}

export { fetchLaunch }
