import { useSelector } from 'react-redux'
import { Tooltip, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import StreakIcon from '@/assets/images/streak.svg?react'
import StreakEmptyIcon from '@/assets/images/streak-empty.svg?react'
import { Container } from './styles'

const StreakInfo = () => {
  const { stats, isStatsLoading } = useSelector((state) => state.users)

  return (
    <Tooltip title={'Complete an activity every day to build your streak.'}>
      <Container $hasStreak={stats?.streak}>
        <div className="current-streak">
          <h5>
            {isStatsLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} /> : stats?.streak}
          </h5>

          {stats?.streak ? <StreakIcon /> : <StreakEmptyIcon />}
        </div>
      </Container>
    </Tooltip>
  )
}

export default StreakInfo
