const proficiencyLevels = [
  {
    name: 'Novice',
    code: 'novice',
  },
  {
    name: 'Intermediate',
    code: 'intermediate',
  },
  {
    name: 'Advanced',
    code: 'advanced',
  },
  {
    name: 'Professional',
    code: 'professional',
  },
  {
    name: 'Expert',
    code: 'expert',
  },
]

export default proficiencyLevels
