import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 5px;
  padding: 24px;
  margin-bottom: 20px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .section-title {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .playground-cards-container {
    .swiper-slide {
      padding: 8px 0px;
    }

    .playground-card-container {
    }
  }
`
