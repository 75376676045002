import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'
import PricingBg from '@/assets/images/plans/pro-pricing-bg.png'

const fadeInAnimation = keyframes`${fadeIn}`

export const Modal = styled(AntModal)`
  .ant-modal-content {
    padding: 0px;
    margin: 0px 20px;

    .ant-modal-body {
      overflow: hidden;
      background: url(${PricingBg}) no-repeat bottom center;

      > .content {
        padding: 60px 90px;

        .ppp-block {
          color: ${(props) => props.theme.colors.gray1};
          background: linear-gradient(
            71deg,
            ${(props) => props.theme.colors.gray10} 28.53%,
            rgba(59, 124, 249, 0.93) 130%
          );
          border-radius: 5px;
          padding: 10px 27px;
          margin-bottom: 20px;
          animation: 1s ${fadeInAnimation};

          .parity-banner-logo {
            display: none;
          }
        }

        > .header-container {
          .pre-title {
            color: ${(props) => props.theme.colors.base.geekblue5};
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 20px;
          }

          .title {
            font-size: 44px;
            font-weight: 700;
            line-height: 44px;
            display: flex;
            gap: 12px;
            margin-bottom: 10px;

            .price-container {
              color: ${(props) => props.theme.colors.base.geekblue4};
              display: flex;

              .small {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;

                .bold {
                  font-weight: 700;
                }

                .secondary {
                  font-size: 14px;
                  margin-left: 6px;

                  .gray {
                    color: ${(props) =>
                      localStorage.getItem('dw-theme') === 'dark'
                        ? props.theme.colors.gray10
                        : props.theme.colors.gray7};
                  }
                }
              }
            }
          }

          .billing-container {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 22px;

            .billing-period-selector.ant-segmented {
              background-color: ${(props) => props.theme.colors.gray2};
              padding: 8px 16px;

              .ant-segmented-group {
                .ant-segmented-item {
                  color: ${(props) => props.theme.colors.base.gray7};
                  font-weight: 700;

                  .ant-segmented-item-label {
                    font-size: 12px;
                  }
                }

                .ant-segmented-item-selected {
                  color: ${(props) => props.theme.colors.base.gray8};
                  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
                }
              }

              .item {
                display: flex;
                align-items: center;
                gap: 8px;

                .tag {
                  background-color: ${(props) => props.theme.colors.base.geekblue5};
                  color: ${(props) => props.theme.colors.base.gray1};
                  display: inline-block;
                  font-size: 11px;
                  font-weight: 700;
                  line-height: 20px;
                  border-radius: 50px;
                  padding: 0px 10px;
                }
              }
            }

            .info-box {
              background: #1f1f1fd4;
              color: ${(props) => props.theme.colors.gray8};
              font-size: 11px;
              font-weight: 400;
              line-height: 16.5px;
              border-radius: 5px;
              padding: 10px 27px;
              margin: 0px;

              .highlight {
                font-weight: 700;
              }
            }
          }
        }

        .features-container {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 20px 10px;
          margin-bottom: 40px;

          .feature-box {
            flex: 0 0 32.2%;

            .content {
              min-height: 86px;
              max-height: 86px;
              background: linear-gradient(180deg, rgba(239, 245, 255, 0.4) 0%, rgba(255, 255, 255, 0.36) 40.1%);
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              gap: 2px;
              border-radius: 5px;
              backdrop-filter: blur(20px);
              padding: 20px;

              .header {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                .icon {
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 20px;
                }

                .feature-name {
                  color: ${(props) => props.theme.colors.base.gray13};
                  font-weight: 500;
                  margin: 0px;
                }

                .coming-soon-tag {
                  color: ${(props) => props.theme.colors.base.gray1};
                  background-color: ${(props) => props.theme.colors.geekblue4};
                  display: inline-block;
                  border-radius: 50px;
                  font-size: 10px;
                  line-height: 20px;
                  padding: 0px 6px;
                  margin-left: 6px;
                }
              }

              .feature-description {
                color: ${(props) =>
                  localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray3 : props.theme.colors.gray8};
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                margin: 0px;
              }
            }
          }
        }

        .footer {
          display: flex;
          align-items: center;
          gap: 24px;

          .info-text {
            color: ${(props) =>
              localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray10 : props.theme.colors.gray8};
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            margin: 0px;
          }
        }
      }
    }
  }

  .ant-modal-close {
    height: 32px;
    width: 32px;
    margin: 2px;
  }

  @media ${device.lgDown} {
    .header-container {
      .billing-container {
        flex-direction: column;
        align-items: center;
      }
    }

    .features-container {
      justify-content: center;

      .feature-box {
        flex: 0 0 48% !important;
      }
    }
  }

  @media ${device.mdDown} {
    .ant-modal-body {
      > .content {
        padding: 30px 20px !important;
      }
    }

    .header-container {
      .title {
        text-align: center;

        .price-container {
          display: block;
        }
      }
    }

    .features-container {
      gap: 10px !important;

      .feature-box {
        flex: 0 0 100% !important;

        .content {
          min-height: initial !important;

          .header {
            flex-direction: row !important;
            gap: 4px;
          }
        }
      }
    }

    .footer {
      flex-direction: column;
      gap: 12px !important;
    }
  }
`
