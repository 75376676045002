import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 392px;
  width: 350px;
  background-color: ${(props) => props.theme.colors.gray2};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  border-radius: 10px;
  padding: 20px 20px 10px;
  transition: 0.3s all;

  &:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  }

  .image-container {
    width: 100%;
    height: 150px;
    background: linear-gradient(
      60deg,
      ${(props) => props.theme.colors.purple8},
      ${(props) => props.theme.colors.cyan7}
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 12px;

    .main-icon {
      width: 62px;
      height: 62px;
      color: ${(props) => props.theme.colors.gray1};
      font-size: 62px;
    }

    ${(props) =>
      props.$playgroundBackgroundImage &&
      css`
        background-image: url(${(props) => props.$playgroundBackgroundImage});
        background-size: cover;
        background-position: center;
        object-fit: cover;

        .main-icon {
          display: none;
        }
      `}
  }

  .playground-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .divider {
        background-color: ${(props) => props.theme.colors.gray3};
        height: 20px;
        width: 2px;
        margin: 8px;
      }

      .icons-container {
        color: ${(props) => props.theme.colors.gray7};
        display: flex;
        align-items: center;
        gap: 12px;

        .data-source-icon,
        .language-icon,
        .interface-icon {
          width: 22px;
        }
      }

      .published-info {
        color: ${(props) => props.theme.colors.gray6};
        font-size: 12px;
        margin: 0px;
      }
    }

    .title {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0px;
    }

    .description {
      height: 60px;
      overflow: hidden;

      * {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: 0px;
      }
    }
  }

  .bottom-info {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    .author-info {
      color: ${(props) => props.theme.colors.gray8};
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      align-self: flex-start;

      .link {
        color: ${(props) => props.theme.colors.gray8};
        cursor: pointer;
      }

      &:hover {
        .link {
          text-decoration: underline;
        }
      }
    }
  }

  @media ${device.smDown} {
    height: 350px;
    width: 280px;

    .image-container {
      height: 120px;

      .main-icon {
        width: 42px;
        height: 42px;
        font-size: 42px;
      }
    }

    .playground-card-content {
      gap: 8px;

      .title {
        font-size: 16px;
        line-height: 22px;
      }

      .description,
      .description * {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
`
