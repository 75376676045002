import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  .skill-block {
    padding: 10px;

    li {
      width: 100% !important;
      height: 40px !important;
    }
  }
`
