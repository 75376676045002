import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'antd'
import Button from '@/components/Button'
import { validateVoucher } from '@/store/vouchers/actions'
import { ContainerTwoTone } from '@ant-design/icons'
import { Form } from './styles'

const RedeemVoucherForm = ({ callback }) => {
  const dispatch = useDispatch()

  const { error: voucherError } = useSelector((state) => state.vouchers)

  const [isLoading, setIsLoading] = useState(false)
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    setIsLoading(true)
    await dispatch(validateVoucher(data))
    setIsLoading(false)
  }

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Controller
        name="voucherCode"
        defaultValue=""
        control={control}
        rules={{ required: 'Voucher code is required' }}
        render={({ field }) => (
          <Input
            {...field}
            onChange={(evt) => {
              let { value } = evt.target
              value = value.toUpperCase()
              value = value.replaceAll('-', '')
              value = value.replaceAll('0', '')

              const key = evt.keyCode || evt.charCode
              const isBackspaceClick = key === 8 || key === 46

              if (value.length > 4 && !isBackspaceClick) {
                value = value.substring(0, 4) + '-' + value.substring(4, 8)
              }
              setValue('voucherCode', value)
            }}
            className={`voucher-input ${(voucherError || errors.voucherCode) && 'error'}`}
            type="text"
            size="large"
            placeholder="Voucher Code"
            prefix={<ContainerTwoTone />}
          />
        )}
      />

      {(voucherError || errors.voucherCode) && (
        <p className="error-text">{voucherError || errors.voucherCode.message}</p>
      )}

      <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
        Redeem Voucher
      </Button>
    </Form>
  )
}

export default RedeemVoucherForm
