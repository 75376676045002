import * as React from "react";
const Svg1Full = (props) => /* @__PURE__ */ React.createElement("svg", { id: "badge-1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 600 600", style: {
  enableBackground: "new 0 0 600 600"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { id: "ribbon", d: "M383.3,491.5l-76.4-47.7c-4-2.5-8.8-2.5-12.9,0l-76.4,47.7c-6.9,4.3-14.7-3.4-13-12.9l27.7-154.5 h136.1l27.7,154.5C397.9,488.1,390.1,495.8,383.3,491.5L383.3,491.5z", style: {
  fill: "#525776"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { id: "medal-border", d: "M395.7,106.7H205.2c-7.2,0-14.1,3.1-19.2,8.6l-47.9,51.9c-8.7,9.4-10.4,23.9-4.2,35.4 l112.5,202.6c5.7,10.4,16.1,16.7,27.3,16.7h53.6c11.2,0,21.5-6.4,27.3-16.7l112.5-202.6c6.2-11.4,4.4-26-4.2-35.4l-48-51.8 C409.7,109.8,402.8,106.7,395.7,106.7L395.7,106.7z", style: {
  fill: "#7F8297"
} })), /* @__PURE__ */ React.createElement("g", { id: "transparent-center" }, /* @__PURE__ */ React.createElement("path", { d: "M300.5,248.2l132.7-89.8l-29.8-31.8c-5-5.3-11.6-8.3-18.7-8.3H216.2c-6.9,0-13.6,3-18.7,8.3l-30,32.2 L300.5,248.2L300.5,248.2z", style: {
  fill: "#989898"
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.6,158.7l-11.3,12c-8.8,9.5-10.6,24.2-4.2,35.8L255.7,396c3.5,6.4,9.9,10.4,16.8,10.4h28V248.2 L167.6,158.7L167.6,158.7z", style: {
  fill: "#CBCBCB"
} }), /* @__PURE__ */ React.createElement("path", { d: "M444.6,170.7l-11.5-12.4l-132.7,89.8v158.3h28.1c6.9,0,13.2-3.9,16.7-10.4l103.6-189.5 C455.2,195,453.5,180.2,444.6,170.7L444.6,170.7L444.6,170.7z", style: {
  fill: "#808080"
} })), /* @__PURE__ */ React.createElement("path", { id: "internal-jewel", d: "M302.1,346.4l88.8-149.6c0.8-1.3-0.2-3-1.7-3H211.7c-1.5,0-2.5,1.7-1.7,3l88.8,149.6 C299.5,347.7,301.4,347.7,302.1,346.4L302.1,346.4L302.1,346.4z", style: {
  opacity: 0.8,
  fill: "#4A4D69",
  enableBackground: "new"
} }), /* @__PURE__ */ React.createElement("path", { id: "spark", d: "M216.3,185.2l2.7-4.9l2.7,4.9c2.3,4.2,5.7,7.7,10,10l4.9,2.7l-4.9,2.7c-4.2,2.3-7.7,5.7-10,10 l-2.7,4.9l-2.7-4.9c-2.3-4.2-5.7-7.7-10-10l-4.9-2.7l4.9-2.7C210.6,192.8,214.1,189.4,216.3,185.2z", style: {
  fill: "#FFFFFF"
} }));
export default Svg1Full;
