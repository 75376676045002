import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;

  .lab-container {
    max-width: calc(50% - 7px);
    flex: 0 50%;
  }
`
