import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox, Alert } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import Button from '@/components/Button'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { createModuleAttempt, setResetModuleModalOpen } from '@/store/modules/actions'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Modal } from './styles'

const ResetModuleModal = () => {
  const dispatch = useDispatch()

  const [isChecked, setIsChecked] = useState(false)

  const { uiConfig } = useSelector((state) => state.accounts)
  const { userProfile } = useSelector((state) => state.users)
  const { currentModule, isResetModuleModalOpen, isModuleAttemptLoading } = useSelector((state) => state.modules)

  const canResumeLab = userProfile?.permissions?.includes('labs.resume_lab')

  const handleCancel = () => {
    dispatch(setResetModuleModalOpen(null))
  }

  const handleResetModule = () => {
    dispatch(createModuleAttempt(currentModule?.id, isResetModuleModalOpen?.config))
    handleCancel()
  }

  const handleOpenUpgradeToProModal = () => {
    dispatch(toggleUpgradeToProModal(true))
  }

  useEffect(() => {
    if (isResetModuleModalOpen?.isOpen) return

    setIsChecked(false)
  }, [isResetModuleModalOpen?.isOpen])

  return (
    <Modal
      open={isResetModuleModalOpen?.isOpen}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={null}
      className="reset-module-modal"
    >
      <div className="content">
        <div className="header">
          <WarningFilled className="icon" />

          <h4 className="title">Are you sure you want to reset your project back to its default state?</h4>

          <Alert
            message="All the progress you’ve made on your activities and lab/code will be lost."
            type="warning"
            showIcon
          />

          {uiConfig?.showUpgradeToProUi && !canResumeLab && (
            <div className="pro-info">
              <img className="pro-badge" src={ProBadge} alt="Update to PRO" />

              <p className="pro-simple-text">
                <span>PRO users enjoy the ability to resume projects once stopped.</span>{' '}
                <a className="upgrade-link" onClick={handleOpenUpgradeToProModal}>
                  <u>Upgrade Now</u>
                </a>
              </p>
            </div>
          )}

          <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
            Yes, I’m sure
          </Checkbox>
        </div>

        <div className="actions">
          <Button size="large" type="default" onClick={handleCancel} loading={isModuleAttemptLoading}>
            Cancel
          </Button>

          <Button
            className="cta-button"
            size="large"
            type="primary"
            danger
            onClick={handleResetModule}
            disabled={!isChecked}
            loading={isModuleAttemptLoading}
          >
            Reset and Launch
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ResetModuleModal
