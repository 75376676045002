import { useInstantSearch, useHits, Pagination } from 'react-instantsearch'
import { Skeleton } from 'antd'
import DataSourceCard from '@/pages/PlaygroundsPage/components/DataSourceCard'
import NotFoundImage from '@/assets/images/not-found.svg'
import { Container } from './styles'

const SearchResults = () => {
  const { results } = useHits()
  const { indexUiState, status } = useInstantSearch()

  const isLoading = status === 'loading' || status === 'stalled'
  const searchText = indexUiState?.query

  return (
    <Container className="data-sources-search-results">
      {isLoading && (
        <div className="results-container is-loading">
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        </div>
      )}

      {!!searchText?.length && results?.hits?.length === 0 && (
        <div className="no-results-box">
          <img src={NotFoundImage} alt="no-results" />
          <h4 className="title">No results found</h4>
          <h5 className="text">
            {searchText && (
              <span>
                “{searchText}” did not match any result.
                <br />
              </span>
            )}
            Please try again.
          </h5>
        </div>
      )}

      <div className="results-container">
        {results?.hits?.map((hit) => {
          if (!hit.id) {
            hit.id = hit.objectID
          }

          return <DataSourceCard key={hit.id} dataSource={hit} showDescription showExtras />
        })}
      </div>

      <Pagination className="pagination-box" padding={2} showFirst={false} showLast={false} hideOnSinglePage />
    </Container>
  )
}

export default SearchResults
