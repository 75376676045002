import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray3};
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .left-content,
  .right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;

    .logo {
      margin-bottom: 30px;
    }

    .content {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .module-title {
      max-width: 330px;
      color: ${(props) => props.theme.colors.base.gray1};
      margin: 0px auto 30px;
    }

    .module-description {
      max-width: 440px;
      color: ${(props) => props.theme.colors.base.gray1};
      margin: 0px auto;
    }

    .module-image {
      max-width: 450px;
      width: 100%;
      margin-bottom: 30px;
    }

    .module-title,
    .module-description,
    .module-image {
      display: none;
    }
  }

  .left-content {
    background-size: cover;
    background-position: center;
    object-fit: cover;

    @media ${device.lgUp} {
      align-items: flex-end;

      .content {
        margin: 0px 16% 0px 4%;
      }
    }
  }

  .right-content {
    .content {
      width: 100%;
      max-width: 420px;
    }

    @media ${device.lgUp} {
      align-items: flex-start;

      .content {
        margin: 0px 4% 0px 16%;
      }
    }
  }

  @media ${device.lgUp} {
    flex-direction: row;

    .left-content,
    .right-content {
      width: 50%;
      min-height: 100vh;
      height: 100vh;
      padding: 60px 20px;

      .module-title,
      .module-description,
      .module-image {
        display: block !important;
      }
    }
  }
`
