import { useSelector, useDispatch } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Container } from './styles'

const DashboardTopNotification = () => {
  const dispatch = useDispatch()

  const { pppInfo } = useSelector((state) => state.app)
  const { userProfile } = useSelector((state) => state.users)

  const hasGroups = !!userProfile?.groups?.length

  const { message: pppMessage, isVpn, isProxy, isTor } = pppInfo || {}
  const shouldShowPPP =
    !hasGroups && pppMessage && pppMessage !== 'No discounts available' && !isVpn && !isProxy && !isTor

  const isNotificationDismissed = !!localStorage.getItem('dw-dismiss-app-dashboard-top-notification')

  const dismissNotification = () => {
    localStorage.setItem('dw-dismiss-app-dashboard-top-notification', true)

    const elem = document.getElementsByClassName('dashboard-top-notification')?.[0]
    if (elem) elem.classList.add('is-dismissed')
  }

  if (!shouldShowPPP || isNotificationDismissed) return

  return (
    <Container className="dashboard-top-notification">
      <div className="main">
        {/* <img className="logo-image" src={DataWarsLogo} alt="DataWars Playgrounds" /> */}

        <div
          className="ppp-block"
          dangerouslySetInnerHTML={{
            __html: pppMessage,
          }}
        />

        {/* <div className="text-container">
          <h4 className="title">Explore Playgrounds!</h4>

          <p className="text">
            Our newest feature that lets you play with datasets freely with an empty notebook using Python, R or Julia
            and publish to showcase your work to the world.
          </p>
        </div> */}
      </div>

      <div className="actions">
        <Button className="pro-button" type="primary" onClick={() => dispatch(toggleUpgradeToProModal(true))}>
          <img className="pro-badge" src={ProBadge} alt="Update to PRO" />
          Upgrade Now
        </Button>

        <Button
          className="close-btn"
          type="text"
          onClick={dismissNotification}
          icon={<CloseOutlined className="icon" />}
        />
      </div>
    </Container>
  )
}

export default DashboardTopNotification
