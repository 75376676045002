import styled from 'styled-components'

export const Container = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 10px;

    .title {
      display: flex;
      align-items: baseline;
      gap: 6px;
      margin: 0px;

      .type-icon {
        color: ${(props) => props.theme.colors.geekblue5};
        font-size: 16px;
      }
    }

    .pro-label {
      margin-top: 4px;
    }
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

    .tag {
      border-radius: 10px;
      margin: 0px;

      &.beta {
        width: 56px;
        background-color: ${(props) => props.theme.colors.magenta1};
        color: ${(props) => props.theme.colors.magenta5};
        border: 1px solid ${(props) => props.theme.colors.magenta5};
        text-align: center;
      }
    }
  }

  .related-data-sources {
    border-top: 1px solid ${(props) => props.theme.colors.gray4};
    padding-top: 24px;
    margin-top: 24px;

    .data-sources-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .no-data-source-content {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 16px;

    .image {
      height: 120px;
      width: 120px;
    }

    .title {
      max-width: 300px;
      color: ${(props) => props.theme.colors.gray7};
      font-weight: 400;
      text-align: center;
    }
  }

  .description-content {
    margin: 16px 0px 0px;
  }
`
