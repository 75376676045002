import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Skeleton } from 'antd'
import { Clock24Regular } from '@fluentui/react-icons'
import FadeIn from '@/components/FadeIn'
import PlaygroundItem from '../PlaygroundItem'
import { Container } from './styles'

const MyPlaygroundsSection = () => {
  const { myPlaygrounds, isLoading } = useSelector((state) => state.playgrounds)

  return (
    <Container className="my-playgrounds-section">
      <FadeIn>
        <div className="header">
          <h5 className="section-title">
            <Clock24Regular />
            My recent Playgrounds
          </h5>

          {!isLoading && myPlaygrounds !== null && (
            <p className="view-all-button">
              <Link to={'/playgrounds?tab=my-playgrounds'}>View all</Link>
            </p>
          )}
        </div>

        <div className="playgrounds-container">
          {(isLoading || myPlaygrounds === null) && (
            <div className="loading-container">
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
          )}

          {!isLoading && myPlaygrounds?.slice(0, 2)?.map((p) => <PlaygroundItem key={p?.id} playground={p} />)}
        </div>
      </FadeIn>
    </Container>
  )
}

export default MyPlaygroundsSection
