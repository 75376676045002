import { useSelector, useDispatch } from 'react-redux'
import Button from '@/components/Button'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { Content } from './styles'

const EarlyAccessBanner = () => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { currentModule } = useSelector((state) => state.modules)

  const canViewEarlyAccess = userProfile?.permissions?.includes('content.module_early_access')

  if (!currentModule?.early_access || canViewEarlyAccess) return <></>

  return (
    <Content className="early-access-banner">
      <div className="content">
        <div className="text-container">
          <p className="title">
            <img className="pro-badge" src={ProBadge} alt="PRO user" />
            Exclusive Early Access
          </p>

          <p className="text">Enjoy exclusive early access to this project before anybody else.</p>
        </div>

        <Button type="primary" onClick={() => dispatch(toggleUpgradeToProModal(true))}>
          Upgrade Now
        </Button>
      </div>
    </Content>
  )
}

export default EarlyAccessBanner
