import { Tooltip, Spin } from 'antd'
import { LoadingOutlined, CheckOutlined, LockOutlined } from '@ant-design/icons'
import { Container } from './styles'

const SkillButton = ({ skill, size }) => {
  const { name, user_status, status: skillStatus } = skill
  const progress = user_status?.progress * 100

  let status = 'pending'
  if (progress > 0 && progress < 100) {
    status = 'in_progress'
  } else if (progress === 100) {
    status = 'finished'
  }

  const getIcon = () => {
    if (skillStatus === 'coming_soon') return <LockOutlined className="icon" />

    return <CheckOutlined className="icon" />
  }

  return (
    <Tooltip title={skill?.status === 'coming_soon' ? 'Coming soon' : name}>
      <Container $skillStatus={skillStatus} $status={status} $size={size}>
        {user_status ? getIcon(status) : <Spin indicator={<LoadingOutlined spin />} />}
      </Container>
    </Tooltip>
  )
}

export default SkillButton
