import { useEffect, useMemo, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useScrollSpy from 'react-use-scrollspy'
import useQueryParam from '@/hooks/useQueryParam'
import ModulePageContent from '../ModulePageContent'
import ModuleExtraContent from '../ModuleExtraContent'
import ModuleBottomProgress from '../ModuleBottomProgress'
import EarlyAccessBanner from './components/EarlyAccessBanner'
import { Content } from './styles'
import { selectPage } from '@/store/modules/actions'

const ModuleContent = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useQueryParam('page')

  const { uiConfig } = useSelector((state) => state.accounts)
  const { isAuthenticated } = useSelector((state) => state.users)
  const { currentModule, currentPage } = useSelector((state) => state.modules)

  const pages = currentModule?.module_pages
  const moduleAttempt = currentModule?.user_status?.last_module_attempt

  const modulePageContainerRef = createRef()
  const pageRefs = useMemo(() => {
    let refs = pages?.map(() => createRef(null))

    // extra ref for ModuleExtraContent
    refs.push(createRef(null))

    return refs
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const scrollAtPage = useScrollSpy({
    scrollingElement: modulePageContainerRef,
    sectionElementRefs: pageRefs,
    offsetPx: -120,
  })

  useEffect(() => {
    if (!page) return

    const pageBody = {
      page: scrollAtPage + 1,
      name: pages?.[scrollAtPage]?.name || 'Next up',
      disableScroll: true,
    }
    dispatch(selectPage(pageBody))
    setPage(parseInt(scrollAtPage) + 1)
  }, [scrollAtPage]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!currentPage?.page || currentPage?.disableScroll) return

    const elemId = pages?.[currentPage?.page - 1]?.id || 'review-page'
    const elem = document.getElementById(elemId)

    if (!elem) return
    elem.scrollIntoView()
  }, [currentPage]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const container = document.getElementById('module-page-container')
    if (container) {
      container.scrollTop = 0
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {uiConfig?.showModuleEarlyAccessUi && <EarlyAccessBanner />}

      <Content id="module-page-container" ref={modulePageContainerRef}>
        {pages.map((page, index) => (
          <div key={page.id} id={page.id} ref={pageRefs[index]}>
            <ModulePageContent pageContent={page} showTitle={index > 0} />
          </div>
        ))}

        {isAuthenticated && (
          <div id="review-page" ref={pageRefs?.slice(-1)?.[0]}>
            <ModuleExtraContent />
          </div>
        )}
      </Content>

      {!!moduleAttempt && <ModuleBottomProgress />}
    </>
  )
}

export default ModuleContent
