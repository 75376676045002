import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    SET_ISSUES_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    ISSUES_UPDATED: (state, { payload }) => {
      state.items = payload
      state.isLoading = false
      state.error = null
    },
    SET_ISSUES_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
    RESET_ISSUES_STATE: () => initialState,
  },
})

export const { SET_ISSUES_LOADING, ISSUES_UPDATED, SET_ISSUES_ERROR, RESET_ISSUES_STATE } = slice.actions
export default slice.reducer
