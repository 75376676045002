import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  background: linear-gradient(84deg, #222249 24.3%, #5479f7 67.59%, #89d2c2 99.33%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 30px;

  > .content {
    width: 100%;
    max-width: 860px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    .text-container {
      .title {
        color: ${(props) => props.theme.colors.gray1};
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 4px;

        .pro-badge {
          width: 21px;
        }
      }

      .text {
        color: ${(props) => props.theme.colors.gray2};
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 0px;
      }
    }
  }
`
