import { Spin, Progress } from 'antd'
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons'
import FadeIn from '@/components/FadeIn'
import { Container } from './styles'

const SkillTrackTableCard = ({ skillTrack, color, onClick }) => {
  const isDraft = skillTrack?.status === 'draft'
  const hasProgress = skillTrack?.user_status?.progress > 0
  const isFinished = skillTrack?.user_status?.progress === 1
  const isLoading = !isDraft && !skillTrack.user_status

  return (
    <Container $color={color} $isLoading={isLoading} $isDraft={isDraft} $isFinished={isFinished} onClick={onClick}>
      <div className="title-container">
        <p className="title">{skillTrack.name}</p>

        {isFinished && <CheckOutlined className="icon" />}
        {isLoading && <Spin indicator={<LoadingOutlined spin />} />}
      </div>

      {hasProgress && !isFinished && (
        <FadeIn>
          <div className="progress-container">
            <Progress percent={skillTrack?.user_status?.progress * 100} showInfo={false} />
            <p className="progress-text">{Math.round(skillTrack?.user_status?.progress * 100)}%</p>
          </div>
        </FadeIn>
      )}
    </Container>
  )
}

export default SkillTrackTableCard
