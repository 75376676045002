import { useEffect } from 'react'

const useOutsideAlerter = (ref, isActive, onClickOutside) => {
  useEffect(() => {
    let isLoaded = false

    const handleClick = (event) => {
      if (isLoaded && isActive && ref.current && !ref.current.contains(event.target)) {
        if (onClickOutside) onClickOutside()
        event.preventDefault()
      }

      isLoaded = true
    }

    const handleMouseWheel = (event) => {
      if (isActive && ref.current && !ref.current.contains(event.target)) {
        event.preventDefault()
      }
    }

    document.addEventListener('click', handleClick)
    document.addEventListener('mousewheel', handleMouseWheel, { passive: false })

    return () => {
      document.removeEventListener('click', handleClick)
      document.removeEventListener('mousewheel', handleMouseWheel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, isActive])
}

export default useOutsideAlerter
