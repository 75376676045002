import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import useQueryParam from '@/hooks/useQueryParam'
import { showToast } from '@/utils/toast'
import Layout1 from '@/layouts/Layout1'
import FadeIn from '@/components/FadeIn'
import SignUpForm from '@/components/auth/SignUpForm'
import LoginForm from '@/components/auth/LoginForm'
import ForgotPasswordForm from '@/components/auth/ForgotPasswordForm'
import ResetPasswordForm from '@/components/auth/ResetPasswordForm'
import LabLoading from '@/components/auth/LabLoading'
import { getModule } from '@/store/modules/actions'
import AuthBg from '@/assets/images/auth/auth-bg.png'
import PlatformPreview from '@/assets/images/platform-preview.png'
import Logo from '@/assets/images/logo-sm-white.svg?react'

import { Container } from './styles'

const AuthPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [moduleId] = useQueryParam('project_id')
  const [redirectTo] = useQueryParam('redirect_to')

  const { isAuthenticated } = useSelector((state) => state.users)
  const { currentModule: module } = useSelector((state) => state.modules)

  const [formMode, setFormMode] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingLab, setIsLoadingLab] = useState(true)

  const handleModuleLoad = async () => {
    setIsLoadingLab(true)
    await dispatch(getModule(moduleId))
    setIsLoadingLab(false)
  }

  useEffect(() => {
    if (!moduleId && !isLoading) {
      setIsLoadingLab(false)
      return
    }

    if (moduleId) {
      handleModuleLoad()
    }
  }, [moduleId, isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (redirectTo && isAuthenticated) {
      window.location.replace(redirectTo)
      return
    }

    if (moduleId === undefined) return

    if (isAuthenticated === false && isLoading) {
      setIsLoading(false)
    }

    if (isAuthenticated) {
      return navigate(moduleId ? `/project/${moduleId}` : '/', { replace: true })
    }
  }, [isAuthenticated, moduleId, redirectTo]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location?.search?.includes('/invite/')) {
      showToast('Please register or log in first before accepting an invite link', 'warning')
    }
  }, [location?.search])

  useEffect(() => {
    if (location?.pathname === '/register') setFormMode('signup')
    if (location?.pathname === '/login') setFormMode('login')
    if (location?.pathname === '/forgot-password') setFormMode('forgotPassword')
    if (location?.pathname === '/reset-password') setFormMode('resetPassword')
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1 isLoading={isLoading}>
      <Container>
        <div className="left-content" style={{ backgroundImage: `url(${AuthBg})` }}>
          <div className="content">
            <Logo className="logo" />

            {isLoadingLab ? (
              <LabLoading />
            ) : (
              <FadeIn>
                <h4 className="module-title">{module?.name || 'Practice Data Science with Real Life projects'}</h4>

                <img className="module-image" src={module?.image_url || PlatformPreview} alt="datawars.io" />

                <p className="module-description">
                  {module?.description || (
                    <>
                      Tired of just watching videos?
                      <br />
                      Our collection of +100 of projects will help you put your skills to a test.
                    </>
                  )}
                </p>
              </FadeIn>
            )}
          </div>
        </div>

        <div className="right-content">
          <div className="content">
            {formMode === 'signup' && <SignUpForm />}
            {formMode === 'login' && <LoginForm />}
            {formMode === 'forgotPassword' && <ForgotPasswordForm />}
            {formMode === 'resetPassword' && <ResetPasswordForm />}
          </div>
        </div>
      </Container>
    </Layout1>
  )
}

export default AuthPage
