import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Alert, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { isDataWarsHostName } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import { isDown } from '@/themes/breakpoints'
import Layout1 from '@/layouts/Layout1'
import Button from '@/components/Button'
import ShareModal from '@/components/ShareModal'
import InactivityModal from '@/pages/ModulePage/components/InactivityModal'
import SiderMenu from './components/SiderMenu'
import InfoSideDrawer from './components/InfoSideDrawer'
import PlaygroundHeader from './components/PlaygroundHeader'
import PlaygroundContent from './components/PlaygroundContent'
import MultiplePlaygroundsModal from './components/MultiplePlaygroundsModal'
import PlaygroundVersionPreviewModal from './components/PlaygroundVersionPreviewModal'
import ResetPlaygroundModal from './components/ResetPlaygroundModal'
import PublishModal from './components/PublishModal'
import PlaygroundsFeedbackModal from '@/pages/PlaygroundsPage/components/PlaygroundsFeedbackModal'
import PlaygroundsFeedbackTag from '@/pages/PlaygroundsPage/components/PlaygroundsFeedbackTag'
import { toggleEmailVerificationModal } from '@/store/users/actions'
import {
  getPlayground,
  createPlayground,
  updatePlaygroundMode,
  getAllocatedPlaygrounds,
  toggleMultiplePlaygroundsModal,
  resetPlaygroundsState,
} from '@/store/playgrounds/actions'
import {
  getLab,
  fetchSessions,
  fetchSession,
  allocateLab,
  deallocateLab,
  setInactivityModalOpen,
  resetLabsState,
} from '@/store/labs/actions'
import { Container } from './styles'

const PlaygroundPage = ({ launchPlaygroundId }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { playgroundId } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()

  const modeSearchParam = searchParams.get('mode')

  const [instanceId, setInstanceId] = useState()

  const { currentAccount } = useSelector((state) => state.accounts)
  const { isAuthenticated, userProfile, emailValidationCodeIsValidated } = useSelector((state) => state.users)
  const {
    currentPlayground: playground,
    playgroundMode,
    allocatedPlaygrounds,
    isLoading: isPlaygroundsLoading,
    error: playgroundError,
  } = useSelector((state) => state.playgrounds)
  const { currentLab, labSessions, isLabLoading, labPingError } = useSelector((state) => state.labs)

  const labSession = currentLab?.allocated_session
  const isDeviceStopping = labSession?.devices?.some((d) => d.status === 'stopping')
  const isLabSessionAllocated = labSession?.status === 'allocated'
  const playgroundDataSource = playground?.data_sources?.[0]

  const isCustomProject = !!playground?.metadata?.account_id
  const isPlaygroundOwner = playground?.user?.id === userProfile?.id
  const isAccountOwner =
    userProfile?.accounts?.find((account) => account.id === playground?.metadata?.account_id)?.role === 'owner'
  const hasPlaygroundCopy = !!playground?.user_status?.last_playground_copy
  const isMemberCopy = playground?.metadata?.is_student_copy
  const hasPlaygroundNewVersionAvailable = !!playground?.user_status?.new_version_available

  const activeLabSession = labSessions?.filter((s) => s?.allocated_by?.id !== userProfile?.id)?.[0]?.allocated_by
  const isOtherUserUsingLab =
    activeLabSession &&
    (activeLabSession?.first_name ? `${activeLabSession?.first_name} ${activeLabSession?.last_name}` : 'another user')

  const requireEmailValidated = userProfile?.organization?.config?.launch_labs_requires_email_validated
  const isEmailValidated = !requireEmailValidated || userProfile?.email_validated_at || emailValidationCodeIsValidated

  const canRunMultipleLabs = userProfile?.permissions?.includes('labs.run_multiple_labs')
  const canSelectInstance = userProfile?.permissions?.includes('labs.select_instance')

  const canLaunchPlayground =
    canRunMultipleLabs ||
    (!canRunMultipleLabs &&
      !!allocatedPlaygrounds &&
      !allocatedPlaygrounds?.filter((p) => p?.playground_id !== playground?.id)?.length)

  const doAllocateLab = async () => {
    const powerOnAllDevices = true
    const config = {
      resume: true,
      ...(playground?.config?.boost ? { boost: true } : {}),
      ...(playground?.config?.internet_on ? { internet_on: true } : {}),
      ...(canSelectInstance && instanceId ? { instance_id: instanceId } : {}),
    }

    await dispatch(allocateLab(playground?.lab_id, 'playground', powerOnAllDevices, config))
  }

  const tryAllocateLab = () => {
    if (!isEmailValidated) {
      dispatch(toggleEmailVerificationModal(true))
      return
    }

    if (!canLaunchPlayground) {
      if (playgroundMode === 'preview') {
        dispatch(toggleMultiplePlaygroundsModal(true))
      }
      return
    }

    dispatch(updatePlaygroundMode('edit'))

    if ((isPlaygroundOwner || isAccountOwner) && !isLabSessionAllocated && !isOtherUserUsingLab) {
      doAllocateLab()
    }
  }

  const handlePlaygroundLoad = () => {
    // Use callback to handle internal playgroundId change on launchPlaygroundId mode
    dispatch(
      getPlayground(launchPlaygroundId || playgroundId, (playground) => {
        const isCustomProject = !!playground?.metadata?.account_id
        const hasPlaygroundCopy = !!playground?.user_status?.last_playground_copy

        if (isCustomProject && hasPlaygroundCopy && launchPlaygroundId) {
          dispatch(resetPlaygroundsState())
          dispatch(resetLabsState())
          dispatch(getPlayground(playground?.user_status?.last_playground_copy?.id))
        }
      }),
    )
  }

  const handleReset = async () => {
    const auxCustomProject = {
      playground_id: playground?.copied_from?.playground_id,
      name: playground?.name,
      metadata: {
        account_id: playground?.metadata?.account_id,
        is_student_copy: true,
      },
      visibility: 'private',
    }

    if (labSession) {
      await dispatch(
        deallocateLab({ labId: playground?.lab_id, labSessionId: labSession?.id, deallocateReason: 'manual' }),
      )
    }

    const loadPlayground = (id) => {
      dispatch(resetPlaygroundsState())
      dispatch(resetLabsState())
      dispatch(getPlayground(id))
      dispatch(updatePlaygroundMode('edit'))
    }

    await dispatch(
      createPlayground(auxCustomProject, (p) =>
        launchPlaygroundId ? loadPlayground(p?.id) : navigate(`/custom-projects/${p?.id}?mode=edit`, { replace: true }),
      ),
    )
  }

  const updateToNewVersion = () => {
    Modal.confirm({
      title: 'Are you sure you want to update to the latest version?',
      content: (
        <>
          <p style={{ marginBottom: 10 }}>
            Updating to the latest version will create a brand new copy of the project but you’ll lose any changes
            you’ve made on your current project.
          </p>
          <p>Download any notebooks or changes before continuing.</p>
        </>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, update to new version',
      cancelText: 'Back to your project',
      onOk: handleReset,
      okButtonProps: { type: 'primary', disabled: isPlaygroundsLoading, loading: isPlaygroundsLoading },
      width: 500,
    })
  }

  useEffect(() => {
    if (!playground?.id) return

    if (!modeSearchParam || ['edit', 'preview'].includes(modeSearchParam)) {
      const newPlaygroundMode = isEmailValidated ? modeSearchParam || 'preview' : 'preview'

      dispatch(updatePlaygroundMode(newPlaygroundMode))
      setSearchParams({ mode: newPlaygroundMode }, { replace: true })

      if (!isEmailValidated) {
        dispatch(toggleEmailVerificationModal(true))
      }
    }
  }, [modeSearchParam, playground?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!playgroundMode) return
    setSearchParams({ mode: playgroundMode }, { replace: true })
  }, [playgroundMode]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!playgroundId && !launchPlaygroundId) return

    dispatch(setInactivityModalOpen(false))
    handlePlaygroundLoad()
  }, [playgroundId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!playground) return
    dispatch(resetLabsState())

    if (!isCustomProject && !isPlaygroundOwner && !isAccountOwner && !userProfile?.is_staff) {
      return navigate('/', { replace: true })
    }

    if (isCustomProject) {
      if (playgroundMode === 'edit' && !isPlaygroundOwner && !isAccountOwner && !userProfile?.is_staff) {
        dispatch(updatePlaygroundMode('preview'))
      }

      if (hasPlaygroundCopy && !launchPlaygroundId) {
        const playgroundUrl = `/custom-projects/${playground?.user_status?.last_playground_copy?.id}`
        return navigate(playgroundUrl, { replace: true })
      }
    }

    const title = (isDataWarsHostName ? 'DataWars' : currentAccount?.name) || 'Practice projects'
    const fullTitle = `${title} - Become an expert Data Scientist`

    let playgroundDescription = `This playground uses ${playground?.jumpbox?.language?.value}`
    if (playgroundDataSource) {
      playgroundDescription += ` to explore a dataset about ${playground?.data_sources?.[0]?.name}: ${playground?.data_sources?.[0]?.short_description_md}`
    }

    document.title = `${playground?.name} | ${title}`
    document.querySelector("meta[property='og:title'").setAttribute('content', `${playground?.name} | ${title}`)
    document.querySelector("meta[name='description'").setAttribute('content', playgroundDescription)
    document.querySelector("meta[property='og:description'").setAttribute('content', playgroundDescription)

    dispatch(getLab(playground.lab_id))

    if (isCustomProject && !isPlaygroundOwner && !isAccountOwner && !userProfile?.is_staff) {
      return
    } else {
      dispatch(getAllocatedPlaygrounds())

      if (playground?.lab_id) {
        dispatch(fetchSessions({ lab_ids: playground?.lab_id, status: 'allocated' }))
      }
    }

    return () => {
      document.title = title
      document.querySelector("meta[property='og:title'").setAttribute('content', fullTitle)
      document
        .querySelector("meta[name='description'")
        .setAttribute('content', 'Project-based playground with +1000 ready-to-solve challenges.')
      document
        .querySelector("meta[property='og:description'")
        .setAttribute('content', 'Project-based playground with +1000 ready-to-solve challenges.')
    }
  }, [playground?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!currentLab) return

    if (!currentLab?.allocated_session) {
      if (playgroundMode === 'edit') {
        tryAllocateLab()
      }

      if (currentLab?.justStopped) {
        handlePlaygroundLoad()
        dispatch(getAllocatedPlaygrounds())
      }
    }

    let refreshSessionInterval
    if (isAuthenticated && isDeviceStopping) {
      refreshSessionInterval = setInterval(() => {
        dispatch(fetchSession(currentLab?.allocated_session?.id))
      }, 1000)
    }

    return () => {
      if (!currentLab?.allocated_session) return

      clearInterval(refreshSessionInterval)
    }
  }, [currentLab?.allocated_session]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      labSessions !== null &&
      canLaunchPlayground &&
      !isOtherUserUsingLab &&
      playgroundMode === 'edit' &&
      currentLab &&
      !currentLab?.allocated_session
    ) {
      tryAllocateLab()
    }
  }, [canLaunchPlayground])

  useEffect(() => {
    if (!labPingError && !isOtherUserUsingLab) return

    dispatch(updatePlaygroundMode('preview'))
  }, [labPingError, isOtherUserUsingLab])

  useEffect(() => {
    if (playgroundError) {
      showToast('There was an error loading content', 'error')
    }
  }, [playgroundError])

  useEffect(() => {
    return () => {
      dispatch(resetPlaygroundsState())
      dispatch(resetLabsState())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1 navbar siderContent={!isDown('md') && !playgroundError && <SiderMenu />} disableSiderCollapse>
      {playground && (
        <InfoSideDrawer
          shouldSetDefaultContent={!!playgroundDataSource}
          instanceId={instanceId}
          setInstanceId={setInstanceId}
        />
      )}

      <Container className="playground-page">
        <PlaygroundHeader
          launchPlaygroundId={launchPlaygroundId}
          isPlaygroundOwner={isPlaygroundOwner}
          isAccountOwner={isAccountOwner}
          isOtherUserUsingLab={isOtherUserUsingLab}
          tryAllocateLab={tryAllocateLab}
        />

        {isCustomProject && isMemberCopy && hasPlaygroundNewVersionAvailable && (
          <Alert
            message={`There’s a new version available for this ${isCustomProject ? 'project' : 'playground'}. Before updating, download the notebooks you've made changes.`}
            type="warning"
            action={
              <Button type="primary" loading={isPlaygroundsLoading || isLabLoading} onClick={updateToNewVersion}>
                Update to new version
              </Button>
            }
            showIcon
            banner
          />
        )}

        <PlaygroundContent
          canLaunchPlayground={canLaunchPlayground}
          isOtherUserUsingLab={isOtherUserUsingLab}
          tryAllocateLab={tryAllocateLab}
        />

        {localStorage.getItem('dw-debug') && (
          <div
            className="debug-box"
            style={{
              width: 200,
              background: 'rgba(127,127,127,0.3',
              position: 'absolute',
              right: 0,
              top: '30%',
              padding: 10,
            }}
          >
            <p>
              pg mode?{' '}
              <span
                style={{ background: playgroundMode === 'edit' ? '#00ffff' : '#00ff00', fontWeight: 600, padding: 2 }}
              >
                {playgroundMode || '-'}
              </span>
            </p>
            <p>pg? {playground ? '✅' : '❌'}</p>
            <p>lab? {currentLab ? '✅' : '❌'}</p>
            <p>
              l.session? {labSession ? '✅' : '❌'} ({labSession?.status})
            </p>
            <p>p.preview_html? {playground?.preview_html ? '✅' : '❌'}</p>
            <p>isDeviceStopping? {isDeviceStopping ? '✅' : '❌'}</p>
          </div>
        )}
      </Container>

      {isDataWarsHostName && <PlaygroundsFeedbackTag />}

      <InactivityModal callback={() => dispatch(updatePlaygroundMode('preview'))} />
      <MultiplePlaygroundsModal />
      <PublishModal />
      <ShareModal contentType="playground" />
      <PlaygroundVersionPreviewModal />
      <ResetPlaygroundModal launchPlaygroundId={launchPlaygroundId} />
      <PlaygroundsFeedbackModal />
    </Layout1>
  )
}

export default PlaygroundPage
