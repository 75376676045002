import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  overflow: hidden;

  .row {
    display: flex;
    gap: 12px;
  }

  .ant-skeleton {
    .ant-skeleton-paragraph {
      margin: 8px 0px;

      li {
        width: 100% !important;
        min-height: 300px !important;
        border-radius: 4px;
        margin-right: 10px;
      }
    }

    &:first-child {
      width: initial;

      .ant-skeleton-paragraph {
        li {
          width: 47px !important;
          min-height: 300px;
          border-radius: 3px;
          margin-right: 24px !important;
        }
      }
    }
  }

  @media ${device.lgDown} {
    .ant-skeleton {
      &:first-child {
        display: none;
      }
    }
  }

  @media ${device.mdDown} {
    .row {
      flex-direction: column;
      gap: 6px;
      margin-bottom: 20px;
    }

    .ant-skeleton {
      .ant-skeleton-paragraph {
        margin: 0px;

        li {
          min-height: 38px !important;
        }
      }
    }
  }
`
