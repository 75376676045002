import { useSelector } from 'react-redux'
import { Container } from './styles'

const ModuleBottomProgress = () => {
  const { currentModule: module } = useSelector((state) => state.modules)
  const scoring = module?.user_status?.last_module_attempt?.user_status?.scoring
  const passedActivities = module?.user_status?.last_module_attempt?.user_status?.passed_activities
  const totalActivities = module?.user_status?.last_module_attempt?.user_status?.total_activities
  const parsedScoring = Math.round(scoring * 100, 2)
  const isFinished = parsedScoring === 100

  return (
    <Container id="module-page-bottom-panel" $isFinished={isFinished}>
      <div id="module-bottom-progress-bar" className="progress">
        <p>
          Completed activities {passedActivities}/{totalActivities}{' '}
          <span className="progress-tag">{`${parsedScoring}%`}</span>
        </p>
        <div className="percentage" style={{ width: `${parsedScoring}%` }} />
      </div>
    </Container>
  )
}

export default ModuleBottomProgress
