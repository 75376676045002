import styled from 'styled-components'

export const Container = styled.div`
  .tag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 20px;
    padding: 0px 8px;
    margin: 0px;

    svg {
      width: 14px;
      height: 14px;
    }

    &.tag-gold {
      background-color: ${(props) => props.theme.colors.gold1};
      color: ${(props) => props.theme.colors.base.gold6};
      border: 1px solid ${(props) => props.theme.colors.gold3};

      svg {
        path {
          fill: ${(props) => props.theme.colors.base.gold6};
        }
      }
    }

    &.tag-red {
      background-color: ${(props) => props.theme.colors.gray1};
      color: ${(props) => props.theme.colors.base.red5};
      border: 1px solid ${(props) => props.theme.colors.red3};

      svg {
        path {
          fill: ${(props) => props.theme.colors.base.red5};
        }
      }
    }

    &.tag-blue {
      background-color: ${(props) => props.theme.colors.geekblue1};
      color: ${(props) => props.theme.colors.base.geekblue5};
      border: 1px solid ${(props) => props.theme.colors.geekblue3};

      svg {
        path {
          fill: ${(props) => props.theme.colors.base.geekblue5};
        }
      }
    }
  }
`
