import API from '../client'
import { apiErrorHandler } from '../../helpers/errorHandlers'

const patchProfile = async (username, data) => {
  let auxData = { ...data }
  Object.keys(auxData).forEach((key) => auxData[key] === null && delete auxData[key])

  const route = `profiles/${username}`
  return API.patch(route, auxData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { patchProfile }
