import ModuleBox from '@/components/ModuleBox'
import { Container } from './styles'

const ModuleBoxesLoading = () => {
  return (
    <Container>
      <div className="lab-container">
        <ModuleBox isLoading />
      </div>

      <div className="lab-container">
        <ModuleBox isLoading />
      </div>

      <div className="lab-container">
        <ModuleBox isLoading />
      </div>
    </Container>
  )
}

export default ModuleBoxesLoading
