import { Layout } from 'antd'
import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled(Layout.Header)`
  width: 100%;
  background-color: #000;
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  z-index: 101;
  transition:
    0.3s transform,
    0.6s opacity;

  ${(props) =>
    props.$color &&
    css`
      background-color: ${props.$color};
    `}

  .ant-menu {
    background-color: transparent;
    border: 0px;

    .ant-menu-item {
      top: 0px;
    }

    .ant-menu-item-disabled {
      cursor: default;

      &:hover {
        background-color: initial !important;
      }
    }

    .menu-item {
      display: flex;
      gap: 6px;

      .new-tag {
        margin: 0px;
      }
    }
  }

  .content {
    /* max-width: 1440px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    overflow: hidden;
  }

  .left-content {
    height: 100%;
    display: flex;
    align-items: center;

    .logo-container {
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 10px;

      .logo {
        width: 116px;
        display: block;
        user-select: none;
        pointer-events: none;
      }

      .logo-sm {
        width: 33px;
        display: none;
        user-select: none;
        pointer-events: none;
      }

      > a {
        height: 100%;
        display: flex;
      }

      .logo-whitelabel {
        height: 100%;
        user-select: none;
        pointer-events: none;
        padding: 8px 0px;
      }
    }
  }

  .center-content {
    max-width: calc(100% - 150px - 250px);

    .ant-breadcrumb {
      ol {
        justify-content: center;

        li {
          max-width: 100%;
          display: flex;
          align-items: center;

          .ant-breadcrumb-link {
            color: #fff;
            font-size: 16px;
            font-weight: 400;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            a {
              color: rgba(255, 255, 255, 0.45);
            }
          }
        }
      }

      .no-link {
        color: rgba(255, 255, 255, 0.45);
      }

      .ant-breadcrumb-separator {
        color: rgba(255, 255, 255, 0.45);
      }

      @media ${device.lgDown} {
        .ant-breadcrumb-separator,
        > ol li:nth-child(1) {
          display: none !important;
        }
      }
    }

    .dropdown-icon {
      font-size: 12px;
      transition: 0.1s all;
      margin-left: 4px;
    }
  }

  .right-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    .ant-menu {
      flex: auto;
      justify-content: flex-end;

      .ant-menu-item {
        padding: 0px;
      }
    }

    .menu-icon {
      display: none;
      margin-left: 10px;
      cursor: pointer;

      .menu-icon-img {
        width: 26px;
      }
    }

    .theme-switch {
      background-color: ${(props) => props.theme.colors.gray10};

      .ant-switch-handle:before {
        background-color: ${(props) => props.theme.colors.gray6};
      }

      .ant-switch-inner {
        .ant-switch-inner-unchecked {
          color: ${(props) => props.theme.colors.gray1};
        }
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.gray9};
      }
    }

    .navbar-btn,
    .navbar-btn a {
      color: ${(props) => props.theme.colors.base.gray1};
    }

    .navbar-btn svg path {
      fill: ${(props) => props.theme.colors.base.gray1};
    }
  }

  .ant-menu-item::after,
  .ant-menu-submenu::after {
    border-bottom: none !important;
    transition: none !important;
  }

  .ant-menu,
  .ant-menu-menu-title,
  .ant-menu-title-content a,
  .ant-menu-title-content span,
  .ant-menu-submenu-title,
  .ant-menu-submenu-content a {
    color: #fff;
    font-weight: 500;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-item:hover,
  .ant-menu-submenu-active,
  .ant-menu-submenu:hover {
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  .ant-btn-text {
    .ant-btn-icon {
      color: #fff;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
  }

  ${(props) =>
    props.$secondaryColor &&
    css`
      .center-content {
        .ant-breadcrumb {
          ol {
            li {
              .ant-breadcrumb-link {
                color: ${props.$secondaryColor} !important;

                a {
                  color: ${props.$secondaryColor} !important;
                  opacity: 0.5;
                }
              }
            }
          }

          .no-link {
            color: ${props.$secondaryColor} !important;
            opacity: 0.5;
          }

          .ant-breadcrumb-separator {
            color: ${props.$secondaryColor} !important;
            opacity: 0.5;
          }
        }
      }

      .right-content {
        .navbar-btn,
        .navbar-btn a {
          color: ${props.$secondaryColor} !important;
          border-color: ${props.$secondaryColor} !important;

          &:hover {
            color: ${props.$secondaryColor}77 !important;
            border-color: ${props.$secondaryColor}77 !important;
          }
        }

        .ant-menu * {
          color: ${props.$secondaryColor} !important;
        }
      }

      .ant-menu,
      .ant-menu-menu-title,
      .ant-menu-title-content a,
      .ant-menu-title-content span,
      .ant-menu-submenu-title,
      .ant-menu-submenu-content a {
        color: ${props.$secondaryColor} !important;
      }

      .ant-menu-item-selected,
      .ant-menu-item-active,
      .ant-menu-item:hover,
      .ant-menu-submenu-active,
      .ant-menu-submenu:hover {
        background-color: ${props.$secondaryColor}0A !important;
      }

      .ant-btn-text {
        .ant-btn-icon {
          color: ${props.$secondaryColor} !important;
        }
      }
    `}

  ${(props) =>
    props.$isAssessment &&
    css`
      background: linear-gradient(
          270deg,
          rgba(201, 214, 255, 0.28) 9.37%,
          rgba(152, 143, 255, 0.28) 46.6%,
          rgba(255, 255, 255, 0.57) 85.96%
        ),
        #fff;
      box-shadow: 0px -1px 0px 0px ${props.theme.colors.base.gray4} inset;

      .content {
        .left-content {
          .logo-container {
            .logo {
              display: none;
            }

            .logo-sm {
              display: block;
            }
          }
        }

        .center-content {
          .ant-breadcrumb {
            ol {
              li {
                .ant-breadcrumb-link {
                  color: ${props.theme.colors.base.gray10};

                  a {
                    color: ${props.theme.colors.base.gray7};
                  }
                }
              }
            }

            .no-link {
              color: ${props.theme.colors.base.gray7};
            }

            .ant-breadcrumb-separator {
              color: ${props.theme.colors.base.gray7};
            }
          }
        }

        .right-content {
          .navbar-btn,
          .navbar-btn a {
            color: ${props.theme.colors.base.gray10};
          }

          .navbar-btn svg path {
            fill: ${props.theme.colors.base.gray10};
          }
        }
      }
    `}

  ${(props) =>
    props.$isDataWarsHostName &&
    css`
      @media ${device.lgDown} {
        background-color: #fff;
        box-shadow: 0px 1px 4px 0px
          ${(props) =>
            localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray7 : props.theme.colors.gray13}26;

        &.hidden {
          opacity: 0;
          transform: translateY(-64px);
          margin-top: -64px;
        }

        .ant-menu {
          background-color: #fff;
          color: #595959;
        }

        .right-content {
          .navbar-btn {
            display: none;
          }
        }

        .content {
          .left-content {
            .logo-container {
              .logo {
                display: none;
              }

              .logo-sm {
                display: block;
              }
            }

            .ant-menu {
              display: none;
            }
          }

          .center-content {
            display: none;
          }

          .right-content {
            #navbar-right-menu {
              display: none;
            }

            .menu-icon {
              display: flex;
            }
          }
        }
      }
    `}
`
