import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;

  .lab-info,
  .lab-session-info {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray4};

    .title {
      margin-bottom: 14px;
    }

    .lab-stats {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 14px;

      .lab-stat {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        &.is-row {
          flex-direction: row;
          align-items: center;
        }

        .title {
          color: ${(props) => props.theme.colors.gray7};
          font-size: 13px;
          line-height: 20px;
          margin: 0px;
        }

        .subtitle {
          font-size: 13px;
          line-height: 20px;
          margin: 0px;

          code {
            font-size: 11px !important;
          }
        }

        .list {
          max-width: 100%;
          padding: 0px;
          margin: 0px;

          .list-item {
            display: flex;
            flex-direction: column;
            padding-left: 16px;
          }
        }
      }
    }
  }
`
