import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Star24Regular } from '@fluentui/react-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import PlaygroundCard from '../PlaygroundCard'
import { getPlaygrounds } from '@/store/playgrounds/actions'
import { Container } from './styles'

const FeaturedPlaygroundsSection = () => {
  const dispatch = useDispatch()

  const { featuredPlaygrounds } = useSelector((state) => state.playgrounds)

  const handleLoad = async () => {
    if (featuredPlaygrounds === null) {
      dispatch(getPlaygrounds({ featured: true }))
    }
  }

  useEffect(() => {
    handleLoad()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!featuredPlaygrounds?.length) return <></>

  return (
    <Container id="featured-playgrounds-section">
      <div className="header">
        <h5 className="section-title">
          <Star24Regular />
          Featured Playgrounds
        </h5>
      </div>

      {featuredPlaygrounds?.length ? (
        <Swiper
          className="white playground-cards-container"
          spaceBetween={16}
          modules={[Navigation]}
          navigation
          slidesPerView={'auto'}
        >
          {featuredPlaygrounds?.map((p) => (
            <SwiperSlide key={p?.id}>
              <PlaygroundCard playground={p} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>There are no featured Playgrounds yet.</p>
      )}
    </Container>
  )
}

export default FeaturedPlaygroundsSection
