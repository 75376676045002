import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin: 32px;

  .title {
    width: 235px;
    position: absolute;
    top: 46px;
    left: 120px;
  }

  .swiper {
    width: 310px;
    height: 66px;
    position: absolute;
    top: 46px;
    right: 5%;

    .swiper-slide {
      background: linear-gradient(
        180deg,
        rgba(190, 234, 224, 0.35) 0%,
        ${(props) => props.theme.colors.gray2}4a 55.21%
      );
      border-radius: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 20px;

      .icon {
        font-size: 20px;
        font-weight: 500;
      }

      .feature-name {
        font-weight: 500;
        margin: 0px;
      }

      .coming-soon-tag {
        color: ${(props) => props.theme.colors.gray1};
        background-color: ${(props) => props.theme.colors.geekblue4};
        border-radius: 50px;
        font-size: 10px;
        line-height: 20px;
        padding: 0px 6px;
      }
    }
  }

  @media ${device.xxlDown} {
    .title {
      width: 100%;
      top: 32px;
      left: 42px;
      font-size: 18px;
    }

    .swiper {
      width: 310px;
      height: 66px;
      position: absolute;
      top: initial;
      bottom: 12px;
      right: 24px;
    }
  }

  @media ${device.lgDown} {
    display: none;
  }
`
