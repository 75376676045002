import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const fetchGptRuns = async (activityId) => {
  const route = `gpt-runs?content_id=${activityId}`

  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchGptTasks = async () => {
  const route = 'gpt-tasks'

  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postGptRun = async (data) => {
  const { activityIds, taskIds } = data
  const route = 'gpt-runs'
  const body = {
    content_type: 'activity',
    content_ids: activityIds,
    task_ids: taskIds,
  }

  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { fetchGptRuns, fetchGptTasks, postGptRun }
