import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'activities',
  initialState: {
    isLoading: false,
    currentActivity: null,
    isReportIssueModalOpen: false,
    error: null,
  },
  reducers: {
    SET_ACTIVITIES_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    TOGGLE_REPORT_ISSUE_MODAL: (state, { payload }) => {
      state.currentActivity = payload
      state.isReportIssueModalOpen = !state.isReportIssueModalOpen
    },
    SET_ACTIVITIES_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
  },
})

export const { SET_ACTIVITIES_LOADING, TOGGLE_REPORT_ISSUE_MODAL, SET_ACTIVITIES_ERROR } = slice.actions
export default slice.reducer
