import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  padding: 0px 8px;
  margin: 24px 0px 0px;

  .filter-category {
    &:not(:last-child) {
      padding-bottom: 10px;
    }

    .ant-collapse-header {
      padding: 0px;

      .ant-collapse-expand-icon {
        color: ${(props) => props.theme.colors.gray7};
        padding-inline-end: 4px;
        margin-left: -16px;
      }
    }

    .ant-collapse-content {
      background-color: transparent;

      .ant-collapse-content-box {
        padding: 0px;
      }
    }

    .filter-title {
      font-weight: 500;
      margin: 0px;
    }

    .ais-RefinementList {
      margin-bottom: 16px;

      .ais-RefinementList-list {
        text-transform: capitalize;
        list-style: none;
        padding: 0px;
        margin: 6px 0px 0px;

        .ais-RefinementList-label {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          cursor: pointer;

          .ais-RefinementList-checkbox {
          }

          .ais-RefinementList-labelText {
            color: ${(props) => props.theme.colors.gray10};
            line-height: 20px;
            flex-grow: 1;
            margin: 0px 10px;
          }

          .ais-RefinementList-count {
            background-color: ${(props) => props.theme.colors.geekblue1};
            color: ${(props) => props.theme.colors.gray8};
            font-size: 12px;
            font-weight: 400;
            border-radius: 3px;
            padding: 1px 4px;
          }
        }
      }
    }

    .ais-RefinementList-showMore--disabled {
      display: none;
    }
  }

  .toggle-refinement {
    background-color: ${(props) => props.theme.colors.geekblue2};
    border-radius: 10px;
    padding: 10px 16px;
    margin: 18px 0px 0px -15px;

    .ais-ToggleRefinement-label {
      display: flex;
      gap: 8px;
      cursor: pointer;

      .toggle-label {
        color: ${(props) => props.theme.colors.gray10};
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 500;
      }
    }
  }

  @media ${device.mdDown} {
    display: none;
  }
`
