import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import MemberAvatar from '@/components/MemberAvatar'
import HTMLBlock from '@/components/HTMLBlock'
import { Container } from './styles'

const CommentsDrawerItem = () => {
  const { currentPlayground } = useSelector((state) => state.playgrounds)
  const comments = currentPlayground?.user_status?.comments

  const renderComment = (comment) => {
    return (
      <div key={comment.id} className="event-content">
        <div className="event-summary">
          <MemberAvatar
            lastName={comment?.user?.last_name}
            firstName={comment?.user?.first_name}
            showName
            avatarUrl={comment?.user?.avatar_url}
          />

          <span className="text">{dayjs(comment?.created).from()}</span>
        </div>

        <div className="comment-content-container">
          <HTMLBlock content={comment?.content_html} className="comment-content" />
        </div>
      </div>
    )
  }
  return (
    <Container className="comments-drawer-item">
      <div className="header">
        <h5 className="title">Comments</h5>
      </div>

      <div className="content">{comments?.map((comment) => renderComment(comment))}</div>
    </Container>
  )
}

export default CommentsDrawerItem
