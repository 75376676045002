import { HierarchicalMenu } from 'react-instantsearch'
import ClearFilters from '../ClearFilters'
import { Container } from './styles'

const TaskFilter = () => {
  return (
    <Container className="task-filter-container">
      <ClearFilters />

      <div className="filter-container">
        <p className="title">Filter by task</p>

        <HierarchicalMenu
          attributes={['tasks.lvl0', 'tasks.lvl1', 'tasks.lvl2']}
          transformItems={(items) => {
            const isItemRefined = items?.some((item) => item.isRefined)
            if (isItemRefined) return items.filter((item) => item.isRefined)
            return items
          }}
          showParentLevel={false}
        />
      </div>
    </Container>
  )
}

export default TaskFilter
