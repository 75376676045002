import * as React from "react";
const Svg5Full = (props) => /* @__PURE__ */ React.createElement("svg", { id: "badge-5", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 600 600", style: {
  enableBackground: "new 0 0 600 600"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { id: "ribbon", d: "M385.1,509l-79.8-49.9c-4.3-2.7-9.2-2.7-13.4,0L212,509c-7.2,4.5-15.3-3.6-13.6-13.4L217.1,334h163 l18.6,161.5C400.5,505.4,392.3,513.5,385.1,509L385.1,509L385.1,509z", style: {
  fill: "#525776"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { id: "medal-border", d: "M367.1,89.7H232.9c-1.1,0-2.2,0.4-3,1.3L135,185.8c-0.8,0.8-1.3,1.9-1.3,3v134.1 c0,1.1,0.5,2.2,1.3,3l94.9,94.8c0.8,0.8,1.9,1.3,3,1.3h134.2c1.1,0,2.2-0.4,3-1.3l94.8-94.9c0.8-0.8,1.3-1.9,1.3-3v-134 c0-1.1-0.5-2.2-1.3-3l-94.8-94.9C369.4,90.1,368.2,89.7,367.1,89.7L367.1,89.7z", style: {
  fill: "#7F8297"
} })), /* @__PURE__ */ React.createElement("g", { id: "transparent-center" }, /* @__PURE__ */ React.createElement("polygon", { points: "300.5,257.5 447.6,318.4 448.6,317.4 448.6,195.7 300.3,257.1  ", style: {
  fill: "#989898"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "300,257.3 300.5,257.5 300.3,257.1  ", style: {
  fill: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "361.4,404.5 361.6,404.5 447.6,318.4 300.5,257.5  ", style: {
  fill: "#B2B2B2"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "299.7,257.4 300,257.3 299.8,257.2  ", style: {
  fill: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "300,256.6 299.8,257.2 300,257.3 300.3,257.1  ", style: {
  fill: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "361.8,107.6 361.6,107.4 238.5,107.4 238.3,107.6 300,256.6  ", style: {
  fill: "#989898"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "152.5,318.4 238.5,404.5 238.8,404.5 299.7,257.4  ", style: {
  fill: "#808080"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "300.3,257.1 448.6,195.7 448.6,194.4 361.8,107.6 300,256.6  ", style: {
  fill: "#CBCBCB"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "300.5,257.5 300,257.3 299.7,257.4 238.8,404.5 361.4,404.5  ", style: {
  fill: "#989898"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "299.8,257.2 151.4,195.8 151.4,317.4 152.5,318.4 299.7,257.4  ", style: {
  fill: "#989898"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "300,256.6 238.3,107.6 151.4,194.4 151.4,195.8 299.8,257.2  ", style: {
  fill: "#B2B2B2"
} })), /* @__PURE__ */ React.createElement("path", { id: "internal-jewel", d: "M331.2,176.5h-63.6c-1.1,0-2.2,0.5-3,1.3l-44.9,44.9c-0.8,0.8-1.3,1.9-1.3,3v63.6 c0,1.1,0.4,2.2,1.3,3l44.9,45c0.8,0.8,1.9,1.3,3,1.3h63.6c1.1,0,2.2-0.4,3-1.3l45-45c0.8-0.8,1.3-1.9,1.3-3v-63.6 c0-1.1-0.4-2.2-1.3-3l-45-44.9C333.4,176.9,332.3,176.5,331.2,176.5L331.2,176.5z", style: {
  opacity: 0.8,
  fill: "#4A4D69",
  enableBackground: "new"
} }), /* @__PURE__ */ React.createElement("path", { id: "spark", d: "M372,214.8l2.9-5.4l2.9,5.4c2.5,4.6,6.2,8.3,10.8,10.8l5.4,2.9l-5.4,2.9c-4.5,2.5-8.3,6.2-10.8,10.8 l-2.9,5.4l-2.9-5.4c-2.5-4.6-6.2-8.3-10.8-10.8l-5.4-2.9l5.4-2.9C365.7,223,369.4,219.3,372,214.8z", style: {
  fill: "#FFFFFF"
} }));
export default Svg5Full;
