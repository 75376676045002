import { Tooltip } from 'antd'
import { capitalize } from '@/utils/functions'
import activityReactions from '@/constants/activityReactions'
import { Container } from './styles'

const ActivityReactions = ({ activity }) => {
  const reactions = activity?.user_status?.reactions

  if (!reactions?.length) return null

  return (
    <Container className="activity-reactions">
      <div className="reactions-container">
        {reactions?.map((reaction) => {
          const reactionData = activityReactions.find((r) => r.name === reaction.reaction)

          return (
            <Tooltip
              key={reaction.user.username}
              title={`${capitalize(reaction.user.first_name)} ${capitalize(reaction.user.last_name)} reacted`}
            >
              <img className="reaction-emoji" src={reactionData?.emoji} alt={reactionData?.name} />
            </Tooltip>
          )
        })}
      </div>
    </Container>
  )
}

export default ActivityReactions
