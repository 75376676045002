import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  margin-bottom: 26px;

  .fade-in-mask {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .section-title {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .view-all-button {
    }
  }

  .playgrounds-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .loading-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .ant-skeleton {
        margin: 0px;

        .ant-skeleton-paragraph {
          margin: 0px;

          li {
            height: 168px;
            width: 100% !important;
          }
        }
      }
    }
  }

  @media ${device.mdDown} {
    .header {
      .view-all-button {
        display: none;
      }
    }
  }
`
