const getEmailRules = () => ({
  required: { value: true, message: 'Email is required' },
  validate: {
    isValid: (value) => RegExp(`^([\\w+_.-]+)+@([\\w-]+\\.)+\\w{2,4}$`).test(value) || 'Enter a valid e-mail address',
  },
})

// const getPasswordRules = () => ({
//   required: { value: true, message: 'Password is required' },
//   minLength: { value: 8, message: 'Password must have at least 8 characters' },
//   maxLength: { value: 70, message: 'Password must have at most 70 character' },
//   validate: {
//     hasLower: value => RegExp('(?=.*[a-z])').test(value) || 'Password should contain at least one lower case letter',
//     hasUpper: value => RegExp('(?=.*[A-Z])').test(value) || 'Password should contain at least one upper case letter',
//     hasNumbers: value => RegExp('(?=.*[0-9])').test(value) || 'Password should contain at least one numeric value',
//     // Cognito allowed special characters: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
//     hasSpecialChars: value =>
//       RegExp(`(?=.*[\\^$*.[\\]{}()?"!@#%&/,><':;|_~\\\`])`).test(value) ||
//       'Password should contain at least one special character',
//     whiteSpaces: value => RegExp(`^\\S*$`).test(value) || 'Whitespaces are not allowed',
//   },
// })

export { getEmailRules }
