import styled from 'styled-components'

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 38px;

  .warning-icon {
    color: ${(props) => props.theme.colors.gold5};
    font-size: 80px;
    margin-bottom: 24px;
  }

  .danger-icon {
    color: ${(props) => props.theme.colors.red5};
    font-size: 80px;
    margin-bottom: 24px;
  }

  .title {
    font-weight: 400;
    margin-bottom: 6px;
  }

  .content {
    color: ${(props) => props.theme.colors.gray7};
    line-height: 22px;
  }

  .actions {
    margin-top: 24px;
  }
`
