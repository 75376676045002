import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.colors.gray4};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: initial;

  ${(props) =>
    props.$disableOverflow &&
    css`
      height: 100%;
      overflow: hidden;
      flex: 1;
    `}

  .fade-in-mask {
    width: 100%;
    height: 100%;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  ${(props) =>
    props.$isBanner &&
    css`
      background-color: ${localStorage.getItem('dw-theme') === 'dark'
        ? props.theme.colors.geekblue2
        : props.theme.colors.gray1};

      .fade-in-mask {
        justify-content: flex-start;
      }
    `}

  .timer-floating-container {
    position: absolute;
    top: 24px;
    right: 32px;
    z-index: 2;
  }

  .module-preview-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: auto;

    .info-box {
      width: 100%;
      position: absolute;
      top: 34%;
      left: 0px;
      display: flex;
      justify-content: center;

      > .content {
        max-width: 278px;
        display: flex;
        flex-direction: column;
        align-items: center;

        > .title {
          color: ${(props) => props.theme.colors.gray10};
          font-weight: 700;
          text-align: center;
          margin: 0px;
        }

        > .text {
          max-width: 260px;
          color: ${(props) => props.theme.colors.gray7};
          text-align: center;
          margin: 0px;
        }

        > button {
          margin-top: 16px;
        }
      }
    }

    .pattern {
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        ${(props) => props.theme.colors.gray1}00 0%,
        ${(props) => props.theme.colors.gray1} 36%,
        ${(props) => props.theme.colors.gray1} 42%,
        ${(props) => props.theme.colors.gray1}00 100%
      );
      position: absolute;
    }

    .module-preview {
      max-width: 840px;
      width: calc(100% - 40px);
      flex: 1;
      padding: 16px 32px;
      margin: 20px;

      > .title {
        color: ${(props) => props.theme.colors.gray10};
        margin-top: 20px;
      }

      .description {
        color: ${(props) => props.theme.colors.gray8};
      }

      &.loading-content {
        filter: blur(0px);
      }
    }
  }

  /* ${(props) =>
    !props.$canResumeLab &&
    !props.$isAttemptFinished &&
    css`
      .module-preview-container {
        overflow: initial;

        .module-preview {
          filter: blur(2px);
        }
      }
    `} */
`
