import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isDataWarsHostName } from '@/helpers/env'
import GlobalLoader from '@/components/GlobalLoader'
import UpgradeToProSuccessModal from '@/components/UpgradeToProSuccessModal'
import { setAppError, setAppNotification } from '@/store/app/actions'
import { fetchAccountMe } from '@/store/accounts/actions'
import { tryAutoLogin, getUserStats } from '@/store/users/actions'
import Router from '@/router'
import Theme from '@/themes/base'
import { initSentry } from '@/utils/sentry'
import { initializeDayjs } from '@/utils/dayjs'

initializeDayjs()

const App = () => {
  const dispatch = useDispatch()
  const [isPageLoading, setPageLoading] = useState(true)

  const { appNotification, appError } = useSelector((state) => state.app)
  const { uiConfig, currentAccount } = useSelector((state) => state.accounts)
  const { isAuthenticated } = useSelector((state) => state.users)

  const onLoad = useCallback(async () => {
    if (!isDataWarsHostName) {
      await dispatch(fetchAccountMe())
    }

    await dispatch(tryAutoLogin())
    setPageLoading(false)
  }, [])

  if (appError) {
    // toastError(appError)
    dispatch(setAppError(null))
  }

  if (appNotification) {
    // toastNotification(appNotification)
    dispatch(setAppNotification(null))
  }

  useEffect(() => {
    if (isAuthenticated === null) {
      onLoad()
      return
    }

    if (isAuthenticated && isDataWarsHostName) {
      dispatch(getUserStats())
    }
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDataWarsHostName) {
      initSentry()
    }
  }, [isDataWarsHostName])

  useEffect(() => {
    const title = (isDataWarsHostName ? 'DataWars' : currentAccount?.name) || 'Practice projects'
    const fullTitle = `${title} - Become an expert Data Scientist`
    const image = isDataWarsHostName
      ? 'https://static.prd.datawars.io/static/og-images/og-image-general.png'
      : 'https://static.prd.datawars.io/static/og-images/og-image-default.png'
    const url = isDataWarsHostName ? 'https://app.datawars.app' : ''

    document.title = title
    document.querySelector("meta[property='og:title'").setAttribute('content', fullTitle)
    document.querySelector("meta[property='og:image'").setAttribute('content', image)
    document.querySelector("meta[property='twitter:title'").setAttribute('content', fullTitle)
    document.querySelector("meta[property='twitter:image'").setAttribute('content', image)
    document.querySelector("meta[property='og:url'").setAttribute('content', url)
  }, [uiConfig])

  return (
    <Theme>
      {isPageLoading ? <GlobalLoader /> : <Router />}

      <UpgradeToProSuccessModal />
    </Theme>
  )
}

export default App
