import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  max-height: calc(100% - 42px);
  height: 100%;
  width: 100%;
  display: flex;

  .main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .search-box {
      width: 100%;
      background-color: ${(props) => props.theme.colors.gray1};
      border: 1px solid ${(props) => props.theme.colors.base.gray5};
      border-radius: 2px;
      position: relative;
      display: flex;
      align-items: center;
      margin: 0px 0px 26px;

      svg {
        color: ${(props) => props.theme.colors.gray13}40;
        font-size: 16px;
        position: absolute;
        top: 6px;
        left: 12px;
      }

      .ais-SearchBox {
        width: 100%;

        input {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          border: 0px;
          outline: 0px;
          padding: 6px 24px 6px 38px;
        }

        .ais-SearchBox-loadingIndicator {
          width: 40px;
          height: 100%;
          position: absolute;
        }

        .ais-SearchBox-submit,
        .ais-SearchBox-reset {
          display: none;
        }
      }
    }

    .results {
      height: 100%;
      width: 100%;
      background-color: ${(props) => props.theme.colors.gray1};
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px;

      .ais-Breadcrumb {
        .ais-Breadcrumb-list {
          list-style: none;
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 0px;
          margin-top: 0px;

          .ais-Breadcrumb-item {
            display: flex;
            align-items: center;
            gap: 4px;

            .ais-Breadcrumb-separator {
              font-size: 11px;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  > .filters-container {
    min-width: 242px;
    overflow: auto;
    padding-left: 8px;
    margin: 58px 0px 0px 28px;
  }

  @media ${device.mdDown} {
    .main-content {
      .search-box {
        margin: 0px 0px 12px;
      }

      .results {
        padding: 0px;

        .ais-Breadcrumb {
          display: none;
        }

        .results-container {
          gap: 0px;

          .datasource-card-container {
            border-radius: 0px;
            border: none;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray4};

            &:last-child {
              border: none;
            }
          }
        }
      }
    }

    > .filters-container {
      display: none;
    }
  }
`
