import styled from 'styled-components'

export const Container = styled.div`
  min-width: 240px;

  .ant-steps {
    .ant-steps-item {
      .ant-steps-item-title {
        font-size: 12px;
        line-height: 18px !important;
      }

      .ant-steps-item-description {
        font-size: 11px;
      }

      &.ant-steps-item-finish {
        .ant-steps-item-icon {
          background-color: transparent;
          border-color: ${(props) => props.theme.colors.geekblue4};
        }
      }

      &.ant-steps-item-wait {
        .ant-steps-item-icon {
          background-color: transparent;
          border-color: ${(props) => props.theme.colors.gray6};

          .ant-steps-icon {
            color: ${(props) => props.theme.colors.gray6};
          }
        }
      }
    }
  }
`
