import styled from 'styled-components'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    .ant-modal-body {
      overflow: hidden;

      > .content {
        background-color: ${(props) => props.theme.colors.base.gray1};
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 13px;
        padding: 10px;

        > .header-container {
          display: flex;
          align-items: center;

          .image {
            width: 192px;
          }

          .title-container {
            width: 100%;
            display: flex;
            flex-direction: column;
          }

          .title {
            color: ${(props) => props.theme.colors.base.gray13};
            margin: 0px 0px 10px;

            .pro {
              background: linear-gradient(45deg, #222249 24.02%, #5479f7 44.81%, #89d2c2 63.94%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-weight: 800;
            }
          }
        }

        .info-text {
          color: ${(props) => props.theme.colors.base.gray8};
          margin-bottom: 20px;
        }

        .running-playgrounds {
          width: 100%;
          margin-bottom: 20px;

          .info-text {
            margin-bottom: 8px;
          }
        }

        .footer {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .ant-modal-close {
    height: 32px;
    width: 32px;

    .ant-modal-close-x {
      svg {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 18px;
      }
    }
  }

  @media ${device.mdDown} {
    .ant-modal-content {
      .ant-modal-body {
        > .content {
          padding: 32px 0px;
          margin: 36px;

          > .header-container {
            max-width: initial;
            padding: 0px 20px;

            .image {
              max-width: 142px;
              margin-left: 37px;
              margin-bottom: -26px;
            }

            .title {
              font-size: 28px;
              margin-bottom: 14px;
            }
          }
        }
      }
    }
  }
`
