import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Tag, Skeleton } from 'antd'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import Button from '@/components/Button'
import ProTooltip from '@/components/ProTooltip'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Container } from './styles'

const VerticalModuleCard = ({ module, isLoading }) => {
  const { userProfile } = useSelector((state) => state.users)
  const canViewEarlyAccess = userProfile?.permissions?.includes('content.module_early_access')

  return (
    <Container className="vertical-module-card">
      <div className="img-container">
        {isLoading ? <Skeleton.Node className="loading-image" active /> : renderModuleImage(module)}
      </div>

      {!isLoading && module?.early_access && (
        <ProTooltip
          isLarge
          title={!canViewEarlyAccess && 'Exclusive Early Access'}
          content={
            <>
              <p className="text">
                This project will become available to Basic users on{' '}
                <b>{dayjs(module?.general_available_at).format('MMMM Do')}</b>.
              </p>
              <p className="text">
                <b>Upgrade to PRO</b> to access this project before anybody else!
              </p>
            </>
          }
          showUpgradeButton
        >
          <div className="early-access-badge">
            <img className="pro-badge" src={ProBadge} alt="PRO user" />
            <span>Early Access</span>
          </div>
        </ProTooltip>
      )}

      <div className="module-info">
        {isLoading ? (
          <Skeleton className="loading-title" active />
        ) : (
          <>
            <div className="info-box">
              <Tag className={`tag difficulty-${module?.difficulty}`}>{module?.difficulty}</Tag>
              {module?.learning_area && <span className="text">{module?.learning_area?.name}</span>}
            </div>

            <p className="title">{module?.name}</p>
          </>
        )}

        {!isLoading && (
          <Link className="link" to={`/project/${module?.id}`}>
            <Button className="main-btn" type="primary">
              {module?.early_access && !canViewEarlyAccess ? 'Preview' : 'Start'} project
            </Button>
          </Link>
        )}
      </div>
    </Container>
  )
}

export default VerticalModuleCard
