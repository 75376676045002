import { useSelector, useDispatch } from 'react-redux'
import Button from '@/components/Button'
import RateModule from '../RateModule'
import { Modal } from './styles'
import { setRatingModalOpen } from '@/store/modules/actions'

const ModuleRatingModal = () => {
  const dispatch = useDispatch()

  const { currentModule, isRatingModalOpen } = useSelector((state) => state.modules)
  const moduleRating = currentModule?.user_status?.module_rating

  return (
    <Modal
      className="module-rating-modal"
      open={isRatingModalOpen}
      onCancel={() => dispatch(setRatingModalOpen(false))}
      footer={null}
    >
      <RateModule />

      {moduleRating?.rating && moduleRating?.comments && (
        <Button className="finish-button" type="secondary" onClick={() => dispatch(setRatingModalOpen(false))}>
          Continue
        </Button>
      )}
    </Modal>
  )
}

export default ModuleRatingModal
