import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  min-height: 92px;
  width: 100%;
  color: ${(props) => props.theme.colors.gray1};
  background: linear-gradient(71deg, ${(props) => props.theme.colors.gray10} 28.53%, rgba(59, 124, 249, 0.93) 130%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  border-radius: 10px;
  padding: 20px 40px;
  transition: 0.6s all;

  &.is-dismissed {
    overflow: hidden;
    min-height: 0px;
    height: 0px;
    opacity: 0;
    transform: translateY(-92px);
    margin-top: -36px;
  }

  .main {
    display: flex;
    align-items: center;
    gap: 24px;

    .ppp-block {
      max-width: 640px;
      line-height: 22px;

      .parity-banner-logo {
        display: none;
      }
    }

    .logo-image {
      width: 72px;
    }

    .text-container {
      max-width: 580px;
      flex: 1;

      .title {
        color: ${(props) => props.theme.colors.gray1};
        margin: 0px;
      }

      .text {
        color: ${(props) => props.theme.colors.gray1};
        margin: 0px;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;

    .pro-button {
      height: 42px;
      display: flex;
      gap: 8px;
      align-items: center;

      .pro-badge {
        width: 20px;
      }
    }

    .close-btn {
      height: 32px;
      width: 32px;
      color: ${(props) => props.theme.colors.base.gray1};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;

      .icon {
        svg {
          font-size: 22px;
        }
      }

      &:hover {
        color: ${(props) => props.theme.colors.base.gray5};
      }
    }
  }

  @media ${device.mdDown} {
    flex-direction: column;
    gap: 16px;

    .main {
      flex-direction: column;
      gap: 8px;

      .logo-image {
        width: 62px;
      }

      .text-container {
        .title,
        .text {
          text-align: center;
        }
      }
    }

    .actions {
      .close-btn {
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }
  }
`
