import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;

  .header {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: inline-flex;
    justify-content: center;
    box-shadow: 0px 17px 14px rgba(0, 0, 0, 0.04);

    .header-content {
      max-width: 1440px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      padding: 50px 70px 0px;

      .title {
        width: 100%;
        border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
        padding-bottom: 38px;
        margin-bottom: 0px;
      }

      .proficiencies-header {
        width: calc(100% - 76px);
        background-color: ${(props) => props.theme.colors.gray1};
        display: flex;
        justify-content: space-between;
        padding: 20px 0px;

        .proficiency-item {
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 10px;
          padding: 12px 0;
          transition: 0.3s all;

          > p {
            color: ${(props) => props.theme.colors.gray7};
            font-weight: 700;
            margin: 0px;
          }

          > img {
            width: 20px;
          }
        }
      }
    }
  }

  .learning-areas-container {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray2};
    display: flex;
    justify-content: center;

    .learning-areas-content {
      max-width: 1440px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      padding: 40px 70px;

      > div:last-of-type {
        .skill-tracks-container {
          border-bottom: none;
        }
      }
    }
  }

  @media ${device.lgDown} {
    .header {
      .header-content {
        padding: 30px 30px 0px;

        .title {
          border-bottom: none;
          padding-bottom: 12px;
        }

        .proficiencies-header {
          display: none;
        }
      }
    }

    .learning-areas-container {
      .learning-areas-content {
        padding: 10px 30px 30px;
      }
    }
  }

  @media ${device.mdDown} {
    .header-content {
      > .title {
        font-size: 24px;
        line-height: 34px;
        text-align: center;
      }
    }

    .learning-areas-container {
      .learning-areas-content {
        padding: 10px 20px;
      }
    }
  }
`

export const LearningAreaContainer = styled.div`
  display: flex;
  gap: 30px;

  .info-container {
    width: 46px;
    min-height: 300px;
    background-color: ${(props) => props.color};
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 8px 12px;
    margin: 8px 0px;

    .title {
      color: ${(props) => props.theme.colors.base.gray13};
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      margin: 0px;
      z-index: 1;
    }
  }

  .skill-tracks-container {
    width: 100%;
    background: linear-gradient(90deg, ${(props) => props.theme.colors.geekblue2} 95%, transparent);
    border-bottom: 1px solid ${(props) => props.theme.colors.geekblue2};
    display: flex;
    gap: 1px;

    .proficiency-container {
      background-color: ${(props) => props.theme.colors.gray2};
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 10px;
    }
  }

  @media ${device.lgDown} {
    flex-direction: column;
    gap: 0px;

    .info-container {
      width: initial;
      min-height: auto;

      .title {
        writing-mode: initial;
        transform: rotate(0);
      }
    }
  }

  @media ${device.mdDown} {
    margin-bottom: 10px;

    .skill-tracks-container {
      background: transparent;
      border-bottom: 0px;
      flex-direction: column;
      gap: 0px;

      .proficiency-container {
        padding: 0px;

        .title-container {
          > .title {
            font-size: 13px;
          }
        }

        > div:last-child {
          margin-bottom: 6px;
        }

        .progress-container {
          max-width: 100%;
        }
      }
    }
  }
`
