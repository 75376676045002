import { useDispatch } from 'react-redux'
import { toggleFeedbackModal } from '@/store/playgrounds/actions'
import { Container } from './styles'

const PlaygroundsFeedbackTag = () => {
  const dispatch = useDispatch()

  return (
    <Container className="playgrounds-feedback-tag" onClick={() => dispatch(toggleFeedbackModal(true))}>
      <span>Feedback</span>
    </Container>
  )
}

export default PlaygroundsFeedbackTag
