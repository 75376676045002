import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'antd'
import { isDataWarsHostName } from '@/helpers/env'
import useQueryParam from '@/hooks/useQueryParam'
import OauthBox from '@/components/auth/OauthBox'
import Button from '@/components/Button'
import PasswordInput from '@/components/PasswordInput'
import { getUtmTagsFromUrl } from '@/helpers/getUtmTagsFromUrl'
import { getEmailRules } from '@/helpers/formValidation'
import { signup } from '@/store/users/actions'
import { MailTwoTone, SmileTwoTone, LockTwoTone } from '@ant-design/icons'
import { LogoHeader, Form } from './styles'

const SignUpForm = ({ toggleMode, callback }) => {
  const dispatch = useDispatch()

  const [defaultEmail] = useQueryParam('email')

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [passwordError, setPasswordError] = useState('')

  const { isAuthModalOpen } = useSelector((state) => state.users)

  const onSubmit = async (data) => {
    const auxData = {
      ...data,
      metadata: getUtmTagsFromUrl(),
    }

    setIsLoading(true)
    await dispatch(signup(auxData, callback))
    setIsLoading(false)
  }

  const validateWhiteSpace = (value) => {
    if (value.trim() === '') {
      return 'Input cannot be only blank spaces'
    }
    return true
  }

  useEffect(() => {
    if (!defaultEmail) return

    setValue('email', defaultEmail, { shouldValidate: true, shouldDirty: true })
  }, [defaultEmail]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit(onSubmit)} autoComplete="off">
      <LogoHeader>
        <h3>Create your free account</h3>
      </LogoHeader>

      {isDataWarsHostName && (
        <OauthBox action="signup" text="Sign up with Google" isLoading={isLoading} setIsLoading={setIsLoading} />
      )}

      <div className="form-row">
        <Controller
          name="first_name"
          defaultValue=""
          control={control}
          rules={{ required: 'First name is required', minLength: 2, validate: validateWhiteSpace }}
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              size="large"
              status={errors.first_name ? 'error' : null}
              placeholder="First name"
              prefix={<SmileTwoTone />}
              rules={[{}]}
            />
          )}
        />

        <Controller
          name="last_name"
          defaultValue=""
          control={control}
          rules={{ required: 'Last name is required', minLength: 2, validate: validateWhiteSpace }}
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              size="large"
              status={errors.last_name ? 'error' : null}
              placeholder="Last name"
              prefix={<SmileTwoTone />}
            />
          )}
        />
      </div>

      <Controller
        name="email"
        defaultValue=""
        control={control}
        rules={getEmailRules()}
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            size="large"
            prefix={<MailTwoTone />}
            status={errors.email ? 'error' : null}
            placeholder="Email address"
            disabled={defaultEmail}
          />
        )}
      />

      <div className="form-row">
        <Controller
          name="password"
          defaultValue=""
          control={control}
          rules={{
            required: 'Password is required',
          }}
          render={({ field }) => {
            const auxProps = { ...field }
            delete auxProps.ref

            return (
              <PasswordInput
                {...auxProps}
                type="password"
                size="large"
                status={errors.password ? 'error' : null}
                placeholder="Password"
                prefix={<LockTwoTone />}
                handleChange={setPasswordError}
              />
            )
          }}
        />

        <Controller
          name="repeat_password"
          defaultValue=""
          control={control}
          rules={{
            required: 'Re-enter your password',
            validate: (value) => value === watch('password') || "Passwords don't match",
          }}
          render={({ field }) => (
            <Input.Password
              {...field}
              type="password"
              size="large"
              status={errors.repeat_password ? 'error' : null}
              placeholder="Repeat password"
              prefix={<LockTwoTone />}
            />
          )}
        />
      </div>

      <div className="error-container">
        {errors && <p className="error-text">{errors[Object.keys(errors)?.[0]]?.message}</p>}
        {!Object.keys(errors)?.length && passwordError && <p className="error-text">{passwordError}</p>}
      </div>

      <Button type="primary" htmlType="submit" disabled={passwordError} loading={isLoading}>
        Register
      </Button>

      {isAuthModalOpen ? (
        <p className="bottom-text">
          Already have an account?{' '}
          <a className="link" onClick={() => toggleMode('login')}>
            Login
          </a>
        </p>
      ) : (
        <p className="bottom-text">
          Already have an account?{' '}
          <Link className="link" to={`/login${window?.location?.search}`}>
            Login
          </Link>
        </p>
      )}

      <p className="bottom-text light">
        <span>
          By continuing, you accept our{' '}
          <a className="link" href="https://www.datawars.io/terms-of-service" target="_blank">
            Terms of Service
          </a>{' '}
          and{' '}
          <a className="link" href="https://www.datawars.io/privacy-policy" target="_blank">
            Privacy Policy
          </a>
          .
        </span>
      </p>
    </Form>
  )
}

export default SignUpForm
