import mermaid from 'mermaid'

// https://github.com/mermaid-js/mermaid/blob/develop/packages/mermaid/src/defaultConfig.ts
const config = {
  theme: 'default',
}

mermaid.initialize(config)

export const initMermaid = () => {
  mermaid.contentLoaded()
}
