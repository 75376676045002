import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Skeleton } from 'antd'
import { DataPie24Regular } from '@fluentui/react-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import DataSourceCard from '../DataSourceCard'
import { getFeaturedDataSources } from '@/store/dataSources/actions'
import { Container } from './styles'

const FeaturedDataSourcesSection = ({ title, subtitle, headerDirection }) => {
  const dispatch = useDispatch()

  const { featuredDataSources, isLoading } = useSelector((state) => state.dataSources)

  const handleLoad = async () => {
    if (featuredDataSources === null) {
      dispatch(getFeaturedDataSources())
    }
  }

  useEffect(() => {
    handleLoad()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="featured-data-sources-section" $headerDirection={headerDirection}>
      <div className="header">
        <h5 className="section-title">
          <DataPie24Regular />
          {title}

          {subtitle && <p className="subtitle">{subtitle}</p>}
        </h5>

        {!isLoading && !!featuredDataSources?.length && (
          <p className="view-all-button">
            <Link to={'/playgrounds?tab=datasets'}>View all</Link>
          </p>
        )}
      </div>

      {(isLoading || featuredDataSources === null) && (
        <div className="loading-container">
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        </div>
      )}

      {!isLoading && featuredDataSources && !featuredDataSources?.length && <p>There are no featured datasets yet.</p>}

      {!isLoading && (
        <Swiper
          className="white datasource-cards-container"
          spaceBetween={16}
          modules={[Navigation]}
          navigation
          slidesPerView={'auto'}
        >
          {featuredDataSources?.map((d) => (
            <SwiperSlide key={d?.id}>
              <DataSourceCard dataSource={d} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Container>
  )
}

export default FeaturedDataSourcesSection
