import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { hotjar } from 'react-hotjar'

const hotjarId = import.meta.env.REACT_APP_HOTJAR_HJID
const hotjarSv = import.meta.env.REACT_APP_HOTJAR_HJSV

export const Hotjar = () => {
  const { isAuthenticated, userProfile } = useSelector((state) => state.users)
  const isValidUser = isAuthenticated && !userProfile?.is_staff && !userProfile?.email?.includes('@datawars.io')

  useEffect(() => {
    if (hotjarId && hotjarSv && isValidUser) {
      hotjar?.initialize(hotjarId, hotjarSv)

      if (hotjar?.initialized()) {
        hotjar?.identify(userProfile?.id, userProfile)
      }
    }
  }, [userProfile]) // eslint-disable-line react-hooks/exhaustive-deps

  return <></>
}
