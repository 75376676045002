import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import Button from '@/components/Button'
import { getModule, stopModuleAttempt } from '@/store/modules/actions'
import { pingLab, setLastLabActionDate, setInactivityModalOpen, deallocateLab } from '@/store/labs/actions'
import Timer from './components/Timer'
import { Modal } from './styles'

const InactivityModal = ({ callback }) => {
  const dispatch = useDispatch()

  const { currentAccount } = useSelector((state) => state.accounts)
  const { userProfile } = useSelector((state) => state.users)
  const { currentModule, isModuleTimerModalOpen } = useSelector((state) => state.modules)
  const { currentLab, lastLabActionDate, isInactivityModalOpen } = useSelector((state) => state.labs)

  const isPro = userProfile?.groups?.includes('Pro')
  const enableInactivityModal = currentAccount?.config?.browse?.lab_inactivity_modal !== false

  const labPingIntervalInSeconds = userProfile?.config?.lab_ping_interval_in_seconds || 5
  const labInactivityModalInSeconds = userProfile?.config?.lab_inactivity_modal_in_seconds
  const labInactivityDeallocationInSeconds = userProfile?.config?.lab_inactivity_deallocation_in_seconds
  const moduleTimeOutModalInMinutes = (userProfile?.config?.module_time_out_modal_in_seconds || 2) / 60

  const moduleAttempt = currentModule?.user_status?.last_module_attempt
  const isAttemptActive = moduleAttempt?.is_active

  const pendingSeconds = moduleAttempt?.remaining_time_in_secs
  const min = Math.floor(pendingSeconds / 60)
  const shouldOpenTimerModal =
    !isPro && !!currentModule?.expiration_time && isAttemptActive && min < moduleTimeOutModalInMinutes

  const labSession = currentLab?.allocated_session
  const isPlayground = currentLab?.lab_type === 'playground'

  const [isLoading, setIsLoading] = useState(false)

  const requestMoreTime = () => {
    dispatch(setLastLabActionDate(dayjs().toISOString()))
    dispatch(setInactivityModalOpen(false))
  }

  const checkInactivity = () => {
    if (labSession?.status !== 'allocated') return

    // lastLabActionDate will be updated on:
    // - module page change
    // - activity submission
    // - hint/solution request
    // - more time is request on inactivity modal
    // - window focus is recovered

    const lastLabActionSecondsAgo = dayjs().diff(lastLabActionDate, 'seconds') || 0

    if ((!isPro || (isPro && !isPlayground)) && lastLabActionSecondsAgo >= labInactivityModalInSeconds) {
      dispatch(setInactivityModalOpen(true))
    }
  }

  const handleLabAutoStop = async () => {
    if (!enableInactivityModal) return

    setIsLoading(true)

    if (isPlayground) {
      const data = {
        labId: currentLab?.id,
        labSessionId: currentLab?.allocated_session?.id,
        deallocateReason: 'inactivity',
      }

      await dispatch(deallocateLab(data))
    }

    if (!isPlayground && isAttemptActive) {
      dispatch(stopModuleAttempt(currentModule?.id, moduleAttempt?.id, { reason: 'inactivity' }))
    }

    if (callback) {
      callback()
    }

    dispatch(setInactivityModalOpen(false))
    setIsLoading(false)
  }

  const onFocus = () => {
    if (labSession?.status !== 'allocated') return

    dispatch(setLastLabActionDate(dayjs().toISOString()))
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)

    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [])

  useEffect(() => {
    let pingLabInterval
    let reFetchInterval

    // TODO: check if all devices are started too?
    if (labSession?.status === 'allocated') {
      pingLabInterval = setInterval(() => {
        if (!lastLabActionDate) dispatch(setLastLabActionDate(dayjs().toISOString()))

        if (!isInactivityModalOpen) checkInactivity()

        const hasFocus = document?.hasFocus()

        dispatch(pingLab(currentLab?.id, labSession?.id, { focus: hasFocus, active: !isInactivityModalOpen }))
      }, labPingIntervalInSeconds * 1000)

      reFetchInterval = setInterval(() => {
        const lastLabActionSecondsAgo = dayjs().diff(lastLabActionDate, 'seconds') || 0

        if (lastLabActionSecondsAgo > 10 && document.hidden && !isPlayground && currentModule?.expiration_time) {
          dispatch(getModule(currentModule?.id))
        }
      }, 10 * 1000)
    }

    return () => {
      if (!labSession) return

      clearInterval(reFetchInterval)
      clearInterval(pingLabInterval)
    }
  }, [labSession?.status, lastLabActionDate, isInactivityModalOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!enableInactivityModal) return null

  return (
    <Modal
      open={isInactivityModalOpen && !isModuleTimerModalOpen && !shouldOpenTimerModal}
      destroyOnClose={true}
      keyboard={false}
      maskClosable={false}
      closeIcon={null}
      footer={null}
      className="inactivity-modal"
    >
      <div className="content">
        <h1 className="timer">
          {isInactivityModalOpen && !isModuleTimerModalOpen && !shouldOpenTimerModal && (
            <Timer
              seconds={labInactivityDeallocationInSeconds - labInactivityModalInSeconds}
              onFinish={handleLabAutoStop}
            />
          )}
        </h1>

        <h3 className="title">Are you still there?</h3>

        <p className="subtitle">
          We have noticed sustained inactivity time. This {currentModule ? 'lab' : 'playground'} will automatically stop
          to preserve resources.
        </p>

        <Button
          className="cta-continue-working"
          size="large"
          type="primary"
          onClick={requestMoreTime}
          loading={isLoading}
        >
          Continue working
        </Button>
      </div>
    </Modal>
  )
}

export default InactivityModal
