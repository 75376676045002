import styled from 'styled-components'
import { Form as AntdForm } from 'antd'
import { device } from '@/themes/breakpoints'

export const LogoHeader = styled.div`
  display: inline-block;
  text-align: center;

  .title {
    color: #00000073;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`

export const Form = styled(AntdForm)`
  width: 100%;
  max-width: 420px;
  text-align: center;
  margin: 0 auto;

  .form-row {
    display: flex;
    gap: 24px;
  }

  .ant-input-affix-wrapper {
    margin: 14px 0px;

    .ant-input-prefix {
      margin-right: 8px;

      svg {
        color: ${(props) => props.theme.colors.gray13};
      }
    }
  }

  .ant-btn {
    width: 100%;
    height: 42px;
    margin: 8px 0 18px;
  }

  .error-container {
    max-width: 360px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 0 auto;

    > p {
      text-align: center;
    }

    .error-text {
      color: ${(props) => props.theme.colors.red4};
      font-size: 13px;
      white-space: pre;
      margin: 10px 0px 0px;
    }
  }

  .bottom-text {
    color: ${(props) => props.theme.colors.gray13};
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    margin: -12px 0px 20px;

    > a {
      color: ${(props) => props.theme.colors.gray13};

      &:hover  {
        text-decoration: underline;
      }
    }
  }

  @media ${device.lgDown} {
    .form-row {
      display: block !important;
    }
  }
`
