import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const MainContent = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray1};

  .assessment-landing {
    > .content {
      .main-container {
        width: 100%;
        justify-content: flex-start;
        gap: 16px;

        .assessment-info {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .pre-title {
            color: ${(props) => props.theme.colors.base.gray6};
            margin: 0px;
          }

          .title {
            color: ${(props) => props.theme.colors.base.gray1};
            font-weight: 700;
            margin: 0px;
          }

          .description {
            max-height: 150px;
            color: ${(props) => props.theme.colors.base.gray1};
            font-weight: 400;
            overflow: auto;
            margin: 0px;
          }
        }

        .objectives-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .objective-item {
            border-radius: 4px;
            background: radial-gradient(
                217.52% 153.59% at 122.4% 118.47%,
                rgba(179, 197, 255, 0.5) 47.5%,
                rgba(152, 143, 255, 0.28) 73%,
                rgba(255, 255, 255, 0.5) 94.27%
              ),
              #f0f2f5;
            color: ${(props) => props.theme.colors.base.gray10};
            font-size: 11px;
            font-weight: 400;
            letter-spacing: 1.76px;
            text-transform: uppercase;
            padding: 8px 22px;
            margin: 0px;
          }
        }

        .actions {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;

          .start-button {
          }
        }
      }

      .info-container {
        min-width: 264px;
        max-width: 264px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .item {
          width: 100%;
          background: rgba(205, 196, 238, 0.3);
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          border-radius: 4px;
          border: 1px solid rgba(205, 196, 238, 0.16);
          backdrop-filter: blur(30px);
          padding: 10px 24px;

          .label-container {
            display: flex;
            align-items: center;
            gap: 6px;

            > svg {
              width: 20px;
              height: 20px;
              color: ${(props) => props.theme.colors.base.gray6};
            }

            > span {
              color: ${(props) => props.theme.colors.base.gray1};
              margin: 0px;
            }
          }

          .value {
            color: ${(props) => props.theme.colors.base.gray1};
            font-weight: 700;
            margin: 0px;
          }
        }
      }
    }
  }

  .assessment-review {
    > .content {
      height: 100%;

      .main-container {
        max-width: 50%;
        min-width: 50%;
        justify-content: space-between;

        &.passed {
          background: linear-gradient(148deg, rgba(54, 207, 201, 0.8) 11.76%, rgba(175, 216, 191, 0.8) 93.43%);
          backdrop-filter: blur(5px);
        }

        &.failed {
          background: linear-gradient(148deg, #ff5366 11.76%, #ff9c6f 93.43%);
          backdrop-filter: blur(5px);
        }

        .title {
          color: ${(props) => props.theme.colors.base.gray1};
          font-weight: 700;
          margin: 0px;
        }

        .score-container {
          color: ${(props) => props.theme.colors.base.gray1};
          display: flex;
          flex-direction: column;

          .score {
            font-size: 100px;
            font-weight: 100;
            line-height: 92px;
            letter-spacing: -4px;
          }

          .text {
            opacity: 0.9;
            font-size: 16px;
            font-weight: 700;
            line-height: 37px;
            letter-spacing: 1.6px;
            text-transform: uppercase;
          }
        }

        .bottom-content {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid ${(props) => props.theme.colors.base.gray1};
          padding: 14px 0px;

          .emoji {
            width: 40px;
            height: 40px;
            color: ${(props) => props.theme.colors.base.gray1};
          }

          .text {
            color: ${(props) => props.theme.colors.base.gray1};
            font-weight: 700;
            margin: 0px;
          }
        }
      }

      .info-container {
        max-width: 50%;
        min-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;

        .review-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 10px;
          border-radius: 0px;
          overflow: auto;

          .collapse-item {
            background: rgba(205, 196, 238, 0.3);
            border: 1px solid rgba(205, 196, 238, 0.16);
            border-radius: 4px;
            backdrop-filter: blur(30px);

            .ant-collapse-expand-icon {
              .anticon {
                color: ${(props) => props.theme.colors.base.gray1};
                font-size: 18px;
              }
            }

            .objective-title {
              color: ${(props) => props.theme.colors.base.gray1};
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
              font-size: 11px;
              letter-spacing: 1.76px;
              text-transform: uppercase;
              margin: 0px;

              .objective-score {
                color: ${(props) => props.theme.colors.base.gray5};
                margin: 0px;
              }
            }

            .activities-container {
              .activity-item {
                display: flex;
                align-items: flex-start;
                gap: 8px;
                border-top: 1px solid ${(props) => props.theme.colors.base.gray1}22;
                padding: 10px 0px;

                &:first-of-type {
                  margin-top: -10px;
                }

                .replacement-content {
                  width: 100%;

                  p {
                    color: ${(props) => props.theme.colors.base.gray1};
                    margin: 0px;
                  }

                  code {
                    background-color: ${(props) => props.theme.colors.base.gray1}22;
                  }
                }

                .result {
                  min-width: 84px;
                  max-width: 84px;
                  background-color: ${(props) => props.theme.colors.base.gray4};
                  color: ${(props) => props.theme.colors.base.gray7};
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  line-height: 22px;
                  border-radius: 6px;

                  &.passed {
                    background-color: ${(props) => props.theme.colors.base.cyan5};
                    color: ${(props) => props.theme.colors.base.gray1};
                  }

                  &.failed {
                    background-color: ${(props) => props.theme.colors.base.red4};
                    color: ${(props) => props.theme.colors.base.gray1};
                  }
                }
              }
            }
          }
        }

        .actions {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;

          .button {
          }
        }
      }
    }
  }

  .assessment-landing,
  .assessment-review {
    height: 100%;
    background: linear-gradient(90deg, ${(props) => props.theme.colors.base.gray10} 25%, #323854f2);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px 20px;

    .bg {
      width: 90%;
      position: absolute;
      bottom: 0px;
      left: 0px;
      user-select: none;

      &.review-bg {
        left: 20%;
        width: 60%;
      }
    }

    > .content {
      max-height: 700px;
      max-width: 900px;
      width: 100%;
      display: flex;
      gap: 20px;
      z-index: 1;

      .main-container {
        background: linear-gradient(87deg, rgba(44, 50, 73, 0.6) 21.87%, rgba(77, 72, 106, 0.56) 136.76%);
        border: 2px solid #3f3f5b;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;
        backdrop-filter: blur(29px);
        padding: 30px 50px;
      }
    }
  }

  .content-container {
    height: 100%;

    .module-content {
      height: 100%;
      display: flex;
      flex-direction: column;

      .replacement-content {
        height: 100%;
        overflow: auto;
        padding: 20px;

        .html-block,
        .custom-replacement-content {
          height: 100%;
          display: contents;
        }

        #activity-content-container {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .activity-container {
            height: 100%;
            overflow: auto;
          }

          .review-status-block {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .actions {
        min-height: 72px;
        max-height: 72px;
        background-color: ${(props) => props.theme.colors.gray3};
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0px 18px;
        margin: 20px;
      }
    }
  }
`

export const SecondaryContent = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .lab-container {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.geekblue1};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: auto;

    .loading-container {
      width: 510px;

      .ant-skeleton {
        margin: 0px;

        .ant-skeleton-paragraph {
          margin: 4px 0px;

          li {
            height: 430px;
            width: 100% !important;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .mobile-message {
    width: 100%;
    color: ${(props) => props.theme.colors.gray1};
    background-color: ${(props) => props.theme.colors.gold5};
    display: none;
    align-items: center;
    justify-content: center;
    padding: 32px 16px;
    margin: 0px;

    p {
      font-size: 13px;
      margin: 0px;

      &:first-child {
        font-weight: 500;
      }
    }
    svg {
      font-size: 32px;
      margin-right: 14px;
    }
  }

  @media ${device.lgDown} {
    .lab-container {
      display: none;
    }

    .mobile-message {
      display: flex;
    }
  }
`
