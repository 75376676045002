import styled from 'styled-components'

export const Content = styled.div`
  background-color: ${(props) => props.theme.colors.gray3};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 12px 16px;

  .text {
    margin: 0px;
  }

  .allocated-module {
    &:not(:last-child) {
      border-bottom: 3px solid ${(props) => props.theme.colors.gray1};
      padding-bottom: 16px;
    }

    .module-name {
      font-weight: 500;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-bottom: 6px;

      .blob {
        margin: 4px 6px 0px 6px;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .right-side {
        display: flex;
        justify-content: flex-end;
        gap: 18px;

        button {
          height: 22px;
          font-weight: 400;
          border-bottom: 1px solid;
          line-height: 20px;
          padding: 0px;
        }
      }
    }
  }
`
