import styled from 'styled-components'

export const Container = styled.div`
  max-width: 460px;
  width: 100%;
  text-align: center;
  padding: 12px;

  > img {
    width: 306px;
    height: 230px;
  }

  > .title {
    margin-bottom: 6px;
  }

  > .text-body {
    color: rgba(0, 0, 0, 0.45);
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 24px 0px 0px;
  }

  > .bottom-text {
    width: fit-content;
    font-size: 12px;
    line-height: 14px;
    border-bottom: 1px solid #444;
    margin: 12px auto 0px;
    cursor: pointer;
  }
`
