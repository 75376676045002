import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  position: relative;
  padding: 0px 8px;

  .title {
    font-weight: 500;
    margin: 0px;
  }

  .ais-HierarchicalMenu {
    .ais-HierarchicalMenu-list {
      list-style: none;
      padding: 0px;
      margin: 10px 0px 0px;

      .ais-HierarchicalMenu-item {
        margin-bottom: 6px;

        .ais-HierarchicalMenu-link {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 2px 2px 2px 0px;

          &:before {
            height: 12px;
            width: 12px;
            color: rgb(0, 0, 0);
            background-color: ${(props) => props.theme.colors.base.gray1};
            border: 1px solid ${(props) => props.theme.colors.base.gray7};
            border-radius: 3px;
            content: '';
            margin: 0 6px 0 0;
            user-select: none;
          }

          &:hover {
            &:before {
              border: 1px solid ${(props) => props.theme.colors.gray7};
            }
          }

          .ais-HierarchicalMenu-label {
            color: ${(props) => props.theme.colors.gray10};
            line-height: 20px;
            flex-grow: 1;
          }

          .ais-HierarchicalMenu-count {
            background-color: ${(props) => props.theme.colors.geekblue1};
            color: ${(props) => props.theme.colors.gray8};
            font-size: 12px;
            font-weight: 400;
            border-radius: 3px;
            padding: 1px 4px;
          }

          &--selected {
            display: none;

            &:last-child {
              display: flex;
              pointer-events: none;

              &:before {
                background-color: ${(props) => props.theme.colors.gray7};
                border: 1px solid ${(props) => props.theme.colors.gray7};
              }
            }
          }
        }
      }
    }
  }

  @media ${device.mdDown} {
    display: none;
  }
`
