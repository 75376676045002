import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { Rate, Spin } from 'antd'
import { profilesURL } from '@/helpers/env'
import Button from '@/components/Button'
import ShareLinkOptions from '@/components/ShareLinkOptions'
import ModuleShareHeaderImage from '@/assets/images/share-project-header.png'
import {
  getRelatedModules,
  createModuleRating,
  setRatingModalOpen,
  setFinishedModuleModalOpen,
} from '@/store/modules/actions'
import { Modal } from './styles'

const FinishedModuleModal = () => {
  const dispatch = useDispatch()
  const { width, height } = useWindowSize()

  const { uiConfig } = useSelector((state) => state.accounts)
  const { userProfile } = useSelector((state) => state.users)
  const { currentModule, relatedModulesInfo, isModuleRatingLoading, isFinishedModuleModalOpen } = useSelector(
    (state) => state.modules,
  )
  const { currentSkillTrack } = useSelector((state) => state.skillTracks)

  const moduleRating = currentModule?.user_status?.module_rating
  const last_module_attempt = currentModule?.user_status?.last_module_attempt
  const moduleScoring = Math.round((last_module_attempt?.user_status?.scoring || 0) * 100, 2)
  const currentSkill = currentSkillTrack?.skills?.find((s) => s.id === currentModule?.skill?.id)
  const remainingModulesOnSkill =
    currentSkill?.user_status?.total_modules - currentSkill?.user_status?.finished_modules - 1
  const nextModule = relatedModulesInfo?.related_modules?.[0]

  const modulePublicUrl = `${profilesURL}${userProfile?.username}/projects/${currentModule?.id}`

  const [ratingValue, setRatingValue] = useState(moduleRating?.rating)

  const openExtraRating = () => {
    dispatch(setFinishedModuleModalOpen(false))
    dispatch(setRatingModalOpen(true))
  }

  const handleRatingChange = async (rating) => {
    setRatingValue(rating)
    const res = await dispatch(createModuleRating(currentModule.id, { rating }))

    if (res) openExtraRating()
  }

  useEffect(() => {
    if (currentModule?.justFinished && moduleScoring === 100) {
      dispatch(setFinishedModuleModalOpen(true))
    }
  }, [currentModule?.justFinished]) // eslint-disable-line react-hooks/exhaustive-deps

  // FIXME: move this to ModulePage?
  useEffect(() => {
    if (!currentModule?.id || nextModule) return

    dispatch(getRelatedModules())
  }, [currentModule?.id, last_module_attempt?.status, nextModule]) // eslint-disable-line react-hooks/exhaustive-deps

  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful']

  return (
    <>
      <Modal
        className="finished-module-modal"
        open={isFinishedModuleModalOpen}
        onCancel={() => dispatch(setFinishedModuleModalOpen(false))}
        footer={null}
      >
        <img className="header-image" src={ModuleShareHeaderImage} alt="Share this Project" />

        <div className="content">
          <div className="title-content">
            <h1 className="title">Congrats!</h1>
            <h4 className="subtitle">Project is complete!</h4>
          </div>

          {uiConfig?.showModuleShareUi && (
            <div className="sharing-content">
              <p className="info-text">Share your accomplishments with the world!</p>

              <div className="sharing-box">
                <ShareLinkOptions
                  link={modulePublicUrl}
                  xShareText={`I completed ${currentModule?.name} DataWars project.`}
                />
              </div>
            </div>
          )}

          {uiConfig?.showModuleRatingUi && !moduleRating?.rating && (
            <div className="rating-content">
              <h5 className="text">Rate this Project</h5>

              {isModuleRatingLoading ? (
                <Spin spinning />
              ) : (
                <Rate
                  className="rate"
                  tooltips={!moduleRating?.rating && desc}
                  value={ratingValue}
                  onChange={handleRatingChange}
                  disabled={isModuleRatingLoading || moduleRating?.rating}
                />
              )}
            </div>
          )}

          {uiConfig?.showModuleNextUp && nextModule && (
            <div className="next-up-block">
              <div className="content">
                <p className="text">
                  {currentModule?.skillFinished || currentModule?.skillTrackFinished || !remainingModulesOnSkill
                    ? 'Continue with'
                    : 'Keep practicing on'}{' '}
                  "{nextModule?.skill?.name}"
                </p>

                <p className="text">{nextModule?.name}</p>
              </div>

              <Link className="link" to={`/project/${nextModule?.id}`}>
                <Button className="next-module-btn" size="large" type="primary">
                  Next project
                </Button>
              </Link>
            </div>
          )}

          {!uiConfig?.showModuleNextUp && (
            <div className="next-up-block">
              <Button
                className="next-module-btn"
                size="large"
                type="primary"
                onClick={() => dispatch(setFinishedModuleModalOpen(false))}
              >
                Continue
              </Button>
            </div>
          )}
        </div>
      </Modal>

      {isFinishedModuleModalOpen && currentModule?.justFinished && (
        <Confetti
          className="confetti-container"
          width={width}
          height={height}
          numberOfPieces={600}
          gravity={0.25}
          recycle={false}
          style={{ position: 'fixed', zIndex: 1001 }}
        />
      )}
    </>
  )
}

export default FinishedModuleModal
