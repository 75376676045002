import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Segmented } from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import FadeIn from '@/components/FadeIn'
import VerticalModuleCard from '../VerticalModuleCard'
import RelatedModulesContainerLoading from '../RelatedModulesContainerLoading'
import { getModules } from '@/store/modules/actions'
import IntroVideo from '@/assets/images/intro-video.gif'
import { Container } from './styles'

const NewUserMainBlock = ({ hideVideo }) => {
  const dispatch = useDispatch()

  const [learningArea, setLearningArea] = useState('Data Analysis')
  const [learningAreaModules, setLearningAreaModules] = useState([])
  const [modalIsOpen, setModalOpen] = useState(false)

  const VIDEO_URL = 'https://www.loom.com/embed/6ae57353431f4e17b9e9faf81e2be4b5'
  const options = ['Data Analysis', 'Machine Learning', 'Databases', 'Programming']

  const { featuredModules } = useSelector((state) => state.modules)

  const handleLearningAreaSelected = (la) => {
    setLearningArea(la)
  }

  const toggleModal = () => {
    setModalOpen(!modalIsOpen)
  }

  useEffect(() => {
    if (!featuredModules) return

    setLearningAreaModules(featuredModules.filter((m) => m?.learning_area?.name === learningArea))
  }, [featuredModules, learningArea]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getModules({ is_featured: true }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="new-user-main-block">
      <FadeIn>
        <div className="content">
          {!hideVideo && (
            <>
              <h4 className="title">We recommend you first...</h4>

              <div className="preview-info">
                <div className="image-container" onClick={toggleModal}>
                  <img className="preview-image" src={IntroVideo} alt="datawars.io" />
                  <div className="play-button">
                    <PlayCircleOutlined className="icon" />
                    <p>Play video</p>
                  </div>
                </div>

                <div className="body">
                  <h4 className="title">Take 30 seconds to understand DataWars platform</h4>
                </div>
              </div>
              <h4 className="title">What subject are you interested to start with?</h4>
            </>
          )}

          <Segmented
            className="la-selector"
            options={options}
            onChange={(option) => handleLearningAreaSelected(option)}
          />

          {featuredModules === null ? (
            <RelatedModulesContainerLoading />
          ) : (
            <div className="featured-modules-container">
              {learningAreaModules?.length ? (
                <>
                  <p className="text">These are some fun projects to start with {learningArea}:</p>

                  <Swiper className="white" spaceBetween={0} modules={[Navigation]} navigation slidesPerView={'auto'}>
                    {learningAreaModules?.map((module, i) => (
                      <SwiperSlide key={i}>
                        <VerticalModuleCard key={module.id} module={module} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              ) : (
                <p className="text">No {learningArea} projects available</p>
              )}
            </div>
          )}
        </div>
      </FadeIn>

      <Modal
        open={modalIsOpen}
        onCancel={toggleModal}
        footer={null}
        centered
        width={1200}
        closable={false}
        destroyOnClose
        bodyStyle={{ padding: 0 }}
      >
        <div style={{ position: 'relative', padding: '0px 0px 56.25%', height: 0 }}>
          <iframe
            title="datawars.io"
            src={`${VIDEO_URL}?autoplay=1&muted=0&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          ></iframe>
        </div>
      </Modal>
    </Container>
  )
}

export default NewUserMainBlock
