import {
  SET_SKILL_TRACKS_LOADING,
  SET_CURRENT_SKILL_TRACK_LOADING,
  SET_RECOMMENDED_SKILL_TRACKS_LOADING,
  SKILL_TRACKS_UPDATED,
  RECOMMENDED_SKILL_TRACKS_UPDATED,
  IN_PROGRESS_SKILL_TRACKS_UPDATED,
  SKILL_TRACK_UPDATED,
  RESET_SKILL_TRACKS_STATE,
  SET_SKILL_TRACK_ERROR,
} from '@/store/skillTracks'
import {
  fetchSkillTracks,
  fetchAlgoliaSkillTracks,
  fetchSkillsStatus,
  fetchSkillTrack,
  postSkillTrackUpvote,
} from '@/services/skillTracks'
import { fetchSkills } from '@/services/skills'

const getSkillTracks = (params) => async (dispatch) => {
  try {
    dispatch(SET_SKILL_TRACKS_LOADING(true))

    const skillTracks = await fetchAlgoliaSkillTracks(params)
    dispatch(SKILL_TRACKS_UPDATED(skillTracks))
  } catch (error) {
    const { message } = error
    dispatch(SET_SKILL_TRACK_ERROR(message))
  }
}

const getRecommendedSkillTracks = () => async (dispatch) => {
  try {
    dispatch(SET_RECOMMENDED_SKILL_TRACKS_LOADING(true))

    const skillTracks = await fetchAlgoliaSkillTracks('is_featured:true')
    dispatch(RECOMMENDED_SKILL_TRACKS_UPDATED(skillTracks))
  } catch (error) {
    const { message } = error
    dispatch(SET_SKILL_TRACK_ERROR(message))
  }
}

const getInProgressSkillTracks = () => async (dispatch) => {
  try {
    dispatch(SET_RECOMMENDED_SKILL_TRACKS_LOADING(true))

    const skillTracks = await fetchSkillTracks({ in_progress: true })
    dispatch(IN_PROGRESS_SKILL_TRACKS_UPDATED(skillTracks))
  } catch (error) {
    const { message } = error
    dispatch(SET_SKILL_TRACK_ERROR(message))
  }
}

// hydratate skill tracks with its user_status
const getSkillTracksStatus = (params) => async (dispatch, getState) => {
  try {
    const { items: skillTracks, recommendedSkillTracks } = getState().skillTracks
    const skillTracksStatus = await fetchSkillsStatus(params)

    // update skill tracks from table
    let auxSkillTracks = skillTracks.map((st) => ({
      ...st,
      user_status: skillTracksStatus?.find((statusData) => statusData.id === st.id)?.user_status || {},
    }))

    dispatch(SKILL_TRACKS_UPDATED(auxSkillTracks))

    // update recommended skill tracks
    let auxRecommendedSkillTracks = recommendedSkillTracks.map((st) => ({
      ...st,
      user_status: skillTracksStatus?.find((statusData) => statusData.id === st.id)?.user_status,
    }))

    dispatch(RECOMMENDED_SKILL_TRACKS_UPDATED(auxRecommendedSkillTracks))
  } catch (error) {
    const { message } = error
    dispatch(SET_SKILL_TRACK_ERROR(message))
  }
}

const getSkillTrack = (skillTrackId, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_CURRENT_SKILL_TRACK_LOADING(true))
    const { items: skillTracks } = getState().skillTracks

    const skillTrack = await fetchSkillTrack(skillTrackId)
    dispatch(SKILL_TRACK_UPDATED(skillTrack))

    // also update skill track info on general list
    let auxSkillTracks = skillTracks.map((st) => {
      if (st.id !== skillTrack.id) return st

      return {
        ...st,
        ...skillTrack,
        // keep it as object to avoid errors
        learning_area: st.learning_area,
      }
    })

    dispatch(SKILL_TRACKS_UPDATED(auxSkillTracks))

    if (cb) {
      cb()
    }

    return skillTrack
  } catch (error) {
    const { message } = error
    dispatch(SET_SKILL_TRACK_ERROR(message))
  }
}

// hydratate skills with its modules
const getSkills = (params) => async (dispatch, getState) => {
  try {
    dispatch(SET_CURRENT_SKILL_TRACK_LOADING(true))

    const { currentSkillTrack } = getState().skillTracks

    const skills = await fetchSkills(params)

    let auxSkillTrack = {
      ...currentSkillTrack,
      isModulesLoaded: true,
      skills: currentSkillTrack.skills?.map((s) => {
        const auxSkill = {
          ...s,
          modules: skills?.results?.find((sk) => sk.id === s.id)?.modules || s.modules,
        }

        return auxSkill
      }),
    }

    dispatch(SKILL_TRACK_UPDATED(auxSkillTrack))
  } catch (error) {
    const { message } = error
    dispatch(SET_SKILL_TRACK_ERROR(message))
  }
}

const createSkillTrackUpvote = (skillTrackId) => async (dispatch) => {
  try {
    const responseObj = await postSkillTrackUpvote(skillTrackId)
    return responseObj
  } catch (error) {
    const { message } = error
    dispatch(SET_SKILL_TRACK_ERROR(message))
  }
}

const resetSkillTracksState = () => (dispatch) => {
  dispatch(RESET_SKILL_TRACKS_STATE())
}

export {
  getSkillTracks,
  getRecommendedSkillTracks,
  getInProgressSkillTracks,
  getSkillTracksStatus,
  getSkillTrack,
  getSkills,
  createSkillTrackUpvote,
  resetSkillTracksState,
}
