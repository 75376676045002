import styled, { css } from 'styled-components'
import { Modal as AntdModal } from 'antd'
import { device } from '@/themes/breakpoints'

export const Box = styled.div`
  height: 140px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.geekblue1};
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s all;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.geekblue3};
  }

  ${(props) =>
    props.$current &&
    css`
      border: 2px solid ${(props) => props.theme.colors.geekblue3};

      &:hover {
        border: 2px solid ${(props) => props.theme.colors.geekblue3};
      }
    `}

  ${(props) =>
    props.$border &&
    css`
      border: 1px solid ${(props) => props.theme.colors.gray3};
    `}

  ${(props) =>
    props.$draft &&
    css`
      cursor: not-allowed;

      &:hover {
        border: 1px solid ${(props) => props.theme.colors.gray3};
      }

      > div:last-child {
        display: none;
      }
    `}

  &.is-loading {
    .module-content {
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding: 0px 20px;
    }

    .left-side {
      width: 80px;
      display: flex;
      align-items: center;

      .ant-skeleton-avatar {
        width: 80px;
        height: 80px;
        border-radius: 16px;
      }
    }

    .right-side {
      display: flex;
      align-items: center;
    }
  }

  ${(props) =>
    !props.$disabled &&
    css`
      cursor: pointer;
    `}
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 20px;

  .module-image {
    min-width: 98px;
    width: 98px;
    height: auto;
    opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
    image-rendering: auto;
    pointer-events: none;
  }

  .module-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow: auto;

    .pre-title {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 4px;

      .la-bullet {
        min-width: 12px;
        width: 12px;
        height: 12px;
        background-color: ${(props) => props.theme.colors.gray7};
        display: inline-block;
        border-radius: 50%;
      }

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        line-height: 16px;
        margin: 0px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .module-title {
      height: initial;
      max-height: 48px;
      color: ${(props) => props.theme.colors.gray11};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0px 0px 10px;
    }

    .info-box {
      display: flex;
      align-items: center;
      gap: 10px;

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        line-height: 16px;
        margin: 0px;
      }
    }

    .early-access-badge {
      background: linear-gradient(
        84deg,
        ${(props) => props.theme.colors.base.gray10} 24.3%,
        #5479f7 67.59%,
        #89d2c2 99.33%
      );
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 0px 7px;

      > span {
        color: ${(props) => props.theme.colors.base.gray1};
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }

      .pro-badge {
        width: 14px;
      }
    }
  }

  ${(props) =>
    props.$draft &&
    css`
      .module-image {
        filter: saturate(0);
        opacity: 0.45;
      }

      .module-info {
        .module-title {
          color: ${props.theme.colors.gray7} !important;
        }
      }
    `}

  .notification {
    background: red;
  }

  .tags-container {
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    overflow: auto;

    .tag {
      background-color: ${(props) => props.theme.colors.base.gray7};
      color: ${(props) => props.theme.colors.base.gray1};
      text-transform: capitalize;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 6px;
      margin: 0px;

      > img {
        width: initial;
      }

      &.module-type {
        color: ${(props) => props.theme.colors.base.gray1};
        background-color: ${(props) => props.theme.colors.base.gray10};
      }

      &.is-draft {
        color: ${(props) => props.theme.colors.base.gray1};
        background-color: ${(props) => props.theme.colors.base.volcano6};
      }

      &.is-beta {
        color: ${(props) => props.theme.colors.base.gray1};
        background-color: ${(props) => props.theme.colors.base.volcano6};
      }

      &.is-archived {
        color: ${(props) => props.theme.colors.base.gray1};
        background-color: ${(props) => props.theme.colors.base.volcano6};
      }

      &.is-completed {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.cyan2};
      }

      &.difficulty-easy {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.green2};
      }

      &.difficulty-medium {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.gold3};
      }

      &.difficulty-hard {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.red2};
      }
    }
  }

  @media ${device.mdDown} {
    .module-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
    }
  }
`

export const Modal = styled(AntdModal)`
  .early-access-banner {
    width: calc(100% - 42px);
    background: linear-gradient(
      84deg,
      ${(props) => props.theme.colors.base.gray10} 24.3%,
      #5479f7 67.59%,
      #89d2c2 99.33%
    );
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    margin-top: -6px;

    .pro-badge {
      width: 16px;
    }

    > span {
      color: ${(props) => props.theme.colors.base.gray1};
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .modal {
    gap: 24px;
    padding: 12px 0px;

    .module-image {
      min-width: 134px;
      width: 134px;
    }

    .module-info {
      .module-title {
        height: initial;
        max-height: initial;
        display: block;
        overflow: initial;
        margin: 0px 0px 8px;
      }

      .rating {
        color: ${(props) => props.theme.colors.gray8};
        font-weight: 500;
        display: flex;
        gap: 4px;
        margin-top: 8px;

        .subtext {
          color: ${(props) => props.theme.colors.gray8};
          font-size: 12px;
          font-weight: 400;
          margin: 0px;
        }

        .ant-rate-star {
          margin-inline-end: 3px;

          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
`

export const ExtraContent = styled.div`
  .alpha-notification {
    background: ${(props) => props.theme.colors.orange1};
    border-radius: 3px;
    padding: 4px 8px;
    margin: 10px 0px;

    .content {
      color: ${(props) => props.theme.colors.gray8};
      font-size: 12px;
      line-height: 20px;
      display: flex;
      gap: 6px;
      align-items: center;
      margin: 0px;

      > .anticon {
        font-size: 16px;
      }
      .highlight {
        font-weight: 600;
      }
    }
  }

  .module-description {
    color: ${(props) => props.theme.colors.gray8};
    margin-bottom: 12px;
  }

  .progress-box {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 14px 0 10px;

    .ant-progress {
      .ant-progress-inner {
        background-color: ${(props) => props.theme.colors.base.geekblue2};
      }

      .ant-progress-bg {
        background-color: ${(props) => props.theme.colors.base.geekblue5};
      }

      &.ant-progress-status-success .ant-progress-bg {
        background-color: ${(props) => props.theme.colors.base.cyan5};
      }
    }

    .progress-text {
      color: ${(props) => props.theme.colors.base.gray7};
      font-size: 12px;
      line-height: 20px;
      margin: 0px;
    }
  }

  .module-actions {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border-top: 1px solid ${(props) => props.theme.colors.gray4};
    padding-top: 14px;
    margin-top: 12px;

    .module-author {
      display: flex;
      align-items: center;
      gap: 10px;

      .ant-avatar {
        width: 32px;
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
      }

      .name {
        font-size: 12px;
        line-height: 20px;
        margin: 0px;
      }
    }
  }

  @media ${device.mdDown} {
    .module-actions {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
`
