import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import VerticalModuleCard from '../VerticalModuleCard'
import { getNewModules } from '@/store/modules/actions'
import { Container } from './styles'

const NewModules = () => {
  const dispatch = useDispatch()

  const { newModules } = useSelector((state) => state.modules)

  useEffect(() => {
    if (newModules !== null) return

    dispatch(getNewModules())
  }, [newModules]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {newModules?.length && (
        <>
          <div className="header">
            <h4 className="title">Newly Released Projects</h4>

            <p className="view-all-button" type="text">
              <Link to={'/catalog'}>View all</Link>
            </p>
          </div>

          <div className="modules-container">
            <Swiper className="blue1" modules={[Navigation]} navigation slidesPerView={'auto'}>
              {newModules.map((m, i) => (
                <SwiperSlide key={i}>
                  <VerticalModuleCard module={m} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
    </Container>
  )
}

export default NewModules
