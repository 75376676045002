import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isDataWarsHostName, teamsURL } from '@/helpers/env'
import Layout1 from '@/layouts/Layout1'
import Button from '@/components/Button'
import NotFoundImage from '@/assets/images/not-found.svg'
import { Container } from './styles'

const NotFoundPage = ({ title, text, image }) => {
  const { uiConfig, currentAccount } = useSelector((state) => state.accounts)
  const { userProfile } = useSelector((state) => state.users)

  const isAccountOwner = isDataWarsHostName
    ? userProfile?.accounts?.some((a) => a.role === 'owner')
    : userProfile?.accounts?.find((account) => account.id === currentAccount?.id)?.role === 'owner'
  const isSuperAccountOwner = userProfile?.permissions?.includes('teams.super_account_owner')

  const getTeamsUrl = () => {
    if (isDataWarsHostName) {
      return teamsURL
    }

    // namespace
    return `${teamsURL}accounts/${currentAccount?.id}`
  }

  return (
    <Layout1 navbar>
      <Container>
        <img src={image || NotFoundImage} alt={title || 'Not found'} />

        {uiConfig?.showNavbarGoToTeamsButton && (isAccountOwner || isSuperAccountOwner) ? (
          <>
            <h3 className="title">{title || 'Oops! Page not found'}</h3>
            <p className="text-body">{text || 'Maybe go to admin platform?'}</p>

            <Link to={getTeamsUrl()}>
              <Button type="primary">Go to admin</Button>
            </Link>
          </>
        ) : (
          <>
            <h3 className="title">{title || 'Oops! Page not found'}</h3>
            <p className="text-body">{text || 'The page you’re looking for doesn’t exist.'}</p>
          </>
        )}
      </Container>
    </Layout1>
  )
}

export default NotFoundPage
