import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const getLaunch = async (launchId) => {
  const route = `launch/${launchId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { getLaunch }
