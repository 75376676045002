import styled, { css } from 'styled-components'

export const Progress = styled.div`
  height: 6px;
  width: 100%;
  background-color: ${(props) => props.$value > 0 && props.theme.colors.geekblue4}40;
  position: relative;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  transition: 0.3s all;

  .progress-value {
    ${(props) => css`
      width: ${props.$value}%;
      background-color: ${props.$value === 100 ? props.theme.colors.base.cyan4 : props.theme.colors.base.geekblue4};
    `}
  }
`
