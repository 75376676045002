import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import { isDown } from '@/themes/breakpoints'
import Button from '@/components/Button'
import DescriptionDrawerItem from './components/DescriptionDrawerItem'
import VersionHistoryDrawerItem from './components/VersionHistoryDrawerItem'
import CommentsDrawerItem from './components/CommentsDrawerItem'
import { selectInfoSideDrawerItem } from '@/store/playgrounds/actions'
import { Container } from './styles'

const InfoSideDrawer = ({ shouldSetDefaultContent = true, instanceId, setInstanceId }) => {
  const dispatch = useDispatch()

  const { infoSideDrawer } = useSelector((state) => state.playgrounds)

  const getContent = () => {
    if (!infoSideDrawer) return <></>

    if (infoSideDrawer === 'description') {
      return <DescriptionDrawerItem instanceId={instanceId} setInstanceId={setInstanceId} />
    }

    if (infoSideDrawer === 'version-history') {
      return <VersionHistoryDrawerItem />
    }

    if (infoSideDrawer === 'comments') {
      return <CommentsDrawerItem />
    }
  }

  useEffect(() => {
    if (!infoSideDrawer && !isDown('lg') && shouldSetDefaultContent) {
      dispatch(selectInfoSideDrawerItem('description'))
    }
  }, [])

  return (
    <Container className="info-side-drawer" $hasContent={!!infoSideDrawer}>
      <Button
        className="close-btn"
        type="text"
        onClick={() => dispatch(selectInfoSideDrawerItem(null))}
        icon={<CloseOutlined className="icon" />}
      />

      {getContent()}
    </Container>
  )
}

export default InfoSideDrawer
