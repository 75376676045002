import styled from 'styled-components'

export const Container = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  .ant-skeleton-paragraph {
    margin: 0 auto;
  }

  .title,
  .description {
    width: 70%;
    margin: 0 auto;

    li {
      margin: 0 auto;
    }
  }

  .image li {
    width: 100% !important;
    height: 260px;
  }
`
