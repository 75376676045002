import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tabs } from 'antd'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import SkillTrackCard from '../SkillTrackCard'
import { getInProgressSkillTracks, getRecommendedSkillTracks } from '@/store/skillTracks/actions'
import { Container } from './styles'

const MoreSkillTracks = () => {
  const dispatch = useDispatch()

  const [tab, changeTab] = useState('1')

  const { inProgressSkillTracks, recommendedSkillTracks } = useSelector((state) => state.skillTracks)

  const InProgressSkillTracks = () => (
    <div className="modules-container">
      {!inProgressSkillTracks?.length ? (
        <p>You don't have any skill track in progress</p>
      ) : (
        <Swiper spaceBetween={20} modules={[Navigation]} navigation slidesPerView={'auto'}>
          {inProgressSkillTracks.map((st, i) => (
            <SwiperSlide key={i}>
              <SkillTrackCard skillTrack={st} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )

  const RecommendedSkillTracks = () => (
    <div className="modules-container">
      {!recommendedSkillTracks?.length ? (
        <p>No recommended skill tracks available</p>
      ) : (
        <Swiper spaceBetween={20} modules={[Navigation]} navigation slidesPerView={'auto'}>
          {recommendedSkillTracks.map((st, i) => (
            <SwiperSlide key={i}>
              <SkillTrackCard skillTrack={st} hideSkillsInfo />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )

  const tabItems = [
    {
      key: '1',
      label: 'In progress',
      children: <InProgressSkillTracks />,
    },
    {
      key: '2',
      label: 'Recommended',
      children: <RecommendedSkillTracks />,
    },
  ]

  useEffect(() => {
    dispatch(getInProgressSkillTracks())
    dispatch(getRecommendedSkillTracks())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      {!!inProgressSkillTracks?.length ? (
        <>
          <h4 className="title">Skill tracks</h4>
          <Tabs defaultActiveKey={tab} items={tabItems} onChange={changeTab} />
        </>
      ) : (
        <>
          <h4 className="title" style={{ marginBottom: 20 }}>
            Recommended Skill tracks
          </h4>
          <RecommendedSkillTracks />
        </>
      )}
    </Container>
  )
}

export default MoreSkillTracks
