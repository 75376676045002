import styled from 'styled-components'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    padding: 45px;

    .ant-modal-body {
      display: flex;
      justify-content: center;

      .content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .timer {
          color: #b3b3b3;
          margin-bottom: 8px;
        }

        .title {
          margin-bottom: 8px;
        }

        .subtitle {
          color: ${(props) => props.theme.colors.gray7};
          margin: 0px;
        }

        .cta-continue-working {
          margin-top: 30px;
        }
      }
    }
  }

  @media ${device.lgDown} {
    display: none;
  }
`
