import { useDispatch } from 'react-redux'
import { MailOutlined } from '@ant-design/icons'
import { toggleEmailVerificationModal } from '@/store/users/actions'

import { Container } from './styles'

const ValidateEmailBanner = () => {
  const dispatch = useDispatch()

  return (
    <Container className="banner validate-email-banner" onClick={() => dispatch(toggleEmailVerificationModal(true))}>
      <div className="info-box">
        <MailOutlined className="icon-container" />

        <div className="content">
          <p className="text">
            <b>Verify your email</b>
          </p>

          <p className="text">Verifying your email gives you access to more features on DataWars.</p>
        </div>
      </div>
    </Container>
  )
}

export default ValidateEmailBanner
