import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Input, Select } from 'antd'
import { SmileTwoTone } from '@ant-design/icons'
import Button from '@/components/Button'
import { updateProfile } from '@/store/users/actions'
import LocationIcon from '@/assets/images/icons/location.svg?react'
import countries from '@/assets/data/countries.json'

const Step1 = ({ onNext }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const { userProfile } = useSelector((state) => state.users)

  const formSubmit = async (values) => {
    setIsLoading(true)
    await dispatch(updateProfile(userProfile?.username, values))
    setIsLoading(false)
    onNext()
  }

  return (
    <div className="content">
      <div className="header">
        <p className="title">Complete your profile</p>
        <p className="subtitle">Tell us a bit about yourself</p>
      </div>

      <Form
        className="form-container"
        initialValues={{
          first_name: userProfile?.first_name,
          last_name: userProfile?.last_name,
          declared_location: userProfile?.declared_location || null,
        }}
        onFinish={formSubmit}
      >
        <div className="row">
          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
                message: 'First name is required.',
              },
            ]}
          >
            <Input size="large" placeholder="First name" prefix={<SmileTwoTone />} disabled={isLoading} />
          </Form.Item>

          <Form.Item
            name="last_name"
            rules={[
              {
                required: true,
                message: 'Last name is required.',
              },
            ]}
          >
            <Input size="large" placeholder="Last name" prefix={<SmileTwoTone />} disabled={isLoading} />
          </Form.Item>
        </div>

        <div className="row">
          <Form.Item name="declared_location">
            <Select
              size={'large'}
              placeholder={'Select your location'}
              options={countries}
              fieldNames={{ label: 'country', value: 'country' }}
              showSearch
              virtual={false}
              prefix={<LocationIcon />}
              notFoundContent={<span style={{ padding: 12 }}>No results</span>}
              disabled={isLoading}
            />
          </Form.Item>
        </div>

        <p className="info-text">This information will be displayed on your Public Profile page.</p>

        <div className="actions">
          <span />

          <div>
            <Button className="skip-button" type="text" onClick={onNext} loading={isLoading} disabled={isLoading}>
              Skip this step
            </Button>

            <Button type="primary" size="large" htmlType="submit" loading={isLoading} disabled={isLoading}>
              Next
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Step1
