import styled from 'styled-components'

export const Content = styled.div`
  padding: 12px 14px;

  .title {
    color: #fff;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0px 0px 4px;

    .icon {
      width: 24px;
    }
  }

  .content {
    > p {
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      margin: 0px;
    }

    > button {
      color: #fff;
      margin-top: 14px;

      &:hover {
        color: #fff !important;
      }
    }
  }

  > .upgrade-btn {
    margin-top: 10px;
  }
`
