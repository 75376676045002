import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    width: 400px;
    background-color: ${(props) => props.theme.colors.gray3};
    text-align: center;
    margin: 0 auto;

    .rate-content {
      padding: 0px;

      .rate {
        svg {
          font-size: 42px;
        }
      }
    }

    .actions {
      border-top: 1px solid #2323231a;
      padding-top: 10px;
      margin-top: 20px;

      .ant-spin {
        margin-bottom: -8px;
      }
    }
  }
`
