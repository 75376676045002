import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 250px;
  height: 290px;
  background: ${(props) => props.theme.colors.gray1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 14px;
  overflow: hidden;
  padding: 15px 20px;
  margin: 8px 10px;
  box-shadow: 0px 1px 9px 2px rgba(92, 136, 223, 0.12);

  .img-container {
    min-height: 112px;
    background-color: ${(props) => props.theme.colors.geekblue1};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-image {
      > span {
        display: none;
      }
    }

    img {
      width: 84px;
      opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
      object-fit: cover;
      pointer-events: none;
    }
  }

  .early-access-badge {
    background: linear-gradient(84deg, #222249 24.3%, #5479f7 67.59%, #89d2c2 99.33%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.35);
    cursor: pointer;

    > span {
      color: ${(props) => props.theme.colors.base.gray1};
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    .pro-badge {
      width: 20px;
    }
  }

  .module-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 12px;

    .info-box {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .tag {
      width: fit-content;
      background-color: ${(props) => props.theme.colors.base.gray7};
      color: ${(props) => props.theme.colors.base.gray1};
      font-size: 11px;
      text-transform: capitalize;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 6px;
      margin: 0px;

      &.difficulty-easy {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.green2};
      }

      &.difficulty-medium {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.gold3};
      }

      &.difficulty-hard {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.red2};
      }
    }

    .text {
      color: ${(props) => props.theme.colors.gray7};
      font-size: 12px;
      line-height: 16px;
      margin: 0px;
    }

    .title {
      height: 44px;
      color: ${(props) => props.theme.colors.gray11};
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .link {
      width: 100%;

      .main-btn {
        width: 100%;
      }
    }
  }

  @media ${device.mdDown} {
    .module-info {
      align-items: center;

      .title {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
    }
  }
`
