import { useState, useEffect } from 'react'

const Timer = ({ seconds, onFinish }) => {
  const [auxSeconds, setAuxSeconds] = useState(seconds)

  let timer
  const displaySeconds = auxSeconds % 60
  const displayMinutes = Math.floor(auxSeconds / 60)
  const isActive = displaySeconds > 0 || displayMinutes > 0

  useEffect(() => {
    if (auxSeconds > 0) {
      timer = setTimeout(() => setAuxSeconds(auxSeconds - 1), 1000)
      return
    }

    onFinish()
  }, [auxSeconds])

  return (
    <span style={{ color: displayMinutes === 0 ? '#ff4d4f' : 'inherit' }}>
      {isActive
        ? `${displayMinutes}:${displaySeconds < 10 ? `0${displaySeconds}` : displaySeconds}`
        : 'Stopping lab...'}
    </span>
  )
}

export default Timer
