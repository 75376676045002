import { useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { Tooltip, Avatar, Rate, Tag, Progress, Skeleton } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { capitalize } from '@/utils/functions'
import Button from '@/components/Button'
import BottomProgress from './components/BottomProgress'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import LearnIcon from '@/assets/images/learn.svg?react'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Box, Content, ExtraContent, Modal } from './styles'

const ModuleBox = ({ module = {}, border, showSkillTrack, disableClick, skipUserStatus, isLoading }) => {
  const [isModalVisible, setModalIsVisible] = useState(false)

  const { userProfile } = useSelector((state) => state.users)
  const { currentModule } = useSelector((state) => state.modules)

  const canViewEarlyAccess = userProfile?.permissions?.includes('content.module_early_access')
  const shouldViewDraftModule = userProfile?.permissions?.includes('content.view_draft_module')
  const shouldViewBetaModule = userProfile?.permissions?.includes('content.view_beta_module')

  const isPublished = module?.status?.toLowerCase() === 'published'
  const isDraft = ['draft', 'coming_soon', 'Coming soon'].includes(module?.status)
  const isBeta = ['beta'].includes(module?.status)
  const isArchived = module?.status?.toLowerCase() === 'archived'
  const hasContent = !!module?.module_pages_count

  const isFetchingScoring = !module?.user_status

  const { user_status: { last_module_attempt } = {} } = module
  const isStarted = !!last_module_attempt
  const moduleScoring = Math.round((last_module_attempt?.user_status?.scoring || 0) * 100, 2)

  const openModal = () => {
    if (!shouldViewDraftModule && isDraft) return
    if (!shouldViewBetaModule && isBeta) return
    setModalIsVisible(true)
  }

  const displayModuleMainActionButton = () => {
    if ((isDraft && !shouldViewDraftModule) || (isBeta && !shouldViewBetaModule)) {
      return (
        <Button type="primary" disabled>
          Coming soon
        </Button>
      )
    }

    if (
      !skipUserStatus &&
      (isPublished || (isDraft && shouldViewDraftModule) || (isBeta && shouldViewBetaModule) || isFetchingScoring)
    ) {
      if (isFetchingScoring) {
        return (
          <Button type="primary" disabled loading>
            Loading project
          </Button>
        )
      }

      if (!hasContent) {
        return (
          <Button type="primary" disabled>
            No content
          </Button>
        )
      }

      if (isStarted) {
        return (
          <Link to={`/project/${module?.id}`} onClick={() => setModalIsVisible(false)}>
            <Button type="primary" disabled={isLoading} loading={isLoading}>
              Continue project
            </Button>
          </Link>
        )
      }
    }

    return (
      <Link to={`/project/${module?.id}`} onClick={() => setModalIsVisible(false)}>
        <Button type="primary" disabled={isLoading} loading={isLoading}>
          Start project
        </Button>
      </Link>
    )
  }

  const renderTags = (hideEarlyAccess) => {
    if (!shouldViewDraftModule && isDraft) {
      return (
        <div className="tags-container">
          <Tag className="tag">Coming soon</Tag>
        </div>
      )
    }

    return (
      <div className="tags-container">
        {!hideEarlyAccess && showSkillTrack && module?.early_access && (
          <div className="early-access-badge">
            <img className="pro-badge" src={ProBadge} alt="PRO user" />
            <span>Early Access</span>
          </div>
        )}
        {shouldViewDraftModule && isDraft && <Tag className="tag is-draft">Draft</Tag>}
        {isBeta && <Tag className="tag is-beta">Beta</Tag>}
        {isArchived && <Tag className="tag is-archived">Archived</Tag>}
        {(isPublished || shouldViewDraftModule) && !hasContent && !isFetchingScoring && (
          <Tag className="tag">No content</Tag>
        )}

        {module?.module_type === 'learn' && (
          <Tag className={`tag module-type`}>
            <LearnIcon />
            Learn
          </Tag>
        )}

        <Tag className={`tag difficulty-${module?.difficulty}`}>{module?.difficulty}</Tag>

        {moduleScoring === 100 && <Tag className="tag is-completed">Completed</Tag>}
      </div>
    )
  }

  if (isLoading || !module?.name)
    return (
      <Box className="is-loading" $draft={isDraft} $border={border} $disabled={disableClick}>
        <Content className="module-content" $draft={isDraft}>
          <Skeleton className="left-side" active avatar title={false} paragraph={{ rows: 0 }} />
          <Skeleton className="right-side" active title={false} paragraph={{ rows: 3 }} />
        </Content>
      </Box>
    )

  return (
    <>
      <Tooltip
        title={
          isBeta && !shouldViewBetaModule
            ? 'Beta projects are available only to a select number of users. Want to be part of our Beta program? Check out our Discord.'
            : ''
        }
        overlayClassName={'on-modal'}
      >
        <Box
          className="module-box"
          $current={currentModule && currentModule?.id === module?.id}
          $draft={isDraft}
          $border={border}
          $disabled={disableClick}
          onClick={disableClick ? null : openModal}
        >
          <Content className="module-content" $draft={isDraft}>
            {renderModuleImage(module)}

            <div className="module-info">
              <div className="upper-tags">
                {!showSkillTrack && module?.early_access && (
                  <div className="early-access-badge">
                    <img className="pro-badge" src={ProBadge} alt="PRO user" />
                    <span>Early Access</span>
                  </div>
                )}
              </div>

              {showSkillTrack && (
                <div className="pre-title">
                  <span className="la-bullet" style={{ backgroundColor: module?.learning_area?.color }} />

                  <Link to={`/skill-track/${module?.skill_track?.id}`}>
                    <p className="text">{module?.skill_track?.name}</p>
                  </Link>
                </div>
              )}

              <h5 className="module-title">{module?.name}</h5>

              <div className="info-box">{renderTags()}</div>
            </div>
          </Content>

          <BottomProgress value={moduleScoring} />
        </Box>
      </Tooltip>

      <Modal title={null} open={isModalVisible} onCancel={() => setModalIsVisible(false)} footer={null}>
        {module?.early_access && (
          <div className="early-access-banner">
            <img className="pro-badge" src={ProBadge} alt="PRO user" />
            {canViewEarlyAccess ? (
              <span>Early Access Project</span>
            ) : (
              <span>
                This project will become available to Basic users on{' '}
                <b>
                  {module?.objectID
                    ? dayjs.unix(module?.general_available_at).format('MMMM Do')
                    : dayjs(module?.general_available_at).format('MMMM Do')}
                </b>
                .
              </span>
            )}
          </div>
        )}

        <Content className="module-content modal" $draft={isDraft}>
          {renderModuleImage(module)}

          <div className="module-info">
            {showSkillTrack && (
              <div className="pre-title">
                <span className="la-bullet" style={{ backgroundColor: module?.learning_area?.color }} />

                <Link to={`/skill-track/${module?.skill_track?.id}`}>
                  <p className="text">{module?.skill_track?.name}</p>
                </Link>
              </div>
            )}

            <h4 className="module-title">{module?.name}</h4>

            {renderTags(true)}

            <div className="rating">
              <Rate disabled allowHalf defaultValue={module?.rating?.avg} />{' '}
              {module?.rating?.avg ? (
                Math.round(module?.rating?.avg * 100) / 100
              ) : (
                <span className="subtext">Not enough data</span>
              )}
            </div>
          </div>
        </Content>

        <ExtraContent>
          {/* {module?.is_alpha && (
            <div className="alpha-notification">
              <p className="content">
                <InfoCircleOutlined />
                <span>
                  <span className="highlight">Alpha!</span> This project is still in testing phase, please report any
                  issues.
                </span>
              </p>
            </div>
          )} */}

          <p className="module-description">{module?.description}</p>

          {!!module?.tags?.length && (
            <div className="tags-container">
              {module?.tags?.map((t) => (
                <Tag key={t} className="module-tag">
                  {t}
                </Tag>
              ))}
            </div>
          )}

          {!isFetchingScoring && (
            <div className="progress-box">
              <Progress percent={moduleScoring} showInfo={false} />
              <p className="progress-text">{moduleScoring}%</p>
            </div>
          )}

          <div className="module-actions">
            <div className="module-author">
              <Avatar src={module?.author?.avatar_url} icon={<UserOutlined />} />

              <p className="name">
                {module?.author?.first_name
                  ? `${capitalize(module?.author?.first_name)} ${capitalize(module?.author?.last_name)}`
                  : 'Anonymous'}
              </p>
            </div>

            {displayModuleMainActionButton()}
          </div>
        </ExtraContent>
      </Modal>
    </>
  )
}

export default ModuleBox
