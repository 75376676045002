import { Tooltip } from 'antd'
import { Copy24Regular } from '@fluentui/react-icons'
import { showToast } from '@/utils/toast'
import XIcon from '@/assets/images/icons/x.svg?react'
import LinkedInIcon from '@/assets/images/icons/linkedin.svg?react'
import { Container } from './styles'

const ShareLinkOptions = ({
  link = 'https://datawars.io',
  xShareText,
  copyToClipboardText,
  hideX,
  hideLinkedIn,
  hideCopyToClipboard,
}) => {
  return (
    <Container className="share-link-options">
      {!hideX && (
        <Tooltip title="Share on X" overlayClassName={'on-modal'}>
          <div
            className="icon x-icon"
            onClick={() => {
              let sharingUrl = `https://x.com/intent/tweet?text=${xShareText}&via=datawars_io&url=${encodeURIComponent(
                link,
              )}`
              window.open(sharingUrl, '_blank')
            }}
          >
            <XIcon />
          </div>
        </Tooltip>
      )}

      {!hideLinkedIn && (
        <Tooltip title="Share on LinkedIn" overlayClassName={'on-modal'}>
          <div
            className="icon linkedin-icon"
            onClick={() => {
              const sharingUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}`
              window.open(sharingUrl, '_blank')
            }}
          >
            <LinkedInIcon />
          </div>
        </Tooltip>
      )}

      {!hideCopyToClipboard && (
        <Tooltip title="Copy link to share" overlayClassName={'on-modal'}>
          <span className="icon copy-icon">
            <Copy24Regular
              onClick={() => {
                let sharingUrl = copyToClipboardText || link
                navigator?.clipboard?.writeText(sharingUrl)
                showToast('Shareable link copied to clipboard')
              }}
            />
          </span>
        </Tooltip>
      )}
    </Container>
  )
}

export default ShareLinkOptions
