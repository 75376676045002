import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ArrowLeftOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import { updateProfile } from '@/store/users/actions'
import StudyingIcon from '@/assets/images/icons/studying.svg?react'
import WorkingIcon from '@/assets/images/icons/working.svg?react'
import OtherIcon from '@/assets/images/icons/other.svg?react'

const Step2 = ({ onPrev, onNext }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const { userProfile } = useSelector((state) => state.users)

  const goToNextStep = async (occupation, skip) => {
    if (occupation && !skip) {
      setIsLoading(true)
      await dispatch(
        updateProfile(userProfile?.username, {
          occupation,
        }),
      )
      setIsLoading(false)
    }

    onNext()
  }

  return (
    <div className="content">
      <div className="header">
        <p className="title">What’s your occupation?</p>
        <p className="subtitle">This better helps us customize the experience for you</p>
      </div>

      <div className="form-container">
        <div className="row big-options-row">
          <div
            className={`big-option ${userProfile?.occupation === 'studying' && 'active'}`}
            onClick={isLoading ? null : () => goToNextStep('studying')}
          >
            <StudyingIcon className="icon" />
            <p className="text">I'm Studying full-time</p>
            <p className="sub-text">You’re here to impress your teachers and classmates</p>
          </div>

          <div
            className={`big-option ${userProfile?.occupation === 'working' && 'active'}`}
            onClick={isLoading ? null : () => goToNextStep('working')}
          >
            <WorkingIcon className="icon" />
            <p className="text">I'm Working full-time</p>
            <p className="sub-text">You’re here to improve your skills and empower your team</p>
          </div>

          <div
            className={`big-option ${userProfile?.occupation === 'other' && 'active'}`}
            onClick={isLoading ? null : () => goToNextStep('other')}
          >
            <OtherIcon className="icon" />
            <p className="text">Other</p>
            <p className="sub-text">Maybe you’re here to improve your skills or just have fun!</p>
          </div>
        </div>

        <div className="actions">
          <Button className="back-button" type="text" onClick={onPrev} loading={isLoading} disabled={isLoading}>
            <ArrowLeftOutlined /> Back
          </Button>

          <div>
            <Button className="skip-button" type="text" onClick={onNext} loading={isLoading} disabled={isLoading}>
              Skip this step
            </Button>

            <Button type="primary" size="large" onClick={() => goToNextStep()} loading={isLoading} disabled={isLoading}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step2
