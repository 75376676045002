import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray10}aa;
  position: absolute;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) => {
    return props.$hasImage
      ? css`
          display: none;
        `
      : css`
          display: initial;
        `
  }}

  .ant-upload-wrapper {
    width: 100%;
    height: 100%;

    .ant-upload {
      width: 100%;
      height: 100%;

      .icon-button {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 2px;

        span {
          color: ${(props) => props.theme.colors.gray1};
          font-size: 11px;
          line-height: 14px;
        }

        &:hover span {
          color: ${(props) => props.theme.colors.gray1};
        }

        .ant-btn-icon span {
          font-size: 24px;
        }
      }
    }

    .ant-upload-list {
      .ant-upload-list-item-container {
        display: none !important;
      }

      .ant-upload {
        width: fit-content !important;
        height: fit-content !important;
        margin: 0px !important;

        &.ant-upload-select {
          background-color: transparent !important;
          border: 0px !important;
        }
      }
    }
  }
`
