import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Layout1 from '@/layouts/Layout1'
import SkillTrackHeader from './components/SkillTrackHeader'
import SkillTrackContent from './components/SkillTrackContent'
import { resetSkillTracksState } from '@/store/skillTracks/actions'
import { Container } from './styles'

const SkillTrackPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetSkillTracksState())

    return () => {
      dispatch(resetSkillTracksState())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1 navbar>
      <Container>
        <SkillTrackHeader />

        <SkillTrackContent />
      </Container>
    </Layout1>
  )
}

export default SkillTrackPage
