import { useClearRefinements } from 'react-instantsearch'
import Button from '@/components/Button'
import { Container } from './styles'

const ClearFilters = () => {
  const { canRefine, refine } = useClearRefinements({})

  return (
    <Container className="clear-filters-container">
      <Button className="clear-filters" size="small" disabled={!canRefine} onClick={refine}>
        Clear filters
      </Button>
    </Container>
  )
}

export default ClearFilters
