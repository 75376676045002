import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Tabs, Collapse, Tooltip, Skeleton } from 'antd'
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { capitalize } from '@/utils/functions'
import HTMLBlock from '@/components/HTMLBlock'
import GptRunButtons from '../GptRunButtons'
import DjangoIcon from '@/assets/images/icons/django.svg?react'
import GitHubIcon from '@/assets/images/icons/github.svg?react'
import { getIssues, resetIssuesState } from '@/store/issues/actions'
import { getGptRuns, getGptTasks, resetGptState } from '@/store/gpt/actions'
import { Modal } from './styles'

const AdminIssuesModal = ({ isVisible, setIsVisible, activity }) => {
  const dispatch = useDispatch()

  const { currentModule: module } = useSelector((state) => state.modules)
  const { items: issues } = useSelector((state) => state.issues)
  const { gptRuns, isLoading: isGptLoading } = useSelector((state) => state.gpt)
  const { userProfile } = useSelector((state) => state.users)

  const shouldShowGptTasks = userProfile?.permissions?.includes('content.view_gpt_tasks')
  const shouldShowContentIssues = userProfile?.permissions?.includes('content.view_content_issues')

  const handleGptTasksLoad = async () => {
    await dispatch(getGptTasks())
  }

  const handleGptRunsLoad = async () => {
    await dispatch(getGptRuns(activity.id))
  }

  const handleIssuesLoad = async () => {
    await dispatch(getIssues({ activity_id: activity.id }))
  }

  useEffect(() => {
    if (!isVisible || !activity) return

    if (shouldShowGptTasks) handleGptTasksLoad()
    if (shouldShowGptTasks) handleGptRunsLoad()
    if (shouldShowContentIssues) handleIssuesLoad()

    return () => {
      dispatch(resetGptState())
      dispatch(resetIssuesState())
    }
  }, [isVisible, activity]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isVisible || !activity || isGptLoading) return
    let timer

    if (
      gptRuns?.some((task) => task.success) ||
      gptRuns?.some((task) => task?.runs?.some((run) => run.status === 'pending'))
    ) {
      timer = setTimeout(() => {
        handleGptRunsLoad()
      }, 3000)
    }

    return () => {
      timer && clearTimeout(timer)
    }
  }, [isVisible, activity, gptRuns]) // eslint-disable-line react-hooks/exhaustive-deps

  const tabItems = [
    ...(shouldShowGptTasks
      ? [
          {
            key: '1',
            label: `GPT`,
            children: (
              <div className="tab-content">
                <div className="activity-header">
                  <div className="activity-body">
                    <span className="activity-number">{(activity?.order || 0) + 1}</span>

                    <div className="activity-info">
                      {activity?.title_html && (
                        <div className="title-container" dangerouslySetInnerHTML={{ __html: activity?.title_html }} />
                      )}
                    </div>
                  </div>

                  <GptRunButtons activity={activity} />
                </div>

                {gptRuns ? (
                  <Collapse
                    className="gpt-tasks-container"
                    items={gptRuns?.map((gptRun, i) => ({
                      key: `task-${i + 1}`,
                      label: dayjs(gptRun.created).format('lll'),
                      children: gptRun.runs ? (
                        <Collapse
                          className="run-container"
                          bordered={false}
                          items={gptRun.runs?.map((run, j) => {
                            const outputContent =
                              run.output_format === 'markdown' ? <HTMLBlock content={run?.output} /> : run.output

                            return {
                              key: `run-${j + 1}`,
                              label: (
                                <p className="run-title">
                                  {run.status === 'pending' && <LoadingOutlined className="icon pending" />}
                                  {run.status === 'success' && <CheckOutlined className="icon success" />}
                                  {run.status === 'failed' && <CloseOutlined className="icon failed" />}
                                  <span>
                                    Task {j + 1}
                                    {run?.task_definition?.name && `: ${run?.task_definition?.name}`}
                                  </span>
                                </p>
                              ),
                              children: (
                                <div className="run-body">
                                  <Collapse
                                    className="run-container"
                                    bordered={false}
                                    items={[
                                      {
                                        key: '1',
                                        label: 'See prompt',
                                        children: JSON.parse(run.prompt).map((prompt, i) => (
                                          <span key={i}>
                                            <p className="response-title">{prompt?.role || 'No role'}</p>
                                            <pre>{prompt?.content || 'No content'}</pre>
                                          </span>
                                        )),
                                      },
                                    ]}
                                  />

                                  <div className="output-container">
                                    <p className="response-title">Output</p>
                                    <pre>{run.status !== 'pending' ? outputContent : 'Generating output....'}</pre>
                                  </div>

                                  {run.error && (
                                    <div className="output-container">
                                      <p className="response-title">Error</p>
                                      <pre className="error">{run.error}</pre>
                                    </div>
                                  )}
                                </div>
                              ),
                            }
                          })}
                        />
                      ) : (
                        <p>Loading task data...</p>
                      ),
                    }))}
                    defaultActiveKey={[`task-1`]}
                  />
                ) : (
                  <Skeleton active title={false} paragraph={{ rows: 4 }} />
                )}

                {!isGptLoading && !gptRuns?.length && (
                  <div style={{ padding: '20px 0px' }}>
                    <p>There are no GPT runs created yet.</p>
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []),
    ...(shouldShowContentIssues
      ? [
          {
            key: '2',
            label: `User Issues`,
            children: (
              <div className="tab-content issues-content">
                <div className="activity-header">
                  <div className="activity-body">
                    <span className="activity-number">{(activity?.order || 0) + 1}</span>

                    <div className="activity-info">
                      {activity?.title_html && (
                        <div className="title-container" dangerouslySetInnerHTML={{ __html: activity?.title_html }} />
                      )}
                    </div>
                  </div>
                </div>

                {issues ? (
                  <>
                    <p className="info">Open issues: {issues?.results?.filter((i) => i?.status === 'open')?.length}</p>

                    <div className="issues-container">
                      {issues?.results?.map((issue) => (
                        <div key={issue?.id} className="issue-body">
                          <p className="text">
                            <b>Description:</b> {issue?.description}
                          </p>

                          <div className="context">
                            {issue?.status === 'open' && <p className="tag open">Open</p>}
                            {issue?.status === 'resolved' && issue?.resolution_label && (
                              <p className="tag resolved">{issue?.resolution_label}</p>
                            )}

                            <p className="username">
                              {capitalize(issue?.user?.first_name)} {capitalize(issue?.user?.last_name)}
                            </p>

                            <p className="date">Created: {dayjs(issue?.created).format('lll')}</p>

                            {issue?.status === 'resolved' && (
                              <p className="date">Updated: {dayjs(issue?.status_changed).format('lll')}</p>
                            )}
                            {issue?.resolved_by && <p className="date">by: {issue?.resolved_by}</p>}

                            <div className="links-info">
                              {issue?.django_admin_url && (
                                <Tooltip title={'Django Admin URL'} overlayClassName={'on-modal'}>
                                  <a className="link" href={issue?.django_admin_url} target="_blank" rel="noreferrer">
                                    <DjangoIcon />
                                  </a>
                                </Tooltip>
                              )}

                              {issue?.github_issue_url && (
                                <Tooltip title={'GitHub Issue URL'} overlayClassName={'on-modal'}>
                                  <a className="link" href={issue?.github_issue_url} target="_blank" rel="noreferrer">
                                    <GitHubIcon />
                                  </a>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <Skeleton active title={false} paragraph={{ rows: 4 }} />
                )}

                <a
                  className="link"
                  href={`${module?.github_repo_url}/issues?q=is%3Aissue+is%3Aopen`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View issues on GitHub
                </a>
              </div>
            ),
          },
        ]
      : []),
  ]

  return (
    <Modal title={null} open={isVisible} onCancel={() => setIsVisible(false)} footer={null} width={1200}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </Modal>
  )
}

export default AdminIssuesModal
