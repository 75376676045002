import DataSourcesSearch from '@/pages/PlaygroundsPage/components/DataSourcesSearch'
import { Container } from './styles'

const DataSourceSelection = () => {
  return (
    <Container className="data-source-selection">
      <h3 className="title">Select a dataset to use</h3>

      <DataSourcesSearch />
    </Container>
  )
}

export default DataSourceSelection
