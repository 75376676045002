const getIconByUrl = async ({ iconUrl, className, onReady }) => {
  if (iconUrl?.startsWith('http')) {
    onReady(<img className={className} src={iconUrl} alt="" />)
    return
  }

  const [iconLibraryCode, iconName] = iconUrl.split('-')

  if (iconLibraryCode === 'ant') {
    const { [iconName]: Icon } = await import('@ant-design/icons')
    onReady(Icon ? <Icon className={className} /> : <></>)
    return
  }

  if (iconLibraryCode === 'fluent') {
    const { [iconName]: Icon } = await import('@fluentui/react-icons')

    onReady(Icon ? <Icon className={className} /> : <></>)
    return
  }
}

export default getIconByUrl
