import { Tooltip } from 'antd'
import { Container } from './styles'

const Blob = ({ text, onClick, size, addPulse, color }) => {
  return (
    <Tooltip title={text}>
      <Container className="blob-container" $size={size} $addPulse={addPulse} onClick={onClick}>
        <span className={`blob ${color}`} />
      </Container>
    </Tooltip>
  )
}

export default Blob
