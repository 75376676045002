import { BadgeContainer } from './styles'
import { Tooltip, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import Badge1Full from '@/assets/images/badges/1-full.svg?react'
import Badge2Full from '@/assets/images/badges/2-full.svg?react'
import Badge3Full from '@/assets/images/badges/3-full.svg?react'
import Badge4Full from '@/assets/images/badges/4-full.svg?react'
import Badge5Full from '@/assets/images/badges/5-full.svg?react'

const Badge = ({ color, proficiency, earned }) => {
  const getBadgeShape = (proficiency) => {
    switch (proficiency) {
      case 'novice':
        return <Badge1Full />
      case 'intermediate':
        return <Badge2Full />
      case 'advanced':
        return <Badge3Full />
      case 'professional':
        return <Badge4Full />
      case 'expert':
        return <Badge5Full />
      default:
        return <Badge1Full />
    }
  }

  return (
    <>
      <BadgeContainer className="badge" $color={color} $earned={false}>
        <Tooltip title={earned ? 'Completed' : 'Acquire all the skills to obtain this badge'}>
          {color ? getBadgeShape(proficiency) : <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />}
        </Tooltip>

        {earned && <div className="glow" />}
      </BadgeContainer>

      {/* <BadgeContainer className="badge" color={color} earned={true}>
        <Tooltip title={earned ? 'Completed' : 'Acquire all the skills to obtain this badge'}>
          {color ? getBadgeShape(proficiency) : <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />}
        </Tooltip>

        {earned && <div className="glow" />}
      </BadgeContainer>

      <BadgeContainer className="badge" color={color} progress={50}>
        <Tooltip title={earned ? 'Completed' : 'Acquire all the skills to obtain this badge'}>
          {color ? getBadgeShape(proficiency) : <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />}
        </Tooltip>

        <div className="badge-progress" />

        {earned && <div className="glow" />}
      </BadgeContainer> */}
    </>
  )
}

export default Badge
