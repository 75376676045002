import styled from 'styled-components'

export const Editor = styled.div`
  border-radius: 5px;
  margin: 12px 0px;

  .cm-editor {
    font-size: 13px;
    border-radius: 3px;

    &.cm-focused {
      outline: none !important;
    }

    .cm-content,
    .cm-gutter {
      min-height: 120px;
    }
    .cm-widgetBuffer {
      width: 0px;
    }
    .cm-gutters {
      margin: 1px;
    }
    .cm-line {
      color: #c0caf5;
    }
    .cm-scroller {
      overflow: auto;
    }
  }
`
