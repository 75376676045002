import { Tooltip, Avatar } from 'antd'
import DefaultAvatar from '@/assets/images/default-avatar.png'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import BasicBadge from '@/assets/images/plans/basic-badge.png'
import { Container } from './styles'

const ProfilePicture = ({ className = '', src, alt, isPro, hideBadge, disableTooltip, size }) => {
  return (
    <Tooltip title={!disableTooltip && `${isPro ? 'PRO' : 'Basic'} user`} placement="left">
      <Container className={`profile-picture ${className}`} $size={size} $hideBadge={hideBadge}>
        <Avatar src={src || DefaultAvatar} alt={alt} />

        {!hideBadge && (
          <>
            {isPro ? (
              <img className="icon pro-badge" src={ProBadge} alt="PRO user" />
            ) : (
              <img className="icon basic-badge" src={BasicBadge} alt="Basic user" />
            )}
          </>
        )}
      </Container>
    </Tooltip>
  )
}

export default ProfilePicture
