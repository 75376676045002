import styled, { css } from 'styled-components'
import { Alert } from 'antd'

export const LogoHeader = styled.div`
  text-align: center;

  .logo {
    max-width: 176px;
    width: 100%;
    image-rendering: crisp-edges;
    margin-bottom: 20px;
  }

  .title {
    max-width: 350px;
    color: #00000073;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    &.long {
      max-width: 550px;
    }
  }
`

export const SAlert = styled(Alert)`
  width: 360px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed rgba(5, 5, 5, 0.3);
  margin: 0px auto 10px;

  ${(props) =>
    props.type === 'default' &&
    css`
      text-align: left;
    `}

  ${(props) =>
    props.type === 'info' &&
    css`
      color: ${(props) => props.theme.colors.blue6};
      background-color: #e6f7ff;
      border: 1px dashed #91d5ff;
    `}
      
  .ant-alert-message {
    font-size: 16px;

    > p {
      margin: 0px;
    }
  }
`
