import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Input, Select, Checkbox } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import RoleIcon from '@/assets/images/icons/role.svg?react'
import CompanyIcon from '@/assets/images/icons/company.svg?react'
import EmailIcon from '@/assets/images/icons/email.svg?react'
import Button from '@/components/Button'
import { updateProfile } from '@/store/users/actions'
import roles from '@/assets/data/roles.json'

const Step3 = ({ onPrev, onNext }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const { userProfile } = useSelector((state) => state.users)
  const isWorking = userProfile?.occupation === 'working'
  const isStudying = userProfile?.occupation === 'studying'

  const getTitle = () => {
    if (isWorking) return 'Tell us more about your Company'
    else if (isStudying) return 'Tell us more about your University/School'
    else return 'Tell us more about yourself'
  }

  const getSubtitle = () => {
    if (isWorking) return 'Enter company details'
    else if (isStudying) return 'Enter school details'
    else return 'Enter your details'
  }

  const getCompanyText = () => {
    if (isWorking) return 'Company Name'
    else if (isStudying) return 'School / Institution'
    else return 'Company or Institution'
  }

  const getRoleText = () => {
    if (isWorking) return 'Role / Position'
    else if (isStudying) return 'Your degree'
    else return 'Your role or degree'
  }

  const getSecondaryEmailText = () => {
    if (isWorking) return 'Company email'
    else if (isStudying) return 'Educational email'
    else return 'Secondary email'
  }

  const getShareText = () => {
    if (isWorking) return 'Display your company and role in your public profile (your email will never be shared)'
    else if (isStudying)
      return 'Display your university and degree in your public profile (your email will never be shared)'
    else return 'Display your company or role in your public profile (your secondary email will never be shared)'
  }

  const formSubmit = async (values) => {
    setIsLoading(true)
    await dispatch(updateProfile(userProfile?.username, values))
    setIsLoading(false)
    onNext()
  }

  return (
    <div className="content">
      <div className="header">
        <p className="title">{getTitle()}</p>

        <p className="subtitle">{getSubtitle()}</p>
      </div>

      <Form
        className="form-container"
        initialValues={{
          company: userProfile?.company,
          role: userProfile?.role || null,
          secondary_email: userProfile?.secondary_email,
          share_role_company_profile: userProfile?.share_role_company_profile,
        }}
        onFinish={formSubmit}
      >
        <div className="row">
          <Form.Item name="company">
            <Input size="large" placeholder={getCompanyText()} prefix={<CompanyIcon />} disabled={isLoading} />
          </Form.Item>
        </div>

        <div className="row">
          <Form.Item name="role">
            <Select
              size={'large'}
              placeholder={getRoleText()}
              options={roles}
              fieldNames={{ label: 'role', value: 'role' }}
              showSearch
              virtual={false}
              prefix={<RoleIcon />}
              notFoundContent={<span style={{ padding: 12 }}>No results</span>}
              disabled={isLoading}
            />
          </Form.Item>
        </div>

        <div className="row">
          <Form.Item
            name="secondary_email"
            rules={[
              {
                type: 'email',
                message: 'Enter a valid email address.',
              },
              {
                validator: (rule, value) => {
                  if (value === userProfile?.email) {
                    return Promise.reject('Secondary email cannot be the same as your primary email.')
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input size="large" placeholder={getSecondaryEmailText()} prefix={<EmailIcon />} disabled={isLoading} />
          </Form.Item>
        </div>

        <div className="info-text">
          <Form.Item name="share_role_company_profile" valuePropName="checked">
            <Checkbox disabled={isLoading}>{getShareText()}</Checkbox>
          </Form.Item>
        </div>

        <div className="actions">
          <Button className="back-button" type="text" onClick={onPrev} loading={isLoading} disabled={isLoading}>
            <ArrowLeftOutlined /> Back
          </Button>

          <div>
            <Button className="skip-button" type="text" onClick={onNext} loading={isLoading} disabled={isLoading}>
              Skip this step
            </Button>

            <Button type="primary" size="large" htmlType="submit" loading={isLoading} disabled={isLoading}>
              Next
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Step3
