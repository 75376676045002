import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 7px;

  .icon {
    width: 32px;
    height: 32px;
    background-color: ${(props) => props.theme.colors.base.gray10};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    cursor: pointer;
    transition: 0.3s all;

    &.x-icon {
      /* background-color: #222; */

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: #fff;
        }
      }
    }

    &.linkedin-icon {
    }

    &.copy-icon {
      font-size: 16px;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: #fff;
        }
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.base.gray9};
      filter: brightness(1.15);
    }
  }
`
