import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const fetchVoucher = async (voucherCode) => {
  const route = `vouchers/${voucherCode}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postVoucherValidate = async (data) => {
  const { voucherCode } = data
  const route = `vouchers/validate`
  const body = {
    voucher_code: voucherCode,
  }

  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postVoucherSignup = async (data) => {
  const route = `vouchers/signup`
  const body = { ...data }

  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postVoucherRedeem = async (data) => {
  const { voucherCode } = data
  const route = `vouchers/${voucherCode}/redeem`
  const body = {}

  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { fetchVoucher, postVoucherValidate, postVoucherSignup, postVoucherRedeem }
