import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .subtitle {
    color: ${(props) => props.theme.colors.gray8};
    margin-bottom: 20px;

    .icon {
      color: ${(props) => props.theme.colors.gold5};
      margin-right: 4px;
    }
  }

  .pro-info {
    background-color: ${(props) => props.theme.colors.gray4};
    border-radius: 2px;
    padding: 16px 18px;
    margin-bottom: 30px;

    .pro-simple-text {
      color: ${(props) => props.theme.colors.gray8};
      margin: 0px 0px 10px;
    }

    > button {
      height: 42px;
      color: ${(props) => props.theme.colors.base.gray1};
      display: flex;
      gap: 8px;
      align-items: center;

      .pro-badge {
        width: 24px;
      }
    }
  }
`
