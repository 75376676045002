import styled from 'styled-components'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'

export const Modal = styled(AntModal)`
  padding: 14px;

  .ant-modal-content {
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 60px 20px;

    .ant-modal-body {
      max-width: 620px;
      width: 420px;
      display: flex;
      flex-direction: column;
    }
  }

  .steps-container {
    height: 70px;
    display: flex;
    justify-content: center;

    .ant-steps {
      width: 360px;

      .ant-steps-item {
        &.ant-steps-item-finish {
          .ant-steps-item-icon {
            background-color: ${(props) => props.theme.colors.blue1};
            border-color: ${(props) => props.theme.colors.blue1};

            .ant-steps-icon {
              color: ${(props) => props.theme.colors.blue6};
            }
          }

          .ant-steps-item-content {
            .ant-steps-item-title::after {
              background-color: ${(props) => props.theme.colors.blue6};
              top: 10px;
            }
          }
        }

        &.ant-steps-item-active {
          .ant-steps-item-icon {
            background-color: ${(props) => props.theme.colors.blue6};
            border-color: ${(props) => props.theme.colors.blue6};

            .ant-steps-icon {
              color: ${(props) => props.theme.colors.base.gray1};
            }
          }
        }

        .ant-steps-item-icon {
          width: 20px;
          height: 20px;
          background-color: ${(props) => props.theme.colors.gray13}15;
          line-height: 18px;

          .ant-steps-icon {
            color: ${(props) => props.theme.colors.gray8};
          }
        }

        .ant-steps-item-content {
          .ant-steps-item-title::after {
            background-color: ${(props) => props.theme.colors.gray13}20;
            top: 10px;
          }
        }
      }
    }
  }

  .content {
    min-height: 350px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      max-width: 420px;
      color: ${(props) => props.theme.colors.gray10};
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 6px;
    }

    .subtitle {
      max-width: 332px;
      color: ${(props) => props.theme.colors.gray7};
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    margin-top: 30px;

    .row {
      width: 100%;
      display: flex;
      gap: 16px;

      .ant-form-item {
        width: 100%;
        margin-bottom: 0px;
      }

      .ant-select {
        width: 100%;
      }

      .ant-input-prefix {
        margin-inline-end: 8px;

        > svg {
          width: 18px;

          path {
            fill: ${(props) => props.theme.colors.geekblue5};
          }
        }
      }

      &.big-options-row {
        width: 630px;
      }

      .big-option {
        width: 200px;
        height: 200px;
        border: 1px solid ${(props) => props.theme.colors.gray5};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.3s all;
        cursor: pointer;

        &:hover,
        &.active {
          border-color: ${(props) => props.theme.colors.base.geekblue4};
          box-shadow: 0px 1px 6px ${(props) => props.theme.colors.base.gray13}40;
        }

        .icon {
          margin: 10px 0px;
        }

        .text {
          color: ${(props) => props.theme.colors.gray11};
          font-size: 12.8px;
          margin-bottom: 4px;
        }

        .sub-text {
          max-width: 140px;
          color: ${(props) => props.theme.colors.gray6};
          font-size: 11px;
          font-weight: 500;
          line-height: 150%;
          text-align: center;
          margin: 0px;
        }
      }
    }

    .info-text,
    .info-text label {
      color: ${(props) => props.theme.colors.gray7};
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    .done-image {
      width: 246px;
      background-color: ${(props) => props.theme.colors.geekblue2};
      border-radius: 6px;
      padding: 12px;
      margin: -10px 0px -30px;
    }

    .actions {
      width: 420px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin-top: 40px;

      &.vertical {
        flex-direction: column;
        margin-bottom: 50px;
      }

      .ant-btn-text {
        &:hover {
          background: transparent;

          > span:last-of-type {
            border-bottom: 1px solid;
            line-height: 18px;
          }
        }
      }

      .back-button {
        color: ${(props) => props.theme.colors.gray10};
        border: none;
      }

      .skip-button {
        color: ${(props) => props.theme.colors.gray7};
        border: none;
      }

      &.center {
        justify-content: center;
        margin-top: 10px;
      }
    }
  }

  @media ${device.smDown} {
    .ant-modal-content {
      padding: 60px 20px 40px;

      .row {
        flex-direction: column;
        align-items: center;

        .big-option {
          height: 120px;
          padding: 10px;
        }
      }
    }

    .steps-container {
      display: none;
    }
  }
`
