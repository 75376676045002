import inProgressPlaceholderImage from '@/assets/images/modules/inProgressPlaceholder.png'
import { Container } from './styles'

const InProgressPlaceholder = () => {
  return (
    <Container>
      <div className="img-container">
        <img src={inProgressPlaceholderImage} alt="Keep making progress!" />
      </div>

      <div className="module-info">
        <h4 className="title">Keep making progress!</h4>

        <h5 className="text">You don't have any project in progress.</h5>
        <h5 className="text">Select one from the list below to keep practicing.</h5>
      </div>
    </Container>
  )
}

export default InProgressPlaceholder
