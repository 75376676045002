import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'

export const initializeDayjs = () => {
  dayjs.extend(LocalizedFormat)
  dayjs.extend(relativeTime)
  dayjs.extend(advancedFormat)
  dayjs.extend(duration)
  dayjs.extend(utc)
}
