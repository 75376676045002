import {
  SET_DATA_SOURCES_LOADING,
  FEATURED_DATA_SOURCES_UPDATED,
  RELATED_DATA_SOURCES_UPDATED,
  DATA_SOURCE_UPDATED,
  RESET_DATA_SOURCES_STATE,
  SET_DATA_SOURCES_ERROR,
} from '@/store/dataSources'
import { fetchAlgoliaDataSources, fetchDataSource } from '@/services/dataSources'

const getFeaturedDataSources = () => async (dispatch) => {
  try {
    dispatch(SET_DATA_SOURCES_LOADING(true))

    const dataSources = await fetchAlgoliaDataSources('is_featured:true')
    dispatch(FEATURED_DATA_SOURCES_UPDATED(dataSources))
  } catch (error) {
    const { message } = error
    dispatch(SET_DATA_SOURCES_ERROR(message))
  } finally {
    dispatch(SET_DATA_SOURCES_LOADING(false))
  }
}

const getRelatedDataSources = (dataSourceTheme) => async (dispatch) => {
  try {
    dispatch(SET_DATA_SOURCES_LOADING(true))

    const dataSources = await fetchAlgoliaDataSources(`theme.value:'${dataSourceTheme}'`)
    dispatch(RELATED_DATA_SOURCES_UPDATED(dataSources))
  } catch (error) {
    const { message } = error
    dispatch(SET_DATA_SOURCES_ERROR(message))
  } finally {
    dispatch(SET_DATA_SOURCES_LOADING(false))
  }
}

const getDataSource = (dataSourceId, cb) => async (dispatch) => {
  try {
    dispatch(SET_DATA_SOURCES_LOADING(true))

    const dataSource = await fetchDataSource(dataSourceId)
    dispatch(DATA_SOURCE_UPDATED(dataSource))

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_DATA_SOURCES_ERROR(message))
  } finally {
    dispatch(SET_DATA_SOURCES_LOADING(false))
  }
}

const resetCurrentDataSource = () => (dispatch) => {
  dispatch(DATA_SOURCE_UPDATED(null))
}

const resetDataSourcesState = () => (dispatch) => {
  dispatch(RESET_DATA_SOURCES_STATE())
}

export { getFeaturedDataSources, getRelatedDataSources, getDataSource, resetCurrentDataSource, resetDataSourcesState }
