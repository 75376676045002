import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const postActivityAttempt = async (data) => {
  const { activityId, moduleAttemptId, labSessionId, selectedAnswer, textAnswer, submittedAnswer } = data
  const route = `activities/${activityId}/attempts`
  const body = {
    module_attempt_id: moduleAttemptId,
    lab_session_id: labSessionId,
    submitted_answer: {
      text_answer: textAnswer,
      selected_answer_ids: selectedAnswer,
      ...(submittedAnswer ? { code_answer: submittedAnswer } : {}),
    },
  }
  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postActivityHint = async (data) => {
  const { activityId, moduleAttemptId } = data
  const route = `activities/${activityId}/hint`
  const body = {
    module_attempt_id: moduleAttemptId,
  }
  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postActivitySolution = async (data) => {
  const { activityId, moduleAttemptId } = data
  const route = `activities/${activityId}/solution`
  const body = {
    module_attempt_id: moduleAttemptId,
  }
  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postActivityReaction = async (activityId, data) => {
  const route = `activities/${activityId}/reactions`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteActivityReaction = async (activityId, data) => {
  const route = `activities/${activityId}/reactions`
  return API.delete(route, { data })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { postActivityAttempt, postActivityHint, postActivitySolution, postActivityReaction, deleteActivityReaction }
