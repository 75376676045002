import { useEffect } from 'react'
import prism from '@/utils/prism'
import { initMermaid } from '@/utils/mermaid'
import { MarkdownBlock } from './styles'

const HTMLBlock = ({ content, className, theme }) => {
  const isDarkTheme = localStorage.getItem('dw-theme') === 'dark' || theme === 'dark'

  useEffect(() => {
    initMermaid()
    window.MathJax.typeset()

    // execute when DOM is ready
    setTimeout(() => prism.highlightAll(), 0)
  }, [content])

  if (typeof content === 'object') {
    return (
      <MarkdownBlock className={`${className} html-block markdown-block ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
        {content}
      </MarkdownBlock>
    )
  }

  return (
    <MarkdownBlock
      className={`${className} html-block markdown-block ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}
      dangerouslySetInnerHTML={{
        // __html: content?.replaceAll('\n', '<br/>'),
        __html: content,
      }}
    />
  )
}

export default HTMLBlock
