import {
  APP_ERROR_UPDATED,
  APP_NOTIFICATION_UPDATED,
  APP_THEME_UPDATED,
  PPP_INFO_UPDATED,
  TOGGLE_UPGRADE_TO_PRO_MODAL,
  TOGGLE_UPGRADE_TO_PRO_SUCCESS_MODAL,
  TOGGLE_CUSTOM_REPORT_ISSUE_MODAL,
} from '@/store/app'
import { fetchPPPInfo } from '@/services/discounts'

const setAppError = (error) => (dispatch) => {
  dispatch(APP_ERROR_UPDATED(error))
}

const setAppNotification = (notification) => (dispatch) => {
  dispatch(APP_NOTIFICATION_UPDATED(notification))
}

const setAppTheme = (theme) => (dispatch) => {
  localStorage.setItem('dw-theme', theme)
  dispatch(APP_THEME_UPDATED(theme))
}

const getPPPInfo = () => async (dispatch) => {
  try {
    const pppInfo = await fetchPPPInfo()

    dispatch(PPP_INFO_UPDATED(pppInfo))
  } catch (error) {
    // const { message } = error
  }
}

const toggleUpgradeToProModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_UPGRADE_TO_PRO_MODAL(isOpen))
}

const toggleUpgradeToProSuccessModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_UPGRADE_TO_PRO_SUCCESS_MODAL(isOpen))
}

const toggleCustomReportIssueModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_CUSTOM_REPORT_ISSUE_MODAL(isOpen))
}

export {
  setAppError,
  setAppNotification,
  setAppTheme,
  getPPPInfo,
  toggleUpgradeToProModal,
  toggleUpgradeToProSuccessModal,
  toggleCustomReportIssueModal,
}
