import { useState, useEffect } from 'react'
import { Input } from 'antd'
import { PasswordContainer as SPasswordContainer } from './styles'

const PasswordInput = (props) => {
  const [meter, setMeter] = useState(false)
  const [password, setPassword] = useState('')

  // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g
  const atLeastOneUppercase = /[A-Z]/g // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g // numbers from 0 to 9
  const atLeastOneSpecialChar = /[\^$*.[\]{}()?"!@#%&\\/,><':;|_~`=+-]/g // any of the special characters within the square brackets
  const eightCharsOrMore = /.{8,}/g // eight characters or more

  const passwordTracker = {
    uppercase: password.match(atLeastOneUppercase),
    lowercase: password.match(atLeastOneLowercase),
    number: password.match(atLeastOneNumeric),
    specialChar: password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: password.match(eightCharsOrMore),
  }

  const passwordStrength = Object.values(passwordTracker).filter((value) => value).length

  useEffect(() => {
    if (!meter || !password) {
      props.handleChange('')
      return
    }

    let errors = []
    if (!passwordTracker.uppercase) errors.push('uppercase')
    if (!passwordTracker.lowercase) errors.push('lowercase')
    if (!passwordTracker.number) errors.push('number')
    if (!passwordTracker.specialChar)
      // errors.push(`special character\n^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \` = + -`)
      errors.push('special character')
    if (!passwordTracker.eightCharsOrGreater) errors.push('at least 8 characters')

    if (passwordStrength < 5) {
      props.handleChange(`Password must contain ${errors.join(', ')}`)
      return
    }

    props.handleChange('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordStrength])

  let auxProps = { ...props }
  delete auxProps.handleChange

  return (
    <SPasswordContainer $level={passwordStrength} {...auxProps}>
      <Input.Password
        {...auxProps}
        onFocus={() => setMeter(true)}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        value={password}
        name="password"
      />

      {meter && <div className="password-strength-meter"></div>}
    </SPasswordContainer>
  )
}

export default PasswordInput
