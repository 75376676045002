import {
  SET_ACCOUNTS_LOADING,
  ACCOUNT_UPDATED,
  ACCOUNT_UI_CONFIG_UPDATED,
  SET_ACCOUNTS_ERROR,
  RESET_ACCOUNTS_STATE,
} from '@/store/accounts'
import { getAccountMe } from '@/services/accounts'
import { getDefaultUiConfig } from '@/helpers/getDefaultUiConfig'

const fetchAccountMe = (onSuccess, onError) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const account = await getAccountMe()

    dispatch(ACCOUNT_UPDATED(account))
    dispatch(ACCOUNT_UI_CONFIG_UPDATED(getDefaultUiConfig(account)))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const { message } = error

    if (onError) {
      onError()
    }

    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(true))
  }
}

const resetAccountsState = () => (dispatch) => {
  dispatch(RESET_ACCOUNTS_STATE())
}

export { fetchAccountMe, resetAccountsState }
