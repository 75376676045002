const capitalize = (string) => {
  if (!string) return

  const firstLetter = string.charAt(0)
  const firstLetterCap = firstLetter.toUpperCase()
  const remainingLetters = string.slice(1)
  const capitalizedWord = firstLetterCap + remainingLetters

  return capitalizedWord
}

export default capitalize
