import DiscordIcon from '@/assets/images/discord.svg?react'
import { Container } from './styles'

const DiscordBanner = () => {
  return (
    <Container
      className="banner discord-banner"
      href={import.meta.env.REACT_APP_DISCORD_INVITE_LINK}
      target="_blank"
      rel="noreferrer"
    >
      <div className="info-box">
        <DiscordIcon className="icon-container" />

        <div className="content">
          <p className="text">
            <b>Join our Discord community!</b>
            <br />
            Get help with your projects and discuss with other likeminded data scientists.
          </p>
        </div>
      </div>
    </Container>
  )
}

export default DiscordBanner
