import DiscordIcon from '@/assets/images/discord.svg?react'

import { Container } from './styles'

const DiscordInfo = () => {
  return (
    <Container>
      <a href={import.meta.env.REACT_APP_DISCORD_INVITE_LINK} target="_blank" rel="noreferrer">
        <p className="title">
          <DiscordIcon /> <span>Join our Community on Discord</span>
        </p>
      </a>

      <p className="text">
        If you have questions about how to resolve the project, please use the{' '}
        <a href={import.meta.env.REACT_APP_DISCORD_INVITE_LINK} target="_blank" rel="noreferrer">
          #help-wanted
        </a>{' '}
        channel on Discord.
      </p>

      <p className="text">
        If you're not sure if what you're experiencing is an issue, start a discussion in the channel{' '}
        <a href={import.meta.env.REACT_APP_DISCORD_INVITE_LINK} target="_blank" rel="noreferrer">
          #platform-issues
        </a>
        .
      </p>
    </Container>
  )
}

export default DiscordInfo
