import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Progress, Rate, Skeleton } from 'antd'
import { showToast } from '@/utils/toast'
import SkillBullet from '../SkillBullet'
import { getLearningAreas } from '@/store/learningAreas/actions'
import { getSkillTrack } from '@/store/skillTracks/actions'
import Badge from '@/components/Badge'
import { Container } from './styles'

const SkillTrackHeader = () => {
  const dispatch = useDispatch()
  const { skillTrackId } = useParams()

  const [isLoading, setIsLoading] = useState(true)

  const { items: learningAreas } = useSelector((state) => state.learningAreas)
  const { currentSkillTrack: skillTrack, error: skillTrackError } = useSelector((state) => state.skillTracks)

  const learningArea = learningAreas?.find((la) => la.id === skillTrack?.learning_area)
  const _COLOR = skillTrack && learningArea?.color

  const progress = Math.round((skillTrack?.user_status?.progress || 0) * 100, 2)
  const isEarned = progress === 100

  const isLoadingSkillsContent = skillTrack?.skills?.[0] && !skillTrack?.skills?.[0]?.modules

  const handleInitialLoad = async () => {
    setIsLoading(true)
    await dispatch(getLearningAreas())
    await dispatch(getSkillTrack(skillTrackId))
    setIsLoading(false)
  }

  useEffect(() => {
    handleInitialLoad()

    if (skillTrackError) {
      showToast('There was an error loading skill track content', 'error')
    }
  }, [skillTrackId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container $color={_COLOR}>
      <div className="content">
        {isLoading || isLoadingSkillsContent ? (
          <Skeleton active style={{ margin: 30 }} />
        ) : (
          <>
            <div className="skill-track-info">
              <Badge proficiency={skillTrack?.proficiency} color={_COLOR} earned={isEarned} />

              <div className="header-body">
                <div className="pre-title-content">
                  <p className={`pre-title ${isEarned ? 'earned' : ''}`}>{isEarned ? 'Track earned' : 'Track'}</p>

                  <div className="rating">
                    <Rate disabled allowHalf defaultValue={skillTrack?.rating?.avg} />{' '}
                    {skillTrack?.rating?.avg ? (
                      Math.round(skillTrack?.rating?.avg * 100) / 100
                    ) : (
                      <span className="subtext">Not enough data</span>
                    )}
                  </div>
                </div>

                <h2 className="title">{skillTrack?.name}</h2>

                <div className="skill-track-progress">
                  <div className="progress-box">
                    <Progress percent={progress} showInfo={false} />
                    <p className="progress-text">{progress}%</p>
                  </div>

                  <div className="skill-bullets-box">
                    <div className="skill-bullets-container">
                      {!skillTrack?.skills?.length && <p>No skills available</p>}

                      {skillTrack?.skills
                        ?.filter((s) => s?.status !== 'coming_soon')
                        ?.map((s) => (
                          <SkillBullet key={s.id} skill={s} />
                        ))}
                    </div>

                    <p className="progress-text">
                      {skillTrack?.user_status?.finished_skills}/{skillTrack?.user_status?.total_skills} skill
                      {skillTrack?.skills?.length === 1 ? '' : 's'} acquired
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="actions">
              {/* <Button type="primary">
                  Keep practicing
                </Button> */}
            </div>
          </>
        )}
      </div>

      {!isLoading && (
        <div className="la-image">
          <div className="shape-1" />
          <div className="shape-2" />
        </div>
      )}
    </Container>
  )
}

export default SkillTrackHeader
