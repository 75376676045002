import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { WarningFilled } from '@ant-design/icons'
import { showToast } from '@/utils/toast'
import Layout1 from '@/layouts/Layout1'
import GlobalLoader from '@/components/GlobalLoader'
import Button from '@/components/Button'
import { logout } from '@/store/users/actions'
import { fetchInvite, acceptInvite } from '@/store/invites/actions'
import Logo from '@/assets/images/logo-white.svg'
import { Container } from './styles'

const InvitePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { inviteId } = useParams()

  const { currentInvite: invite, isLoading, error } = useSelector((state) => state.invites)
  const inviteDoesNotExistError =
    error?.includes("Invite link doesn't exist or has expired") &&
    'If you think this is an error, please reach to your admin.'
  const userHasOtherAccountError =
    error?.includes("You can't accept this invite") &&
    'Your user is already associated to another DataWars Teams account. If you think this is an error, please reach out at support@datawars.io.'
  const isWrongUserError =
    error?.includes('This invite has been sent to') && 'Please log in using that email address to accept it.'

  const redirect = () => {
    navigate('/', { replace: true })
  }

  const handleAcceptInvite = async () => {
    const isAccepted = await dispatch(acceptInvite(inviteId))

    if (isAccepted) {
      redirect()
      showToast('Invite has been accepted')
    }
  }

  const handleLogout = () => {
    const invitationEmail =
      isWrongUserError && error?.split('This invite has been sent to')?.[1]?.split(' ')?.[0]?.replaceAll('+', '%2B')

    dispatch(logout(() => navigate(`&email=${invitationEmail}`, { replace: true })))
  }

  useEffect(() => {
    if (!inviteId) redirect()

    dispatch(fetchInvite(inviteId))
  }, [inviteId]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!invite && !error) {
    return <GlobalLoader />
  }

  return (
    <Layout1 navbar>
      <Container>
        <div className="content">
          <div className="logo-header">
            <img className="logo" src={Logo} alt="datawars.io logo" />

            <p className="teams-logo">
              <span className="divider" />
              Teams
            </p>
          </div>

          <div className="body">
            <div className="title-container">
              <h1 className="title">Upskill your team with Real Life projects.</h1>
            </div>

            <div className="card-container">
              {error ? (
                <div className="error-icon-container">
                  <WarningFilled className="icon" />
                </div>
              ) : (
                <div className="account-image-container">
                  {invite?.account?.image_url && (
                    <img className="account-image" src={invite?.account?.image_url} alt={invite?.account?.name} />
                  )}
                </div>
              )}

              <h3 className="title">
                {error ? error : `You have been invited to join ${invite?.account?.name} on DataWars.`}
              </h3>

              {inviteDoesNotExistError && <p className="text">{inviteDoesNotExistError}</p>}
              {userHasOtherAccountError && <p className="text">{userHasOtherAccountError}</p>}
              {isWrongUserError && <p className="text">{isWrongUserError}</p>}

              {!error && (
                <p className="text">
                  <span className="highlight">{invite?.account?.name}</span> is inviting you to join their Teams account
                  on DataWars.
                </p>
              )}

              {!error && invite?.custom_text_html && (
                <div
                  className="custom-text-container"
                  dangerouslySetInnerHTML={{
                    __html: invite?.custom_text_html,
                  }}
                />
              )}

              <div className="actions">
                {error ? (
                  isWrongUserError ? (
                    <Button className="cta" type="secondary" size="large" onClick={handleLogout}>
                      Sign out
                    </Button>
                  ) : (
                    <Button className="cta" type="secondary" size="large" onClick={redirect}>
                      Go to Dashboard
                    </Button>
                  )
                ) : (
                  <Button
                    className="cta"
                    type="secondary"
                    size="large"
                    onClick={handleAcceptInvite}
                    loading={isLoading}
                  >
                    Accept invitation
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout1>
  )
}

export default InvitePage
