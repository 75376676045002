import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Container } from './styles'

const ProLabel = () => {
  return (
    <Container className="pro-label">
      <img className="pro-badge" src={ProBadge} alt="PRO user" />
      <span>PRO</span>
    </Container>
  )
}

export default ProLabel
