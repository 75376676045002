import * as React from "react";
const Svg2Full = (props) => /* @__PURE__ */ React.createElement("svg", { id: "badge-2", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 600 600", style: {
  enableBackground: "new 0 0 600 600"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { id: "ribbon", d: "M381.6,507.8l-75.2-47c-4-2.5-8.7-2.5-12.7,0l-75.2,47c-6.8,4.2-14.5-3.3-12.8-12.7l17.6-152.3H377 L394.4,495C396,504.4,388.4,512,381.6,507.8L381.6,507.8L381.6,507.8z", style: {
  fill: "#525776"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { id: "medal-border", d: "M128,253.8L284.2,97.6c8.7-8.7,22.8-8.7,31.5,0L472,253.8c8.7,8.7,8.7,22.8,0,31.5 L315.7,441.5c-8.7,8.7-22.8,8.7-31.5,0L128,285.3C119.3,276.6,119.3,262.5,128,253.8L128,253.8z", style: {
  fill: "#7F8297"
} })), /* @__PURE__ */ React.createElement("g", { id: "transparent-center" }, /* @__PURE__ */ React.createElement("polygon", { points: "135.1,269.5 300,434.4 300,269.5  ", style: {
  fill: "#808080"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "300,104.6 135.1,269.5 300,269.5  ", style: {
  fill: "#CBCBCB"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "300,434.4 464.9,269.5 300,269.5  ", style: {
  fill: "#B2B2B2"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "300,269.5 464.9,269.5 300,104.6  ", style: {
  fill: "#989898"
} })), /* @__PURE__ */ React.createElement("path", { id: "internal-jewel", d: "M301,180.2l88.4,88.4c0.6,0.6,0.6,1.4,0,2L301,358.8c-0.6,0.6-1.4,0.6-2,0l-88.4-88.4 c-0.6-0.6-0.6-1.4,0-2l88.4-88.3C299.6,179.6,300.4,179.6,301,180.2L301,180.2z", style: {
  opacity: 0.8,
  fill: "#4A4D69",
  enableBackground: "new"
} }), /* @__PURE__ */ React.createElement("path", { id: "spark", d: "M214,255.5l3-5.5l3,5.5c2.6,4.7,6.3,8.5,11,11l5.5,3l-5.5,3c-4.7,2.6-8.5,6.3-11,11l-3,5.5l-3-5.5 c-2.6-4.7-6.3-8.5-11-11l-5.5-3l5.5-3C207.6,264,211.5,260.1,214,255.5L214,255.5L214,255.5z", style: {
  fill: "#FFFFFF"
} }));
export default Svg2Full;
