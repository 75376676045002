import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .streak-box {
    background-color: ${(props) => props.theme.colors.geekblue2};
    border-radius: 10px;
    padding: 24px 30px;

    .title {
      margin: 0px;
    }

    .text {
      color: ${(props) => props.theme.colors.gray7};
    }

    .streak-info {
      display: flex;
      gap: 14px;

      .current-streak {
        color: ${(props) => props.theme.colors.gray10};
        background-color: ${(props) => props.theme.colors.geekblue3};
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 8px 6px 8px 16px;

        > span {
          font-size: 28px;
          font-weight: 500;
          line-height: 40px;
        }

        > svg {
          width: 34px;
        }
      }

      .week-days-streak {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .day-streak {
          width: 30px;
          height: 30px;
          background-color: ${(props) => props.theme.colors.gray1};
          font-size: 14px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px;

          &.current-day {
            border: 2px solid ${(props) => props.theme.colors.gray9};
          }

          &.active {
            background-color: ${(props) => props.theme.colors.cyan3};
          }
        }
      }
    }
  }

  .activity-box {
    background-color: ${(props) => props.theme.colors.gray1};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 24px 30px;

    > img {
      filter: ${localStorage.getItem('dw-theme') === 'dark' ? 'brightness(90%)' : 'brightness(100%)'};
      margin: -30px 0px -50px;
    }

    .title {
      margin: 0px;
    }

    .chart {
      margin: 30px 0px 0px;

      * {
        font-family: 'Inter', sans-serif;
      }
    }

    .text {
      max-width: 330px;
      color: ${(props) => props.theme.colors.gray7};
      text-align: center;
      margin: 0px auto 20px;
    }
  }

  .stats-box {
    background-color: ${(props) => props.theme.colors.geekblue2};
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .stat-container {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      border-right: 1px solid ${(props) => props.theme.colors.geekblue3};
      padding: 20px 0px;

      &:last-child {
        border-right: none;
      }

      .stat-info {
        width: 88px;

        .title {
          color: ${(props) => props.theme.colors.gray8};
          opacity: 60%;
          display: block;
          margin-bottom: 6px;
        }

        .value {
          margin: 0px;
        }
      }
    }
  }

  @media ${device.xlDown} {
    .streak-box {
      padding: 24px 20px;

      .streak-info {
        gap: 8px;

        .current-streak {
          padding: 6px 4px 6px 10px;

          > span {
            font-size: 28px;
          }

          > svg {
            width: 28px;
          }
        }

        .week-days-streak {
          .day-streak {
            width: 24px;
            height: 24px;
            font-size: 12px;
          }
        }
      }
    }
  }

  @media ${device.lgDown} {
    .streak-box {
      background: blue;
      text-align: center;
      padding: 24px 20px;

      .streak-info {
        flex-direction: column;
        align-items: center;

        .current-streak {
          width: fit-content;
          padding: 6px 4px 6px 14px;

          > span {
            font-size: 28px;
          }

          > svg {
            width: 28px;
          }
        }

        .week-days-streak {
          display: none;
        }
      }
    }
  }
`
