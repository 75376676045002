import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;

  .loading-container,
  .error-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }

  .wizard-content {
    height: 100%;
    display: flex;
    justify-content: flex-start;

    > .wizard-steps {
      margin-top: 105px;
    }

    .step-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &.center {
        align-items: center;
      }
    }
  }

  .actions {
    max-width: calc(100% + 100px);
    width: 100%;
    position: sticky;
    bottom: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-btn-text {
      color: ${(props) => props.theme.colors.gray10};

      &:hover {
        color: ${(props) => props.theme.colors.gray9};
      }
    }
  }

  @media ${device.xlDown} {
    .wizard-content {
      height: calc(100% - 40px);
      padding-bottom: 10px;

      > .wizard-steps {
        display: none;
      }
    }

    .step-content {
      align-items: center !important;

      .datasources-search-container {
        justify-content: center;
      }
    }

    .actions {
      background-color: ${(props) => props.theme.colors.gray4};
      position: relative;
      bottom: 0px;
      padding: 10px 0px;
      margin-top: -10px;
    }
  }
`
