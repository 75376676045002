import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-tabs {
    margin-top: -14px;

    .tab-content {
      .activity-header {
        padding-bottom: 10px;
        margin-bottom: 10px;

        .activity-body {
          display: flex;
          flex: 1;
          gap: 10px;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid ${(props) => props.theme.colors.gray4};

          .activity-number {
            color: ${(props) => props.theme.colors.gray10};
            height: 26px;
            max-width: 26px;
            width: 100%;
            background-color: ${(props) => props.theme.colors.gray4};
            font-size: 13px;
            font-weight: 600;
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-top: -2px;
          }

          .activity-info {
            .title-container {
              flex: 1;

              p {
                color: ${(props) => props.theme.colors.gray10};
                font-weight: 600;
                font-size: 15px;
                line-height: 22px;
                margin: 0px;
              }
            }

            .subtitle {
              color: ${(props) => props.theme.colors.gray7};
              margin: 0px;
            }
          }
        }

        .tasks-body {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          .tasks-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .task-container {
              width: 180px;
              text-align: center;
              border: 1px solid ${(props) => props.theme.colors.gray5};
              padding: 10px;

              .task-name {
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                margin-bottom: 4px;
              }
            }

            .ant-skeleton-paragraph {
              display: flex;
              gap: 10px;

              li {
                width: 200px !important;
                height: 84px;
                border-radius: 0px;
                border-bottom: none;
                margin: 0px;
              }
            }
          }
        }
      }

      .gpt-tasks-container {
        border-radius: 0px;

        .ant-collapse-item {
          border-radius: 0px;
        }

        .run-container {
          background-color: ${(props) => props.theme.colors.gray2};

          .ant-collapse-item {
            background-color: ${(props) => props.theme.colors.gray1};
            border: none;
            margin-bottom: 1px;

            .ant-collapse-header {
              background-color: ${(props) => props.theme.colors.gray3};
              padding: 6px 10px;
            }

            &:not(.ant-collapse-item-active) .ant-collapse-header {
              background: ${(props) => props.theme.colors.gray1};
            }
          }

          .run-title {
            display: flex;
            align-items: center;
            gap: 4px;
            margin: 0px;

            .icon {
              color: ${(props) => props.theme.colors.gray1};
              background-color: ${(props) => props.theme.colors.gray9};
              font-size: 10px;
              border-radius: 50%;
              padding: 3px;

              &.success {
                background-color: ${(props) => props.theme.colors.green5};
              }

              &.pending {
                background-color: ${(props) => props.theme.colors.geekblue5};
              }

              &.failed {
                background-color: ${(props) => props.theme.colors.red5};
              }
            }
          }

          .run-body {
            .ant-collapse-header {
              background: ${(props) => props.theme.colors.gray1};
            }

            .response-title {
              color: ${(props) => props.theme.colors.gray7};
              font-size: 12px;
              font-weight: 700;
              line-height: 20px;
              margin: 0px;
            }

            pre {
              color: ${(props) => props.theme.colors.gray9};
              font-size: 12px;
              white-space: pre-wrap;
              border-radius: 8px;
              border: 1px solid ${(props) => props.theme.colors.gray4};
              margin: 0px 0px 10px;

              &.error {
                background-color: ${(props) => props.theme.colors.red1};
              }

              .markdown-block {
                background-color: ${(props) => props.theme.colors.gray2};

                p {
                  font-size: 12px;
                }

                p code,
                pre code {
                  font-size: 11px;
                }
              }
            }
          }
        }

        .output-container {
          padding: 16px;
        }
      }

      .ant-skeleton-paragraph {
        li {
          height: 46px;
          border-radius: 0px;
          border-bottom: 1px solid ${(props) => props.theme.colors.gray9};
          margin: 0px;
        }

        > li:last-child:not(:first-child) {
          width: 100% !important;
        }
      }

      &.issues-content {
        .info {
          color: ${(props) => props.theme.colors.gray8};
        }

        .link {
          color: ${(props) => props.theme.colors.gray10};
          text-decoration: underline;
        }

        .issues-container {
          max-height: 448px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          overflow: auto;
          margin-bottom: 10px;

          .issue-body {
            position: relative;
            display: flex;
            gap: 30px;
            border-radius: 8px;
            border: 1px solid ${(props) => props.theme.colors.gray4};
            padding: 20px;

            .text {
              width: 100%;
              color: ${(props) => props.theme.colors.gray9};
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
            }

            .context {
              min-width: 182px;

              .tag {
                background-color: ${(props) => props.theme.colors.gray8};
                color: ${(props) => props.theme.colors.gray1};
                display: inline-block;
                font-size: 9px;
                text-transform: uppercase;
                font-weight: 600;
                line-height: 20px;
                border-radius: 50px;
                padding: 0px 7px;
                margin: 0px 0px 6px;

                &.open {
                  background-color: ${(props) => props.theme.colors.green4};
                }
              }

              .username {
                color: ${(props) => props.theme.colors.gray13};
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                margin: 0px;
              }

              .date {
                color: ${(props) => props.theme.colors.gray7};
                font-size: 11px;
                font-weight: 400;
                line-height: 18px;
                margin: 0px;
              }

              .links-info {
                display: flex;
                align-items: center;
                gap: 12px;
                border-top: 1px solid ${(props) => props.theme.colors.gray4};
                padding-top: 10px;
                margin-top: 8px;

                .link {
                  display: flex;
                  border-radius: 50%;

                  &.error {
                    background: ${(props) => props.theme.colors.red4};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
