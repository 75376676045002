import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.a`
  flex: 1 1 50px;

  .info-box {
    height: 84px;
    background-color: ${(props) => props.theme.colors.geekblue2};
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    padding: 12px 34px 12px 14px;

    .icon-container {
      width: 60px;
      height: 60px;
      background-color: ${(props) => props.theme.colors.base.geekblue4};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: ${(props) => props.theme.colors.gray1};
        font-size: 26px;
      }
    }

    > .content {
      width: calc(100% - 70px);

      .title {
        margin: 0px;
      }

      .text {
        .link {
          color: initial;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  &.validate-email-banner {
  }

  @media ${device.xlDown} {
    .info-box {
      flex-direction: column;
      border-radius: 10px;

      .icon-container {
        display: none;
      }

      > .content {
        width: 100%;
        text-align: center;

        .text {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
`
