import {
  SET_CUSTOM_PROJECTS_LOADING,
  CUSTOM_PROJECTS_UPDATED,
  RESET_CUSTOM_PROJECTS_STATE,
  SET_CUSTOM_PROJECTS_ERROR,
} from '@/store/customProjects'
import { getCustomProjects } from '@/services/customProjects'

const fetchCustomProjects = (params) => async (dispatch) => {
  try {
    dispatch(SET_CUSTOM_PROJECTS_LOADING(true))

    const customProjects = await getCustomProjects(params)
    dispatch(CUSTOM_PROJECTS_UPDATED(customProjects))
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_PROJECTS_ERROR(message))
  } finally {
    dispatch(SET_CUSTOM_PROJECTS_LOADING(false))
  }
}

const resetCustomProjectsState = () => (dispatch) => {
  dispatch(RESET_CUSTOM_PROJECTS_STATE())
}

export { fetchCustomProjects, resetCustomProjectsState }
