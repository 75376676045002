import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Rate, Input, Spin } from 'antd'
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import { createModuleRating, updateModuleRating, setRatingModalOpen } from '@/store/modules/actions'
import Camping from '@/assets/images/camping.gif'
import { RateContainer } from './styles'

const RateModule = () => {
  const dispatch = useDispatch()

  const { currentModule, isModuleRatingLoading } = useSelector((state) => state.modules)
  const moduleRating = currentModule?.user_status?.module_rating

  const [animationKey, setAnimationKey] = useState()
  const [ratingValue, setRatingValue] = useState(moduleRating?.rating)
  const [extraRatingValue, setExtraRatingValue] = useState()
  const [comments, setComments] = useState()

  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful']

  const handleSetRating = async (rating) => {
    await dispatch(createModuleRating(currentModule.id, { rating }))
    setRatingValue(null)
  }

  const handleSetExtraRating = async (ratingData = {}, shouldClose) => {
    const data = {
      ...moduleRating,
      ...ratingData,
      comments,
    }

    await dispatch(updateModuleRating(moduleRating?.module, data))
    setExtraRatingValue(null)

    if (shouldClose) dispatch(setRatingModalOpen(false))
  }

  useEffect(() => {
    setAnimationKey(Math.random())
  }, [])

  useEffect(() => {}, [moduleRating])

  const customIcons = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />,
  }

  const getRatingStep = () => {
    // FIXME: check if this step is broken
    if (!moduleRating?.rating) {
      return (
        <div className="row">
          <h5 className="title">Rate this project</h5>

          <Rate
            className="rate"
            tooltips={!moduleRating?.rating && desc}
            value={ratingValue}
            onChange={handleSetRating}
            disabled={isModuleRatingLoading || moduleRating?.rating}
          />

          <Spin spinning={isModuleRatingLoading} />
        </div>
      )
    }

    if (!moduleRating?.content_rating) {
      return (
        <>
          <h5 className="title">How well was this project written?</h5>
          <p className="text">(english, spelling, eloquence, etc)</p>

          <div className="rate-faces-container">
            <Rate
              className="rate"
              character={({ index }) => customIcons[index + 1]}
              value={extraRatingValue}
              onChange={(value) => handleSetExtraRating({ content_rating: value })}
              disabled={isModuleRatingLoading || moduleRating?.content_rating}
            />
          </div>

          <div className="actions">
            <p className="text">Question 1 of 3</p>

            <Spin spinning={isModuleRatingLoading} />
          </div>
        </>
      )
    }

    if (!moduleRating?.activities_rating) {
      return (
        <>
          <h5 className="title">How do you rate the activities of this project?</h5>
          <p className="text">(engaging, well explained, etc)</p>

          <div className="rate-faces-container">
            <Rate
              className="rate"
              character={({ index }) => customIcons[index + 1]}
              value={extraRatingValue}
              onChange={(value) => handleSetExtraRating({ activities_rating: value })}
              disabled={isModuleRatingLoading || moduleRating?.activities_rating}
            />
          </div>

          <div className="actions">
            <p className="text">Question 2 of 3</p>

            <Spin spinning={isModuleRatingLoading} />
          </div>
        </>
      )
    }

    if (!moduleRating?.comments) {
      return (
        <>
          <p className="text">Help us to improve DataWars</p>
          <h5 className="title">Anything else you'd like to tell us?</h5>

          <div className="rate-faces-container">
            <Input.TextArea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Your feedback"
              autoSize={{ minRows: 2, maxRows: 6 }}
              disabled={isModuleRatingLoading || moduleRating?.comments}
            />
          </div>

          <div className="actions">
            <p className="text">Question 3 of 3</p>

            <Button
              type="secondary"
              onClick={() => handleSetExtraRating(null, true)}
              loading={isModuleRatingLoading}
              disabled={isModuleRatingLoading}
            >
              Continue
            </Button>
          </div>
        </>
      )
    }

    return (
      <>
        <img src={Camping} alt="Thank you for providing your feedback" />
        <h5 className="title">Thank you for providing your feedback</h5>
        <p className="text" style={{ marginBottom: 16 }}>
          Your input helps us improve DataWars.
        </p>
      </>
    )
  }

  return (
    <RateContainer>
      <div key={animationKey} className="rate-content">
        {getRatingStep()}
      </div>
    </RateContainer>
  )
}

export default RateModule
