import { useSelector } from 'react-redux'
import { Steps } from 'antd'
import { Container } from './styles'

const WizardSteps = ({ isCustomProject }) => {
  const { wizard } = useSelector((state) => state.playgrounds)

  const itemsFlow1 = [
    {
      title: 'Choose environment',
      description: wizard?.jumpbox?.name,
    },
    {
      title: 'Select dataset',
      description:
        wizard?.dataSource !== null ? (wizard?.dataSource?.id ? wizard?.dataSource?.name : 'No dataset selected') : '',
    },
    {
      title: `Configure your ${isCustomProject ? 'Project' : 'Playground'}`,
    },
  ]

  const itemsFlow2 = [
    {
      title: 'Select dataset',
      description: wizard?.dataSource?.name,
    },
    {
      title: 'Choose environment',
      description: wizard?.jumpbox?.name,
    },
    {
      title: `Configure your ${isCustomProject ? 'Project' : 'Playground'}`,
    },
  ]

  const getCurrentStep = () => {
    let step = 0

    // start wizard from data source selection
    if (wizard?.type === '1') {
      step = wizard?.dataSource ? 2 : 1
      return step
    }

    // start wizard with jumpbox selection
    if (wizard?.type === '2') {
      step = wizard?.dataSource ? (wizard?.jumpbox ? 2 : 1) : 0
      return step
    }

    // start without any selection
    if (wizard?.type === '0') {
      step = wizard?.jumpbox ? (wizard?.dataSource ? 2 : 1) : 0
      return step
    }

    return step
  }

  return (
    <Container className="wizard-steps">
      <Steps
        direction="vertical"
        size="small"
        current={getCurrentStep()}
        items={wizard?.type === '2' ? itemsFlow2 : itemsFlow1}
      />
    </Container>
  )
}

export default WizardSteps
