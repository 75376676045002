import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Tag, Switch, Select, Tooltip } from 'antd'
import { InfoCircleOutlined, RocketOutlined, ExportOutlined } from '@ant-design/icons'
import { Timer24Regular, Star24Filled, CheckmarkCircleRegular } from '@fluentui/react-icons'
import dayjs from 'dayjs'
import { isDataWarsHostName, profilesURL } from '@/helpers/env'
import Button from '@/components/Button'
import ProTooltip from '@/components/ProTooltip'
import ProfilePicture from '@/components/ProfilePicture'
import DevicesBox from '../DevicesBox'
import AllocatedModules from '../AllocatedModules'
import LabError from '../LabError'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import { capitalize } from '@/utils/functions'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { toggleAuthModal, toggleEmailVerificationModal } from '@/store/users/actions'
import { createModuleAttempt, resumeModuleAttempt, setResetModuleModalOpen } from '@/store/modules/actions'
import { setLabsError } from '@/store/labs/actions'
import WeightIcon from '@/assets/images/weight.svg?react'
import LearnIcon from '@/assets/images/learn.svg?react'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Content } from './styles'

const LaunchCard = ({ isBanner }) => {
  const dispatch = useDispatch()

  const { uiConfig } = useSelector((state) => state.accounts)
  const { isAuthenticated, userProfile, emailValidationCodeIsValidated } = useSelector((state) => state.users)
  const { currentModule, allocatedModules, isModuleAttemptLoading, moduleAttemptError } = useSelector(
    (state) => state.modules,
  )
  const { currentLab, error: labsError } = useSelector((state) => state.labs)

  const [isBoosted, setBoosted] = useState(false)
  const [instanceId, setInstanceId] = useState()

  const isPro = userProfile?.is_pro || userProfile?.groups?.includes('Pro')

  const requireEmailValidated = userProfile?.organization?.config?.launch_labs_requires_email_validated
  const shouldViewDraftModule = userProfile?.permissions?.includes('content.view_draft_module')
  const canBoostLab = userProfile?.permissions?.includes('labs.boost_lab')
  const canResumeLab = userProfile?.permissions?.includes('labs.resume_lab')
  const canRunMultipleLabs = userProfile?.permissions?.includes('labs.run_multiple_labs')
  const canSelectInstance = userProfile?.permissions?.includes('labs.select_instance')
  const canViewEarlyAccess = userProfile?.permissions?.includes('content.module_early_access')

  const labSession = currentLab?.allocated_session
  const isDeviceStopping = labSession?.devices?.some((d) => d.status === 'stopping')

  const isDraft = ['draft', 'coming_soon', 'Coming soon'].includes(currentModule?.status)
  const isBeta = ['beta'].includes(currentModule?.status)
  const isArchived = currentModule?.status?.toLowerCase() === 'archived'
  const isCustomProject = currentModule?.title?.name === 'Custom Projects'

  const moduleAttempt = currentModule?.user_status?.last_module_attempt
  const isAttemptActive = moduleAttempt?.is_active

  const totalActivities = currentModule?.module_pages?.map((p) => p?.activities?.length).reduce((a, b) => a + b, 0)
  const showMultipleProjectsLimitBlock = !canRunMultipleLabs && !!allocatedModules?.length

  const modulePublicUrl = `${profilesURL}${userProfile?.username}/projects/${currentModule?.id}`

  const openUpgradeToProModal = () => {
    dispatch(toggleUpgradeToProModal(true))
  }

  const getTopBanner = () => {
    const deallocateReason = currentLab?.last_deallocated_session?.deallocate_reason

    if (deallocateReason === 'timed_out') {
      return <div className="lab-top-banner">You ran out of time, your project has been stopped automatically</div>
    }

    if (['inactivity', 'no_ping'].includes(deallocateReason)) {
      return (
        <div className="lab-top-banner">
          This project has been {canResumeLab ? 'paused' : 'stopped'} due to inactivity
        </div>
      )
    }
  }

  const handleCreateAttempt = async () => {
    if (!isAuthenticated) {
      dispatch(toggleAuthModal(true))
      return
    }

    if (!userProfile?.email_validated_at && requireEmailValidated && !emailValidationCodeIsValidated) {
      dispatch(toggleEmailVerificationModal(true))
      return
    }

    const config = {
      ...(isBoosted ? { boost: true } : {}),
      ...(canSelectInstance ? { instance_id: instanceId } : {}),
    }

    if (!!moduleAttempt && !isAttemptActive) {
      dispatch(setResetModuleModalOpen({ isOpen: true, config }))

      return
    }

    await dispatch(createModuleAttempt(currentModule?.id, config))
  }

  const handleResumeAttempt = async () => {
    const config = {
      ...(isBoosted ? { boost: true } : {}),
      ...(canSelectInstance && instanceId ? { instance_id: instanceId } : {}),
    }

    await dispatch(resumeModuleAttempt(currentModule?.id, moduleAttempt?.id, config))
  }

  if (labsError || moduleAttemptError) {
    const isMaxRunningLabsReachedError =
      labsError?.includes('concurrent running labs reached') ||
      moduleAttemptError?.includes('concurrent running labs reached')

    return (
      <Content>
        <div className="card">
          <LabError
            type={isMaxRunningLabsReachedError ? 'warning' : 'danger'}
            title={isMaxRunningLabsReachedError ? 'Can not start this project' : 'Could not start this project'}
            content={
              isMaxRunningLabsReachedError ? (
                <span>
                  This is a safety measure to prevent abuse.
                  <br />
                  Maximum number of concurrent labs running reached.
                </span>
              ) : (
                <span>
                  {labsError || moduleAttemptError || 'Problem ocurred while starting project.'}
                  <br />
                  Please try again.{' '}
                  {isDataWarsHostName ? (
                    <>
                      If the problem persists, please send us an email to:{' '}
                      <a href="mailto:support@datawars.io?subject=Error while starting project">support@datawars.io</a>.
                    </>
                  ) : (
                    'If the problem persists, please contact your lab provider.'
                  )}
                </span>
              )
            }
            buttonContent={
              isMaxRunningLabsReachedError ? <Link to="/dashboard">Back to Dashboard</Link> : <span>Try again</span>
            }
            buttonOnClick={isMaxRunningLabsReachedError ? null : () => dispatch(setLabsError())}
          />
        </div>
      </Content>
    )
  }

  return (
    <Content id="launch-card-container" $isBanner={isBanner} $hasLab={!!currentLab}>
      {uiConfig?.showModuleShareUi && currentModule?.user_status?.last_finished_at && (
        <div className="share-card">
          <p className="text">
            <CheckmarkCircleRegular className="icon" /> Completed on{' '}
            {dayjs(currentModule?.user_status?.last_finished_at).format('LL')}.
          </p>

          <Link to={modulePublicUrl} target="_blank">
            <Button className="share-btn" type="secondary" icon={<ExportOutlined />}>
              Statement of completion
            </Button>
          </Link>
        </div>
      )}

      <div id="launch-module-card" className="card">
        {isBanner && !currentLab && renderModuleImage(currentModule)}

        <div className="content">
          {getTopBanner()}

          {uiConfig?.showModuleEarlyAccessUi && currentModule?.early_access && (
            <div className="early-access-banner">
              <img className="pro-badge" src={ProBadge} alt="PRO user" />
              {canViewEarlyAccess ? (
                <span>Early Access Project</span>
              ) : (
                <span>
                  This project will become available to Basic users on{' '}
                  <b>{dayjs(currentModule?.general_available_at).format('MMMM Do')}</b>.
                </span>
              )}
            </div>
          )}

          <div className="module-header">
            {!isBanner && renderModuleImage(currentModule)}

            <div className="module-header-content">
              {uiConfig?.showModuleSkillTrack && (
                <div className="pre-title">
                  <span className="la-bullet" style={{ backgroundColor: currentModule?.learning_area?.color }} />

                  {isAuthenticated && !isCustomProject ? (
                    <Link className="link" to={`/skill-track/${currentModule?.skill_track?.id}`}>
                      <p className="text">{currentModule?.skill_track?.name}</p>
                    </Link>
                  ) : (
                    <p className="text">{currentModule?.skill_track?.name}</p>
                  )}
                </div>
              )}

              <h4 className="module-title">{currentModule?.name}</h4>

              <div className="module-info">
                <div className="tags-container">
                  {shouldViewDraftModule && isDraft && <Tag className="tag is-draft">Draft</Tag>}
                  {isBeta && <Tag className="tag is-beta">Beta</Tag>}
                  {isArchived && <Tag className="tag is-archived">Archived</Tag>}

                  {currentModule?.module_type === 'learn' && (
                    <Tag className={`tag module-type`}>
                      <LearnIcon />
                      Learn
                    </Tag>
                  )}

                  {uiConfig?.showModuleDifficulty && (
                    <Tag className={`tag difficulty-${currentModule?.difficulty}`}>{currentModule?.difficulty}</Tag>
                  )}
                </div>

                {uiConfig?.showModuleRatingUi && (
                  <Tooltip title="Project average rating">
                    <div className="rating">
                      <Star24Filled className="icon" />

                      {currentModule?.rating?.avg ? (
                        Math.round(currentModule?.rating?.avg * 100) / 100
                      ) : (
                        <span className="subtext">Not enough data</span>
                      )}
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          {uiConfig?.showUpgradeToProUi && showMultipleProjectsLimitBlock ? (
            <Tag className="multiple-projects-limit-block">
              As a Basic user, you can only have one concurrent project running at the same time.
              {isBanner ? <></> : <br />} <a onClick={openUpgradeToProModal}>Upgrade to PRO</a> to start multiple
              projects simultaneously.
            </Tag>
          ) : (
            <>
              <div className="module-description">
                {currentModule?.description ? (
                  <p className="description">{currentModule?.description}</p>
                ) : (
                  <>
                    <p>To start this lab, click on the "Start project" button from below.</p>
                    <p>You can find guidelines and instructions by navigating on the left side of the screen.</p>
                  </>
                )}
              </div>

              <div className="bottom-content">
                <div className="module-settings">
                  <div className="settings-block">
                    {totalActivities && (
                      <Tooltip
                        title={
                          <div className="content">
                            This project contains <b>{totalActivities} interactive activities</b>
                          </div>
                        }
                      >
                        <div className="setting-box activities-count">
                          <WeightIcon className="icon" />
                          <span className="text">{totalActivities}</span>
                        </div>
                      </Tooltip>
                    )}

                    <Tooltip
                      title={
                        <div className="content">
                          <b>Timed project</b> — complete the activities before the timer runs out.
                        </div>
                      }
                    >
                      <div className="setting-box module-time">
                        <Timer24Regular className="icon" />
                        <span className="text">
                          {!isPro && currentModule?.expiration_time
                            ? `${currentModule?.expiration_time} min.`
                            : 'Unlimited'}
                        </span>
                      </div>
                    </Tooltip>

                    {currentLab?.devices && <DevicesBox devices={currentLab?.devices} />}

                    {uiConfig?.showModuleBoostLab && (
                      <Tooltip
                        title={
                          <div className="content">
                            <b>Boost Lab</b> — add extra RAM and CPU for your instance.
                          </div>
                        }
                      >
                        <div className="setting-box is-boosted">
                          <RocketOutlined className="icon" />

                          <ProTooltip
                            isLarge
                            title={!canBoostLab && 'Only PRO users can boost labs.'}
                            content={
                              <p className="text">
                                Boosted Labs have extra CPU and RAM. You'll notice the difference while doing
                                compute-intensive activities or processing a large datasets.
                              </p>
                            }
                            showUpgradeButton
                          >
                            <Switch
                              checked={isBoosted}
                              onChange={setBoosted}
                              disabled={!canBoostLab}
                              loading={isModuleAttemptLoading}
                            />
                          </ProTooltip>
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  {canSelectInstance && (
                    <div className="instance-block">
                      <Select
                        allowClear
                        value={instanceId}
                        placeholder={'Select instance (optional)'}
                        virtual={false}
                        onChange={(e) => setInstanceId(e)}
                        disabled={isModuleAttemptLoading || isDeviceStopping}
                      >
                        {currentModule?.instances?.map((i) => (
                          <Select.Option key={i?.id} value={i?.id}>
                            {i.domain} ({i.type})
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>

                <div className="bottom-options">
                  {uiConfig?.showModuleAuthor && isAttemptActive ? (
                    <span className="author-info">
                      <ProfilePicture
                        src={currentModule?.author?.avatar_url}
                        alt={capitalize(currentModule?.author?.first_name)}
                        size="small"
                        hideBadge
                        disableTooltip
                      />

                      <span className="link">
                        {capitalize(currentModule?.author?.first_name)} {capitalize(currentModule?.author?.last_name)}
                      </span>
                    </span>
                  ) : (
                    <span />
                  )}

                  <div className="main-row">
                    {!isDeviceStopping && currentLab?.last_commit?.created && (
                      <p className="last-checkpoint-date">
                        Last saved: {dayjs(currentLab?.last_commit?.created).format('MMM D, h:mm a')}
                      </p>
                    )}

                    {isDeviceStopping ? (
                      <Button id="stopping-lab-btn" type="primary" size="large" loading disabled>
                        Saving lab
                      </Button>
                    ) : (
                      <ProTooltip
                        isLarge
                        title={
                          uiConfig?.showUpgradeToProUi &&
                          !canViewEarlyAccess &&
                          currentModule?.early_access &&
                          'Exclusive Early Access'
                        }
                        content={
                          <>
                            <p className="text">
                              This project will become available to Basic users on{' '}
                              <b>{dayjs(currentModule?.general_available_at).format('MMMM Do')}</b>.
                            </p>
                            <p className="text">
                              <b>Upgrade to PRO</b> to access this project before anybody else!
                            </p>
                          </>
                        }
                        showUpgradeButton
                      >
                        <Button
                          id="launch-lab-btn"
                          type={!!moduleAttempt && !isAttemptActive ? 'default' : 'primary'}
                          size={'large'}
                          onClick={handleCreateAttempt}
                          loading={isModuleAttemptLoading || isDeviceStopping}
                          disabled={
                            (!canViewEarlyAccess && currentModule?.early_access) ||
                            (!canRunMultipleLabs && !!allocatedModules?.length)
                          }
                        >
                          {!!moduleAttempt && !isAttemptActive ? 'Reset and Launch' : 'Start project'}
                        </Button>
                      </ProTooltip>
                    )}

                    {!!moduleAttempt && !isDeviceStopping && !isAttemptActive && (
                      <div className="resume-lab-container">
                        <ProTooltip
                          isLarge
                          title={uiConfig?.showUpgradeToProUi && !canResumeLab && 'Only PRO users can resume projects.'}
                          content={
                            <p className="text">Your previous work is saved, but only PRO users can resume projects.</p>
                          }
                          showUpgradeButton
                          upgradeButtonText="Upgrade to access it"
                        >
                          <Button
                            id="resume-lab-btn"
                            type="primary"
                            size={'large'}
                            onClick={handleResumeAttempt}
                            loading={isModuleAttemptLoading || isDeviceStopping}
                            disabled={!canResumeLab || (!canRunMultipleLabs && !!allocatedModules?.length)}
                          >
                            Resume {!canResumeLab && <InfoCircleOutlined />}
                          </Button>
                        </ProTooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {showMultipleProjectsLimitBlock && (
            <div className="running-labs">
              <AllocatedModules allocatedModules={allocatedModules} />
            </div>
          )}
        </div>
      </div>
    </Content>
  )
}

export default LaunchCard
