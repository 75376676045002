import { Link } from 'react-router-dom'
import { Progress, Tag, Skeleton } from 'antd'
import dayjs from 'dayjs'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import Button from '@/components/Button'
import { Container } from './styles'

const InProgressModuleCard = ({ module, isLoading }) => {
  const moduleScoring = Math.round((module?.user_status?.last_module_attempt?.user_status?.scoring || 0) * 100, 2)
  const lastActivityAt =
    module?.user_status?.last_module_attempt?.last_activity_at &&
    dayjs(module?.user_status?.last_module_attempt?.last_activity_at).fromNow()

  return (
    <Container className="in-progress-module-card">
      <div className="img-container">
        {isLoading ? <Skeleton.Node className="loading-image" active /> : renderModuleImage(module)}
      </div>

      <div className="module-info">
        {isLoading ? (
          <Skeleton className="loading-title" active paragraph={{ rows: 2 }} />
        ) : (
          <>
            <div className="pre-title">
              <span className="la-bullet" style={{ backgroundColor: module?.learning_area?.color }} />

              <Link to={`/skill-track/${module?.skill_track?.id}`}>
                <p className="text">{module?.skill_track?.name}</p>
              </Link>
            </div>

            <h5 className="title">{module?.name}</h5>

            <div className="module-stats">
              <Tag className={`tag difficulty-${module?.difficulty}`}>{module?.difficulty}</Tag>

              <div className="progress-container">
                <Progress percent={moduleScoring} showInfo={false} />
                <p className="progress-text">{moduleScoring}%</p>
              </div>
            </div>
          </>
        )}
      </div>

      {!isLoading && (
        <div className="actions">
          <Link to={`/project/${module?.id}`}>
            <Button type="primary">Continue project</Button>
          </Link>

          {!!lastActivityAt && <p className="last-activity-text">Last activity: {lastActivityAt}</p>}
        </div>
      )}
    </Container>
  )
}

export default InProgressModuleCard
