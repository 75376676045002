import { useDispatch } from 'react-redux'
import Button from '@/components/Button'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { Container } from './styles'

const GoProButton = () => {
  const dispatch = useDispatch()

  return (
    <Container>
      <Button type="primary" ispro="true" onClick={() => dispatch(toggleUpgradeToProModal(true))}>
        Become <span className="hightlight">PRO</span>
        <img className="pro-badge" src={ProBadge} alt="PRO user" />
      </Button>
    </Container>
  )
}

export default GoProButton
