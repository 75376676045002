import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.geekblue1};
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
`
