import styled from 'styled-components'

export const Container = styled.div`
  overflow: hidden;

  .row {
    display: flex;

    > div {
      min-width: 248px;
      width: 248px;
    }
  }
`
