import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  background: ${(props) => props.theme.colors.geekblue1};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: auto;

  .fade-in-mask {
    width: 100%;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .error-card-container {
    max-width: 360px;
    margin-bottom: 100px;
  }

  .playground-preview {
    max-width: 840px;
    width: calc(100% - 40px);
    background: ${(props) =>
      localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.geekblue2 : props.theme.colors.gray1};
    flex: 1;
    padding: 16px 32px;
    margin: 20px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    > .title {
      color: ${(props) => props.theme.colors.gray10};
      margin-top: 20px;
    }

    .description {
      color: ${(props) => props.theme.colors.gray8};
    }

    .no-preview-content {
      height: 100%;
      max-width: 550px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 22px;
      text-align: center;
      padding: 0px 0px 10%;
      margin: 0px auto;

      .icon {
        color: ${(props) => props.theme.colors.geekblue5};
        font-size: 70px;
      }

      .title {
        max-width: 300px;
        margin: 0px 0px -8px;
      }

      .description {
        max-width: 450px;
        color: ${(props) => props.theme.colors.gray7};
        margin: 0px;
      }

      .allocated-modules-container {
        width: 100%;
      }
    }
  }

  .mobile-message {
    width: 100%;
    color: ${(props) => props.theme.colors.gray1};
    background-color: #3390ff;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 32px 16px;
    margin: 0px;

    p {
      font-size: 13px;
      margin: 0px;

      &:first-child {
        font-weight: 500;
      }
    }
    svg {
      font-size: 32px;
      margin-right: 14px;
    }
  }

  @media ${device.mdDown} {
    .error-card-container {
      margin-bottom: 1px;
    }
  }

  @media ${device.lgDown} {
    .device-content {
      display: none;
    }

    .mobile-message {
      display: flex;
    }
  }
`
