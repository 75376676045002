import styled, { css } from 'styled-components'

export const Container = styled.div`
  .votes-info {
    height: 28px;
    min-width: 50px;
    color: ${(props) => props.theme.colors.gray7};
    font-size: 15px;
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 2px 10px;
    margin: 0px;
    transition: 0.3s all;
    cursor: pointer;

    .icon {
      font-size: 16px;
    }

    .ant-spin {
      .ant-spin-dot-item {
        background-color: ${(props) =>
          props.$isAlreadyVoted ? props.theme.colors.gray1 : props.theme.colors.geekblue5};
      }
    }

    ${(props) =>
      props.$shape === 'squared' &&
      css`
        height: 32px;
        border-radius: 2px;
      `}

    ${(props) =>
      props.$isReadOnly
        ? css`
            border: none;
            cursor: default;
          `
        : css`
            &:hover {
              color: ${props.theme.colors.base.gray3};
              background-color: ${props.theme.colors.geekblue4};
              box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
            }
          `}

    ${(props) =>
      props.$isAlreadyVoted &&
      css`
        color: ${props.theme.colors.base.gray1};
        background-color: ${props.theme.colors.geekblue5};
        border-color: ${props.theme.colors.geekblue5};
      `}
    
    ${(props) =>
      props.$isLoading &&
      css`
        background-color: ${props.theme.colors.gray1};
        border-color: ${props.theme.colors.gray5};
        opacity: 0.4;
        cursor: not-allowed;

        .ant-spin {
          .ant-spin-dot-item {
            background-color: ${props.theme.colors.geekblue5};
          }
        }
      `}
  }
`
