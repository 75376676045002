import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .ant-tabs {
    width: 100%;

    .ant-tabs-nav {
      height: 40px;
      margin: 0px;

      &:before {
        border-color: ${(props) => props.theme.colors.gray4};
      }
    }

    .ant-tabs-extra-content {
      margin: 0 16px 0 8px;

      .actions-container {
        display: flex;
        gap: 8px;

        .stop-btn {
          &.ant-btn-dangerous {
            &.ant-btn[disabled] {
              color: ${(props) => props.theme.colors.disabledText};
              border-color: ${(props) => props.theme.colors.gray5};
              background: ${(props) => props.theme.colors.gray1};
              text-shadow: none;
              box-shadow: none;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.geekblue1};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ant-spin {
      margin-bottom: 20px;
    }

    .device-stopped-icon {
      color: #ff4d4f;
      font-size: 58px;
      margin-bottom: 20px;
    }

    .title {
      margin-bottom: 10px;
    }

    .subtitle {
      color: rgba(0, 0, 0, 0.45);
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
`
