import { Tooltip } from 'antd'
import DefaultDeviceIcon from '@/assets/images/device/default-icon.png'
import JupyterDeviceIcon from '@/assets/images/device/jupyter-icon.png'
import PostgresDeviceIcon from '@/assets/images/device/postgres-icon.png'
import MysqlDeviceIcon from '@/assets/images/device/mysql-icon.png'
import VscodeDeviceIcon from '@/assets/images/device/vscode-icon.png'
import PhpmyadminDeviceIcon from '@/assets/images/device/phpmyadmin-icon.png'
import { Content } from './styles'

const DevicesBox = ({ devices }) => {
  const getIcon = (name) => {
    if (name.toLowerCase().includes('jupyter')) {
      return JupyterDeviceIcon
    }

    if (name.toLowerCase().includes('postgres') || name.toLowerCase().includes('pgadmin')) {
      return PostgresDeviceIcon
    }

    if (name.toLowerCase().includes('mysql')) {
      return MysqlDeviceIcon
    }

    if (name.toLowerCase().includes('vscode')) {
      return VscodeDeviceIcon
    }

    if (name.toLowerCase().includes('php')) {
      return PhpmyadminDeviceIcon
    }

    return DefaultDeviceIcon
  }

  return (
    <Content id="devices-box">
      {devices?.map((d) => {
        return (
          <Tooltip key={d.id} title={d?.name}>
            <div className="device-item">
              <img className="icon" src={getIcon(d?.name)} alt={d?.name} />
            </div>
          </Tooltip>
        )
      })}
    </Content>
  )
}

export default DevicesBox
