import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'jumpboxes',
  initialState,
  reducers: {
    SET_JUMPBOXES_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    JUMPBOXES_UPDATED: (state, { payload }) => {
      state.items = payload
      state.isLoading = false
      state.error = null
    },
    RESET_JUMPBOXES_STATE: () => initialState,
    SET_JUMPBOXES_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
  },
})

export const { SET_JUMPBOXES_LOADING, JUMPBOXES_UPDATED, RESET_JUMPBOXES_STATE, SET_JUMPBOXES_ERROR } = slice.actions
export default slice.reducer
