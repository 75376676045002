import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .module-info {
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 14px;
    }

    .lab-stats {
      display: flex;
      gap: 32px;
      margin-bottom: 14px;

      .lab-stat {
        .title {
          color: ${(props) => props.theme.colors.gray7};
          font-size: 12px;
          line-height: 20px;
          margin: 0px;
        }

        .subtitle {
          color: ${(props) => props.theme.colors.gray8};
          font-size: 12px;
          line-height: 20px;
          margin: 0px;
        }
      }
    }

    .issues-info {
      display: flex;
      gap: 18px;
      margin-bottom: 14px;

      .issue-info {
        color: ${(props) => props.theme.colors.gray11};
        background-color: ${(props) => props.theme.colors.red1};
        border-radius: 5px;
        text-decoration: underline;
        padding: 8px 15px 11px 15px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .links-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid ${(props) => props.theme.colors.gray4};
      border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
      padding: 20px;

      .link {
        display: flex;
        border-radius: 50%;
        padding: 4px;

        &.error {
          background: ${(props) => props.theme.colors.red4};
        }

        > svg {
          filter: ${localStorage.getItem('dw-theme') === 'dark' ? 'invert(1)' : 'invert(0)'};
        }
      }
    }
  }

  .errors-container {
    flex: 1;
    overflow: auto;

    .error-content {
      display: flex;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
      padding: 20px 0px 20px 10px;

      .bullet {
        min-width: 10px;
        height: 10px;
        background-color: ${(props) => props.theme.colors.red5};
        display: block;
        border-radius: 50%;
        margin: 6px 6px 6px 0px;
      }

      .error-info {
        .title {
          color: ${(props) => props.theme.colors.red5};
          margin: 0px;
        }

        .description {
          color: ${(props) => props.theme.colors.gray7};
          margin: 0px;
        }
      }
    }
  }
`
