import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  min-height: 220px;
  width: 100%;
  background: linear-gradient(90deg, ${(props) => props.theme.colors.base.gray10} 20.07%, #343958 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  > .content {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    overflow: hidden;
  }

  .skill-track-info {
    width: 100%;
    display: flex;
    gap: 12px;
    padding: 10px 90px;

    > .badge {
      margin-top: 26px;
    }

    .header-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      z-index: 1;

      .pre-title-content {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 4px;
      }

      .pre-title {
        color: ${(props) => props.theme.colors.base.geekblue3};
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.19em;
        line-height: 20px;
        text-transform: uppercase;
        margin-bottom: 0px;

        &.earned {
          width: fit-content;
          color: ${(props) => props.theme.colors.base.gray10};
          background-color: ${(props) => props.theme.colors.base.cyan5};
          border-radius: 20px;
          padding: 0px 10px;
        }
      }

      .rating {
        color: ${(props) => props.theme.colors.base.gray1};
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        margin-top: -2px;

        .subtext {
          color: ${(props) => props.theme.colors.base.gray8};
          font-size: 12px;
          font-weight: 400;
          margin: 0px;
        }

        .ant-rate-star {
          margin-inline-end: 2px;

          svg {
            font-size: 14px;
          }

          &:not(.ant-rate-star-full) {
            .ant-rate-star-second {
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }

      .title {
        color: ${(props) => props.theme.colors.base.gray1};
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .skill-track-progress {
        .progress-box {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 10px;

          .ant-progress {
            width: 290px;

            .ant-progress-inner {
              background-color: ${(props) => props.theme.colors.base.geekblue2};
            }

            .ant-progress-bg {
              background-color: ${(props) => props.theme.colors.base.geekblue5};
            }

            &.ant-progress-status-success .ant-progress-bg {
              background-color: ${(props) => props.theme.colors.base.cyan5};
            }
          }
        }

        .skill-bullets-box {
          display: flex;
          gap: 20px;

          .skill-bullets-container {
            display: flex;
            gap: 12px;
            margin-bottom: 8px;

            > p {
              color: ${(props) => props.theme.colors.gray1};
              margin: 0px;
            }
          }
        }

        .progress-text {
          color: ${(props) => props.theme.colors.base.gray1};
          margin: 0px;
        }
      }
    }
  }

  .la-image {
    min-height: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    right: 0px;
    padding-right: 54px;

    .shape-1 {
      width: 90px;
      height: 100%;
      background: linear-gradient(
        70deg,
        ${(props) => props.theme.colors.base.gray10} 22%,
        rgba(59, 124, 249, 0.93) 137%
      );
      transform: skew(-30deg);
    }

    .shape-2 {
      width: 180px;
      height: 100%;
      background-color: ${(props) => props.$color};
      filter: brightness(${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)});
      transform: skew(-30deg);
    }
  }

  @media ${device.lgDown} {
    .la-image {
      padding-right: 0px;
      margin-right: -50px;

      .shape-1 {
        width: 70px;
      }

      .shape-2 {
        width: 130px;
      }
    }
  }

  @media ${device.mdDown} {
    min-height: 280px;

    .skill-track-info {
      flex-direction: column;
      padding: 40px 30px;

      .badge {
        margin: 0px -12px;
      }

      .header-body {
        .title {
          font-size: 26px;
          line-height: 32px;
        }

        .skill-track-progress {
          .progress {
            flex-direction: column;
            align-items: flex-start;
            gap: 0px;

            .ant-progress {
              max-width: 100%;
            }
          }

          .skill-bullets-container {
            flex-wrap: wrap;
          }

          .skill-bullets-box {
            flex-direction: column;
            gap: 0px;
          }
        }
      }
    }
  }
`
