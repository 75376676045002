import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentLab: null,
  labDebug: null,
  labSessions: null,
  currentDevice: null,
  currentView: null,
  currentViewIsLoaded: null,
  lastLabActionDate: null,
  isInactivityModalOpen: false,
  isLabLoading: false,
  isLabDebugLoading: false,
  labPingError: null,
  error: null,
}

const slice = createSlice({
  name: 'labs',
  initialState,
  reducers: {
    SET_LAB_LOADING: (state, { payload }) => {
      state.isLabLoading = payload
    },
    SET_LAB_DEBUG_LOADING: (state, { payload }) => {
      state.isLabDebugLoading = payload
    },
    LAB_UPDATED: (state, { payload }) => {
      state.currentLab = payload
      // if any device was updated and match currentDevice ID, update it
      state.currentLab?.allocated_session?.devices?.forEach((d) => {
        if (state.currentDevice?.id === d.id) {
          state.currentDevice = d
        }
      })

      // if module has no session, remove device and view
      if (!state.currentLab?.allocated_session) {
        state.currentDevice = null
        state.currentView = null
        state.currentViewIsLoaded = null
      }

      state.error = null
      state.labPingError = false
      state.lastLabActionDate = null
    },
    LAB_DEBUG_UPDATED: (state, { payload }) => {
      state.labDebug = payload
    },
    LAB_SESSIONS_UPDATED: (state, { payload }) => {
      state.labSessions = payload
    },
    DEVICE_UPDATED: (state, { payload }) => {
      state.currentDevice = payload

      // also update device on module info
      state.currentLab = {
        ...state.currentLab,
        allocated_session: {
          ...state.currentLab?.allocated_session,
          devices: state.currentLab?.allocated_session?.devices?.map((ds) => {
            if (ds.id === state.currentDevice.id) {
              return { ...state.currentDevice }
            }

            return ds
          }),
        },
      }

      state.currentView = null
      state.error = null
    },
    VIEW_UPDATED: (state, { payload }) => {
      state.currentViewIsLoaded = null
      state.currentView = payload
      state.error = null
    },
    VIEW_LOADED: (state) => {
      state.currentViewIsLoaded = true
    },
    SET_LAST_LAB_ACTION_DATE: (state, { payload }) => {
      state.lastLabActionDate = payload
    },
    SET_INACTIVITY_MODAL_OPEN: (state, { payload }) => {
      state.isInactivityModalOpen = payload
    },
    RESET_LABS_STATE: () => initialState,
    SET_LAB_PING_ERROR: (state, { payload }) => {
      state.labPingError = payload
    },
    SET_LAB_ERROR: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const {
  SET_LAB_LOADING,
  SET_LAB_DEBUG_LOADING,
  LAB_UPDATED,
  LAB_DEBUG_UPDATED,
  LAB_SESSIONS_UPDATED,
  DEVICE_UPDATED,
  VIEW_UPDATED,
  VIEW_LOADED,
  SET_LAST_LAB_ACTION_DATE,
  SET_INACTIVITY_MODAL_OPEN,
  RESET_LABS_STATE,
  SET_LAB_PING_ERROR,
  SET_LAB_ERROR,
} = slice.actions
export default slice.reducer
