import { Button as SButton } from './styles'

const Button = ({ children, className, ...rest }) => {
  return (
    <SButton className={`dw-btn ${className}`} {...rest}>
      {children}
    </SButton>
  )
}

export default Button
