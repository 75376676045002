import s from './styles.module.scss'
import { Spin } from 'antd'

const OverlappedLoader = () => {
  return (
    <div className={s['container']}>
      <Spin size="large" />
    </div>
  )
}

export default OverlappedLoader
