import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;

  .dashboard-container {
    background-color: ${(props) => props.theme.colors.geekblue1};
  }

  .dashboard-container,
  .career-table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;

    > .content {
      max-width: 1440px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 36px;
      padding: 40px 70px;

      .main-row {
        display: flex;
        gap: 40px;

        .primary-box {
          height: 100%;
          background-color: ${(props) => props.theme.colors.gray1};
          border-radius: 8px;
          border: 1px solid ${(props) => props.theme.colors.geekblue2};
          box-shadow: 0px 2px 8px ${(props) => `${props.theme.colors.gray13}26`};
          flex: 2 1 0px;
          overflow: auto;

          .link {
            display: block;
            text-align: center;
            margin: 0px 0px 24px 0px;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .secondary-box {
          display: flex;
          flex-direction: column;
          gap: 20px;
          flex: 1 1 50px;
          overflow: auto;
        }
      }

      &.fluid {
        max-width: initial;
        align-items: center;
        padding: 0px;

        &.bg-1 {
          background: radial-gradient(
              217.52% 153.59% at 122.4% 118.47%,
              rgba(179, 197, 255, 0.5) 47.5%,
              rgba(133, 165, 255, 0.5) 73%,
              rgba(240, 245, 255, 0.5) 94.27%
            ),
            ${(props) => props.theme.colors.gray2};
        }

        &.bg-2 {
          background: radial-gradient(
              100% 200% at 90% 90%,
              rgba(179, 197, 255, 0.5) 47.5%,
              rgba(152, 143, 255, 0.28) 73%,
              ${(props) => props.theme.colors.gray1} 95%
            ),
            ${(props) => props.theme.colors.gray2};
        }
      }
    }

    .custom-projects-block {
      max-width: 1440px;
      width: 100%;
      background: transparent;
      display: flex;
      flex-direction: column;
      padding: 40px 70px;
    }

    .featured-data-sources-section {
      max-width: 1440px;
      width: 100%;
      background: transparent;
      display: flex;
      align-items: center;
      gap: 24px;
      padding: 40px 70px;
    }
  }

  @media ${device.lgDown} {
    .dashboard-container {
      > .content {
        padding: 20px 12px;

        .main-row {
          .secondary-box {
            display: none;
          }
        }
      }

      .custom-projects-block {
        padding: 30px 12px;
      }

      .featured-data-sources-section {
        flex-direction: column;
        align-items: center;
        padding: 30px 12px;

        > .header {
          width: 100%;
          flex-direction: row;

          .section-title {
            width: 100%;
          }
        }
      }
    }

    .career-table-container {
      > .content {
        padding: 20px;
      }
    }
  }

  @media ${device.mdDown} {
    .dashboard-container {
      > .content {
        gap: 14px;

        .main-row {
          .primary-box {
            background: transparent;
            box-shadow: none;
            border: none;

            .content {
              padding: 0px 10px 26px;

              > .title {
                font-size: 24px;
                line-height: 34px;
              }
            }
          }
        }
      }

      .featured-data-sources-section {
        > .header {
          .section-title {
            align-items: center;
            font-size: 24px;
            line-height: 34px;
          }
        }
      }
    }
  }
`
