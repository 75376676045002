import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import FadeIn from '@/components/FadeIn'
import NewUserMainBlock from '../NewUserMainBlock'
import VerticalModuleCard from '../VerticalModuleCard'
import VerticalModulesContainerLoading from '../RelatedModulesContainerLoading'
import InProgressPlaceholder from './components/InProgressPlaceholder'
import InProgressModuleCard from './components/InProgressModuleCard'
import InProgressModulesCarousel from './components/InProgressModulesCarousel'
import { getRelatedModules } from '@/store/modules/actions'
import { Container } from './styles'

const ActiveUserMainBlock = ({ isInProgressModulesLoading }) => {
  const dispatch = useDispatch()

  const [isRelatedModulesLoading, setRelatedModulesLoading] = useState(true)

  const { inProgressModules, relatedModulesInfo } = useSelector((state) => state.modules)
  const { last_activity_track, related_modules } = relatedModulesInfo || {}

  const loadRelatedModules = async () => {
    await dispatch(getRelatedModules())
    setRelatedModulesLoading(false)
  }

  useEffect(() => {
    if (!inProgressModules) return
    loadRelatedModules()
  }, [inProgressModules]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="active-user-main-block">
      <FadeIn>
        <div className="content">
          <h4 className="title">Continue where you left</h4>

          {isInProgressModulesLoading ? (
            <div className="module-card-loading-container">
              <InProgressModuleCard isLoading />
            </div>
          ) : inProgressModules?.length ? (
            <InProgressModulesCarousel modules={inProgressModules} />
          ) : (
            <InProgressPlaceholder />
          )}

          <h4 className="title">Other related projects</h4>
          {!isRelatedModulesLoading && last_activity_track && (
            <h5 className="subtitle">Because you've been working on the "{last_activity_track?.name}" track</h5>
          )}

          {isRelatedModulesLoading ? (
            <VerticalModulesContainerLoading />
          ) : (
            <div className="related-modules-container">
              {related_modules?.length ? (
                <Swiper className="white" spaceBetween={0} modules={[Navigation]} navigation slidesPerView={'auto'}>
                  {related_modules?.map((module, i) => (
                    <SwiperSlide key={i}>
                      <VerticalModuleCard key={module.id} module={module} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <NewUserMainBlock hideVideo />
              )}
            </div>
          )}
        </div>
      </FadeIn>
    </Container>
  )
}

export default ActiveUserMainBlock
