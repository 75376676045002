import FadeIn from '@/components/FadeIn'
import JumpboxesSection from '../JumpboxesSection'
import { Container } from './styles'

const NewPlaygroundTab = () => {
  return (
    <Container className="new-playground-tab">
      <FadeIn>
        <JumpboxesSection />
      </FadeIn>
    </Container>
  )
}

export default NewPlaygroundTab
