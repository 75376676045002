import * as React from "react";
const Svg3Full = (props) => /* @__PURE__ */ React.createElement("svg", { id: "badge-3", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 600 600", style: {
  enableBackground: "new 0 0 600 600"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { id: "ribbon", d: "M385.9,503.2l-76.3-47.6c-4.1-2.5-8.8-2.5-12.9,0l-76.3,47.6c-6.9,4.3-14.6-3.4-13-12.9l17.9-154.4 H381l17.9,154.4C400.6,499.8,392.8,507.5,385.9,503.2L385.9,503.2L385.9,503.2z", style: {
  fill: "#525776"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { id: "medal-border", d: "M283.3,101.1l-151.5,110c-10,7.3-14.2,20.2-10.4,31.9l57.8,178.1 c3.8,11.7,14.8,19.7,27.1,19.7h187.2c12.3,0,23.3-8,27.1-19.7l57.9-178c3.8-11.7-0.3-24.6-10.4-31.9L316.8,101.1 C306.8,93.8,293.2,93.8,283.3,101.1L283.3,101.1L283.3,101.1z", style: {
  fill: "#7F8297"
} })), /* @__PURE__ */ React.createElement("g", { id: "transparent-center" }, /* @__PURE__ */ React.createElement("polygon", { points: "300,110.5 137.7,228.4 137.8,228.7 300,264.9  ", style: {
  fill: "#CBCBCB"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "461.7,228 300,110.5 300,264.9  ", style: {
  fill: "#989898"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "462.3,228.4 461.7,228 300,264.9 398,419.2 400.3,419.2  ", style: {
  fill: "#B2B2B2"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "137.8,228.7 198.8,416.5 300,264.9  ", style: {
  fill: "#989898"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "198.8,416.5 199.7,419.2 398,419.2 300,264.9  ", style: {
  fill: "#808080"
} })), /* @__PURE__ */ React.createElement("path", { id: "internal-jewel", d: "M293.8,193.2L224,243.9c-3.8,2.7-5.3,7.5-3.9,11.9l26.6,82c1.4,4.4,5.5,7.4,10.1,7.4h86.3 c4.6,0,8.6-3,10.1-7.4l26.6-82c1.4-4.4-0.1-9.2-3.9-11.9l-69.7-50.7C302.6,190.5,297.5,190.5,293.8,193.2L293.8,193.2z", style: {
  opacity: 0.8,
  fill: "#4A4D69",
  enableBackground: "new"
} }), /* @__PURE__ */ React.createElement("path", { id: "spark", d: "M219.4,236.7l2.9-5.3l2.9,5.3c2.4,4.5,6.1,8.2,10.6,10.6l5.3,2.9l-5.3,2.9 c-4.5,2.4-8.2,6.1-10.6,10.6l-2.9,5.3l-2.9-5.3c-2.4-4.5-6.1-8.2-10.6-10.6l-5.3-2.9l5.3-2.9C213.2,244.8,216.9,241.1,219.4,236.7 L219.4,236.7z", style: {
  fill: "#FFFFFF"
} }));
export default Svg3Full;
