import { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Steps, Badge } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Comment24Regular } from '@fluentui/react-icons'
import useQueryParam from '@/hooks/useQueryParam'
import useOutsideAlerter from '@/hooks/useOutsideAlerter'
import Button from '@/components/Button'
import FadeIn from '@/components/FadeIn'
import { selectPage } from '@/store/modules/actions'
import { Container, PagesContainer, PageItem } from './styles'

const ModuleTitleHeader = ({ handleStopModuleAttempt, openCommentsDrawer }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useQueryParam('page')

  const { uiConfig } = useSelector((state) => state.accounts)
  const { isAuthenticated } = useSelector((state) => state.users)
  const { currentModule: module, currentPage } = useSelector((state) => state.modules)

  const moduleAttempt = module?.user_status?.last_module_attempt
  const isAttemptActive = moduleAttempt?.is_active
  const hasComments = module?.user_status?.comments?.recent?.length || module?.user_status?.comments?.previous?.length

  const pages = module?.module_pages
  const pageContent = pages?.[page - 1]
  const totalPages = pages?.length
  const activitiesPerPage = module?.user_status?.last_module_attempt?.user_status?.activities_per_page

  const isNextUpPage = !!page && parseInt(page) === totalPages + 1

  const [isPageContainerOpen, setPageContainerOpen] = useState(false)

  const pagesContainerRef = useRef(null)
  useOutsideAlerter(pagesContainerRef, isPageContainerOpen, () => setPageContainerOpen(false))

  const checkPageValidity = () => {
    if (page && !module.module_pages?.[page - 1] && !isNextUpPage) {
      setPage(1)
    }
  }

  const handleSelectPage = (pageIndex) => {
    setPage(parseInt(pageIndex))
    setPageContainerOpen(false)
  }

  const getPageItems = () => {
    const pageItems = pages.map((p, i) => {
      const passedActivities = activitiesPerPage?.[p.id]?.passed_activities
      const totalActivities = activitiesPerPage?.[p.id]?.total_activities
      const isDone = passedActivities && passedActivities === totalActivities

      return {
        title: (
          <PageItem $isDone={isDone} onClick={() => handleSelectPage(i + 1)}>
            <h5 className="title">{p.name}</h5>
          </PageItem>
        ),
      }
    })

    if (uiConfig?.showModuleNextUp && isAuthenticated) {
      pageItems.push({
        title: (
          <PageItem $isDone={false} onClick={() => handleSelectPage(totalPages + 1)}>
            <h5 className="title">Next up</h5>
          </PageItem>
        ),
      })
    }

    return pageItems
  }

  useEffect(() => {
    if (parseInt(page) === currentPage?.page) return

    if (page === null || page === '') {
      setPage(1)
      return
    }

    checkPageValidity()

    const pageBody = {
      page,
      name: pageContent?.name,
    }
    dispatch(selectPage(pageBody))
  }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container id="module-page-title-header" $isOpen={isPageContainerOpen} $hasLab={!!module?.lab_id}>
      <div className="nav">
        <div className="header-container">
          {hasComments && (
            <Button type="circle" className="comments-button" onClick={openCommentsDrawer}>
              <Badge count={module?.user_status?.comments?.recent?.length} offset={[5, 0]}>
                <Comment24Regular />
              </Badge>
            </Button>
          )}

          {(currentPage?.name || isNextUpPage) && (
            <FadeIn>
              <div className="module-name-container" onClick={() => setPageContainerOpen(!isPageContainerOpen)}>
                <h4 className="module-name">{isNextUpPage ? 'Next up' : currentPage?.name}</h4>

                <DownOutlined />
              </div>
            </FadeIn>
          )}
        </div>
      </div>

      <PagesContainer className="pages-container" ref={pagesContainerRef} $isOpen={isPageContainerOpen}>
        <Steps className="page-list" progressDot current={page - 1} direction="vertical" items={getPageItems()} />

        {isAuthenticated && (
          <div className="actions">
            {isAttemptActive && (
              <Button id="reset-activities-btn" danger size="large" onClick={handleStopModuleAttempt}>
                Stop project
              </Button>
            )}
          </div>
        )}
      </PagesContainer>
    </Container>
  )
}

export default ModuleTitleHeader
