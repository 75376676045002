import styled from 'styled-components'
import { Form as AntdForm } from 'antd'

export const LogoHeader = styled.div`
  display: inline-block;
  text-align: center;

  .title {
    word-break: break-word;
  }
`

export const Form = styled(AntdForm)`
  width: 100%;
  max-width: 380px;
  text-align: center;
  margin: 0 auto;

  .form-row {
    display: flex;
    gap: 24px;
  }

  .ant-input-affix-wrapper {
    margin: 14px 0px;

    .ant-input-prefix {
      margin-right: 8px;

      svg {
        color: ${(props) => props.theme.colors.gray13};
      }
    }
  }

  .ant-btn {
    width: 100%;
    height: 42px;
    margin: 8px 0 18px;
  }

  .error-container {
    display: flex;
  }

  .error-text {
    color: ${(props) => props.theme.colors.red4};
    font-size: 12px;
    text-align: left;
    margin: -12px 4px 4px;
  }

  .bottom-text {
    color: ${(props) => props.theme.colors.gray13};
    font-size: 12px;

    .link {
      color: ${(props) => props.theme.colors.blue6};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`
