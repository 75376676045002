import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Progress, Rate } from 'antd'
import Badge from '@/components/Badge'
import SkillBullet from '../../../SkillTrackPage/components/SkillBullet'
import { Container } from './styles'

const SkillTrackCard = ({ skillTrack, disableClick, hideSkillsInfo }) => {
  const { items: learningAreas } = useSelector((state) => state.learningAreas)

  const isEarned = skillTrack?.user_status?.progress === 1
  const isDraft = skillTrack?.status === 'draft'

  const getLearningAreaColor = (learningArea) => {
    return learningAreas.find((la) => la.id === (learningArea?.id || learningArea))?.color
  }
  const color = getLearningAreaColor(skillTrack?.learning_area)

  const content = (
    <>
      <div className="skill-track-content">
        <div className="badge-container">
          <Badge proficiency={skillTrack?.proficiency} color={color} earned={isEarned} />
        </div>

        <div className="skill-track-info">
          <div className="pre-title-content">
            <p className={`pre-title ${isEarned ? 'earned' : ''}`}>{isEarned ? 'Track earned' : 'Track'}</p>

            {skillTrack?.rating?.count > 3 && (
              <div className="rating">
                <Rate disabled allowHalf defaultValue={skillTrack?.rating?.avg} />{' '}
                {Math.round(skillTrack?.rating?.avg * 100) / 100}
              </div>
            )}
          </div>

          <h4 className="title">{skillTrack.name}</h4>

          {isDraft && <p className="pre-title coming-soon">coming later this year</p>}

          {!isDraft && skillTrack?.user_status && (
            <div className="progress-container">
              <Progress percent={skillTrack?.user_status?.progress * 100} showInfo={false} />
              <p className="progress-text">{Math.round(skillTrack?.user_status?.progress * 100)}%</p>
            </div>
          )}

          {!isDraft && skillTrack?.skills && !hideSkillsInfo && (
            <div className="skill-bullets-box">
              <div className="skill-bullets-container">
                {!skillTrack?.skills?.length && <p className="subtitle">No skills available</p>}

                {skillTrack?.skills
                  ?.filter((s) => s.status !== 'coming_soon')
                  ?.map((s) => (
                    <SkillBullet key={s.id} skill={s} size={'sm'} />
                  ))}
              </div>
            </div>
          )}

          {!isDraft && skillTrack?.skills && !hideSkillsInfo && (
            <p className="subtitle">
              {skillTrack?.skills?.find((s) => s.user_status) ? (
                <>
                  {skillTrack?.user_status?.finished_skills}/{skillTrack?.user_status?.total_skills} skill
                  {skillTrack?.skills?.length === 1 ? '' : 's'} acquired from this skill track
                </>
              ) : (
                <span>Getting your progress...</span>
              )}
            </p>
          )}
        </div>
      </div>

      <div className="la-image">
        <div className="shape-1" />
        <div className="shape-2" />
      </div>
    </>
  )

  return (
    <Container className="skill-track-card" $color={color} $isDraft={isDraft} $disableClick={disableClick}>
      {disableClick ? (
        <div className="skill-track-body">{content}</div>
      ) : (
        <Link to={!isDraft && !disableClick ? `/skill-track/${skillTrack.id}` : '#'} className="skill-track-body">
          {content}
        </Link>
      )}
    </Container>
  )
}

export default SkillTrackCard
