import * as Sentry from '@sentry/react'
import { isStaging, isProduction } from '@/helpers/env'

import { version } from '@/helpers/env'
const sentryDNS = import.meta.env.REACT_APP_SENTRY_DNS
const sentryProject = import.meta.env.REACT_APP_SENTRY_PROJECT

export const initSentry = () => {
  if (!sentryDNS || !sentryProject) return

  Sentry.init({
    dsn: sentryDNS,
    release: `${sentryProject}@${version}`,
    integrations: [
      Sentry.browserTracingIntegration({
        // add custom options here
      }),
    ],
    tracesSampleRate: 1.0,
    beforeSend: (event) => {
      const exceptionType = event?.exception?.values?.[0]?.type // "Error", "TypeError", ...
      const exceptionValue = event?.exception?.values?.[0]?.value // "Failed to fetch", "Object captured as exception...", ...
      const exceptionStackTrace = event?.exception?.values?.[0]?.stacktrace?.frames
      const crashedFilename = exceptionStackTrace?.[exceptionStackTrace.length - 2]?.filename

      if (exceptionType === 'TypeError' && exceptionValue === 'Failed to fetch') {
        const failedToFetchUrl = event?.breadcrumbs?.pop()?.data?.url

        const forbiddenUrls = ['xdtsdmart.com']
        const isForbiddenFetch = forbiddenUrls.map((url) => failedToFetchUrl?.includes(url)).some((item) => item)

        const forbiddenFilenames = ['chrome-extension://']
        const isForbiddenFilename = forbiddenFilenames
          .map((filename) => crashedFilename?.includes(filename))
          .some((item) => item)

        if (isForbiddenFetch || isForbiddenFilename) return null
      }

      if (!isStaging && !isProduction) {
        console.error('[Sentry error]', exceptionType, exceptionValue, event)
        return null
      }

      return event
    },
  })

  // Simulate exceptions
  // Sentry.captureException({ msg: 'Something went wrong' })
  // fetch('https://trans.xdtsmart.com/jj/rMain.bundle.js')
}
