import { useSelector, useDispatch } from 'react-redux'
import { Tooltip, Progress } from 'antd'
import { RightCircleFilled, CheckCircleFilled } from '@ant-design/icons'
import { togglePublicProfileModal } from '@/store/users/actions'
import { Container } from './styles'

const PublicProfileTag = () => {
  const dispatch = useDispatch()

  const { publicProfileCompletionPerc } = useSelector((state) => state.users)
  const isFinished = publicProfileCompletionPerc === 100

  return (
    <Tooltip
      title={
        <>
          <b>{isFinished ? 'Update your profile' : 'Complete your profile'}</b>
          <br />
          <span style={{ opacity: 0.7 }}>and showcase your profile with others!</span>
        </>
      }
      placement={'right'}
    >
      <Container onClick={() => dispatch(togglePublicProfileModal(true))} className={isFinished ? 'success' : ''}>
        {!isFinished && (
          <Progress
            percent={publicProfileCompletionPerc}
            strokeColor={publicProfileCompletionPerc === 100 ? '#52c41a' : { from: '#232323', to: '#3B7CF9' }}
            trailColor={'#fff'}
          />
        )}

        <p className="text">
          Profile <span className="highlight">{publicProfileCompletionPerc}%</span> complete
        </p>

        {publicProfileCompletionPerc === 100 ? (
          <CheckCircleFilled className="icon success" />
        ) : (
          <RightCircleFilled className="icon" />
        )}
      </Container>
    </Tooltip>
  )
}

export default PublicProfileTag
