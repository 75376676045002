import styled from 'styled-components'
import InviteBg from '@/assets/images/invite-bg.png'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.base.geekblue10};
  background: url(${InviteBg}) no-repeat center center;
  background-size: cover;
  background-position: center;
  object-fit: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow: auto;
    padding: 72px 82px;
    margin: 0 auto;

    .logo-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .teams-logo {
        color: ${(props) => props.theme.colors.base.gray1};
        display: flex;
        align-items: center;
        gap: 20px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 2px;
        margin: 0px;

        .divider {
          width: 127px;
          height: 1px;
          background-color: ${(props) => props.theme.colors.base.gray1}55;
        }
      }
    }

    .body {
      max-width: 1280px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex: 1;
      gap: 20px;
      margin: 70px 0px 0px;

      .title-container {
        margin-top: 100px;

        .title {
          max-width: 420px;
          color: ${(props) => props.theme.colors.base.gray1};
          font-weight: 700;
          margin: 0px;
        }
      }

      .card-container {
        max-width: 420px;
        width: 100%;
        background-color: ${(props) => props.theme.colors.base.gray1};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        border-radius: 10px;
        padding: 72px 42px;
        animation: fadeIn 1.5s;

        .error-icon-container {
          width: 60px;
          height: 60px;
          background-color: ${(props) => props.theme.colors.gold1};
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;

          .icon {
            color: ${(props) => props.theme.colors.gold5};
            font-size: 30px;
          }
        }

        .account-image-container {
          min-height: 60px;
          max-height: 60px;
          min-width: 60px;
          max-width: 60px;
          background: linear-gradient(86deg, #39476d 8.49%, #4150d0 67.75%, rgba(179, 114, 244, 0.62) 97.13%);
          display: flex;
          border-radius: 7px;
          overflow: hidden;
          box-shadow: 0px 1px 4px 2px ${(props) => props.theme.colors.gray6}66;
          user-select: none;

          .account-image {
            width: 100%;
            object-fit: cover;
            pointer-events: none;
          }
        }

        .title {
          color: ${(props) => props.theme.colors.base.gray10};
          font-weight: 700;
          line-height: 32px;
          word-break: break-word;
          margin: 0px;
        }

        .text {
          max-width: 440px;
          color: ${(props) => props.theme.colors.gray8};
          font-weight: 400;
          margin: 0px;

          .highlight {
            font-weight: 500;
          }
        }

        .custom-text-container > * {
          max-width: 440px;
          color: ${(props) => props.theme.colors.gray8};
          font-weight: 400;
        }

        .actions {
          display: flex;
          gap: 10px;
          margin-top: 20px;
        }
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media ${device.lgDown} {
    > .content {
      .body {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 40px 0px 0px;

        .title-container {
          margin: 0px;

          .title {
            text-align: center;
          }
        }
      }
    }
  }

  @media ${device.smDown} {
    > .content {
      padding: 20px;

      .body {
        .title-container {
          .title {
            font-size: 32px;
            line-height: 38px;
          }
        }

        .card-container {
          padding: 32px 24px;
        }
      }
    }
  }
`
