import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const MainContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .header {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    position: sticky;
    top: 0px;
    z-index: 2;

    .ant-breadcrumb {
      margin-bottom: 16px;

      .back-icon {
        font-size: 10px;
        margin-right: 8px;
      }

      ol {
        flex-wrap: initial;

        li {
          white-space: nowrap;
        }

        li:not(:first-child) {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        > span:last-child a {
          color: rgba(0, 0, 0, 0.45);
          transition: color 0.3s;

          &:hover {
            color: #40a9ff;
          }
        }
      }
    }
  }
`

export const SecondaryContent = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .lab-container {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray4};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: auto;

    .loading-container {
      width: 510px;

      .ant-skeleton {
        margin: 0px;

        .ant-skeleton-paragraph {
          margin: 4px 0px;

          li {
            height: 430px;
            width: 100% !important;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .mobile-message {
    width: 100%;
    color: ${(props) => props.theme.colors.gray1};
    background-color: ${(props) => props.theme.colors.gold5};
    display: none;
    align-items: center;
    justify-content: center;
    padding: 32px 16px;
    margin: 0px;

    p {
      font-size: 13px;
      margin: 0px;

      &:first-child {
        font-weight: 500;
      }
    }
    svg {
      font-size: 32px;
      margin-right: 14px;
    }
  }

  @media ${device.lgDown} {
    .lab-container {
      display: none;
    }

    .mobile-message {
      display: flex;
    }
  }
`
