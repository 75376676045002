import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  z-index: 101;

  .chat-open-icon {
    background-color: transparent;
    inset-block-end: 24px;
    z-index: 101;

    .ant-float-btn-group-wrap {
      display: flex;
      margin-bottom: 32px;
    }

    .ant-float-btn {
      background-color: transparent;
      box-shadow: none;
      outline: 0px;

      .ant-float-btn-body {
        background-color: transparent;

        .ant-float-btn-content {
          .ant-float-btn-icon {
            display: none;
          }

          .ant-float-btn-description {
            .chat-open-btn {
              height: 50px;
              width: 172px;
              background: radial-gradient(
                  217.52% 153.59% at 122.4% 118.47%,
                  #9196ff 47.5%,
                  #ac8fff 73%,
                  #9eb4fe 94.27%
                ),
                #f0f2f5;
              color: ${(props) => props.theme.colors.base.gray1};
              border-radius: 25px;
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              user-select: none;
              cursor: pointer;
              outline: none;
              touch-action: manipulation;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              position: absolute;
              bottom: 0px;
              right: 0px;
              opacity: 0.8;
              overflow: hidden;
              box-shadow: 0px 6px 8px 2px #081e4844;
              transition: 0.3s all;

              .btn-title {
                white-space: nowrap;
                transition: 1s all;
              }

              &:hover {
                opacity: 1;
              }

              > svg {
                height: 25px;
                width: 25px;
                margin: 0px;

                g path {
                  fill: ${(props) => props.theme.colors.base.gray1};
                }
              }

              > span svg {
                height: 20px;
                width: 25px;
                margin: 0px;
              }
            }
          }
        }
      }
    }
  }

  .chat-container {
    max-height: calc(100vh - 180px);
    background-color: ${(props) => props.theme.colors.gray2};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow:
      0px 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    transition: 0.3s all;

    ${(props) =>
      props.$isExpanded
        ? css`
            max-width: 1000px;
            min-width: 1000px;
            height: 670px;
            margin-left: calc(-1000px + 42px);
          `
        : css`
            max-width: 420px;
            min-width: 420px;
            height: 380px;
            margin-left: calc(-420px + 42px);
          `}

    .chat-header {
      min-height: 46px;
      background: radial-gradient(217.52% 153.59% at 122.4% 118.47%, #9196ff 47.5%, #ac8fff 73%, #9eb4fe 94.27%);
      color: ${(props) => props.theme.colors.gray1};
      display: flex;
      border-radius: 10px;
      text-align: center;
      align-items: center;
      justify-content: space-between;
      padding: 0px 14px;

      .chat-icon {
        width: 26px;
        margin: 0px 2px 2px 0px;

        path {
          fill: ${(props) => props.theme.colors.gray2};
        }
      }

      .title {
        color: ${(props) => props.theme.colors.gray2};
        font-size: 14px;
        margin: 0px;
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 10px;

        .clear-icon {
          color: ${(props) => props.theme.colors.gray2};
          font-size: 16px;
          cursor: pointer;
          opacity: 0.8;
          transition: 0.3s all;

          &:hover {
            color: ${(props) => props.theme.colors.red6};
            opacity: 1;
          }
        }

        .expand-icon,
        .close-icon {
          color: ${(props) => props.theme.colors.gray2};
          font-size: 16px;
          cursor: pointer;
          opacity: 0.8;
          transition: 0.3s all;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .chat-body {
      height: 100%;
      overflow-y: auto;
      padding: ${(props) => (props.$isExpanded ? '20px 24px' : '16px 10px')};

      .messages {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .chat-loading {
          margin-top: -10px;

          .ant-skeleton-paragraph {
            margin: 0px;

            li {
              width: 100% !important;
              height: 46px;
              border-radius: 20px;
              margin-top: 10px;
            }
          }
        }

        .info-box {
          text-align: center;
          padding: 0px 20px;

          .title {
            font-weight: 500;
            margin: 0px 0px 4px;
          }

          .text {
            margin: 0px;
          }
        }

        .message-container {
          display: flex;
          word-wrap: break-word;

          .message {
            max-width: 90%;
            text-align: left;
            padding: 12px 14px;

            &.response {
              color: ${(props) => props.theme.colors.gray13};
              background-color: ${(props) => props.theme.colors.gray4};
              border-radius: 0px 20px 20px 20px;

              p {
                margin: 0px 0px 10px;
              }
            }

            &.request {
              color: ${(props) => props.theme.colors.gray13};
              background-color: ${(props) => props.theme.colors.geekblue3};
              border-radius: 20px 20px 0px 20px;
              margin-left: auto;
            }

            &.error {
              background-color: ${(props) => props.theme.colors.red2};
              border-radius: 0px 20px 20px 20px;
            }
          }
        }
      }
    }

    .chat-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: ${(props) => (props.$isExpanded ? '12px 24px' : '6px 10px 12px')};

      .example-questions {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 6px;

        .question-box {
          background-color: ${(props) => props.theme.colors.geekblue2};
          border-radius: 20px;
          padding: 6px 10px;

          > p {
            font-size: 10px;
            line-height: 14px;
            margin: 0px;
          }
        }
      }

      .send-box {
        width: 100%;
        position: relative;

        .input {
          min-height: 42px;
          width: 100%;
          color: ${(props) => props.theme.colors.gray13};
          background-color: ${(props) => props.theme.colors.gray1};
          font-size: 14px;
          font-family: 'Inter', sans-serif;
          border: 1px solid ${(props) => props.theme.colors.geekblue2};
          border-radius: 20px;
          outline: 0;
          padding: 10px 34px 10px 14px;
          transition: 0.3s all;
          resize: none;

          &:focus {
            border: 1px solid ${(props) => props.theme.colors.geekblue3};
          }

          &::placeholder {
            color: ${(props) => props.theme.colors.gray8};
          }

          &:disabled {
            cursor: not-allowed;
          }
        }

        .send-btn {
          color: ${(props) => props.theme.colors.base.geekblue3};
          font-size: 20px;
          position: absolute;
          top: calc(50% - 12px);
          right: 10px;
          transition: 0.3s all;
          cursor: pointer;

          &:hover {
            color: ${(props) => props.theme.colors.base.geekblue4};
          }

          &.disabled {
            color: ${(props) => props.theme.colors.base.geekblue3};
            cursor: not-allowed;
            user-actions: none;
          }
        }

        &:hover {
          .input {
            border: 1px solid ${(props) => props.theme.colors.base.geekblue3};
          }
        }
      }
    }
  }

  @media ${device.lgDown} {
    display: none;

    .chat-container {
      ${(props) =>
        props.$isExpanded &&
        css`
          max-width: calc(100vw - 40px);
          margin-left: calc(-100vw + 100px);
        `}
    }
  }
`
