import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 160px;
  background: ${(props) =>
    localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.blue1 : props.theme.colors.gray1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border: 1px solid
    ${(props) => (localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.blue3 : 'transparent')};
  border-radius: 15px;
  overflow: hidden;
  padding: 10px 26px;
  box-shadow: ${localStorage.getItem('dw-theme') === 'dark' ? 'none' : '0px 4px 13px 4px rgba(186, 209, 255, 0.18)'};

  .img-container {
    min-height: 132px;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-image {
      width: 100px;

      > span {
        display: none;
      }
    }

    .module-image {
      width: 100px;
      opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
      object-fit: cover;
      pointer-events: none;
    }
  }

  .module-info {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .loading-title {
      .ant-skeleton-paragraph {
        margin-bottom: 0px;
      }
    }

    .pre-title {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 4px;

      .la-bullet {
        min-width: 12px;
        width: 12px;
        height: 12px;
        background-color: ${(props) => props.theme.colors.green1};
        display: inline-block;
        border-radius: 50%;
      }

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        line-height: 16px;
        margin: 0px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .title {
      color: ${(props) => props.theme.colors.gray11};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0px 0px 10px;
    }

    .module-stats {
      width: 100%;
      max-width: 260px;
      display: flex;
      align-items: center;
      gap: 10px;

      .tag {
        width: fit-content;
        background-color: ${(props) => props.theme.colors.base.gray7};
        color: ${(props) => props.theme.colors.base.gray1};
        text-transform: capitalize;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 6px;
        margin: 0px 0px 4px;

        &.difficulty-easy {
          color: ${(props) => props.theme.colors.base.gray9};
          background-color: ${(props) => props.theme.colors.base.green2};
        }

        &.difficulty-medium {
          color: ${(props) => props.theme.colors.base.gray9};
          background-color: ${(props) => props.theme.colors.base.gold3};
        }

        &.difficulty-hard {
          color: ${(props) => props.theme.colors.base.gray9};
          background-color: ${(props) => props.theme.colors.base.red2};
        }
      }

      .progress-container {
        width: 100%;
        display: flex;
        gap: 8px;

        .progress-text {
          color: ${(props) => props.theme.colors.gray10};
          font-size: 12px;
          margin: 0px;
        }

        .ant-progress {
          .ant-progress-inner {
            height: 12px;
            background-color: ${(props) => props.theme.colors.base.geekblue2};
          }

          .ant-progress-bg {
            height: 12px !important;
            background-color: ${(props) => props.theme.colors.base.geekblue5};
          }

          &.ant-progress-status-success .ant-progress-bg {
            height: 12px !important;
            background-color: ${(props) => props.theme.colors.base.cyan5};
          }
        }
      }
    }
  }

  .actions {
    max-width: 192px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;

    .last-activity-text {
      color: ${(props) => props.theme.colors.gray8};
      font-size: 12px;
      line-height: 20px;
      white-space: nowrap;
      margin: 0px;
    }
  }

  @media ${device.xlDown} {
    height: initial;
    flex-direction: column;
    padding: 32px 16px;

    .img-container {
      .loading-image {
        width: 148px;
        height: 148px;
      }

      .module-image {
        width: 148px;
      }
    }

    .module-info {
      align-items: center;
      margin-top: -8px;

      > .title {
        text-align: center;
      }

      .loading-title {
        .ant-skeleton-title {
          margin: 0 auto;
        }

        .ant-skeleton-paragraph li {
          margin: 10px auto 0px;
        }
      }
    }
  }

  @media ${device.lgDown} {
    height: 160px;
    flex-direction: row;
    padding: 10px 26px;

    .img-container {
      .loading-image {
        width: 100px;
        height: 100px;
      }

      .module-image {
        width: 100px;
      }
    }

    .module-info {
      align-items: flex-start;
      margin-top: 0px;

      > .title {
        text-align: initial;
      }

      .loading-title {
        .ant-skeleton-title {
          margin: initial;
        }

        .ant-skeleton-paragraph li {
          margin: 10px 0px 0px;
        }
      }
    }
  }

  @media ${device.mdDown} {
    height: initial;
    flex-direction: column;
    padding: 32px 16px;

    .img-container {
      .loading-image {
        width: 148px;
        height: 148px;
      }

      .module-image {
        width: 148px;
      }
    }

    .module-info {
      align-items: center;
      margin-top: -8px;

      > .title {
        text-align: center;
      }

      .loading-title {
        .ant-skeleton-title {
          margin: 0 auto;
        }

        .ant-skeleton-paragraph li {
          margin: 10px auto 0px;
        }
      }
    }
  }
`
