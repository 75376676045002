import { SET_GPT_LOADING, GPT_TASKS_UPDATED, GPT_RUNS_UPDATED, SET_GPT_ERROR, RESET_GPT_STATE } from '@/store/gpt'
import { fetchGptRuns, fetchGptTasks, postGptRun } from '@/services/gpt'

const getGptTasks = () => async (dispatch) => {
  try {
    const gptTasks = await fetchGptTasks()
    dispatch(GPT_TASKS_UPDATED(gptTasks.results))
  } catch (error) {
    const { message } = error
    dispatch(SET_GPT_ERROR(message))
  }
}

const getGptRuns = (activityId) => async (dispatch) => {
  try {
    dispatch(SET_GPT_LOADING(true))

    const gptRuns = await fetchGptRuns(activityId)
    dispatch(GPT_RUNS_UPDATED(gptRuns))
  } catch (error) {
    const { message } = error
    dispatch(SET_GPT_ERROR(message))
  }
}

const createGptRun = (data) => async (dispatch, getState) => {
  // data: { activityIds, taskIds }
  try {
    const { gptRuns } = getState().gpt
    const auxGptRun = await postGptRun(data)

    let auxGptRuns = [auxGptRun, ...gptRuns]

    dispatch(GPT_RUNS_UPDATED(auxGptRuns))
  } catch (error) {
    const { message } = error
    dispatch(SET_GPT_ERROR(message))
  }
}

const resetGptState = () => (dispatch) => {
  dispatch(RESET_GPT_STATE())
}

export { getGptRuns, getGptTasks, createGptRun, resetGptState }
