import styled, { css } from 'styled-components'
import { Button as AntdButton } from 'antd'

export const Button = styled(AntdButton)`
  font-weight: 500;

  &.dw-btn {
    &.ant-btn-text {
      color: ${(props) => props.theme.colors.gray13};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${(props) => props.theme.colors.gray13};
        background-color: ${(props) => props.theme.colors.gray13}11;
      }
    }

    &.ant-btn-icon-only {
      &:disabled,
      &.ant-btn-disabled {
        background-color: initial !important;
        border-color: transparent !important;
      }
    }

    &.ant-btn-default {
      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: transparent;
      }
    }

    &.ant-btn-primary {
      color: ${(props) => props.theme.colors.gray1};
      background: ${(props) =>
        localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray9 : props.theme.colors.gray13};
      border-color: ${(props) =>
        localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray9 : props.theme.colors.gray13};
      transition: 0.3s all;

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${(props) => props.theme.colors.gray1};
        background: ${(props) => props.theme.colors.gray13};
        opacity: 0.8;
      }

      &.ant-btn-dangerous {
        color: ${(props) =>
          localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray10 : props.theme.colors.gray1};
        background-color: ${(props) => props.theme.colors.red5};

        &:not(:disabled):not(.ant-btn-disabled):hover {
          color: ${(props) =>
            localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray10 : props.theme.colors.gray1};
          background-color: ${(props) => props.theme.colors.red4};
        }
      }
    }

    &.ant-btn-secondary {
      color: ${(props) =>
        localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray10 : props.theme.colors.gray1};
      background-color: ${(props) => props.theme.colors.geekblue5};
      border-color: ${(props) => props.theme.colors.geekblue5};

      &:hover {
        color: ${(props) =>
          localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray10 : props.theme.colors.gray1};
        background-color: ${(props) => props.theme.colors.geekblue4};
      }
    }

    &.ant-btn-dangerous {
      color: ${(props) => props.theme.colors.red5};
      border-color: ${(props) => props.theme.colors.red5};

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${(props) => props.theme.colors.red4};
        border-color: ${(props) => props.theme.colors.red4};
      }
    }

    ${(props) =>
      props.ispro &&
      css`
        color: #fff !important;
        background: linear-gradient(45deg, #222249 44.48%, #5479f7 76.52%, #89d2c2 100%) !important;
        border: none;
      `}

    &.ant-btn[disabled] {
      color: ${(props) => props.theme.colors.disabledText};
      border-color: ${(props) => props.theme.colors.gray4};
      background: ${(props) => props.theme.colors.gray2};
      text-shadow: none;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
`
