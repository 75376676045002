import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .activity-title {
    display: inline-block;

    * {
      font-weight: 700;
    }
  }

  .warning-content {
    > p {
      color: ${(props) => props.theme.colors.text};
    }

    .actions-checklist {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-left: 16px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .link {
      color: ${(props) => props.theme.colors.gray10};
      font-size: 11px;
      font-weight: 400;
      margin: 0px;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        color: ${(props) => props.theme.colors.gray7};
      }
    }
  }
`
