import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modules-container {
    position: relative;
    display: flex;
    gap: 20px;
    overflow: auto;

    .swiper {
      width: 100%;
      margin: 0px;

      .swiper-slide:nth-of-type(1) > div {
        margin-left: 0px;
      }
    }
  }

  @media ${device.mdDown} {
    padding: 0px 10px;

    > .title {
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      margin: 18px 0px;
    }

    > .ant-tabs-nav-wrap {
      justify-content: center;
    }
  }
`
