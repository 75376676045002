import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  .event-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    animation: 1s ${fadeInAnimation};

    .event-summary {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 6px;
      padding: 3px 0px;

      .member-avatar {
        .profile-picture {
          margin-right: 6px;
        }

        .name-container {
          .name {
            font-weight: 500;
          }
        }
      }

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-weight: 500;
      }
    }

    .comment-content-container {
      background-color: ${(props) => props.theme.colors.gray2};
      position: relative;
      margin-bottom: 12px;
      border-radius: 2px;

      .comment-content {
        padding: 12px;
      }
    }
  }

  .previous-comments-divider {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0px;
    cursor: pointer;

    .text {
      color: ${(props) => props.theme.colors.gray7};
      white-space: nowrap;
      margin: 0px;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: ${(props) => props.theme.colors.gray6};
    }
  }
`
