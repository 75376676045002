import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

export const RateContainer = styled.div`
  background: ${(props) => props.theme.colors.gray1};
  border-radius: 20px;

  .rate-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: 1s ${fadeInAnimation};
    padding: 16px 32px;

    .row {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    .title {
      font-weight: 400;
      margin: 0px;
    }

    .text {
      color: ${(props) => props.theme.colors.gray7};
      margin-bottom: 0px;
    }

    > img {
      max-width: 185px;
    }

    .rate {
      display: flex;
      justify-content: center;
      margin: 0px;

      svg {
        font-size: 20px;
      }
    }

    .rate-faces-container {
      width: 100%;
      margin-top: 12px;

      .rate {
        .ant-rate-star:not(:last-child) {
          margin-right: 12px;
        }

        .ant-rate-star-full:nth-child(1) {
          color: #ff4d4f;
        }
        .ant-rate-star-full:nth-child(2) {
          color: #ffa940;
        }
        .ant-rate-star-full:nth-child(3) {
          color: #fadb14;
        }
        .ant-rate-star-full:nth-child(4) {
          color: #bae637;
        }
        .ant-rate-star-full:nth-child(5) {
          color: #52c41a;
        }

        svg {
          font-size: 32px;
        }
      }
    }

    .actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
    }
  }
`
