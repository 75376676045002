import { useSelector, useDispatch } from 'react-redux'
import WizardContent from './components/WizardContent'
import { toggleWizardModal } from '@/store/playgrounds/actions'
import { Modal } from './styles'

const WizardModal = () => {
  const dispatch = useDispatch()

  const { wizard } = useSelector((state) => state.playgrounds)

  return (
    <Modal
      open={wizard?.isWizardModalOpen}
      onCancel={() => dispatch(toggleWizardModal(false))}
      destroyOnClose
      footer={null}
      className="playground-wizard-modal"
    >
      <WizardContent />
    </Modal>
  )
}

export default WizardModal
