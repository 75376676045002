import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import InProgressModuleCard from '../InProgressModuleCard'

import { Container } from './styles'

const InProgressModulesCarousel = ({ modules }) => {
  return (
    <Container>
      <Swiper
        modules={[Navigation, Pagination]}
        pagination={{
          clickable: true,
        }}
        navigation
        spaceBetween={0}
      >
        {modules?.slice(0, 4)?.map((m) => (
          <SwiperSlide key={m?.id}>
            <InProgressModuleCard module={m} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  )
}

export default InProgressModulesCarousel
