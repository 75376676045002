import styled, { css, keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  width: calc(100% + 60px);
  background: ${(props) => props.theme.colors.gray1};
  position: relative;
  padding: 32px 30px 20px;
  margin: 20px -30px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
  box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.gray7};

  ${(props) =>
    props.$isReview &&
    css`
      display: flex;
      justify-content: space-between;
    `}

  .activity-container {
    .activity-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      animation: 1s ${fadeInAnimation};

      .activity-tag {
        background-color: ${(props) => props.theme.colors.base.gray4};
        color: ${(props) => props.theme.colors.base.gray10};
        align-self: flex-start;
        font-weight: 500;
        padding: 5px 12px;
        margin-bottom: 4px;
      }

      .activity-body {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .activity-number {
          color: ${(props) => props.theme.colors.gray10};
          height: 30px;
          max-width: 30px;
          width: 100%;
          background-color: ${(props) => props.theme.colors.gray4};
          font-size: 12px;
          font-weight: 500;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          margin-top: -2px;
        }

        .title-container {
          flex: 1;

          p {
            color: ${(props) => props.theme.colors.gray10};
            font-size: 15px;
            font-weight: 700;
            line-height: 22px;
          }
        }
      }

      .activity-content-markdown {
        display: grid;

        p,
        ul li {
          color: ${(props) => props.theme.colors.gray8};
          font-size: 13px;
          font-weight: 400;
        }

        pre,
        code {
          font-size: 12px;
        }

        .markdown-block {
          overflow: auto;

          p {
            margin: 0px 0px 10px;
          }
        }
      }

      .activity-expected-outcome-container {
        border-radius: 6px;
        border: 1px solid ${(props) => props.theme.colors.gray4};
        padding: 6px 10px;
        margin-bottom: 14px;

        .section-title {
          color: ${(props) => props.theme.colors.gray8};
          font-size: 10px;
          font-weight: 500;
          text-transform: uppercase;
          margin: 0px;
        }

        p,
        ul li {
          color: ${(props) => props.theme.colors.gray8};
          font-size: 13px;
          font-weight: 400;
        }

        pre,
        code {
          font-size: 12px;
        }

        .markdown-block {
          overflow: auto;
        }
      }
    }
  }

  &.correct {
    background: ${(props) => props.theme.colors.cyan1}33;
    box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.cyan6};

    .activity-container {
      .activity-content {
        .activity-tag {
          background-color: ${(props) => props.theme.colors.cyan3};
        }

        .activity-body {
          .activity-number {
            background-color: ${(props) => props.theme.colors.cyan3};
          }
        }
      }
    }
  }

  &.incorrect {
    background: ${(props) => props.theme.colors.red1}3a;
    box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.red4};

    .activity-container {
      .activity-content {
        .activity-tag {
          color: ${(props) => props.theme.colors.base.gray1};
          background-color: ${(props) => props.theme.colors.red4};
        }

        .activity-body {
          .activity-number {
            color: ${(props) => props.theme.colors.base.gray1};
            background-color: ${(props) => props.theme.colors.red4};
          }
        }
      }
    }
  }

  .info-block {
    width: 100%;
    color: ${(props) => props.theme.colors.gray9};
    background-color: ${(props) => props.theme.colors.gray4};
    border-radius: 6px;
    overflow: auto;
    padding: 12px;
    margin: 8px 0px;

    .code-toolbar {
      margin-bottom: 12px;

      > pre {
        background-color: ${(props) => props.theme.colors.gray2};
      }
    }

    p,
    ul li {
      color: ${(props) => props.theme.colors.gray8};
      font-size: 13px;
      font-weight: 400;
    }

    pre,
    code {
      font-size: 12px;
    }

    .markdown-block {
      overflow: auto;
    }

    &.out {
      background-color: ${(props) => props.theme.colors.gray3};
    }

    &.error {
      background-color: ${(props) => props.theme.colors.red1};
      color: ${(props) => props.theme.colors.red5};
    }
  }

  .activity-actions {
    display: flex;
    justify-content: space-between;
    gap: 6px;
    margin-bottom: 10px;

    button {
      background-color: transparent;

      &.is-selected {
        background-color: ${(props) => props.theme.colors.gray4};
        color: ${(props) => props.theme.colors.gray10};
        border: none;
      }

      &.danger {
        color: ${(props) => props.theme.colors.base.gold6};
        border-color: ${(props) => props.theme.colors.base.gold6};
      }
    }
  }

  .activity-input {
    color: ${(props) => props.theme.colors.gray12};
    background-color: ${(props) => props.theme.colors.gray1};
    margin-bottom: 12px;
  }

  .activity-answers-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    animation: 1s ${fadeInAnimation};

    > label {
      width: 100%;
      margin-bottom: 8px;

      > span:nth-child(2) {
        width: 100%;
        overflow: auto;
      }

      p {
        margin: 0px;
      }
    }
  }

  .error-text {
    color: #fa541c;
    font-size: 12px;
    margin: -12px 0px 12px;
  }

  .activity-status-btn {
    background-color: transparent;
    margin-left: 16px;
    cursor: default;

    &:hover,
    &:focus,
    &:active {
      color: ${(props) => props.theme.colors.text} !important;
      border-color: ${(props) => props.theme.colors.gray9} !important;
    }

    .ant-wave {
      display: none;
    }

    &.correct {
      color: ${(props) => props.theme.colors.base.cyan7} !important;
      border-color: ${(props) => props.theme.colors.base.cyan7} !important;

      &:hover,
      &:focus,
      &:active {
        color: ${(props) => props.theme.colors.base.cyan7} !important;
        border-color: ${(props) => props.theme.colors.base.cyan7} !important;
      }
    }

    &.incorrect {
      color: ${(props) => props.theme.colors.base.red5} !important;
      border-color: ${(props) => props.theme.colors.base.red5} !important;

      &:hover,
      &:focus,
      &:active {
        color: ${(props) => props.theme.colors.base.red5} !important;
        border-color: ${(props) => props.theme.colors.base.red5} !important;
      }
    }

    &.not-actioned {
      color: rgba(0, 0, 0, 0.3) !important;
      border-color: rgba(0, 0, 0, 0.3) !important;

      &:hover,
      &:focus,
      &:active {
        color: rgba(0, 0, 0, 0.3) !important;
        border-color: rgba(0, 0, 0, 0.3) !important;
      }
    }
  }

  .review-status-block {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    position: relative;

    ${(props) =>
      props.$isReview &&
      css`
        align-items: flex-end;
      `}

    .submit-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .submit-info {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 11px;
        margin: 0px;
      }
    }

    .feedback-box {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      user-select: none;

      .feedback-btn {
        color: ${(props) => props.theme.colors.gray7};
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 11px;
        font-weight: 400;
        padding: 0px;
        margin: 0px;
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
          color: ${(props) => props.theme.colors.gray7};
        }
      }
    }
  }

  .activity-footer-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: 1px solid ${(props) => props.theme.colors.gray4};
    padding-top: 14px;
    margin-top: 20px;

    .footer-item {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 12px;
      padding: 12px;
      cursor: pointer;

      &:hover {
        background: ${(props) => props.theme.colors.gray2};
      }

      &:not(:last-of-type) {
        border-right: 1px solid ${(props) => props.theme.colors.gray4};
      }

      > span {
        color: ${(props) => props.theme.colors.gray7};
      }

      .anticon {
        color: ${(props) => props.theme.colors.gray8};
        font-size: 16px;
      }
    }
  }

  ${(props) =>
    props.$isAssessment &&
    css`
      height: 100%;
      width: 100%;
      background: ${(props) => props.theme.colors.gray1} !important;
      border-bottom: 0px !important;
      padding: 0px;
      margin: 0px;
      box-shadow: none !important;

      .activity-container {
        .activity-content {
          .activity-body {
            .title-container {
              p {
                color: ${(props) => props.theme.colors.gray10};
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
                margin: 0px 0px 10px;
              }
            }
          }
        }
      }

      .review-status-block {
        flex: 0;

        .assessment-content-actions {
          width: 100%;

          @keyframes smooth-appear {
            to {
              opacity: 1;
            }
          }

          .status-block {
            height: 50px;
            background-color: ${(props) => props.theme.colors.gray5};
            width: 100%;
            opacity: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            padding: 0px 18px 10px;
            margin-bottom: -10px;
            animation: smooth-appear 0.4s ease forwards;
          }

          &.is-past {
            .status-block {
              height: 100%
              position: relative;
              opacity: 1;
              padding: 0px 10px;
              animation: none;
            }
          }

          &.correct {
            .status-block {
              background-color: ${(props) => props.theme.colors.cyan3};
            }
          }

          &.incorrect {
            .status-block {
              background-color: ${(props) => props.theme.colors.red2};
            }
          }

          &.skipped {
            .status-block {
              background-color: ${(props) => props.theme.colors.gray3};
            }
          }

          .actions-container {
            height: 72px;
            background-color: ${(props) => props.theme.colors.gray3};
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;
            gap: 20px;
            padding: 0px 18px;

            .skip-button.ant-btn-default,
            .skip-button.ant-btn-default:hover {
              background-color: ${(props) => props.theme.colors.gray1};
            }

            .submit-container {
              flex-direction: row;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }
    `}

  @media ${device.mdDown} {
    padding: 24px 18px;
  }
`
