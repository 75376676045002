import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox, Select, Input, Tooltip } from 'antd'
import Button from '@/components/Button'
import DiscordInfo from '@/components/DiscordInfo'
import { toggleReportIssueModal } from '@/store/activities/actions'
import { createIssue } from '@/store/issues/actions'
import { Modal } from './styles'

const ReportIssueModal = () => {
  const dispatch = useDispatch()

  const { currentModule } = useSelector((state) => state.modules)
  const { currentLab } = useSelector((state) => state.labs)
  const { isReportIssueModalOpen, currentActivity } = useSelector((state) => state.activities)
  const last_module_attempt = currentModule?.user_status?.last_module_attempt
  const labSession = currentLab?.allocated_session

  const [isReportingActivityIssue, setIsReportingActivityIssue] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [issueType, setIssueType] = useState(null)
  const [description, setDescription] = useState('')
  const [showWarning, setShowWarning] = useState(currentModule && !currentModule?.is_alpha)

  const [checked1, setChecked1] = useState(false)
  const [checked2, setChecked2] = useState(false)
  const [checked3, setChecked3] = useState(false)
  const isConfirmed = checked1 && checked2 && checked3

  const cleanup = () => {
    setIssueType(null)
    setDescription('')
    setShowWarning(currentModule && !currentModule?.is_alpha)
    setChecked1(false)
    setChecked2(false)
    setChecked3(false)
    dispatch(toggleReportIssueModal())
  }

  const handleSubmit = async () => {
    const issueData = {
      contentId: currentModule?.id,
      issueType: issueType || 'content_issue',
      description,
      moduleAttemptId: last_module_attempt?.id,
      labSessionId: labSession?.id,
      activityId: issueType ? undefined : currentActivity?.id,
    }

    setIsLoading(true)
    await dispatch(createIssue(issueData, cleanup))
    setIsLoading(false)
  }

  const closeWarning = () => {
    setShowWarning(false)
  }

  const getModalTitle = () => {
    if (showWarning) return 'Are you sure about your issue?'
    return isReportingActivityIssue ? 'Report issue with this activity' : 'Help improve Datawars'
  }

  useEffect(() => {
    setShowWarning(currentModule && !currentModule?.is_alpha)
  }, [currentModule])

  useEffect(() => {
    setIsReportingActivityIssue(!!currentActivity)
  }, [currentActivity])

  return (
    <Modal title={getModalTitle()} open={isReportIssueModalOpen} onCancel={cleanup} footer={null} destroyOnClose>
      {showWarning && (
        <div className="warning-content">
          <p>
            {isReportingActivityIssue
              ? `Hundreds of users resolved this activity correctly and this project has an average rating of ${currentModule?.rating?.avg?.toFixed(
                  2,
                )}.`
              : `Hundreds of users resolved this project correctly and it has an average rating of ${currentModule?.rating?.avg?.toFixed(
                  2,
                )}.`}
          </p>

          <p>Please double check the following items before submitting the issue:</p>

          <ul className="actions-checklist">
            <li>
              <Checkbox checked={checked1} onChange={(evt) => setChecked1(evt.target.checked)}>
                {currentModule?.config?.resolve_in_order
                  ? 'Activities in this project are supposed to be executed in order, from first to last.'
                  : 'Sometimes activities might be dependent on other activities or the global context of the project. Some projects require activities to be executed in order.'}
              </Checkbox>
            </li>
            <li>
              <Checkbox checked={checked2} onChange={(evt) => setChecked2(evt.target.checked)}>
                If your lab stopped for some reason, try resetting activities and start over. Your lab might be in an
                inconsistent state.
              </Checkbox>
            </li>
            <li>
              <Checkbox checked={checked3} onChange={(evt) => setChecked3(evt.target.checked)}>
                Check the solution of the activity. If your code matches the proposed solution, your issue might be due
                to the overall context of your data or the order of the activities.
              </Checkbox>
            </li>
          </ul>

          <p>Still want to report the issue?</p>

          <div className="actions">
            <span />
            <Tooltip title={!isConfirmed && 'Review all items above to continue'} overlayClassName={'on-modal'}>
              <Button type="primary" onClick={closeWarning} disabled={!isConfirmed}>
                Report issue
              </Button>
            </Tooltip>
          </div>
        </div>
      )}

      {!showWarning && (
        <div>
          {isReportingActivityIssue ? (
            <div
              className="activity-title"
              dangerouslySetInnerHTML={{
                __html: currentActivity?.title_html,
              }}
            />
          ) : (
            <p>Let us know what issue you've found so we can fix it as soon as possible.</p>
          )}

          {isReportingActivityIssue ? (
            <p>Please enter a brief description of the issue:</p>
          ) : (
            <Select
              value={issueType}
              placeholder="What is the issue about?"
              style={{ width: '100%', margin: '0 0 10px' }}
              dropdownStyle={{ zIndex: 9999 }}
              onChange={(e) => setIssueType(e)}
            >
              <Select.Option value="platform_issue">Platform is broken (errors, bugs, etc)</Select.Option>
              <Select.Option value="content_issue">Content is wrong (typo, missing assets, etc)</Select.Option>
              <Select.Option value="layout_issue">Layout, accessibility, look and feel</Select.Option>
              <Select.Option value="other">Other</Select.Option>
            </Select>
          )}

          {(isReportingActivityIssue || issueType) && (
            <Input.TextArea
              rows={4}
              placeholder="Describe the issue and how to reproduce it."
              maxLength={480}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{ marginBottom: 10 }}
            />
          )}

          <div className="actions">
            {isReportingActivityIssue ? (
              <p className="link" onClick={() => setIsReportingActivityIssue(false)}>
                My issue is not associated with a particular activity
              </p>
            ) : (
              <p style={{ fontSize: 11 }}>
                Are you having a different issue? Please{' '}
                <a href="mailto:support@datawars.io?subject=Report an issue" target="_blank" rel="noreferrer">
                  contact support
                </a>
                .
              </p>
            )}

            <Button type="primary" onClick={handleSubmit} loading={isLoading} disabled={!description || isLoading}>
              Submit
            </Button>
          </div>

          <DiscordInfo />
        </div>
      )}
    </Modal>
  )
}

export default ReportIssueModal
