import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray3};
  padding: 30px 24px;

  .fade-in-mask {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .datasources-search-content {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;

    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .section-title {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    > .datasources-search-body {
      height: calc(100% - 12px);
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 6px;

      .datasources-search-container {
        max-height: 100%;
        height: 100%;
      }
    }
  }

  @media ${device.mdDown} {
    height: calc(100% - 64px);
  }

  @media ${device.smDown} {
    padding: 20px 0px 0px;

    .datasources-search-content {
      > .header {
        padding-left: 20px;
      }

      .datasources-search-body {
        .search-box {
          width: calc(100% - 40px);
        }
      }
    }
  }
`
