import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '@/components/Button'
import ErrorImage from '@/assets/images/error.gif'
import { Container } from './styles'

const ErrorCard = ({
  title,
  text,
  primaryButtonText = 'Go to Catalog',
  primaryButtonLinkTo = '/catalog?status=Published',
  primaryButtonOnClick,
  buttonText,
  buttonOnClick,
  bottomText,
  bottomOnClick,
}) => {
  const navigate = useNavigate()

  const { uiConfig } = useSelector((state) => state.accounts)
  const { isAuthenticated } = useSelector((state) => state.users)

  return (
    <Container className="error-card-container">
      <img src={ErrorImage} alt={title} />

      <h3 className="title">{title}</h3>
      {!!text && <p className="text-body">{text}</p>}

      {uiConfig?.showErrorCardActions && (
        <div className="actions">
          {isAuthenticated && (
            <Button
              className="button"
              type="primary"
              onClick={primaryButtonLinkTo ? navigate(primaryButtonLinkTo) : primaryButtonOnClick}
            >
              {primaryButtonText}
            </Button>
          )}

          {!!buttonText && (
            <Button className="button" type={isAuthenticated ? 'default' : 'primary'} onClick={buttonOnClick}>
              {buttonText}
            </Button>
          )}
        </div>
      )}

      {!!bottomText && (
        <p className="bottom-text" onClick={bottomOnClick}>
          {bottomText}
        </p>
      )}
    </Container>
  )
}

export default ErrorCard
