import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 160px;
  background: ${(props) => props.theme.colors.gray2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  border: 2px dotted ${(props) => props.theme.colors.geekblue3};
  border-radius: 15px;
  overflow: hidden;
  padding: 24px 40px;
  margin-bottom: 48px;

  .img-container {
    min-height: 132px;
    display: flex;
    align-items: center;
    justify-content: center;

    .module-image {
      width: 100px;
      object-fit: cover;
      pointer-events: none;
    }
  }

  .module-info {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .title {
      color: ${(props) => props.theme.colors.gray11};
      margin-bottom: 6px;
    }

    .text {
      color: ${(props) => props.theme.colors.gray7};
      font-weight: 400;
      margin: 0px;
    }
  }

  @media ${device.xlDown} {
    height: initial;
    flex-direction: column;

    .module-info {
      align-items: center;

      .title,
      .text {
        text-align: center;
      }
    }
  }
`
