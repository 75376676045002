import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  min-height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  p {
    margin: 0px;
  }

  .user-info {
    flex: 2 1 0px;

    .title-container {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 6px;

      .title {
        margin-bottom: 0px;
      }
    }

    .subtitle {
      color: ${(props) => props.theme.colors.gray8};
      font-size: 16px;
    }
  }

  > .swiper {
    flex: 1 1 50px;
    overflow: hidden;

    .swiper-pagination {
      margin-top: 8px;
    }
  }

  @media ${device.lgDown} {
    min-height: initial;

    .user-info {
      padding: 20px 20px 10px;
    }

    .banner-container {
      display: none;
    }
  }

  @media ${device.mdDown} {
    .user-info {
      text-align: center;

      .title-container {
        flex-direction: column;

        .title {
          font-size: 28px;
          line-height: 34px;
        }
      }

      .subtitle {
        display: none;
      }
    }
  }
`
