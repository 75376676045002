import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Collapse, Skeleton, Spin } from 'antd'
import { WarningOutlined } from '@ant-design/icons'
import {
  BookQuestionMark24Regular,
  Timer24Regular,
  RibbonStar24Regular,
  Emoji24Filled,
  EmojiSad24Filled,
} from '@fluentui/react-icons'
import { isDataWarsHostName } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import Layout1 from '@/layouts/Layout1'
import Button from '@/components/Button'
import DevicesBox from '@/pages/ModulePage/components/DevicesBox'
import Replacement from '@/pages/ModulePage/components/Replacement'
import DeviceContent from '@/pages/ModulePage/components/DeviceContent'
import InactivityModal from '@/pages/ModulePage/components/InactivityModal'
import ErrorCard from '@/pages/ErrorPage/components/ErrorCard'
import Pagination from './components/Pagination'
import { toggleAuthModal, toggleEmailVerificationModal } from '@/store/users/actions'
import {
  getModule,
  getAllocatedModules,
  createModuleAttempt,
  stopModuleAttempt,
  fetchModuleAttemptContent,
  skipAssessmentContent,
  fetchModuleAttemptScore,
  selectObjective,
  selectPage,
  resetModulesState,
} from '@/store/modules/actions'
import { getLab, fetchSession, resetLabsState } from '@/store/labs/actions'
import AssessmentIntroBg from '@/assets/images/assessments/assessment-intro.png'
import AssessmentPassedBg from '@/assets/images/assessments/assessment-passed.png'
import AssessmentFailedBg from '@/assets/images/assessments/assessment-failed.png'
import { MainContent, SecondaryContent } from './styles'

const AssessmentPage = () => {
  const dispatch = useDispatch()
  const { moduleId } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [viewAssessmentIntro, setViewAssessmentIntro] = useState(false)

  const { currentAccount } = useSelector((state) => state.accounts)
  const {
    isAuthenticated,
    userProfile,
    emailValidationCodeIsValidated,
    isLoading: isUsersLoading,
  } = useSelector((state) => state.users)
  const {
    currentModule,
    moduleContentStream,
    moduleAttemptScore,
    currentPage,
    currentObjective,
    isModuleAttemptLoading,
    isModuleContentStreamLoading,
    isModuleLoading,
    error: modulesError,
  } = useSelector((state) => state.modules)
  const { currentLab, isLabLoading } = useSelector((state) => state.labs)

  const isAssessment = currentModule?.module_type === 'assessment'
  // TODO: redirect of not assessment

  const moduleAttempt = currentModule?.user_status?.last_module_attempt
  const isAttemptStarted = moduleAttempt?.status === 'started'
  const isAttemptFinished = moduleAttempt?.status === 'finished'
  const isAttemptExpired = moduleAttempt?.remaining_time_in_secs === 0
  const shouldTimeOutAttempt = !isAttemptFinished && isAttemptExpired

  const labSession = currentLab?.allocated_session
  const isLabSessionAllocated = labSession?.status === 'allocated'
  const isDeviceStopping = labSession?.devices?.some((d) => d.status === 'stopping')

  const currentContent = moduleContentStream?.filter((content) => content?.objective === currentObjective)?.[
    currentPage
  ]
  const contentIsActivity = currentContent?.type === 'activity'
  const shouldShowLoading =
    isLoading ||
    (isModuleLoading && !currentModule) ||
    (isLabLoading && !currentLab) ||
    (isModuleContentStreamLoading && !moduleContentStream) ||
    isModuleAttemptLoading
  const shouldShowAssessmentIntro =
    (currentModule && moduleContentStream && !isAttemptStarted && !isAttemptFinished && !modulesError) ||
    !moduleAttempt ||
    viewAssessmentIntro
  const shouldShowAssessmentReview = isAttemptFinished && !modulesError

  let groupedPages = {}
  moduleContentStream?.forEach((content) => {
    if (groupedPages[content?.objective]) {
      groupedPages[content?.objective].push(content)
    } else {
      groupedPages[content?.objective] = [content]
    }
  })

  const handleModuleLoad = async () => {
    setIsLoading(true)
    await dispatch(getModule(moduleId))

    if (isAuthenticated) {
      await dispatch(getAllocatedModules())
    }

    setIsLoading(false)
  }

  const handleLoadAttemptContent = async () => {
    if (!moduleAttempt?.id) return

    await dispatch(fetchModuleAttemptContent(currentModule?.id, moduleAttempt?.id))
  }

  const handleLoadAttemptScore = async () => {
    if (!moduleAttempt?.id) return

    await dispatch(fetchModuleAttemptScore(currentModule?.id, moduleAttempt?.id))
  }

  const handleLabLoad = async (labId) => {
    setIsLoading(true)
    await dispatch(getLab(labId))
    setIsLoading(false)
  }

  const handleCreateAttempt = async () => {
    if (!isAuthenticated) {
      dispatch(toggleAuthModal(true))
      return
    }

    if (!userProfile?.email_validated_at && !emailValidationCodeIsValidated) {
      dispatch(toggleEmailVerificationModal(true))
      return
    }

    if (viewAssessmentIntro) setViewAssessmentIntro(false)

    await dispatch(createModuleAttempt(currentModule?.id))
  }

  const renderAssessmentContent = () => {
    if (shouldShowLoading) {
      return (
        <div style={{ maxWidth: 800, width: '100%', overflow: 'hidden', padding: '20px 38px 20px' }}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      )
    }

    if (shouldShowAssessmentIntro) {
      return (
        <div className="assessment-landing">
          <img className="bg" src={AssessmentIntroBg} alt="DataWars.io assessments" />

          <div className="content">
            <div className="main-container">
              <div className="assessment-info">
                {currentLab?.devices && <DevicesBox devices={currentLab?.devices} />}

                <h5 className="pre-title">Complete this assessment</h5>

                <h2 className="title">{currentModule?.name}</h2>

                <h5 className="description">{currentModule?.description}</h5>
              </div>

              {!!currentModule?.objectives?.length && (
                <div className="objectives-container">
                  {currentModule?.objectives?.map((o, i) => (
                    <p key={i} className="objective-item">
                      {o.name}
                    </p>
                  ))}
                </div>
              )}

              <div className="actions">
                <Button className="start-button" type="primary" size="large" onClick={handleCreateAttempt}>
                  Start assessment
                </Button>
              </div>
            </div>

            <div className="info-container">
              {currentModule?.module_pages_count && (
                <div className="item">
                  <div className="label-container">
                    <BookQuestionMark24Regular /> <span>Number of activities:</span>
                  </div>
                  <h5 className="value">{currentModule?.total_activities}</h5>
                </div>
              )}

              {currentModule?.expiration_time && (
                <div className="item">
                  <div className="label-container">
                    <Timer24Regular /> <span>Duration:</span>
                  </div>
                  <h5 className="value">{currentModule?.expiration_time} minutes</h5>
                </div>
              )}

              <div className="item">
                <div className="label-container">
                  <RibbonStar24Regular /> <span>Score to pass:</span>
                </div>
                <h5 className="value">{currentModule?.scoring_config?.min_global_score_to_pass} correct</h5>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (shouldShowAssessmentReview) {
      const totalActivities = moduleAttemptScore?.objectives
        ?.map((o) => o?.activities?.length)
        ?.reduce((a, b) => a + b, 0)

      return (
        <div className="assessment-review">
          <img
            className="bg review-bg"
            src={moduleAttemptScore?.is_passed ? AssessmentPassedBg : AssessmentFailedBg}
            alt="DataWars.io assessments"
          />

          <div className="content">
            <div className={`main-container ${moduleAttemptScore?.is_passed ? 'passed' : 'failed'}`}>
              <h3 className="title">{currentModule?.name}</h3>

              <div className="score-container">
                <span className="score">
                  {moduleAttemptScore?.global_points}/{totalActivities}
                </span>
                <span className="text">your score</span>
              </div>

              <div className="bottom-content">
                <h2 className="text">{moduleAttemptScore?.is_passed ? 'Passed' : 'Failed'}</h2>

                {moduleAttemptScore?.is_passed ? (
                  <Emoji24Filled className="emoji" />
                ) : (
                  <EmojiSad24Filled className="emoji" />
                )}
              </div>
            </div>

            <div className="info-container">
              <Collapse
                className="review-container"
                ghost
                expandIconPosition="end"
                items={moduleAttemptScore?.objectives?.map((o, i) => {
                  const passedActivities = o?.activities?.filter((a) => a?.is_passed)?.length
                  const totalActivities = o?.activities?.length

                  return {
                    key: i,
                    className: 'collapse-item',
                    label: (
                      <div className="objective-title">
                        {o?.name}{' '}
                        <h5 className="objective-score">
                          {passedActivities}/{totalActivities}
                        </h5>
                      </div>
                    ),
                    children: (
                      <div className="activities-container">
                        {o?.activities?.map((a, i) => (
                          <div key={i} className="activity-item">
                            <Replacement customClass="replacement-content" contentHtml={a?.title_html} />

                            <span
                              className={`result ${a?.action === 'submit' ? (a?.is_passed ? 'passed' : 'failed') : 'skipped'}`}
                            >
                              {a?.action === 'submit' ? (a?.is_passed ? 'Correct' : 'Incorrect') : 'Not actioned'}
                            </span>
                          </div>
                        ))}
                      </div>
                    ),
                  }
                })}
              />

              <div className="actions">
                {!isDeviceStopping && (
                  <Button
                    className="button"
                    type="primary"
                    size="large"
                    onClick={() => setViewAssessmentIntro(true)}
                    loading={isModuleAttemptLoading}
                  >
                    Retake assessment
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (!modulesError) {
      return (
        <>
          <div className="content-container">
            <div className="module-content">
              <Replacement
                customClass="replacement-content"
                contentHtml={
                  contentIsActivity
                    ? `{{assessment-activity."${currentContent?.id?.split('-')?.[0]}}}`
                    : currentContent?.content_html
                }
              />
              {!contentIsActivity && !currentContent?.actions?.length && (
                <div className="actions">
                  <Button type="primary" onClick={() => dispatch(skipAssessmentContent('next', currentContent?.id))}>
                    Continue
                  </Button>
                </div>
              )}
            </div>
          </div>

          {isAuthenticated && <InactivityModal />}
        </>
      )
    }

    return (
      <ErrorCard
        title={modulesError === 'Not found.' ? 'Project not found' : 'There was an error loading this project'}
        buttonText={isAuthenticated ? 'Back to dashboard' : 'Go to Login page'}
        buttonOnClick={isAuthenticated ? () => navigate('/') : () => navigate('/login')}
      />
    )
  }

  useEffect(() => {
    if (!moduleContentStream || currentObjective) return

    const lastContent = moduleContentStream?.findLast((p) => 'content_html' in p)
    const lastContentIndex = groupedPages?.[lastContent?.objective].findIndex((p) => p?.id === lastContent?.id)

    dispatch(selectObjective(lastContent?.objective))
    dispatch(selectPage(lastContentIndex))
  }, [moduleContentStream])

  useEffect(() => {
    if (!isAttemptStarted || moduleContentStream) return

    handleLoadAttemptContent()
  }, [isAttemptStarted])

  useEffect(() => {
    if (!isAttemptFinished) return

    handleLoadAttemptScore()
  }, [isAttemptFinished])

  useEffect(() => {
    if (shouldTimeOutAttempt) {
      dispatch(stopModuleAttempt(moduleId, moduleAttempt.id, { reason: 'timed_out' }))
    }
  }, [currentLab?.allocated_session?.id])

  useEffect(() => {
    if (!currentLab) return

    let refreshSessionInterval
    if (isAuthenticated && isDeviceStopping) {
      refreshSessionInterval = setInterval(() => {
        dispatch(fetchSession(currentLab?.allocated_session?.id))
      }, 1000)
    }

    if (currentLab && !currentLab?.allocated_session && isAuthenticated) {
      dispatch(getAllocatedModules())
    }

    return () => {
      if (!labSession) return

      clearInterval(refreshSessionInterval)
    }
  }, [currentLab?.allocated_session]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!currentModule) return

    if (!isAssessment) {
      return navigate('/', { replace: true })
    }

    const title = (isDataWarsHostName ? 'DataWars' : currentAccount?.name) || 'Practice projects'
    const fullTitle = `${title} - Become an expert Data Scientist`

    document.title = `${currentModule?.name} | ${title}`
    document.querySelector("meta[property='og:title'").setAttribute('content', `${currentModule?.name} | ${title}`)
    document.querySelector("meta[name='description'").setAttribute('content', currentModule?.description)
    document.querySelector("meta[property='og:description'").setAttribute('content', currentModule?.description)

    if (currentModule?.lab_id) {
      handleLabLoad(currentModule.lab_id)
    }

    return () => {
      document.title = title
      document.querySelector("meta[property='og:title'").setAttribute('content', fullTitle)
      document
        .querySelector("meta[name='description'")
        .setAttribute('content', 'Project-based playground with +1000 ready-to-solve challenges.')
      document
        .querySelector("meta[property='og:description'")
        .setAttribute('content', 'Project-based playground with +1000 ready-to-solve challenges.')
    }
  }, [currentModule?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!moduleId) return

    handleModuleLoad()

    // TODO: is this working or needs another useEffect?
    if (modulesError) {
      showToast(
        modulesError === 'Not found.' ? 'Assessment not found' : 'There was an error loading this project',
        'error',
      )
    }
  }, [moduleId, isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(resetModulesState())
      dispatch(resetLabsState())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1
      navbar
      isLoading={isAuthenticated === null}
      resizable
      mainContent={<MainContent>{renderAssessmentContent()}</MainContent>}
      secondaryContent={
        (shouldShowLoading || (!shouldShowAssessmentIntro && !shouldShowAssessmentReview && !modulesError)) && (
          <SecondaryContent>
            <div className="mobile-message">
              <WarningOutlined />
              <div>
                <p>Our interactive projects are optimized for Desktop environments.</p>
                <p>
                  Preview projects on mobile, but working on them is only available on desktop. This includes: starting
                  the project and/or submitting activities.
                </p>
              </div>
            </div>
            <div className="lab-container">
              {shouldShowLoading ? (
                <Spin size="large" />
              ) : !currentLab || isUsersLoading ? (
                <div className="loading-container">
                  <Skeleton active title={false} paragraph={{ rows: 1 }} />
                </div>
              ) : isLabSessionAllocated ? (
                <DeviceContent />
              ) : (
                <Spin size="large" />
              )}
            </div>
          </SecondaryContent>
        )
      }
      footerContent={isAttemptStarted && !shouldShowLoading && <Pagination groupedPages={groupedPages} />}
    />
  )
}

export default AssessmentPage
