import { useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Drawer } from 'antd'
import { ArrowLeftOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'
import MemberAvatar from '@/components/MemberAvatar'
import HTMLBlock from '@/components/HTMLBlock'
import { Container } from './styles'

const ModuleCommentsDrawer = ({ isOpen, onClose }) => {
  const { currentModule } = useSelector((state) => state.modules)

  const recentComments = currentModule?.user_status?.comments?.recent
  const previousComments = currentModule?.user_status?.comments?.previous

  const [isCollapsed, setIsCollapsed] = useState(!!recentComments?.length)

  const renderComment = (comment) => {
    return (
      <div key={comment.id} className="event-content">
        <div className="event-summary">
          <MemberAvatar
            lastName={comment?.user?.last_name}
            firstName={comment?.user?.first_name}
            showName
            avatarUrl={comment?.user?.avatar_url}
          />

          <span className="text">{dayjs(comment?.created).from()}</span>
        </div>

        <div className="comment-content-container">
          <HTMLBlock content={comment?.content_html} className="comment-content" />
        </div>
      </div>
    )
  }
  return (
    <Drawer
      rootClassName="module-comments-drawer-root"
      open={isOpen}
      onClose={onClose}
      title="Comments"
      placement={'left'}
      closeIcon={<ArrowLeftOutlined />}
      key={'module-comments-drawer'}
      getContainer={false}
    >
      <Container className="module-comments-drawer-container">
        {recentComments?.map((comment) => renderComment(comment))}

        {!!recentComments?.length && !!previousComments?.length && (
          <div className="previous-comments-divider" onClick={() => setIsCollapsed(!isCollapsed)}>
            <div className="divider" />
            <p className="text">Previous comments {isCollapsed ? <UpOutlined /> : <DownOutlined />}</p>
            <div className="divider" />
          </div>
        )}

        {!isCollapsed && previousComments?.map((comment) => renderComment(comment))}
      </Container>
    </Drawer>
  )
}

export default ModuleCommentsDrawer
