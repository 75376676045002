import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const fetchSkills = async (queryParams) => {
  // ?ids=short_id_1,short_id_2
  const route = `skills`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { fetchSkills }
