import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const postRegisterUser = async (userData) => {
  const route = `users/signup`
  return API.post(route, userData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postAuthUserWithGoogle = async (data) => {
  const route = `users/auth/google-oauth2`
  return API.post(route, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    .then((response) => Promise.resolve({ ...response.data, responseStatus: response.status }))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postAuthenticateUser = async (userCredentials) => {
  const route = `users/login`
  return API.post(route, userCredentials)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postLogoutUser = async () => {
  const route = `users/logout`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postForgotPassword = async (data) => {
  const route = `users/password-reset`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postResetPassword = async (data) => {
  const route = `users/password-reset/confirm`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchUserMe = async () => {
  const route = `users/me`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchUserStats = async () => {
  const route = `users/stats`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postValidateUserEmail = async (userId) => {
  const route = `users/${userId}/email/validate`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postConfirmUserEmail = async (userId, token) => {
  const route = `users/${userId}/email/confirm`
  return API.post(route, { token })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postUserCheckout = async (data) => {
  const route = 'users/checkout'
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postUserBilling = async (data) => {
  const route = 'users/billing'
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postUserFeedback = async (data) => {
  const route = `users/feedback`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export {
  postRegisterUser,
  postAuthUserWithGoogle,
  postAuthenticateUser,
  postLogoutUser,
  postForgotPassword,
  postResetPassword,
  fetchUserMe,
  fetchUserStats,
  postValidateUserEmail,
  postConfirmUserEmail,
  postUserCheckout,
  postUserBilling,
  postUserFeedback,
}
