import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentInvite: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'invites',
  initialState,
  reducers: {
    SET_INVITES_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    INVITE_UPDATED: (state, { payload }) => {
      state.currentInvite = payload
      state.error = null
    },
    RESET_INVITES_STATE: () => initialState,
    SET_INVITES_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
  },
})

export const { SET_INVITES_LOADING, INVITE_UPDATED, RESET_INVITES_STATE, SET_INVITES_ERROR } = slice.actions
export default slice.reducer
