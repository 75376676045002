import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  launchData: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'launch',
  initialState,
  reducers: {
    SET_LAUNCH_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    LAUNCH_DATA_UPDATED: (state, { payload }) => {
      state.launchData = payload
    },
    SET_LAUNCH_ERROR: (state, { payload }) => {
      state.error = payload
    },
  },
})

export const { SET_LAUNCH_LOADING, LAUNCH_DATA_UPDATED, SET_LAUNCH_ERROR } = slice.actions
export default slice.reducer
