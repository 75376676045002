import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  .custom-projects-creation-page-container {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 38px 24px;
  }
`
