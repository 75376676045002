import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown, Tooltip } from 'antd'
import { RightOutlined, DownOutlined, ReadOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import FadeIn from '@/components/FadeIn'
import ModuleBox from '@/components/ModuleBox'
import SkillBullet from '../SkillBullet'
import SkillsTableLoading from './components/SkillsTableLoading'
import ModuleBoxesLoading from './components/ModuleBoxesLoading'
import { getSkills } from '@/store/skillTracks/actions'
import Weight from '@/assets/images/weight.svg'
import { Container } from './styles'

const SkillTrackContent = () => {
  const dispatch = useDispatch()

  const [selectedSkill, selectSkill] = useState(null)

  const { currentSkillTrack, isLoading: isSkillTrackLoading } = useSelector((state) => state.skillTracks)

  const hasModules = !!selectedSkill?.modules?.length
  const skillLearnModules = selectedSkill?.modules?.filter((l) => l.module_type === 'learn')
  const skillPracticeModules = selectedSkill?.modules?.filter((l) => l.module_type === 'practice')
  const skillAssessments = selectedSkill?.modules?.filter((l) => l.module_type === 'assessment')

  const isLoadingSkillsContent = currentSkillTrack?.skills?.[0] && !currentSkillTrack?.skills?.[0]?.modules

  const currentSkill = currentSkillTrack?.skills?.find((s) => s.id === selectedSkill?.id)
  const allModules = currentSkill?.modules

  const learnModules = allModules?.filter((m) => m?.module_type === 'learn')
  const learnCompletedModules = learnModules?.filter(
    (m) => m?.user_status?.last_module_attempt?.user_status?.scoring === 1,
  )

  const practiceModules = allModules?.filter((m) => m?.module_type === 'practice')
  const practiceCompletedModules = practiceModules?.filter(
    (m) => m?.user_status?.last_module_attempt?.user_status?.scoring === 1,
  )

  const handleInitialLoad = async () => {
    const _SKILLS = currentSkillTrack?.skills?.map((s) => s.id.split('-')?.[0])
    if (!_SKILLS || currentSkillTrack?.isModulesLoaded) return

    dispatch(getSkills({ ids: _SKILLS?.toString() }))
  }

  const openAssessment = () => {
    const elem = document.getElementsByClassName('is-assessment')
    elem?.[0].childNodes?.[0]?.click()
  }

  useEffect(() => {
    if (!currentSkillTrack?.skills?.[0]?.modules) return

    selectSkill(
      currentSkillTrack?.skills
        ?.filter((s) => s?.status !== 'coming_soon')
        ?.find((s) => s?.user_status?.progress !== 1) ||
        currentSkillTrack?.skills?.[0] ||
        {},
    )
  }, [currentSkillTrack?.skills]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleInitialLoad()
  }, [currentSkillTrack]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FadeIn>
      <Container>
        <div className="content">
          <p className="pre-title">Acquire all the skills to obtain the completion badge</p>

          <div className="skill-track-content">
            <div className="skills-container">
              {!currentSkillTrack || isSkillTrackLoading || isLoadingSkillsContent ? (
                <SkillsTableLoading />
              ) : currentSkillTrack?.skills?.length ? (
                currentSkillTrack?.skills?.map((s) => {
                  const isComingSoon = s?.status === 'coming_soon'

                  return (
                    <div
                      className={`skill-row ${selectedSkill?.id === s.id ? 'selected' : ''} ${
                        isComingSoon && 'coming-soon'
                      }`}
                      key={s.id}
                      onClick={selectedSkill && !isComingSoon ? () => selectSkill(s) : null}
                    >
                      <SkillBullet skill={s} size={'lg'} />
                      <p className="skill-name">{s.name}</p>

                      <div className="select-btn">
                        <RightOutlined />
                      </div>
                    </div>
                  )
                })
              ) : (
                <p className="error-text">No skills available</p>
              )}
            </div>

            <div className="skill-content">
              {selectedSkill && (
                <h3 className="skill-header">
                  <div className="mobile-menu">
                    <Dropdown
                      menu={{
                        items: currentSkillTrack?.skills
                          ?.filter((s) => s?.status !== 'coming_soon')
                          ?.map((s) => ({
                            label: <span onClick={selectedSkill ? () => selectSkill(s) : null}>{s.name}</span>,
                            key: s.id,
                          })),
                      }}
                      trigger={['click']}
                      placement={'bottom'}
                    >
                      <span>
                        <DownOutlined /> {selectedSkill?.name}
                      </span>
                    </Dropdown>
                  </div>

                  <span className="web-menu">{selectedSkill?.name}</span>

                  <div className="right-section">
                    <div className="module-info-container">
                      {!!learnModules?.length && (
                        <Tooltip title="Completed Learn projects">
                          <div className="module-info">
                            <Button className="module-type" type="text" shape="circle" icon={<ReadOutlined />} />
                            {learnCompletedModules?.length}
                          </div>
                        </Tooltip>
                      )}

                      {!!practiceModules?.length && (
                        <Tooltip title="Completed Practice projects">
                          <div className="module-info">
                            <Button
                              className="module-type"
                              type="text"
                              shape="circle"
                              icon={<img src={Weight} alt="Practice" />}
                            />
                            {practiceCompletedModules?.length}
                          </div>
                        </Tooltip>
                      )}
                    </div>

                    {!!skillAssessments?.length && (
                      <Button className="skill-assessment-btn" type="primary" onClick={openAssessment}>
                        Take assessment
                      </Button>
                    )}
                  </div>
                </h3>
              )}

              <div className="skill-modules-container">
                {!selectedSkill && <ModuleBoxesLoading />}

                {!!skillLearnModules?.length && (
                  <div className="skill-section">
                    <p className="title">
                      <span className="highlight">Learn projects</span> Learn the fundamentals of this skill
                    </p>

                    <div className="modules-container">
                      {skillLearnModules?.map((m) => (
                        <div key={m.id} className="module-container">
                          <FadeIn>
                            <ModuleBox module={m} />
                          </FadeIn>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {!!skillPracticeModules?.length && (
                  <div className="skill-section">
                    <p className="title">
                      <span className="highlight">Practice projects</span> Practice and master this Skill
                    </p>

                    <div className="modules-container">
                      {skillPracticeModules?.map((m) => (
                        <div key={m.id} className="module-container">
                          <FadeIn>
                            <ModuleBox module={m} />
                          </FadeIn>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {!!skillAssessments?.length && (
                  <div className="skill-section">
                    <p className="title">
                      <span className="highlight">Assessment</span> Take the assessment and earn this Skill
                    </p>

                    <div className="modules-container">
                      {skillAssessments?.map((m) => (
                        <div key={m.id} className="module-container is-assessment">
                          <ModuleBox module={m} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {selectedSkill && !hasModules && (
                <div className="modules-error">
                  <p>No projects available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </FadeIn>
  )
}

export default SkillTrackContent
