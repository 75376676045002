import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    width: 390px !important;
  }

  .rate-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;

    > .title {
      max-width: 230px;
      text-align: center;
      margin-top: 20px;
    }

    .ant-rate-star {
      margin-inline-end: 8px;

      svg {
        font-size: 20px;
      }
    }

    .text {
      color: ${(props) => props.theme.colors.gray7};
    }

    .comments {
      width: 100%;
      position: relative;
      margin-top: 12px;
    }

    .sent-image {
      max-width: 185px;
      margin-bottom: -20px;
    }

    .actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;

      .link {
        color: ${(props) => props.theme.colors.gray10};
        font-size: 11px;
        font-weight: 400;
        margin: 0px;
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
          color: ${(props) => props.theme.colors.gray7};
        }
      }
    }
  }
`
