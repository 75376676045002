import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  max-width: 1020px;
  width: 100% !important;
  display: flex;
  align-self: center;
  padding: 20px;

  .ant-modal-content {
    min-height: 740px;
    padding: 0px 20px;

    .ant-modal-body {
      display: flex;
      justify-content: center;

      > .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;
        padding: 40px;

        .version-info {
          width: 100%;

          .version-item {
            display: flex;
            align-items: center;
            text-align: left;
            gap: 16px;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
            padding: 10px 0px;

            .version-code {
              min-width: 40px;
              min-height: 40px;
              background-color: ${(props) => props.theme.colors.volcano2};
              color: ${(props) => props.theme.colors.volcano6};
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 400;
              text-transform: uppercase;
              border-radius: 50%;
              margin: 0px;
            }

            .playground-name {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              margin: 0px;
            }

            .date {
              color: ${(props) => props.theme.colors.gray7};
            }
          }

          &.is-latest {
            .version-code {
              background-color: ${(props) => props.theme.colors.green2};
              color: ${(props) => props.theme.colors.green6};
            }
          }
        }

        .loading-container {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 20px;
        }

        .playground-version-preview {
          width: 100%;
        }
      }
    }
  }
`
