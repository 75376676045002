import styled from 'styled-components'

export const Container = styled.div`
  max-width: 700px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  display: flex;
  gap: 24px;
  overflow: auto;
  padding: 22px 24px;

  > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .tab-content {
      .related-data-sources {
        margin-bottom: 24px;
      }
    }
    .no-data-source-content {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 40px 0px 80px;

      > .content {
        max-width: 360px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0px 20px;

        .image {
          margin-bottom: 10px;
        }

        .title {
          font-weight: 700;
        }

        .description {
          color: ${(props) => props.theme.colors.gray7};
          margin-bottom: 4px;
        }
      }
    }
  }
`
