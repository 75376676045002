import { VOUCHER_UPDATED, SET_VOUCHER_ERROR, RESET_VOUCHERS_STATE } from '@/store/vouchers'
import { showToast } from '@/utils/toast'
import { USER_PROFILE_UPDATED } from '@/store/users'
import { fetchVoucher, postVoucherValidate, postVoucherSignup, postVoucherRedeem } from '@/services/vouchers'

const getVoucher = (voucherCode) => async (dispatch) => {
  try {
    const voucher = await fetchVoucher(voucherCode)
    dispatch(VOUCHER_UPDATED(voucher))
  } catch (error) {
    const { message } = error
    dispatch(SET_VOUCHER_ERROR(message))
  }
}

const validateVoucher = (data) => async (dispatch) => {
  try {
    const voucher = await postVoucherValidate(data)
    dispatch(VOUCHER_UPDATED(voucher))
  } catch (error) {
    const { message } = error
    dispatch(SET_VOUCHER_ERROR(message))
  }
}

const signupWithVoucher = (data) => async (dispatch) => {
  try {
    const userProfile = await postVoucherSignup(data)
    dispatch(USER_PROFILE_UPDATED({ ...userProfile, justCreated: true }))
  } catch (error) {
    const { message } = error
    dispatch(SET_VOUCHER_ERROR(message))
  }
}

const redeemVoucher = (data, cb) => async (dispatch, getState) => {
  try {
    const { users, vouchers } = getState()
    const { userProfile } = users
    const { currentVoucher } = vouchers

    await postVoucherRedeem(data)

    const auxVoucher = {
      ...currentVoucher,
      user: userProfile.id,
    }

    dispatch(VOUCHER_UPDATED(auxVoucher))

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    showToast(message, 'error')
    dispatch(SET_VOUCHER_ERROR(message))
  }
}

const resetVouchersState = () => (dispatch) => {
  dispatch(RESET_VOUCHERS_STATE())
}

export { getVoucher, validateVoucher, signupWithVoucher, redeemVoucher, resetVouchersState }
