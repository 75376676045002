import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Modal, Tooltip, Button, Dropdown } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  DataPie24Regular,
  MoreVertical24Filled,
  Archive24Regular,
  Options24Regular,
  ArchiveArrowBack24Regular,
  ShareAndroid24Regular,
} from '@fluentui/react-icons'
import getIconByUrl from '@/utils/getIconByUrl'
import PlaygroundStatusTag from '@/pages/PlaygroundPage/components/PlaygroundStatusTag'
import PlaygroundForkInfo from '@/components/PlaygroundForkInfo'
import PlaygroundVote from '@/components/PlaygroundVote'
import { toggleWizardModal, updateWizard, updatePlayground, setSharingModalOpen } from '@/store/playgrounds/actions'
import { Container } from './styles'

const PlaygroundItem = ({ playground }) => {
  const [mainIcon, setMainIcon] = useState(null)
  const [languageIcon, setLanguageIcon] = useState(null)
  const [interfaceIcon, setInterfaceIcon] = useState(null)

  const dispatch = useDispatch()

  const { isLoading } = useSelector((state) => state.playgrounds)

  const isCustomProject = !!playground?.metadata?.account_id
  const lastVersion = playground?.versions?.[playground?.versions?.length - 1]

  const archivePlayground = () => {
    Modal.confirm({
      title: 'Archive playground',
      content: 'Are you sure you want to archive this playground?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, archive',
      cancelText: 'No',
      onOk: () => dispatch(updatePlayground(playground?.id, { status: 'archived' })),
      okButtonProps: {
        danger: true,
        type: 'primary',
        disabled: isLoading,
        loading: isLoading,
      },
    })
  }

  const unarchivePlayground = () => {
    Modal.confirm({
      title: 'Restore playground',
      content: 'Are you sure you want to restore this playground as draft?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, restore',
      cancelText: 'No',
      onOk: () => dispatch(updatePlayground(playground?.id, { status: 'draft' })),
      okButtonProps: {
        danger: true,
        type: 'primary',
        disabled: isLoading,
        loading: isLoading,
      },
    })
  }

  const getStatusDate = () => {
    if (playground?.status === 'draft') return `Created ${dayjs(playground?.created).from()}`

    if (playground?.status === 'published') return `Published ${dayjs(lastVersion?.created).from()}`

    if (playground?.status === 'archived') return `Archived ${dayjs(playground?.status_changed).from()}`
  }

  const playgroundMenuItems = [
    ...(playground?.status !== 'archived'
      ? [
          {
            key: 'edit',
            label: (
              <a
                onClick={() => {
                  dispatch(
                    updateWizard({
                      isEdit: true,
                      playgroundId: playground?.id,
                      name: playground?.name,
                      description: playground?.description_md,
                      imageUrl: playground?.image_url,
                      jumpbox: playground?.jumpbox,
                      preDataSource: playground?.data_sources?.[0] || {},
                      dataSource: playground?.data_sources?.[0] || {},
                      boost: playground?.config?.boost || false,
                      internetOn: playground?.config?.internet_on || false,
                    }),
                  )
                  dispatch(toggleWizardModal(true))
                }}
                className="menu-item"
              >
                <Options24Regular /> Settings
              </a>
            ),
          },
          ...(playground?.status === 'published'
            ? [
                {
                  key: 'share',
                  label: (
                    <a onClick={() => dispatch(setSharingModalOpen(playground))} className="menu-item">
                      <ShareAndroid24Regular /> Share
                    </a>
                  ),
                },
              ]
            : []),
          {
            key: 'archive',
            label: (
              <a onClick={archivePlayground} className="menu-item">
                <Archive24Regular /> Archive
              </a>
            ),
            danger: true,
          },
        ]
      : [
          {
            key: 'restore',
            label: (
              <a onClick={unarchivePlayground} className="menu-item">
                <ArchiveArrowBack24Regular /> Restore
              </a>
            ),
            danger: true,
          },
        ]),
  ]

  useEffect(() => {
    if (!playground) return

    getIconByUrl({
      iconUrl: playground?.data_sources?.[0]?.theme?.logo_url || 'ant-BuildOutlined',
      className: 'main-icon',
      onReady: setMainIcon,
    })

    getIconByUrl({
      iconUrl: playground?.jumpbox?.language?.logo_url || 'ant-FileTextOutlined',
      className: 'language-icon',
      onReady: setLanguageIcon,
    })

    getIconByUrl({
      iconUrl: playground?.jumpbox?.interface?.logo_url || 'ant-FileTextOutlined',
      className: 'interface-icon',
      onReady: setInterfaceIcon,
    })
  }, [playground])

  return (
    <Container className="playground-item-container" $playgroundBackgroundImage={playground?.image_url}>
      <Link to={`/playgrounds/${playground?.id}`}>
        <div className="main-content">
          <div className="image-container">{mainIcon}</div>

          <div className="content">
            <div className="header">
              <div className="title-container">
                <h4 className="title">{playground?.name}</h4>
              </div>

              <div className="actions" onClick={(evt) => evt.preventDefault()}>
                <Tooltip title={getStatusDate}>
                  <div>
                    <PlaygroundStatusTag
                      status={playground?.status}
                      visibility={playground?.visibility}
                      isCustomProject={isCustomProject}
                    />
                  </div>
                </Tooltip>

                <Dropdown
                  overlayClassName={'playground-menu'}
                  trigger={['click']}
                  menu={{
                    items: playgroundMenuItems,
                  }}
                >
                  <Button className="open-options-button" type="text">
                    <MoreVertical24Filled className="icon" />
                  </Button>
                </Dropdown>
              </div>
            </div>

            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: playground?.description_html,
              }}
            />
          </div>
        </div>
      </Link>

      <div className="bottom-info">
        <div className="left">
          {playground?.data_sources?.[0] && (
            <Tooltip title={playground?.data_sources?.[0]?.name}>
              <DataPie24Regular className="data-source-icon" />
            </Tooltip>
          )}

          <Tooltip title={playground?.jumpbox?.language?.value}>{languageIcon}</Tooltip>

          <Tooltip title={playground?.jumpbox?.interface?.value}>{interfaceIcon}</Tooltip>

          <PlaygroundForkInfo playground={playground} />
        </div>

        <div className="right">
          <PlaygroundVote playground={playground} isReadOnly />
        </div>
      </div>
    </Container>
  )
}

export default PlaygroundItem
