import styled, { css } from 'styled-components'

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;

  .device-item {
    width: 42px;
    height: 42px;
    background-color: ${(props) => props.theme.colors.geekblue1};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    &:not(:first-child) {
      margin-left: -10px;
    }

    .icon {
      width: 20px;

      ${localStorage.getItem('dw-theme') === 'dark' &&
      css`
        filter: brightness(300%);
      `}
    }
  }
`
