import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { LikeOutlined, LikeFilled } from '@ant-design/icons'
import { createPlaygroundVote, removePlaygroundVote } from '@/store/playgrounds/actions'
import { Container } from './styles'

const PlaygroundVote = ({ playground, shape, isReadOnly }) => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { isPlaygroundVoteLoading: isLoading } = useSelector((state) => state.playgrounds)

  const canVote = playground?.user?.username !== userProfile?.username
  const isAlreadyVoted = playground?.user_status?.vote === 'upvote'

  const handleVote = () => {
    if (!canVote || isLoading) return

    if (isAlreadyVoted) {
      dispatch(removePlaygroundVote(playground?.id))
      return
    }

    dispatch(createPlaygroundVote(playground?.id, { action: 'upvote' }))
  }

  return (
    <Container
      className="playground-vote"
      $shape={shape}
      $isReadOnly={isReadOnly || !canVote}
      $isAlreadyVoted={isAlreadyVoted}
      $isLoading={isLoading}
    >
      <p className="votes-info" onClick={handleVote}>
        {isLoading ? (
          <Spin size="small" />
        ) : (
          <>
            {isAlreadyVoted ? <LikeFilled className="icon" /> : <LikeOutlined className="icon" />}{' '}
            {playground?.votes?.num_votes || 0}
          </>
        )}
      </p>
    </Container>
  )
}

export default PlaygroundVote
