import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tooltip, Skeleton } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import { getGptTasks, createGptRun } from '@/store/gpt/actions'

const GptRunButtons = ({ activity }) => {
  const dispatch = useDispatch()

  const [isCreating, setIsCreating] = useState(false)

  const { gptTasks } = useSelector((state) => state.gpt)
  const { userProfile } = useSelector((state) => state.users)

  const canRunGptTasks = userProfile?.permissions?.includes('content.run_gpt_tasks')

  const handleGptTasksLoad = async () => {
    await dispatch(getGptTasks())
  }

  const handleCreateGptRun = async (gptTaskId) => {
    setIsCreating(true)
    await dispatch(
      createGptRun({
        activityIds: [activity.id],
        taskIds: gptTaskId ? [gptTaskId] : null,
      }),
    )
    setIsCreating(false)
  }

  useEffect(() => {
    handleGptTasksLoad()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="tasks-body">
      <div className="tasks-container">
        {gptTasks ? (
          gptTasks?.map((gptTask) => (
            <div key={gptTask.id} className="task-container">
              <p className="task-name">
                {gptTask.name}{' '}
                <Tooltip title={gptTask.description}>
                  <InfoCircleOutlined />
                </Tooltip>
              </p>

              {canRunGptTasks && (
                <Button
                  type="secondary"
                  onClick={() => handleCreateGptRun(gptTask.id)}
                  size="small"
                  loading={isCreating}
                  disabled={isCreating}
                >
                  Run GPT task
                </Button>
              )}
            </div>
          ))
        ) : (
          <Skeleton active title={false} paragraph={{ rows: 3 }} />
        )}
      </div>

      {canRunGptTasks && (
        <Button type="secondary" onClick={() => handleCreateGptRun()} loading={isCreating} disabled={isCreating}>
          Run all GPT tasks
        </Button>
      )}
    </div>
  )
}

export default GptRunButtons
