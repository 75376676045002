import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 30px 20px;

  .title {
    color: ${(props) => props.theme.colors.gray10};
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin: 0px 0px 10px;
  }

  .module-content {
  }
`
