import { useSelector, useDispatch } from 'react-redux'
import { Skeleton } from 'antd'
import JumpboxCard from '../JumpboxCard'
import { toggleWizardModal, updateWizard } from '@/store/playgrounds/actions'
import { Container } from './styles'

const JumpboxesSection = () => {
  const dispatch = useDispatch()

  const { items: jumpboxes, isLoading } = useSelector((state) => state.jumpboxes)

  return (
    <Container className="jumpboxes-section">
      <h4 className="title">+ Start new Playground</h4>

      {(isLoading || jumpboxes === null) && (
        <div className="loading-container">
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        </div>
      )}

      {!isLoading && jumpboxes && !jumpboxes?.length && <p>There are no environments yet.</p>}

      <div className="jumpboxes-container">
        {jumpboxes?.map((j) => (
          <JumpboxCard
            key={j?.id}
            jumpbox={j}
            onClick={(data) => {
              dispatch(updateWizard(data))
              dispatch(toggleWizardModal(true))
            }}
          />
        ))}
      </div>
    </Container>
  )
}

export default JumpboxesSection
