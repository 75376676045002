import { useSelector } from 'react-redux'
import { Tag } from 'antd'
import {
  Drafts24Regular,
  Archive24Regular,
  Earth24Filled,
  Link24Regular,
  CheckmarkCircle24Filled,
} from '@fluentui/react-icons'
import { Container } from './styles'

const PlaygroundStatusTag = ({ status, visibility, isCustomProject }) => {
  const { currentPlayground: playground } = useSelector((state) => state.playgrounds)

  const isMemberCopy = playground?.metadata?.is_student_copy

  const renderContent = () => {
    if (status === 'draft') {
      return (
        <Tag className="tag tag-gold">
          <Drafts24Regular />
          {isMemberCopy ? 'not submitted' : 'draft'}
        </Tag>
      )
    }

    if (status === 'archived') {
      return (
        <Tag className="tag tag-red">
          <Archive24Regular />
          archived
        </Tag>
      )
    }

    if (status === 'published') {
      return (
        <Tag className="tag tag-blue">
          {isCustomProject ? (
            <CheckmarkCircle24Filled />
          ) : visibility === 'unlisted' ? (
            <Link24Regular />
          ) : (
            <Earth24Filled />
          )}

          {isMemberCopy ? 'submitted' : 'published'}
        </Tag>
      )
    }
  }

  return <Container className="status-tag">{renderContent()}</Container>
}

export default PlaygroundStatusTag
