import { SET_JUMPBOXES_LOADING, JUMPBOXES_UPDATED, RESET_JUMPBOXES_STATE, SET_JUMPBOXES_ERROR } from '@/store/jumpboxes'
import { fetchJumpboxes } from '@/services/jumpboxes'

const getJumpboxes = (params) => async (dispatch) => {
  try {
    dispatch(SET_JUMPBOXES_LOADING(true))

    const jumpboxes = await fetchJumpboxes(params)
    dispatch(JUMPBOXES_UPDATED(jumpboxes))
  } catch (error) {
    const { message } = error
    dispatch(SET_JUMPBOXES_ERROR(message))
  }
}

const resetJumpboxesState = () => (dispatch) => {
  dispatch(RESET_JUMPBOXES_STATE())
}

export { getJumpboxes, resetJumpboxesState }
