import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  > .header {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
    padding: 16px 24px;

    .title {
      font-weight: 500;
      margin: 0px;
    }
  }

  > .content {
    height: calc(100% - 57px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow: auto;
    padding: 24px;

    .event-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6px;
      animation: 1s ${fadeInAnimation};

      .event-summary {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 6px;
        padding: 3px 0px;

        .member-avatar {
          .profile-picture {
            margin-right: 6px;
          }

          .name-container {
            .name {
              font-weight: 500;
            }
          }
        }

        .text {
          color: ${(props) => props.theme.colors.gray7};
          font-weight: 500;
        }
      }

      .comment-content-container {
        background-color: ${(props) => props.theme.colors.gray2};
        position: relative;
        margin-bottom: 12px;
        border-radius: 2px;

        .comment-content {
          padding: 12px;
        }
      }
    }
  }
`
