import styled from 'styled-components'

export const Container = styled.div`
  background: linear-gradient(
    84deg,
    ${(props) => props.theme.colors.base.gray10} 24.3%,
    #5479f7 67.59%,
    #89d2c2 99.33%
  );
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: 5px;
  padding: 0px 6px;

  > span {
    color: ${(props) => props.theme.colors.base.gray1};
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }

  .pro-badge {
    width: 15px;
  }
`
