import styled from 'styled-components'

export const Container = styled.span`
  .embed-btn {
    margin: 0px 0px 10px;
  }

  .embed-property {
    margin: 0px;

    .copy-icon {
      opacity: 0.5;
      cursor: pointer;
      margin-left: 2px;
    }
  }
`
