import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import axios from 'axios'
import { Spin } from 'antd'
import HTMLBlock from '@/components/HTMLBlock'
import { selectPlaygroundVersion } from '@/store/playgrounds/actions'
import { Modal } from './styles'

const PlaygroundVersionPreviewModal = () => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [previewHtml, setPreviewHtml] = useState(false)

  const { currentPlayground: playground, playgroundVersion } = useSelector((state) => state.playgrounds)
  const isCustomProject = !!playground?.metadata?.account_id
  const isMemberCopy = playground?.metadata?.is_student_copy
  const isLatestVersion = playground?.versions?.[playground?.versions?.length - 1]?.id === playgroundVersion?.id

  const handleLoad = async () => {
    if (!playgroundVersion?.preview_html) return

    setIsLoading(true)
    const previewHtml = await axios({
      method: 'GET',
      url: playgroundVersion?.preview_html,
    })
    setPreviewHtml(previewHtml?.data)
    setIsLoading(false)
  }

  const handleCancel = () => {
    dispatch(selectPlaygroundVersion(null))
  }

  useEffect(() => {
    if (!playgroundVersion) return

    handleLoad()
  }, [playgroundVersion])

  return (
    <Modal
      open={!!playgroundVersion}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={null}
      className="playground-version-preview-modal"
    >
      <div className="content">
        <div className={`version-info ${isLatestVersion ? 'is-latest' : ''}`}>
          <div className="version-item">
            <h5 className="version-code">
              {isMemberCopy ? playgroundVersion?.name?.replace('v', 's') : playgroundVersion?.name}
            </h5>

            <div>
              <h5 className="playground-name">{playground?.name}</h5>
              <p className="date">
                {isMemberCopy ? 'Submitted' : 'Published'} on {dayjs(playgroundVersion?.created).format('LLL')}
              </p>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="loading-container">
            <Spin size="large" />
            <h6 className="title">Loading {isCustomProject ? 'project' : 'playground'} preview...</h6>
          </div>
        ) : (
          <HTMLBlock className="playground-version-preview" content={previewHtml} />
        )}
      </div>
    </Modal>
  )
}

export default PlaygroundVersionPreviewModal
