import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    padding: 45px;

    .ant-modal-body {
      display: flex;
      justify-content: center;

      .content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;

        .timer-container {
        }

        .header {
          .text {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 4px;
          }

          .title {
            margin-bottom: 8px;
          }
        }

        .pro-info {
          background-color: ${(props) => props.theme.colors.gray4};
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 6px;
          border-radius: 2px;
          padding: 16px 18px;

          .pro-simple-text {
            color: ${(props) => props.theme.colors.gray7};
            margin: 0px 0px 10px;
          }

          > button {
            height: 42px;
            color: ${(props) => props.theme.colors.base.gray1};
            display: flex;
            gap: 8px;
            align-items: center;

            .pro-badge {
              width: 24px;
            }
          }
        }

        .cta-button {
        }
      }
    }
  }
`
