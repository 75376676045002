import styled from 'styled-components'

export const Container = styled.div`
  color: ${(props) => props.theme.colors.gray8};
  font-size: 12px;
  line-height: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray4};
  border-radius: 8px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 4px;
  white-space: nowrap;
  padding: 3px 6px;

  .link {
    color: ${(props) => props.theme.colors.geekblue6};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
