import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray4};
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 10px;
  box-shadow: 0px -6px 19px 1px rgba(42, 55, 101, 0.22);

  .progress {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${(props) => props.theme.colors.gray13};
      font-size: 13px;
      font-weight: 400;
      margin: 0px;
      z-index: 1;
    }

    .progress-tag {
      width: 56px;
      background: ${(props) => (props.$isFinished ? props.theme.colors.cyan2 : props.theme.colors.geekblue2)};
      color: ${(props) => (props.$isFinished ? props.theme.colors.cyan7 : props.theme.colors.geekblue6)};
      border-radius: 3px;
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      padding: 2px 6px;
      margin-left: 4px;
    }

    .percentage {
      height: inherit;
      background-color: ${(props) => (props.$isFinished ? props.theme.colors.cyan4 : props.theme.colors.geekblue5)};
      position: absolute;
      left: 0;
    }
  }
`
