import { Skeleton } from 'antd'
import { Container } from './styles'

const SkillsTableLoading = () => {
  return (
    <Container>
      <div className="skill-block">
        <Skeleton active title={false} paragraph={{ rows: 4 }} />
      </div>
    </Container>
  )
}

export default SkillsTableLoading
