import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 18px;

  .sign-in-btn {
    background-color: ${(props) => props.theme.colors.gray1} !important;
    color: ${(props) => props.theme.colors.gray7};
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 20px;
      margin-right: 10px;
    }
  }

  .oauth-divider {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    padding: 18px 0;

    &:before {
      content: '';
      border-bottom: 1px solid ${(props) => props.theme.colors.gray6};
      flex: 1 0 auto;
      height: 0.6em;
      margin: 0;
    }

    &:after {
      content: '';
      border-bottom: 1px solid ${(props) => props.theme.colors.gray6};
      flex: 1 0 auto;
      height: 0.6em;
      margin: 0;
    }

    > span {
      color: ${(props) => props.theme.colors.gray7};
      font-size: 12px;
      font-weight: 500;
      flex: 0.2 0 auto;
    }
  }
`
