import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SafetyCertificateTwoTone } from '@ant-design/icons'
import Button from '@/components/Button'
import { validateEmail } from '@/store/users/actions'

const SendCode = () => {
  const dispatch = useDispatch()
  const { userProfile } = useSelector((state) => state.users)
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = () => {
    setIsLoading(true)
    dispatch(validateEmail())
  }

  return (
    <div className="content">
      <SafetyCertificateTwoTone />

      <h2 className="title">Please verify your account.</h2>

      <p>Send a verification email to {userProfile?.email}</p>

      <Button type="primary" size="large" onClick={handleClick} loading={isLoading} disabled={isLoading}>
        Send Verification Code
      </Button>
    </div>
  )
}

export default SendCode
