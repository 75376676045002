import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const PlaygroundsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow: auto;

  @media ${device.lgDown} {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const MenuContainer = styled.div`
  min-width: 210px;
  max-width: 210px;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.geekblue1 : props.theme.colors.gray1};
  padding-top: 4px;
  box-shadow: 0px 2px 8px 0px
    ${(props) => (localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray7 : props.theme.colors.gray13)}26;
  z-index: 1;

  .ant-menu {
    border-inline-end: none !important;

    .ant-menu-item {
      padding: 0px 0px 0px 16px !important;
      margin: 0px;

      &.new-playground-menu-item {
        display: none;
      }

      svg {
        width: 14px;
        height: 14px;

        path {
          fill: ${(props) => props.theme.colors.text};
        }
      }

      &.ant-menu-item-selected {
        svg {
          path {
            fill: ${(props) =>
              localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray13 : props.theme.colors.blue6};
          }
        }
      }

      &.ant-menu-item-disabled {
        svg {
          path {
            fill: ${(props) => props.theme.colors.disabledText};
          }
        }
      }

      .has-tag {
        display: flex;
        align-items: center;
        gap: 10px;

        .coming-soon-tag {
          background-color: ${(props) => props.theme.colors.geekblue3};
          color: ${(props) => props.theme.colors.geekblue6};
          font-size: 8px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          text-transform: uppercase;
          border-radius: 5px;
          padding: 2px 6px;
          margin: 0px;
        }
      }
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-item {
        padding: 0px;
      }
    }
  }

  @media ${device.lgDown} {
    height: 64px;
    max-width: initial;
    padding: 0px;

    > .ant-menu {
      height: 64px;
      display: flex;

      .ant-menu-item {
        height: auto !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px !important;

        .ant-menu-item-icon {
          height: 28px !important;
          width: 28px !important;
        }

        &.new-playground-menu-item {
          display: flex;
        }
      }
    }
  }
`
