import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    overflow: hidden;
    padding: 0px !important;

    .ant-modal-close {
      width: 40px;
      height: 40px;
      background-color: ${(props) => props.theme.colors.gray4};
      border-radius: 50%;
      top: 18px;
      right: 18px;

      .ant-modal-close-x svg {
        color: ${(props) => props.theme.colors.gray11};
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.gray5};
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      > .header-image {
        width: 100%;
        height: auto;
      }

      > .content {
        padding: 24px 32px 14px;

        > .header-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
          padding: 0px 0px 20px;

          .info-text {
            max-width: 300px;
            color: ${(props) => props.theme.colors.gray8};
            text-align: center;
            margin: 0px;
          }

          .pre-title {
            color: ${(props) => props.theme.colors.gray10};
            text-align: center;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
            padding-bottom: 14px;
            margin: 0px;
          }
        }

        .version-info {
          .version-item {
            display: flex;
            align-items: center;
            gap: 16px;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
            padding: 10px 0px;

            .version-code {
              min-width: 40px;
              min-height: 40px;
              background-color: ${(props) => props.theme.colors.green2};
              color: ${(props) => props.theme.colors.green6};
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 400;
              text-transform: uppercase;
              border-radius: 50%;
              margin: 0px;
            }

            .playground-name {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              margin: 0px;
            }
          }
        }

        .title {
          background-color: ${(props) => props.theme.colors.gray1};
          color: ${(props) => props.theme.colors.gray11};
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          text-align: center;
          margin: -10px 0px 0px;
        }

        .sharing-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          padding: 20px 0px 10px;

          .link-input {
            height: 40px;

            .ant-input {
              font-size: 14px;
              line-height: 24px;
            }

            .ant-input-group-addon {
              .ant-input-search-button {
                background-color: ${(props) => props.theme.colors.geekblue5};
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }

        .actions {
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 14px 0px;
        }
      }
    }
  }
`
