import { useDispatch } from 'react-redux'
import { Tooltip } from 'antd'
import Button from '@/components/Button'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { Content } from './styles'

const ProTooltip = ({ title, content, isLarge, showUpgradeButton, upgradeButtonText = 'Upgrade Now', children }) => {
  const dispatch = useDispatch()

  const openUpgradeToProModal = () => {
    dispatch(toggleUpgradeToProModal(true))
  }

  return (
    <Tooltip
      overlayClassName={isLarge && 'large'}
      title={
        title && (
          <Content>
            <p className="title">
              <img className="icon" src={ProBadge} alt="Upgrade to PRO" />
              {title}
            </p>

            <div className="content">{content}</div>

            {showUpgradeButton && (
              <Button className="upgrade-btn" type="primary" ispro="true" onClick={openUpgradeToProModal}>
                {upgradeButtonText}
              </Button>
            )}
          </Content>
        )
      }
    >
      {children}
    </Tooltip>
  )
}

export default ProTooltip
