import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isDataWarsHostName } from '@/helpers/env'

const PrivateRoute = ({ children, ...props }) => {
  const { currentAccount } = useSelector((state) => state.accounts)
  const { userProfile, isAuthenticated } = useSelector((state) => state.users)

  const loginFormIsEnabled = currentAccount?.config?.login?.login_form

  if (isAuthenticated === false) {
    const originalPath = window?.location?.href
    return (
      <Navigate
        to={
          loginFormIsEnabled || isDataWarsHostName
            ? userProfile
              ? '/login'
              : `/login?redirect_to=${originalPath}`
            : '/not-found'
        }
        replace
      />
    )
  }

  return children || <Outlet />
}

export default PrivateRoute
