import { useSelector, useDispatch } from 'react-redux'
import { Skeleton } from 'antd'
import Button from '@/components/Button'
import FadeIn from '@/components/FadeIn'
import HTMLBlock from '@/components/HTMLBlock'
import ModuleTimer from '../ModuleTimer'
import LaunchCard from '../LaunchCard'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { Container } from './styles'

const ModuleLabContent = ({ disableOverflow }) => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { currentModule, modulePreview, modulePreviewError } = useSelector((state) => state.modules)
  const { currentLab } = useSelector((state) => state.labs)

  const isPro = userProfile?.is_pro || userProfile?.groups?.includes('Pro')
  const canResumeLab = userProfile?.permissions?.includes('labs.resume_lab')

  const hasLab = !!currentModule?.lab_id
  const moduleAttempt = currentModule?.user_status?.last_module_attempt
  const isAttemptFinished = moduleAttempt?.status === 'finished'
  const isAttemptActive = moduleAttempt?.is_active

  const labSession = currentLab?.allocated_session
  const isDeviceStopping = labSession?.devices?.some((d) => d.status === 'stopping' && d?.device?.generate_preview)

  const previewHtmlUrl = moduleAttempt?.preview_html?.[0]?.preview_html

  const renderContent = (returnBannerValue) => {
    let isBanner = false

    if (!hasLab) {
      isBanner = false

      if (returnBannerValue) return isBanner

      if (!isAttemptActive) {
        return <LaunchCard isBanner />
      }

      if (!isPro && currentModule?.expiration_time && !isAttemptFinished && isAttemptActive) {
        return (
          <div className="timer-floating-container">
            <ModuleTimer />
          </div>
        )
      }

      return null
    }

    if (isDeviceStopping || (previewHtmlUrl && !modulePreview && !modulePreviewError)) {
      isBanner = true
      return returnBannerValue ? (
        isBanner
      ) : (
        <>
          <div className="module-preview-container">
            <div className="module-preview loading-content">
              <h4 className="title">Generating preview...</h4>
              <p className="description">This could take a few seconds...</p>

              <Skeleton active title={false} paragraph={{ rows: 4 }} />
              <Skeleton active title={false} paragraph={{ rows: 6 }} />
              <Skeleton active title={false} paragraph={{ rows: 2 }} />
              <Skeleton active title={false} paragraph={{ rows: 7 }} />
            </div>
          </div>

          <LaunchCard isBanner />
        </>
      )
    }

    if (previewHtmlUrl && modulePreview) {
      isBanner = true
      return returnBannerValue ? (
        isBanner
      ) : (
        <>
          <div className="module-preview-container">
            <HTMLBlock className="module-preview" content={modulePreview} />

            {/* {!canResumeLab && !isAttemptFinished && (
              <>
                <div className="pattern" />

                <div className="info-box">
                  <div className="content">
                    <h5 className="title">Resume project unavailable</h5>
                    <p className="text">Your previous work is saved, but only PRO users can resume projects.</p>

                    <Button type="primary" ispro="true" onClick={() => dispatch(toggleUpgradeToProModal(true))}>
                      Upgrade Now
                    </Button>
                  </div>
                </div>
              </>
            )} */}
          </div>

          <LaunchCard isBanner />
        </>
      )
    }

    return returnBannerValue ? isBanner : <LaunchCard isBanner={isBanner} />
  }

  return (
    <Container
      className="module-lab-content"
      $isBanner={renderContent(true)}
      $canResumeLab={canResumeLab}
      $isAttemptFinished={isAttemptFinished}
      $disableOverflow={disableOverflow}
    >
      <FadeIn>{renderContent()}</FadeIn>
    </Container>
  )
}

export default ModuleLabContent
