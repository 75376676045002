import styled from 'styled-components'
import { Drawer as AntDrawer } from 'antd'

export const OpenButton = styled.div`
  position: absolute;
  top: 180px;
  right: 0px;
  z-index: 2;
`

export const Drawer = styled(AntDrawer)`
  &.ant-drawer-content {
    background-color: ${(props) => props.theme.colors.gray1};

    .ant-drawer-header {
      .ant-drawer-header-title {
        flex-direction: row-reverse;

        .ant-drawer-title {
          color: ${(props) => props.theme.colors.gray13};
        }

        .ant-drawer-close {
          color: ${(props) => props.theme.colors.gray13};
        }
      }
    }

    .ant-tabs {
      height: 100%;
      margin-top: -20px;

      .ant-tabs-content {
        height: 100%;
        overflow: auto;
      }
    }
  }
`
