import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useQueryParam from '@/hooks/useQueryParam'
import Layout1 from '@/layouts/Layout1'
import PublicProfileModal from '@/components/PublicProfileModal'
import DashboardTopNotification from './components/DashboardTopNotification'
import DashboardHeader from './components/DashboardHeader'
import ActiveUserMainBlock from './components/ActiveUserMainBlock'
import NewUserMainBlock from './components/NewUserMainBlock'
import ActivityInfo from './components/ActivityInfo'
import CustomProjectsBlock from './components/CustomProjectsBlock'
import MoreSkillTracks from './components/MoreSkillTracks'
import NewModules from './components/NewModules'
import FeaturedDataSourcesSection from '@/pages/PlaygroundsPage/components/FeaturedDataSourcesSection'
import CareerSkillTracksTable from './components/CareerSkillTracksTable'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { resetModulesState, getModules } from '@/store/modules/actions'
import { resetSkillTracksState } from '@/store/skillTracks/actions'
import { Container } from './styles'

const DashboardPage = () => {
  const dispatch = useDispatch()
  const [shouldOpenUpgradeToProModal] = useQueryParam('upgrade_to_pro')

  const [isInProgressModulesLoading, setInProgressModulesLoading] = useState(true)

  const { userProfile, stats } = useSelector((state) => state.users)
  const { inProgressModules } = useSelector((state) => state.modules)

  const isAccountMember = userProfile?.accounts?.some((a) => a?.role === 'member')
  const hasCompletedModules = stats?.completed?.labs

  const loadInProgressModules = async () => {
    await dispatch(getModules({ in_progress: true }))
    setInProgressModulesLoading(false)
  }

  useEffect(() => {
    if (shouldOpenUpgradeToProModal === 'true') {
      dispatch(toggleUpgradeToProModal(true))
    }
  }, [shouldOpenUpgradeToProModal]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(resetSkillTracksState())
    dispatch(resetModulesState())
    loadInProgressModules()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1 navbar>
      <Container>
        <div className="dashboard-container">
          <div className="content">
            <DashboardTopNotification />

            <DashboardHeader />

            <div className="main-row">
              <div className="primary-box">
                {!!inProgressModules?.length || hasCompletedModules || isInProgressModulesLoading ? (
                  <ActiveUserMainBlock isInProgressModulesLoading={isInProgressModulesLoading} />
                ) : (
                  <NewUserMainBlock />
                )}

                <a className="link" href="#career-skill-tracks">
                  Want to see more? Explore all tracks
                </a>
              </div>

              <div className="secondary-box">
                <ActivityInfo />
              </div>
            </div>
          </div>

          {isAccountMember && (
            <div className="content fluid bg-1">
              <CustomProjectsBlock />
            </div>
          )}

          <div className="content">
            <MoreSkillTracks />

            <NewModules />
          </div>

          <div className="content fluid bg-2">
            <FeaturedDataSourcesSection title="Continue Practicing with Playgrounds" subtitle="Featured datasets" />
          </div>
        </div>

        <div className="career-table-container">
          <CareerSkillTracksTable />
        </div>
      </Container>

      <PublicProfileModal />
    </Layout1>
  )
}

export default DashboardPage
