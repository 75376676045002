import styled from 'styled-components'

export const Container = styled.div`
  .clear-filters {
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    top: 0px;
    right: 0px;
  }
`
