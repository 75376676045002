import { algoliasearch } from 'algoliasearch'
import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const client = algoliasearch(
  import.meta.env.REACT_APP_ALGOLIA_APP_ID,
  import.meta.env.REACT_APP_ALGOLIA_SEARCH_ONLY_KEY,
)

const fetchSkillTracks = async (queryParams) => {
  // ?is_featured=True
  // ?in_progress=True
  // ?page_size=None
  const route = `skill-tracks`
  return API.get(route, { params: { ...queryParams, page_size: 'None' } })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchAlgoliaSkillTracks = async (filter) => {
  const baseFilter = "title.name:'Data Scientist with Python'"

  return client
    .searchSingleIndex({
      indexName: import.meta.env.REACT_APP_ALGOLIA_SKILLTRACKS_INDEX,
      searchParams: { query: '', filters: filter ? `${baseFilter} AND ${filter}` : baseFilter, hitsPerPage: 100 },
    })
    .then((response) => {
      const auxData = response?.hits?.map((item) => ({
        ...item,
        id: item?.objectID,
      }))

      return Promise.resolve(auxData)
    })
    .catch((error) => Promise.reject(apiErrorHandler(error)))
}

const fetchSkillsStatus = async (queryParams) => {
  // ?ids=short_id_1,short_id_2
  const route = `skill-tracks/status`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchSkillTrack = async (skillTrackId) => {
  const route = `skill-tracks/${skillTrackId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postSkillTrackUpvote = async (skillTrackId) => {
  const route = `skill-tracks/${skillTrackId}/upvote`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { fetchSkillTracks, fetchAlgoliaSkillTracks, fetchSkillsStatus, fetchSkillTrack, postSkillTrackUpvote }
