import styled from 'styled-components'
import jumpboxesBg from '@/assets/images/playgrounds/jumpboxes-bg.png'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: calc(100vh - 64px);
  width: 420px;
  background-color: ${(props) =>
    localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray2 : props.theme.colors.gray1};
  background-image: url(${jumpboxesBg});
  background-size: cover;
  background-position: center;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 24px;

  > .title {
    font-size: 20px;
    font-weight: 700;
    margin: 0px 0px 30px;
  }

  .jumpboxes-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .loading-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .ant-skeleton {
      margin: 0px;

      .ant-skeleton-paragraph {
        margin: 0px;

        li {
          height: 240px;
          width: 100% !important;
          border-radius: 16px;
        }
      }
    }
  }

  @media ${device.xlDown} {
    width: 240px;

    > .title {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      margin: 0px 0px 20px;
    }
  }
`
