import styled from 'styled-components'
import playgroundsBg from '@/assets/images/playgrounds/playgrounds-header-bg.png'
import playgroundsPattern from '@/assets/images/playgrounds/playgrounds-header-pattern.png'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  min-height: 180px;
  background-image: url(${playgroundsBg});
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  padding: 28px 40px 16px;
  margin-bottom: 36px;

  .swiper {
    .swiper-slide {
      max-width: 700px;
      width: 100%;
      height: auto;

      > div {
        height: 100%;
        cursor: grab;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: ${(props) => props.theme.colors.base.gray1};
      background: transparent;
      align-items: flex-end;

      &:after {
        font-size: 16px;
        font-weight: 900;
      }

      &:hover {
        background: transparent;
      }
    }

    .swiper-pagination {
      margin-top: 18px;

      .swiper-pagination-bullet {
        background: transparent !important;
        color: ${(props) => props.theme.colors.base.gray1};
        font-size: 14px;
        opacity: 0.8;
        margin: 0px 8px;

        &.swiper-pagination-bullet-active {
          font-weight: 600;
          text-decoration: underline;
          opacity: 1;
        }
      }
    }
  }

  > .title {
    color: ${(props) => props.theme.colors.base.gray1};
    margin-bottom: 20px;
  }

  .item {
    min-height: 260px;
    background-image: url(${playgroundsPattern});
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    padding: 20px 0px 20px 60px;

    .title-container {
      height: 100%;
      max-width: 292px;
      background: linear-gradient(90deg, ${(props) => props.theme.colors.base.gray1} 60%, transparent);
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;
      padding-right: 40px;
      margin-right: 40px;
      z-index: 1;

      .title {
        color: ${(props) => props.theme.colors.base.gray10};
        font-size: 30px;
        font-weight: 700;
        line-height: 34px;
        margin: 0px 0px 4px;
      }

      .description {
        color: ${(props) => props.theme.colors.base.gray7};
        margin: 0px;
      }
    }

    .image {
      position: absolute;
      right: 0px;

      &.image-0 {
        height: 80%;
      }

      &.image-1,
      &.image-2 {
        height: 100%;
      }
    }
  }

  @media ${device.xlDown} {
    gap: 20px;
    padding: 20px;

    .item {
      .title-container {
        margin-right: 0px;
      }
    }
  }

  @media ${device.lgDown} {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 30px 40px;
    margin: 0px 10px 36px 10px;

    .swiper {
      .swiper-slide {
        max-width: 300px;
      }
    }

    .item {
      min-height: 390px;
      flex-direction: column-reverse;
      justify-content: center;
      padding: 20px;

      .title-container {
        align-items: flex-start;
        padding: 0px;
        margin: 0px;
      }

      .image {
        width: 240px;
        position: relative;
      }
    }
  }
`
