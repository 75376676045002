import { useSelector, useDispatch } from 'react-redux'
import { Button, Tooltip } from 'antd'
import {
  ApiOutlined,
  RightOutlined,
  CopyOutlined,
  // PoweroffOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { showToast } from '@/utils/toast'
import DefaultDeviceIcon from '@/assets/images/device/default-icon.png'
import JupyterDeviceIcon from '@/assets/images/device/jupyter-icon.png'
import PostgresDeviceIcon from '@/assets/images/device/postgres-icon.png'
import MysqlDeviceIcon from '@/assets/images/device/mysql-icon.png'
import VscodeDeviceIcon from '@/assets/images/device/vscode-icon.png'
import PhpmyadminDeviceIcon from '@/assets/images/device/phpmyadmin-icon.png'
import {
  // startDeviceSession, stopDeviceSession,
  restartDeviceSession,
  selectDevice,
} from '@/store/labs/actions'
import { Dropdown, MenuItem } from './styles'

const DeviceSelector = ({ collapsible = true, disabled }) => {
  const dispatch = useDispatch()

  const { currentLab: lab, currentDevice: deviceSession } = useSelector((state) => state.labs)
  const { allocated_session: labSession } = lab
  const devices = labSession?.devices || []

  const getIcon = (name) => {
    if (name.toLowerCase().includes('jupyter')) {
      return JupyterDeviceIcon
    }

    if (name.toLowerCase().includes('postgres') || name.toLowerCase().includes('pgadmin')) {
      return PostgresDeviceIcon
    }

    if (name.toLowerCase().includes('mysql')) {
      return MysqlDeviceIcon
    }

    if (name.toLowerCase().includes('vscode')) {
      return VscodeDeviceIcon
    }

    if (name.toLowerCase().includes('php')) {
      return PhpmyadminDeviceIcon
    }

    return DefaultDeviceIcon
  }

  // const handleToggleDevice = async (deviceSessionId, status) => {
  //   if (status !== 'started') {
  //     await dispatch(startDeviceSession(labSession?.id, deviceSessionId))
  //   } else {
  //     await dispatch(stopDeviceSession(labSession?.id, deviceSessionId))
  //   }
  // }

  const handleRestartDevice = async (deviceSessionId, status) => {
    if (status !== 'started') {
      return
    }

    await dispatch(restartDeviceSession(labSession?.id, deviceSessionId))
  }

  const handleSwitchDevice = (deviceSession) => {
    dispatch(selectDevice(deviceSession))
  }

  const getSwitchTooltipText = (ds) => {
    if (ds.id === deviceSession?.id) return 'You are viewing this interface'
    if (!ds.views?.length) return 'This device does not contain an interface'
  }

  const menuItems = devices?.map((ds, i) => {
    return {
      key: ds?.id,
      label: (
        <MenuItem
          key={ds?.id}
          className={`${ds.id === deviceSession?.id ? 'is-selected' : ''} ${i + 1 === devices?.length ? 'is-last' : ''}`}
        >
          <div className="device-menu-item">
            <img className="icon" src={getIcon(ds.device?.name)} alt={ds.name} />

            <div className="device-info">
              <p className="device-name">
                {ds.device.name}
                <span className={`status ${ds.status}`}></span>
              </p>

              {ds.ip_address && (
                <Tooltip title="Copy to clipboard">
                  <p
                    className="device-ip"
                    onClick={() => {
                      navigator.clipboard.writeText(ds.ip_address)
                      showToast('IP copied to clipboard')
                    }}
                  >
                    IP {ds.ip_address} <CopyOutlined />
                  </p>
                </Tooltip>
              )}
            </div>

            <div className="device-actions">
              {ds?.device?.device_type !== 'jumpbox' && (
                <>
                  {/* <Tooltip title={`Power ${ds.status === 'started' ? 'off' : 'on'}`}>
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      ghost
                      disabled={['starting', 'stopping', 'restarting'].includes(ds.status)}
                      loading={['starting', 'stopping', 'restarting'].includes(ds.status)}
                      icon={<PoweroffOutlined />}
                      onClick={() => handleToggleDevice(ds.id, ds.status)}
                    />
                  </Tooltip> */}

                  <Tooltip title="Restart">
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      ghost
                      disabled={ds.status !== 'started'}
                      loading={['starting', 'stopping', 'restarting'].includes(ds.status)}
                      icon={<SyncOutlined />}
                      onClick={() => handleRestartDevice(ds.id, ds.status)}
                    />
                  </Tooltip>
                </>
              )}

              {devices?.length > 1 && (
                <Tooltip title={getSwitchTooltipText(ds)}>
                  <Button
                    type="primary"
                    size="small"
                    disabled={!ds.views?.length || ds.id === deviceSession?.id}
                    onClick={() => handleSwitchDevice(ds)}
                  >
                    Switch
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </MenuItem>
      ),
    }
  })

  if (!collapsible) {
    return <div className="device-selector">{menuItems?.map((i) => i.label)}</div>
  }

  return (
    <Dropdown
      className="device-selector"
      menu={{
        items: menuItems,
      }}
      trigger={['click']}
      disabled={disabled}
    >
      <Button id="device-selector" className="device-selector-btn" icon={<ApiOutlined />} disabled={disabled}>
        {deviceSession?.device?.name}
        <RightOutlined />
      </Button>
    </Dropdown>
  )
}
export default DeviceSelector
