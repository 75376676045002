import { useSelector, useDispatch } from 'react-redux'
import { profilesURL } from '@/helpers/env'
import Button from '@/components/Button'
import ShareLinkOptions from '@/components/ShareLinkOptions'
import PlaygroundShareHeaderImage from '@/assets/images/share-playground-header.png'
import ModuleShareHeaderImage from '@/assets/images/share-project-header.png'
import { setSharingModalOpen as setModuleSharingModalOpen } from '@/store/modules/actions'
import { setSharingModalOpen as setPlaygroundSharingModalOpen } from '@/store/playgrounds/actions'
import { Modal } from './styles'

const ShareModal = ({ contentType }) => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { currentModule, isSharingModalOpen: isModulesModalOpen } = useSelector((state) => state.modules)
  const { currentPlayground, isSharingModalOpen: isPlaygroundsModalOpen } = useSelector((state) => state.playgrounds)

  const isPlayground = contentType === 'playground'
  const isModule = contentType === 'module'

  const isSharingModalOpen = isModule ? isModulesModalOpen : isPlaygroundsModalOpen
  const handleClose = () =>
    isModule ? dispatch(setModuleSharingModalOpen(false)) : dispatch(setPlaygroundSharingModalOpen(false))
  const headerImage = isModule ? ModuleShareHeaderImage : PlaygroundShareHeaderImage

  const modulePublicUrl = `${profilesURL}${userProfile?.username}/projects/${currentModule?.id}`
  const playground = currentPlayground || isSharingModalOpen // when called from playgrounds list, playground is stored in isSharingModalOpen
  const isCustomProject = !!playground?.metadata?.account_id
  const playgroundPublicUrl = `${profilesURL}${playground?.user?.username}/playgrounds/${playground?.id}`
  const lastVersion = playground?.versions?.[playground?.versions?.length - 1]

  const sharingUrl = isModule ? modulePublicUrl : playgroundPublicUrl

  return (
    <Modal open={isSharingModalOpen} onCancel={handleClose} footer={null} className="share-modal">
      <img className="header-image" src={headerImage} alt={`Share this ${isModule ? 'Project' : 'Playground'}`} />

      <div className="content">
        <div className="header-container">
          <p className="info-text">
            {isModule
              ? 'Share your accomplishments with the world!'
              : isCustomProject
                ? 'Share this project with your users.'
                : 'Share your playground with everybody and let them see your work.'}
          </p>

          {/* {isModule && <h4 className="pre-title">Finishing a DataWars project is not something to take likely!</h4>} */}
        </div>

        {isPlayground && (
          <div className="version-info">
            <div className="version-item">
              <h5 className="version-code">{lastVersion?.name}</h5>

              <h5 className="playground-name">{playground?.name}</h5>
            </div>
          </div>
        )}

        {isModule && <h4 className="title">{currentModule?.name}</h4>}

        <div className="sharing-box">
          <ShareLinkOptions
            link={sharingUrl}
            xShareText={
              isModule
                ? `I completed ${currentModule?.name} DataWars project.`
                : `I just published ${playground?.name} DataWars playground.`
            }
            hideX={isCustomProject}
            hideLinkedIn={isCustomProject}
          />
        </div>

        <div className="actions">
          <Button type="secondary" onClick={handleClose}>
            Done
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ShareModal
