import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  customProjectsData: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'customProjects',
  initialState,
  reducers: {
    SET_CUSTOM_PROJECTS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    CUSTOM_PROJECTS_UPDATED: (state, { payload }) => {
      state.customProjectsData = payload
      state.error = null
    },
    RESET_CUSTOM_PROJECTS_STATE: () => initialState,
    SET_CUSTOM_PROJECTS_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
  },
})

export const {
  SET_CUSTOM_PROJECTS_LOADING,
  CUSTOM_PROJECTS_UPDATED,
  RESET_CUSTOM_PROJECTS_STATE,
  SET_CUSTOM_PROJECTS_ERROR,
} = slice.actions
export default slice.reducer
