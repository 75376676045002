import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray2};
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  scroll-behavior: initial; /* to avoid spy-scroll errors */

  > div {
    width: 100%;
    max-width: 860px;

    &:first-child {
      margin: 0px;
    }
  }
`
