import { useSelector, useDispatch } from 'react-redux'
import { isDataWarsHostName } from '@/helpers/env'
import Button from '@/components/Button'
import AllocatedModules from '@/pages/ModulePage/components/AllocatedModules'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { toggleMultiplePlaygroundsModal } from '@/store/playgrounds/actions'
import ProSuccessBg from '@/assets/images/plans/pro-success.png'
import { Modal } from './styles'
import { useEffect } from 'react'

const MultiplePlaygroundsModal = () => {
  const dispatch = useDispatch()

  const { allocatedPlaygrounds, isMultiplePlaygroundsModalOpen } = useSelector((state) => state.playgrounds)
  const isPlaygroundPage = window.location.pathname.startsWith('/playgrounds/')

  const handleUpgradeToPro = () => {
    dispatch(toggleUpgradeToProModal(true))
    dispatch(toggleMultiplePlaygroundsModal(false))
  }

  useEffect(() => {
    if (allocatedPlaygrounds?.length === 0) {
      dispatch(toggleMultiplePlaygroundsModal(false))
    }
  }, [allocatedPlaygrounds])

  return (
    <Modal
      open={isMultiplePlaygroundsModalOpen}
      onCancel={() => dispatch(toggleMultiplePlaygroundsModal(false))}
      keyboard={false}
      footer={null}
      className="upgrade-to-pro-success-modal"
    >
      <div className="content">
        <div className="header-container">
          {isDataWarsHostName && <img className="image" src={ProSuccessBg} alt="Your are now PRO user" />}

          <div className="title-container">
            {isDataWarsHostName ? (
              <h2 className="title">
                Become <span className="pro">PRO</span>
                <p className="info-text">
                  to edit multiple {isPlaygroundPage ? 'playgrounds' : 'projects'} simultaneously.
                </p>
              </h2>
            ) : (
              <>
                <h4 className="title">You are running other {isPlaygroundPage ? 'playground' : 'project'}</h4>
                <p className="title">
                  You can only edit one {isPlaygroundPage ? 'playground' : 'project'} at the same time
                </p>
              </>
            )}

            {isDataWarsHostName && (
              <p className="info-text">
                As a Basic User, you can only edit one {isPlaygroundPage ? 'playground' : 'project'} at the same time.
              </p>
            )}
          </div>
        </div>

        {!!allocatedPlaygrounds?.length && (
          <div className="running-playgrounds">
            <p className="info-text">Other {isPlaygroundPage ? 'Playgrounds' : 'Projects'} running:</p>

            <AllocatedModules
              allocatedModules={allocatedPlaygrounds}
              onClick={() => dispatch(toggleMultiplePlaygroundsModal(false))}
            />
          </div>
        )}

        {isDataWarsHostName && (
          <div className="footer">
            <Button type="primary" size="large" ispro="true" onClick={handleUpgradeToPro}>
              Upgrade to PRO
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default MultiplePlaygroundsModal
