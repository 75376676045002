import styled from 'styled-components'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    padding: 0px;
  }

  .ant-modal-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 20px 40px;

    @media ${device.mdDown} {
      height: initial;
    }

    .content {
      max-width: 330px;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .anticon svg {
        font-size: 32px;
        margin-bottom: 10px;
      }

      .title {
        max-width: 250px;
        margin-bottom: 10px;
      }

      > p {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
      }

      > button {
        margin-bottom: 8px;
      }

      > a {
        font-size: 14px;
        color: ${(props) => props.theme.colors.gray13};
        display: block;
        text-decoration: underline;
      }

      > img {
        width: 100%;
        margin-bottom: 20px;
      }

      .bottom-text {
        font-size: 13px;
        margin: 20px 0px 0px;

        &.gray {
          color: ${(props) => props.theme.colors.gray7};

          a {
            color: ${(props) => props.theme.colors.gray7};
            text-decoration: underline;
          }
        }
      }

      .resend-btn {
        border: none;

        span {
          color: ${(props) => props.theme.colors.text};
          font-size: 13px;
          text-decoration: underline;
        }
      }

      .error-text {
        color: #fa541c;
        font-size: 14px;
        margin: -10px 0px 20px;
      }

      .code-input-container {
        width: 380px;
        margin-left: -30px;
        margin-bottom: 24px;

        @media ${device.mdDown} {
          width: initial;
          margin-left: 0px;
        }

        .code-input-content {
          width: 100%;
          display: flex;
          gap: 7px;

          .code-input {
            height: 75px;
            width: inherit;
            flex-grow: 1;
            background: ${(props) => props.theme.colors.gray1};
            color: ${(props) => props.theme.colors.gray13};
            font-size: 29px;
            font-weight: 400px;
            line-height: 40px;
            text-align: center;
            text-transform: uppercase;
            border: 1px solid ${(props) => props.theme.colors.gray9};
            border-radius: 3px;

            &.error {
              color: #fc7272;
              border-color: #fc7272;
            }

            &:nth-child(4) {
              margin-left: 14px;
            }

            &:focus {
              appearance: none;
              outline: 0;
              box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
            }
          }
        }
      }
    }
  }

  .ant-modal-close {
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.colors.gray4};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 2px;

    .ant-modal-close-x {
      line-height: inherit;

      svg {
        color: ${(props) => props.theme.colors.gray10};
        font-size: 18px;
      }
    }
  }
`
