import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'learningAreas',
  initialState: {
    items: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    SET_LEARNING_AREA_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    LEARNING_AREAS_UPDATED: (state, { payload }) => {
      state.items = payload
      state.isLoading = false
      state.error = null
    },
    SET_LEARNING_AREA_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
  },
})

export const { SET_LEARNING_AREA_LOADING, LEARNING_AREAS_UPDATED, LEARNING_AREA_UPDATED, SET_LEARNING_AREA_ERROR } =
  slice.actions
export default slice.reducer
