import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

const pulseWhite = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`

const pulseRed = (props) => keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${props.theme.colors.red4}BB;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px ${props.theme.colors.red4}00;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 8px ${props.theme.colors.red4}00;
  }
`

const pulseOrange = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
`

const pulseYellow = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
`

const pulseBlue = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
`

const pulseGreen = (props) => keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${props.theme.colors.cyan5}BB;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px ${props.theme.colors.cyan5}00;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 8px ${props.theme.colors.cyan5}00;
  }
`

const pulsePurple = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
  }
`

export const Container = styled.span`
  animation: 1s ${fadeInAnimation};

  > .blob {
    background: ${(props) => props.theme.colors.gray7};
    display: inline-block;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);

    ${(props) => props.onClick && `cursor: pointer;`}

    ${(props) => !props.$addPulse && `animation: none !important;`}

  ${(props) =>
      props.$size === 'small' &&
      `
    width: 9px;
    height: 9px;
  `}

  &.white {
      background: white;
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
      animation: ${pulseWhite} 2s infinite;
    }

    &.red {
      background: ${(props) => props.theme.colors.red4};
      box-shadow: 0 0 0 0 ${(props) => props.theme.colors.red1};
      animation: ${pulseRed} 2s infinite;
    }

    &.orange {
      background: rgba(255, 121, 63, 1);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
      animation: ${pulseOrange} 2s infinite;
    }

    &.yellow {
      background: rgba(255, 177, 66, 1);
      box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
      animation: ${pulseYellow} 2s infinite;
    }

    &.blue {
      background: rgba(52, 172, 224, 1);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
      animation: ${pulseBlue} 2s infinite;
    }

    &.green {
      background: ${(props) => props.theme.colors.cyan5};
      box-shadow: 0 0 0 0 ${(props) => props.theme.colors.cyan2};
      animation: ${pulseGreen} 2s infinite;
    }

    &.purple {
      background: rgba(142, 68, 173, 1);
      box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
      animation: ${pulsePurple} 2s infinite;
    }
  }
`
