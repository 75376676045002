import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  > .title {
    color: ${(props) => props.theme.colors.gray10};
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    padding-right: calc(240px + 28px);
    margin: 0px 0px 20px;
  }

  .datasources-search-container {
    .main-content {
      max-width: 720px;
      width: 100%;
    }

    .filters-container {
      margin-bottom: 56px;
    }
  }

  @media ${device.mdDown} {
    > .title {
      font-size: 22px;
      line-height: 26px;
      padding: 0px;
    }
  }
`
