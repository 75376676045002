import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { profilesURL } from '@/helpers/env'
import Button from '@/components/Button'
import PublicProfileDone from '@/assets/images/public-profile-done.gif'

const Step5 = () => {
  const { userProfile } = useSelector((state) => state.users)

  return (
    <div className="content">
      <div className="header">
        <p className="title">Your public profile is ready!</p>
        <p className="subtitle">You can always update your details from your public profile.</p>
      </div>

      <div className="form-container">
        <img className="done-image" src={PublicProfileDone} alt="Your public profile is ready!" />

        <div className="actions vertical">
          <Link to={`${profilesURL}${userProfile?.username}`} target="_blank">
            <Button type="primary" size="large">
              Go to my public profile
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Step5
