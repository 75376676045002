import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Input } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import BioIcon from '@/assets/images/icons/bio.svg?react'
import LinkedInIcon from '@/assets/images/icons/linkedin.svg?react'
import Button from '@/components/Button'
import { updateProfile } from '@/store/users/actions'

const Step4 = ({ onPrev, onNext }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const { userProfile } = useSelector((state) => state.users)

  const formSubmit = async (values) => {
    setIsLoading(true)
    await dispatch(updateProfile(userProfile?.username, values))
    setIsLoading(false)
    onNext()
  }

  return (
    <div className="content">
      <div className="header">
        <p className="title">Tell us more about yourself</p>
        <p className="subtitle">Let others browsing your public profile know about your background and objectives</p>
      </div>

      <Form
        className="form-container"
        initialValues={{
          bio: userProfile?.bio,
          linkedin_url: userProfile?.linkedin_url,
        }}
        onFinish={formSubmit}
      >
        <div className="row">
          <Form.Item name="bio">
            <Input.TextArea
              style={{ height: 96, resize: 'none' }}
              placeholder="Share a brief description about you"
              prefix={<BioIcon />}
              disabled={isLoading}
            />
          </Form.Item>
        </div>

        <div className="row">
          <Form.Item name="linkedin_url" rules={[{ type: 'url', message: 'Enter a valid URL.' }]}>
            <Input size="large" placeholder="LinkedIn URL" prefix={<LinkedInIcon />} disabled={isLoading} />
          </Form.Item>
        </div>

        <p className="info-text">This information will be displayed on your Public Profile page.</p>

        <div className="actions">
          <Button className="back-button" type="text" onClick={onPrev} loading={isLoading} disabled={isLoading}>
            <ArrowLeftOutlined /> Back
          </Button>

          <div>
            <Button className="skip-button" type="text" onClick={onNext} loading={isLoading} disabled={isLoading}>
              Skip this step
            </Button>

            <Button type="primary" size="large" htmlType="submit" loading={isLoading} disabled={isLoading}>
              Next
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Step4
