import * as React from "react";
const Svg4Full = (props) => /* @__PURE__ */ React.createElement("svg", { id: "badge-4", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 600 600", style: {
  enableBackground: "new 0 0 600 600"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { id: "ribbon", d: "M382.3,504.2l-75.9-47.4c-4-2.5-8.8-2.5-12.8,0l-75.9,47.4c-6.9,4.2-14.6-3.4-12.9-12.8l17.7-153.6 h155l17.7,153.6C397,500.8,389.2,508.5,382.3,504.2L382.3,504.2L382.3,504.2z", style: {
  fill: "#525776"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { id: "medal-border", d: "M285.7,98.4L160,171c-8.8,5.1-14.3,14.5-14.3,24.8v145.1c0,10.2,5.5,19.6,14.3,24.7 l125.7,72.6c8.8,5.1,19.7,5.1,28.6,0L440,365.7c8.8-5.1,14.3-14.5,14.3-24.7V195.8c0-10.2-5.5-19.6-14.3-24.8L314.3,98.4 C305.4,93.3,294.5,93.3,285.7,98.4L285.7,98.4z", style: {
  fill: "#7F8297"
} })), /* @__PURE__ */ React.createElement("g", { id: "transparent-center" }, /* @__PURE__ */ React.createElement("polygon", { points: "300,263.7 300,107.5 164.7,185.6  ", style: {
  fill: "#B2B2B2"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "435.3,186.6 300,108.5 300,264.7  ", style: {
  fill: "#CBCBCB"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "164.7,186.6 161.6,188.4 161.6,344.6 300,264.7  ", style: {
  fill: "#989898"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "300,264.7 161.6,344.6 161.6,348.3 300,428.2  ", style: {
  fill: "#808080"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "435.3,186.6 300,264.7 300,264.7 438.4,344.6 438.4,188.4  ", style: {
  fill: "#989898"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "300,428.2 438.4,348.3 438.4,344.6 300,264.7  ", style: {
  fill: "#B2B2B2"
} })), /* @__PURE__ */ React.createElement("path", { id: "internal-jewel", d: "M294,189l-59.8,34.5c-3.7,2.1-6,6.1-6,10.4v69c0,4.3,2.3,8.3,6,10.4l59.8,34.5 c3.7,2.1,8.3,2.1,12,0l59.8-34.5c3.7-2.1,6-6.1,6-10.4v-69c0-4.3-2.3-8.3-6-10.4L306,189C302.3,186.8,297.7,186.8,294,189L294,189z", style: {
  opacity: 0.8,
  fill: "#4A4D69",
  enableBackground: "new"
} }), /* @__PURE__ */ React.createElement("path", { id: "spark", d: "M364.6,218l2.9-5.3l2.9,5.3c2.5,4.4,6.1,8.1,10.5,10.5l5.3,2.9l-5.3,2.9c-4.5,2.5-8.1,6.1-10.5,10.5 l-2.9,5.3l-2.9-5.3c-2.5-4.4-6.1-8.1-10.5-10.5l-5.3-2.9l5.3-2.9C358.5,226.1,362.2,222.4,364.6,218z", style: {
  fill: "#FFFFFF"
} }));
export default Svg4Full;
