import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isDataWarsHostName } from '@/helpers/env'
import RouteTracking from '@/router/RouteTracking'
import PrivateRoute from '@/router/PrivateRoute'
import CatalogPage from '@/pages/CatalogPage'
import DashboardPage from '@/pages/DashboardPage'
// import EmailValidationPage from '@/pages/EmailValidationPage'
import ErrorPage from '@/pages/ErrorPage'
import InvitePage from '@/pages/InvitePage'
import LaunchContentPage from '@/pages/LaunchContentPage'
import CustomProjectsCreationPage from '@/pages/CustomProjectsCreationPage'
import ModulePage from '@/pages/ModulePage'
import AssessmentPage from '@/pages/AssessmentPage'
// import ModuleReportPage from '@/pages/ModuleReportPage'
import NotFoundPage from '@/pages/NotFoundPage'
import PlaygroundPage from '@/pages/PlaygroundPage'
import PlaygroundsPage from '@/pages/PlaygroundsPage'
import AuthPage from '@/pages/AuthPage'
import AuthChannelPage from '@/pages/AuthChannelPage'
import SkillTrackPage from '@/pages/SkillTrackPage'
import RedeemVoucherPage from '@/pages/RedeemVoucherPage'
import SessionExpiredImage from '@/assets/images/session-expired.png'

const dataWarsRoutes = [
  {
    path: '/login',
    element: <AuthPage />,
  },
  {
    path: '/register',
    element: <AuthPage />,
  },
  {
    path: '/forgot-password',
    element: <AuthPage />,
  },
  {
    path: '/reset-password',
    element: <AuthPage />,
  },
  // {
  //   path: '/email-validation',
  //   element: <EmailValidationPage />,
  // },
  {
    path: '/project/:moduleId',
    element: <ModulePage />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '/dashboard',
        element: <DashboardPage />,
      },
      {
        path: '/catalog',
        element: <CatalogPage />,
      },
      {
        path: '/skill-track/:skillTrackId',
        element: <SkillTrackPage />,
      },
      {
        path: '/playgrounds/:playgroundId',
        element: <PlaygroundPage />,
      },
      {
        path: '/playgrounds',
        element: <PlaygroundsPage />,
      },
      {
        path: '/custom-projects/:playgroundId',
        element: <PlaygroundPage />,
      },
      {
        path: '/custom-projects/:accountId/create',
        element: <CustomProjectsCreationPage />,
      },
      {
        path: '/custom-projects/:accountId/:projectId/settings',
        element: <CustomProjectsCreationPage />,
      },
      {
        path: '/assessment/:moduleId',
        element: <AssessmentPage />,
      },
      {
        path: '/invite/:inviteId',
        element: <InvitePage />,
      },
      // {
      //   path: '/project/:moduleId/report',
      //   element: <ModuleReportPage />,
      // },
    ],
  },
  {
    path: '/not-found',
    element: <NotFoundPage />,
  },
  {
    path: '*',
    element: <Navigate replace to={'/dashboard'} />,
  },
]

const getAccountRoutes = (accountConfig, accountRole) => {
  const isAccountOwner = accountRole === 'owner'

  // login
  // const loginFormIsEnabled = accountConfig?.login?.login_form

  // fulfillment
  const voucherFulfillmentIsEnabled = accountConfig?.fulfillment?.voucher

  // launch
  const selfLaunchIsEnabled = accountConfig?.launch?.self_launch
  const apiLaunchIsEnabled = accountConfig?.launch?.api_launch

  // browse
  const browsePreviewType = accountConfig?.browse?.preview // 'auth', 'anonymous'
  const enablePlaygrounds = accountConfig?.browse?.playgrounds

  const getContentRoutes = () => {
    return [
      ...(selfLaunchIsEnabled
        ? [
            {
              path: '/project/:moduleId',
              element: <ModulePage />,
            },
          ]
        : []),
    ]
  }

  return [
    ...(true
      ? [
          {
            path: '/login',
            element: <AuthChannelPage />,
          },
          {
            path: '/forgot-password',
            element: <AuthChannelPage />,
          },
          {
            path: '/reset-password',
            element: <AuthChannelPage />,
          },
        ]
      : []),
    ...(voucherFulfillmentIsEnabled
      ? [
          {
            path: '/redeem-voucher',
            element: <RedeemVoucherPage />,
          },
          {
            path: '/redeem-voucher/existing-user',
            element: <RedeemVoucherPage />,
          },
          {
            path: '/redeem-voucher/new-user',
            element: <RedeemVoucherPage />,
          },
          {
            path: '/redeem-voucher/content-selection',
            element: <RedeemVoucherPage />,
          },
        ]
      : []),
    ...(browsePreviewType !== 'auth' ? getContentRoutes() : []),
    {
      element: <PrivateRoute />,
      children: [
        ...(browsePreviewType === 'auth' ? getContentRoutes() : []),
        ...(selfLaunchIsEnabled
          ? [
              {
                path: '/assessment/:moduleId',
                element: <AssessmentPage />,
              },
            ]
          : []),
        ...(apiLaunchIsEnabled
          ? [
              {
                path: '/launch/:launchId',
                element: <LaunchContentPage />,
              },
              {
                path: '/session-expired',
                element: (
                  <NotFoundPage
                    title="Session expired"
                    text="For your security, your session has timed out. Please go to your LMS and log in again this content."
                    image={SessionExpiredImage}
                  />
                ),
              },
            ]
          : []),
        ...(enablePlaygrounds
          ? [
              {
                path: '/custom-projects/:playgroundId',
                element: <PlaygroundPage />,
              },
              ...(isAccountOwner
                ? [
                    {
                      path: '/custom-projects/:accountId/create',
                      element: <CustomProjectsCreationPage />,
                    },
                    {
                      path: '/custom-projects/:accountId/:projectId/settings',
                      element: <CustomProjectsCreationPage />,
                    },
                  ]
                : []),
            ]
          : []),
      ],
    },
    {
      path: '/not-found',
      element: <NotFoundPage />,
    },
    {
      path: '*',
      element: <Navigate replace to={'/not-found'} />,
    },
  ]
}

const getRoutes = (currentAccount, accountRole) => {
  return [
    {
      element: <RouteTracking />,
      errorElement: <ErrorPage />,
      children: isDataWarsHostName ? dataWarsRoutes : getAccountRoutes(currentAccount?.config, accountRole),
    },
  ]
}

const Router = () => {
  const { currentAccount } = useSelector((state) => state.accounts)
  const { userProfile } = useSelector((state) => state.users)
  const accountRole = userProfile?.accounts?.find((account) => account.id === currentAccount?.id)?.role

  const router = createBrowserRouter(getRoutes(currentAccount, accountRole))

  return <RouterProvider router={router} />
}

export default Router
