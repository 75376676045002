import { createSlice } from '@reduxjs/toolkit'
import { getDefaultUiConfig } from '@/helpers/getDefaultUiConfig'

const initialState = {
  uiConfig: getDefaultUiConfig(),
  currentAccount: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    SET_ACCOUNTS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    ACCOUNT_UPDATED: (state, { payload }) => {
      state.currentAccount = payload
      state.error = null
    },
    ACCOUNT_UI_CONFIG_UPDATED: (state, { payload }) => {
      state.uiConfig = payload
    },
    SET_ACCOUNTS_ERROR: (state, { payload }) => {
      state.error = payload
    },
    RESET_ACCOUNTS_STATE: () => initialState,
  },
})

export const {
  SET_ACCOUNTS_LOADING,
  ACCOUNT_UPDATED,
  ACCOUNT_UI_CONFIG_UPDATED,
  SET_ACCOUNTS_ERROR,
  RESET_ACCOUNTS_STATE,
} = slice.actions
export default slice.reducer
