import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FadeIn from '@/components/FadeIn'
import NoPlaygroundsHeader from '../NoPlaygroundsHeader'
import JumpboxesSection from '../JumpboxesSection'
import MyPlaygroundsSection from '../MyPlaygroundsSection'
import FeaturedDataSourcesSection from '../FeaturedDataSourcesSection'
import FeaturedPlaygroundsSection from '../FeaturedPlaygroundsSection'
import { getPlaygrounds } from '@/store/playgrounds/actions'
import { getJumpboxes } from '@/store/jumpboxes/actions'
import { Container } from './styles'

const OverviewTab = () => {
  const dispatch = useDispatch()

  const { myPlaygrounds } = useSelector((state) => state.playgrounds)
  const { items: jumpboxes } = useSelector((state) => state.jumpboxes)

  const handleLoad = async () => {
    if (myPlaygrounds === null) {
      dispatch(getPlaygrounds({ user: 'me' }))
    }

    if (jumpboxes === null) {
      dispatch(getJumpboxes())
    }
  }

  useEffect(() => {
    handleLoad()
  }, [myPlaygrounds]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="playgrounds-overview-tab">
      <FadeIn>
        <div className="main-container">
          <div className="content">
            {myPlaygrounds && !myPlaygrounds?.length ? <NoPlaygroundsHeader /> : <MyPlaygroundsSection />}

            <FeaturedDataSourcesSection title="Featured datasets" headerDirection="row" />

            <FeaturedPlaygroundsSection />
          </div>
        </div>

        <JumpboxesSection />
      </FadeIn>
    </Container>
  )
}

export default OverviewTab
