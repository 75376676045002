import styled from 'styled-components'

export const Loader = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(51, 144, 255, 0.6);
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ant-spin-dot {
    font-size: 38px !important;
  }
`
