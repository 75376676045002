import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 26px 30px;

    .title {
      color: ${(props) => props.theme.colors.gray8};
      margin-bottom: 20px;
    }

    .preview-info {
      background-color: ${(props) => props.theme.colors.geekblue2};
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 14px 16px;
      margin-bottom: 28px;

      .image-container {
        width: 234px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;

        .preview-image {
          width: 100%;
          filter: blur(3px);
        }

        .play-button {
          background-color: ${(props) => props.theme.colors.geekblue2};
          border-radius: 100px;
          display: flex;
          align-items: center;
          position: absolute;
          transition: 0.3s all;

          > p {
            font-weight: 500;
            padding: 16px 16px 16px 10px;
            margin: 0px;
          }

          .icon {
            background-color: ${(props) => props.theme.colors.geekblue5};
            border-radius: 50%;
            box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);

            svg {
              font-size: 54px;
              fill: ${(props) => props.theme.colors.gray1};

              path:nth-child(1) {
                fill: transparent;
              }
            }
          }
        }

        &:hover {
          .play-button {
            background-color: ${(props) => props.theme.colors.geekblue3};
            box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);
          }
        }
      }

      .body {
        flex: 1;
        display: flex;
        justify-content: flex-start;

        .title {
          max-width: 320px;
          font-size: 18px;
          line-height: 24px;
          margin: 0px 0px 0px 24px;
        }
      }
    }

    .la-selector.ant-segmented {
      background-color: transparent !important;
      padding: 0px;
      margin-bottom: 14px;

      .ant-segmented-group {
        gap: 10px;
        flex-wrap: wrap;

        .ant-segmented-item {
          background-color: ${(props) => props.theme.colors.geekblue1};
          border: 1px solid ${(props) => props.theme.colors.geekblue2};
          border-radius: 8px;
          transition: none;
          box-shadow: none;

          &:hover {
            background-color: ${(props) => props.theme.colors.geekblue2} !important;
          }

          &.ant-segmented-item-selected {
            background-color: ${(props) => props.theme.colors.geekblue2};
            border: 1px solid ${(props) => props.theme.colors.geekblue2};

            .ant-segmented-item-label {
              color: ${(props) => props.theme.colors.gray10};
            }
          }

          .ant-segmented-item-label {
            color: ${(props) => props.theme.colors.gray7};
            padding: 0px 12px;
          }
        }

        .ant-segmented-thumb {
          box-shadow: none;
        }
      }
    }

    .featured-modules-container {
      cursor: grab;

      .text {
        color: ${(props) => props.theme.colors.gray8};
      }
    }
  }

  @media ${device.mdDown} {
    .content {
      .title {
        text-align: center;
      }

      .preview-info {
        padding: 0px;

        .image-container {
          width: 100%;
        }

        .body {
          display: none;
        }
      }

      .ant-segmented-group {
        flex-wrap: wrap;
        max-width: 80%;
        justify-content: center;
        margin: 0 auto;
      }

      .featured-modules-container {
        .text {
          text-align: center;
        }
      }
    }
  }
`
