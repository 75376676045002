import { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MailTwoTone } from '@ant-design/icons'
import AuthCode from 'react-auth-code-input'
import { isDataWarsHostName } from '@/helpers/env'
import Button from '@/components/Button'
import { validateEmail, confirmEmail } from '@/store/users/actions'
import { useEffect } from 'react'

const ValidateCode = () => {
  const dispatch = useDispatch()
  const CodeInputRef = useRef(null)
  const { emailValidationCodeIsResent, emailValidationCodeError: error } = useSelector((state) => state.users)

  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState('')

  const supportEmail = isDataWarsHostName ? 'support@datawars.io' : 'support@namespace.im'

  const resendCode = () => {
    setIsLoading(true)
    dispatch(validateEmail(true))
  }

  const handleClick = () => {
    setIsLoading(true)
    dispatch(confirmEmail(token))
  }

  useEffect(() => {
    setIsLoading(false)
  }, [emailValidationCodeIsResent])

  useEffect(() => {
    if (error) {
      setIsLoading(false)
      // CodeInputRef.current?.clear()
    }
  }, [error])

  return (
    <div className="content">
      <MailTwoTone />

      <h2 className="title">An email has been sent to you.</h2>

      <p>Check the email that's associated with your account for the verification code.</p>

      <div className="code-input-container">
        <AuthCode
          ref={CodeInputRef}
          autoFocus
          allowedCharacters={'numeric'}
          onChange={setToken}
          containerClassName="code-input-content"
          inputClassName={`code-input ${error && 'error'}`}
          disabled={isLoading}
        />
      </div>

      {error && <p className="error-text">{error}</p>}

      <Button
        type="primary"
        size="large"
        onClick={handleClick}
        loading={isLoading}
        disabled={isLoading || token?.length !== 6}
      >
        Validate Code
      </Button>

      <div>
        <p className="bottom-text">
          Please check out your Spam Folder.{' '}
          {!emailValidationCodeIsResent && "If you don't receive the code within 1 minute click on"}
        </p>

        <Button
          className="resend-btn"
          type="text"
          onClick={resendCode}
          loading={isLoading}
          disabled={isLoading || emailValidationCodeIsResent}
        >
          {!emailValidationCodeIsResent && 'Resend code'}
        </Button>

        <p className="bottom-text gray">
          Still not getting it? Write us{' '}
          <a href={`mailto:${supportEmail}?subject=I didn't get the verification code`}>{supportEmail}</a>
        </p>
      </div>
    </div>
  )
}

export default ValidateCode
