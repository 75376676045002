import axios from 'axios'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const fetchPPPInfo = async () => {
  const route = `https://api.paritydeals.com/api/v1/deals/discount/?url=${window.location.href}`

  return axios
    .get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { fetchPPPInfo }
