import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const fetchPlaygrounds = async (queryParams) => {
  // ?user=me
  // ?featured=true
  // ?data_source=<ID>
  const route = 'playgrounds'
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchPlayground = async (playgroundId) => {
  const route = `playgrounds/${playgroundId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postPlayground = async (data) => {
  const route = 'playgrounds'
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const patchPlayground = async (playgroundId, data) => {
  const route = `playgrounds/${playgroundId}`
  return API.patch(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchAllocatedPlaygrounds = async () => {
  const route = 'playgrounds/allocated'
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postPlaygroundPreview = async (playgroundId) => {
  const route = `playgrounds/${playgroundId}/previews`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postPlaygroundPublish = async (playgroundId, data) => {
  const route = `playgrounds/${playgroundId}/publish`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postPlaygroundVote = async (playgroundId, data) => {
  const route = `playgrounds/${playgroundId}/vote`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const deletePlaygroundVote = async (playgroundId) => {
  const route = `playgrounds/${playgroundId}/vote`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postPlaygroundImage = async (file) => {
  const route = `playgrounds/upload-image`
  return API.post(
    route,
    { image_file: file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export {
  fetchPlaygrounds,
  fetchPlayground,
  postPlayground,
  patchPlayground,
  fetchAllocatedPlaygrounds,
  postPlaygroundPreview,
  postPlaygroundPublish,
  postPlaygroundVote,
  deletePlaygroundVote,
  postPlaygroundImage,
}
