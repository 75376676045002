import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

// const multiFetchModules = async (moduleIds) => {
//   const route = `modules?page_size=none&ids=${moduleIds?.toString()}`
//   return API.get(route)
//     .then((response) => Promise.resolve(response.data))
//     .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
// }

const fetchModules = async (queryParams) => {
  // ?is_featured=true
  // ?in_progress=true
  // ?finished=true
  const route = `modules`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchModule = async (moduleId) => {
  const route = `modules/${moduleId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const patchModule = async (moduleId, newData) => {
  const route = `modules/${moduleId}`
  return API.patch(route, newData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchRelatedModules = async () => {
  const route = `modules/related`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchNewModules = async () => {
  const route = `modules/new`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const multiFetchModulesProgress = async (moduleIds) => {
  const route = `modules/progress?page_size=none&ids=${moduleIds?.toString()}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postModuleAttempt = async (moduleId, body) => {
  const route = `modules/${moduleId}/attempts`
  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postModuleAttemptStop = async (moduleId, attemptId, body) => {
  const route = `modules/${moduleId}/attempts/${attemptId}/stop`
  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postModuleAttemptResume = async (moduleId, attemptId, body) => {
  const route = `modules/${moduleId}/attempts/${attemptId}/resume`
  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postModuleAttemptExtendTime = async (moduleId, attemptId) => {
  const route = `modules/${moduleId}/attempts/${attemptId}/extend-time`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const getModuleAttemptContent = async (moduleId, attemptId) => {
  const route = `modules/${moduleId}/attempts/${attemptId}/content`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postModuleAttemptAction = async (moduleId, attemptId, body) => {
  const route = `modules/${moduleId}/attempts/${attemptId}/action`
  return API.post(route, body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const getModuleAttemptScore = async (moduleId, attemptId) => {
  const route = `modules/${moduleId}/attempts/${attemptId}/score`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

// const fetchModuleReport = async (moduleId, moduleAttemptId) => {
//   const route = `modules/${moduleId}/attempts/${moduleAttemptId}/report`
//   return API.post(route)
//     .then((response) => Promise.resolve(response.data))
//     .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
// }

const fetchAllocatedModules = async () => {
  const route = `modules/allocated`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchModuleToolbar = async (moduleId) => {
  const route = `modules/${moduleId}/toolbar`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postModuleRating = async (moduleId, ratingData) => {
  const route = `modules/${moduleId}/ratings`
  return API.post(route, ratingData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const patchModuleRating = async (moduleId, ratingData) => {
  const route = `modules/${moduleId}/ratings/${ratingData.id}`
  return API.patch(route, ratingData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const fetchModuleConversation = async (moduleId, conversationId) => {
  const route = `modules/${moduleId}/conversations/${conversationId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postModuleConversation = async (moduleId) => {
  const route = `modules/${moduleId}/conversations`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const putModuleConversation = async (moduleId, conversationId, message) => {
  const route = `modules/${moduleId}/conversations/${conversationId}`
  return API.put(route, { message })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const deleteModuleConversation = async (moduleId, conversationId) => {
  const route = `modules/${moduleId}/conversations/${conversationId}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postModuleImport = async (data) => {
  const route = `modules/import`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postModuleRegenerateOGImage = async (moduleId) => {
  const route = `modules/${moduleId}/og-image`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postModuleWebflowUpsert = async (moduleId) => {
  const route = `modules/${moduleId}/webflow-upsert`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export {
  fetchModules,
  fetchModule,
  patchModule,
  fetchRelatedModules,
  fetchNewModules,
  multiFetchModulesProgress,
  postModuleAttempt,
  postModuleAttemptStop,
  postModuleAttemptResume,
  postModuleAttemptExtendTime,
  getModuleAttemptContent,
  postModuleAttemptAction,
  getModuleAttemptScore,
  // fetchModuleReport,
  fetchAllocatedModules,
  fetchModuleToolbar,
  postModuleRating,
  patchModuleRating,
  fetchModuleConversation,
  postModuleConversation,
  putModuleConversation,
  deleteModuleConversation,
  postModuleImport,
  postModuleRegenerateOGImage,
  postModuleWebflowUpsert,
}
