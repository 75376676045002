import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import Button from '@/components/Button'
import { getVoucher, redeemVoucher } from '@/store/vouchers/actions'
import { ContentSelectionBox } from './styles'

const ContentSelection = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { currentVoucher: voucher } = useSelector((state) => state.vouchers)

  let [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(true)

  const redirectTo = searchParams.get('redirect_to')

  const onLoad = useCallback(async () => {
    if (voucher?.code) {
      await dispatch(getVoucher(voucher?.code))
    }
    setIsLoading(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const commitSelection = async () => {
    setIsLoading(true)

    const contentId = voucher?.content?.available?.[0]?.id
    const redeemData = {
      voucherCode: voucher?.code,
      contentIds: [contentId],
    }

    await dispatch(
      redeemVoucher(redeemData, () => {
        if (redirectTo) {
          window.location.replace(redirectTo)
          return
        }
        navigate('/', { replace: true })
      }),
    )

    setIsLoading(false)
  }

  useEffect(() => {
    onLoad()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentSelectionBox>
      {voucher?.products?.map((p) => (
        <div key={p.id} className="title-container">
          <h5 className="title">{p?.name}</h5>

          <p className="description" dangerouslySetInnerHTML={{ __html: p?.description_html }} />

          {voucher?.license_valid_to && (
            <h5 className="subtitle">Valid till: {dayjs(voucher?.license_valid_to).format('LL')}</h5>
          )}
        </div>
      ))}

      <Button type="primary" size="large" onClick={commitSelection} loading={isLoading}>
        Confirm
      </Button>
    </ContentSelectionBox>
  )
}

export default ContentSelection
