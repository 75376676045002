import ThumbsUpIcon from '@/assets/images/emojis/thumbsup.png'
import ThumbsDownIcon from '@/assets/images/emojis/thumbsdown.png'
import HeartIcon from '@/assets/images/emojis/heart.png'
import EyesIcon from '@/assets/images/emojis/eyes.png'

// https://emojipedia.org/apple/ios-11.2
const activityReactions = [
  {
    emoji: ThumbsUpIcon,
    name: 'thumbsup',
  },
  {
    emoji: ThumbsDownIcon,
    name: 'thumbsdown',
  },
  {
    emoji: HeartIcon,
    name: 'heart',
  },
  {
    emoji: EyesIcon,
    name: 'eyes',
  },
]

export default activityReactions
