import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import BaseFormLayout from '@/layouts/BaseFormLayout'
import LoginForm from './components/LoginForm'
import ForgotPasswordForm from './components/ForgotPasswordForm'
import ResetPasswordForm from './components/ResetPasswordForm'

const LoginPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { isAuthenticated } = useSelector((state) => state.users)

  let [searchParams, setSearchParams] = useSearchParams()
  const [formMode, setFormMode] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const redirectTo = searchParams.get('redirect_to')

  const renderForm = () => {
    if (formMode === 'login') return <LoginForm />
    if (formMode === 'forgotPassword') return <ForgotPasswordForm />
    if (formMode === 'resetPassword') return <ResetPasswordForm />
  }

  useEffect(() => {
    if (redirectTo && isAuthenticated) {
      window.location.replace(redirectTo)
      return
    }

    if (isAuthenticated === false && isLoading) {
      setIsLoading(false)
    }

    if (isAuthenticated) {
      return navigate('/', { replace: true })
    }
  }, [isAuthenticated, redirectTo]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location?.pathname === '/login') setFormMode('login')
    if (location?.pathname === '/forgot-password') setFormMode('forgotPassword')
    if (location?.pathname === '/reset-password') setFormMode('resetPassword')
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  return <BaseFormLayout isLoading={isLoading}>{!isLoading && renderForm()}</BaseFormLayout>
}

export default LoginPage
