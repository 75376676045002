import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FadeIn from '@/components/FadeIn'
import Loading from './components/Loading'
import SkillTrackTableCard from './components/SkillTrackTableCard'
import SkillTrackModal from './components/SkillTrackModal'
import proficiencyLevels from '@/constants/proficiencyLevels'
import ProficiencyIcons from '@/constants/proficiencyIcons'
import { getLearningAreas } from '@/store/learningAreas/actions'
import { getSkillTracks, getSkillTracksStatus } from '@/store/skillTracks/actions'
import { Container, LearningAreaContainer } from './styles'

const CareerSkillTracksTable = () => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [selectedSkillTrack, selectSkillTrack] = useState(false)

  const { items: learningAreas } = useSelector((state) => state.learningAreas)
  const { items: skillTracks } = useSelector((state) => state.skillTracks)

  const handleInitialLoad = async () => {
    setIsLoading(true)
    await dispatch(getLearningAreas())
    await dispatch(getSkillTracks())
    setIsLoading(false)
  }

  const renderProficiencySkillTracks = (proficiency, learningArea, learningAreaSkillTracks) => {
    const filteredSkillTracks = learningAreaSkillTracks
      ?.filter((st) => st?.proficiency === proficiency?.code)
      ?.sort((st, st2) => st?.order - st2?.order)

    return filteredSkillTracks.map((st) => {
      const allowModal = st.status === 'draft' || (st.status === 'published' && st.user_status)

      return (
        <SkillTrackTableCard
          key={st?.id}
          skillTrack={st}
          color={learningArea?.color}
          onClick={allowModal ? () => selectSkillTrack(st) : () => {}}
        />
      )
    })
  }

  useEffect(() => {
    const _MISSING_STATUS_SKILL_TRACKS = skillTracks
      ?.filter((st) => st?.status === 'published' && !st?.user_status)
      ?.map((st) => st?.id?.split('-')?.[0])

    if (!skillTracks?.length || !_MISSING_STATUS_SKILL_TRACKS?.length) return

    dispatch(getSkillTracksStatus({ ids: _MISSING_STATUS_SKILL_TRACKS?.toString() }))
  }, [skillTracks]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleInitialLoad()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container id="career-skill-tracks">
      <div className="header">
        <div className="header-content">
          <h4 className="title">Browse all skill tracks</h4>

          <div className="proficiencies-header">
            {proficiencyLevels?.map((p, i) => (
              <div key={i} className="proficiency-item">
                <img src={ProficiencyIcons[p.code]} alt={p.name} />
                <p>{p.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="learning-areas-container">
        <div className="learning-areas-content">
          {isLoading ? (
            <Loading />
          ) : !skillTracks?.length ? (
            <FadeIn>
              <p>There are no available skill tracks yet.</p>
            </FadeIn>
          ) : (
            <>
              {learningAreas
                ?.filter((la) => {
                  const learningAreaSkillTracks = skillTracks.filter((st) => st?.learning_area?.id === la.id)
                  return !!learningAreaSkillTracks?.length
                })
                ?.map((la) => {
                  const learningAreaSkillTracks = skillTracks.filter((st) => st?.learning_area?.id === la.id)

                  return (
                    <FadeIn key={la.id}>
                      <LearningAreaContainer className="learning-area-container" color={la.color}>
                        <div className="info-container">
                          <p className="title">{la.name}</p>
                        </div>

                        <div className="skill-tracks-container">
                          {proficiencyLevels?.map((p, i) => (
                            <div key={i} className="proficiency-container">
                              {renderProficiencySkillTracks(p, la, learningAreaSkillTracks)}
                            </div>
                          ))}
                        </div>
                      </LearningAreaContainer>
                    </FadeIn>
                  )
                })}
            </>
          )}
        </div>
      </div>

      <SkillTrackModal selectedSkillTrack={selectedSkillTrack} onClose={() => selectSkillTrack(null)} />
    </Container>
  )
}

export default CareerSkillTracksTable
