import styled, { css } from 'styled-components'

export const Content = styled.div`
  background-color: ${(props) => props.theme.colors.geekblue1};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 14px;

  .icon-container {
    align-self: flex-start;

    svg {
      color: ${(props) => props.theme.colors.gray10};
      font-size: 20px;
      align-self: flex-start;
    }
  }

  .content {
    .title {
      margin: 0px;
    }

    .text {
      color: ${(props) => props.theme.colors.gray7};
      font-size: 12px;
      line-height: 20px;
      margin: 0px;
    }
  }

  .items-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .coming-soon-tag {
      background-color: ${(props) => props.theme.colors.geekblue3};
      color: ${(props) => props.theme.colors.geekblue6};
      font-size: 8px;
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      white-space: nowrap;
      padding: 2px 4px;
    }
  }

  ${(props) =>
    props.$disabled &&
    css`
      .title,
      .text {
        color: ${(props) => props.theme.colors.gray7};
      }

      .icon-container {
        svg {
          color: ${(props) => props.theme.colors.gray7};
        }
      }
    `}
`
