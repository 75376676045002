import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import JumpboxCard from '@/pages/PlaygroundsPage/components/JumpboxCard'
import { updateWizard } from '@/store/playgrounds/actions'
import { Container } from './styles'

const JumpboxSelection = ({ isCustomProject }) => {
  const dispatch = useDispatch()

  const { items: jumpboxes, isLoading } = useSelector((state) => state.jumpboxes)
  const [selected, setSelected] = useState(jumpboxes?.[0]?.id)

  const handleSelectPreJumpbox = (jumpbox) => {
    dispatch(updateWizard({ preJumpbox: jumpbox }))
    setSelected(jumpbox?.id)
  }

  useEffect(() => {
    handleSelectPreJumpbox(jumpboxes?.[0])
  }, [])

  return (
    <Container className="jumpbox-selection">
      <h3 className="title">Choose your {isCustomProject ? 'project' : 'playground'} environment</h3>

      {isLoading && (
        <div className="loading-container">
          <Spin />
          <h5>Loading {isCustomProject ? 'project' : 'playground'} environment...</h5>
        </div>
      )}

      {!isLoading && !jumpboxes?.length && <p>There are no environments yet.</p>}

      <div className="jumpboxes-container">
        {jumpboxes?.map((j) => (
          <div
            key={j?.id}
            className={`jumpbox-mask ${j?.id === selected ? 'selected' : ''}`}
            onClick={() => handleSelectPreJumpbox(j)}
          >
            <JumpboxCard key={j?.id} jumpbox={j} />
          </div>
        ))}
      </div>
    </Container>
  )
}

export default JumpboxSelection
