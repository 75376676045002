import { DataPie24Regular } from '@fluentui/react-icons'
import FadeIn from '@/components/FadeIn'
import DataSourcesSearch from '../DataSourcesSearch'
import { Container } from './styles'

const DataSourcesSearchTab = () => {
  return (
    <Container className="datasources-search-tab">
      <FadeIn>
        <div className="datasources-search-content">
          <div className="header">
            <h5 className="section-title">
              <DataPie24Regular />
              Browse datasets
            </h5>
          </div>

          <div className="datasources-search-body">
            <DataSourcesSearch />
          </div>
        </div>
      </FadeIn>
    </Container>
  )
}

export default DataSourcesSearchTab
