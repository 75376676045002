import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: null,
  userProfile: null,
  stats: null,
  billingUrl: null,
  publicProfileCompletionPerc: null,
  isAuthModalOpen: false,
  isPublicProfileModalOpen: false,
  isEmailVerificationModalOpen: false,
  emailValidationCodeIsSent: null,
  emailValidationCodeIsResent: null,
  emailValidationCodeIsValidated: null,
  emailValidationCodeError: null,
  isLoading: false,
  isStatsLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    SET_USERS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    SET_USER_STATS_LOADING: (state, { payload }) => {
      state.isStatsLoading = payload
    },
    USER_AUTHENTICATION_UPDATED: (state, { payload }) => {
      state.isAuthenticated = payload
    },
    USER_PROFILE_UPDATED: (state, { payload }) => {
      const auxPayload = { ...payload }

      state.userProfile = auxPayload
      state.isAuthenticated = !!payload

      const steps = ['first_name', 'last_name', 'declared_location', 'company', 'role', 'bio', 'linkedin_url']
      const completedSteps = steps?.map((s) => (state.userProfile[s] && state.userProfile[s] !== '' ? true : false))
      const completedPerc = Math.round((completedSteps?.filter((s) => s)?.length / steps?.length) * 100, 2)
      state.publicProfileCompletionPerc = completedPerc
    },
    USER_STATS_UPDATED: (state, { payload }) => {
      state.stats = payload
      state.isStatsLoading = false
    },
    USER_EMAIL_VALIDATION_UPDATED: (state, { payload }) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key]
      })
    },
    USER_BILLING_URL_UPDATED: (state, { payload }) => {
      state.billingUrl = payload
    },
    TOGGLE_AUTH_MODAL: (state, { payload }) => {
      state.isAuthModalOpen = payload
    },
    TOGGLE_PUBLIC_PROFILE_MODAL: (state, { payload }) => {
      state.isPublicProfileModalOpen = payload
    },
    TOGGLE_EMAIL_VERIFICATION_MODAL: (state, { payload }) => {
      state.isEmailVerificationModalOpen = payload
    },
    SET_USERS_ERROR: (state, { payload }) => {
      state.error = payload
      state.isStatsLoading = false
    },
    CLEAR_ERRORS: (state) => {
      state.error = null
      state.emailValidationCodeError = null
    },
    RESET_USERS_STATE: () => initialState,
  },
})

export const {
  SET_USERS_LOADING,
  SET_USER_STATS_LOADING,
  USER_AUTHENTICATION_UPDATED,
  USER_PROFILE_UPDATED,
  USER_STATS_UPDATED,
  USER_EMAIL_VALIDATION_UPDATED,
  USER_BILLING_URL_UPDATED,
  TOGGLE_AUTH_MODAL,
  TOGGLE_PUBLIC_PROFILE_MODAL,
  TOGGLE_EMAIL_VERIFICATION_MODAL,
  SET_USERS_ERROR,
  CLEAR_ERRORS,
  RESET_USERS_STATE,
} = slice.actions
export default slice.reducer
