import ProficiencyIcon1 from '@/assets/images/badges/proficiency/1.svg'
import ProficiencyIcon2 from '@/assets/images/badges/proficiency/2.svg'
import ProficiencyIcon3 from '@/assets/images/badges/proficiency/3.svg'
import ProficiencyIcon4 from '@/assets/images/badges/proficiency/4.svg'
import ProficiencyIcon5 from '@/assets/images/badges/proficiency/5.svg'

const ProficiencyIcons = {
  novice: ProficiencyIcon1,
  intermediate: ProficiencyIcon2,
  advanced: ProficiencyIcon3,
  professional: ProficiencyIcon4,
  expert: ProficiencyIcon5,
}

export default ProficiencyIcons
