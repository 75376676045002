import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  max-width: 760px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: ${(props) => props.theme.colors.gray10};
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0px 20px;
  }

  .loading-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 20px 0px;
  }

  .jumpboxes-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 28px 10px;
    overflow: auto;
    margin-top: 40px;

    .jumpbox-mask {
      width: calc(50% - 25px);
      border-radius: 11px;
      padding: 0px 10px;

      &:hover {
        .jumpbox-card-container {
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
        }
      }

      &.selected {
        .jumpbox-card-container {
          box-shadow: inset 0px 0px 0px 2px ${(props) => props.theme.colors.geekblue4};
        }
      }
    }

    .jumpbox-card-container {
      background-color: ${(props) => props.theme.colors.gray1};
      cursor: pointer;

      .libs-box {
        .lib {
          height: 15px;
        }
      }
    }
  }

  @media ${device.lgDown} {
    .jumpboxes-container {
      .jumpbox-mask {
        max-width: 350px;
        width: 100%;

        .jumpbox-card-container {
          min-height: 166px;

          .description {
            display: block;
          }
        }
      }
    }
  }

  @media ${device.mdDown} {
    > .title {
      font-size: 22px;
      line-height: 26px;
      padding: 0px;
    }

    .jumpboxes-container {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      gap: 10px;
      margin-top: 20px;
    }
  }
`
