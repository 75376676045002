import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: inline-block;

  > span {
    display: flex;
    align-items: center;

    .name-container {
      display: flex;
      flex-direction: column;

      .name {
        color: ${(props) => props.theme.colors.gray10};
      }

      .email {
        font-size: 13px;
        line-height: 16px;
        opacity: 0.7;
      }
    }

    ${(props) =>
      !props.$showName &&
      css`
        .profile-picture {
          margin-right: 4px;
        }
      `}
  }
`
