import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 0px;
  right: 0px;
  overflow: hidden;

  .reactions-container {
    height: 36px;
    min-width: 36px;
    background-color: ${(props) => props.theme.colors.geekblue1};
    border: 1px solid ${(props) => props.theme.colors.gray5};
    font-size: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px;

    .reaction-emoji {
      width: 18px;
      height: 18px;
      image-rendering: auto;
      pointer-events: initial;
      margin: 0px;
    }
  }
`
