import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Segmented, Spin } from 'antd'
import searchParameters from '../../../searchParameters'
import { getModules, setCatalogIsLoading } from '@/store/modules/actions'
import { Container } from './styles'

const ModuleUserStatusFilter = ({ indexUiState, refine, setPage }) => {
  const dispatch = useDispatch()

  const { isCatalogLoading } = useSelector((state) => state.modules)

  const [moduleStatus, setModuleStatus] = useState('all')

  const currentSearchParameters = indexUiState?.configure

  const moduleStatusOptions = [
    { label: 'All', value: 'all' },
    { label: 'Finished', value: 'finished' },
    { label: 'In progress', value: 'in_progress' },
  ]

  const getAlgoliaData = (modules) => {
    const moduleIds = modules?.map((module) => `objectID:${module?.id}`)?.join(' OR ')

    dispatch(setCatalogIsLoading(true))
    refine({
      ...currentSearchParameters,
      page: 0,
      filters: `${searchParameters?.filters} AND (${moduleIds || 'objectID:no-results'})`,
    })
    setPage(1)
  }

  useEffect(() => {
    if (!searchParameters) return
    dispatch(setCatalogIsLoading(true))

    if (moduleStatus === 'all') {
      refine({
        ...currentSearchParameters,
        page: 0,
        filters: `${searchParameters?.filters}`,
      })
      setPage(1)

      return
    }

    if (['in_progress', 'finished'].includes(moduleStatus)) {
      dispatch(getModules({ [moduleStatus]: true }, getAlgoliaData))
    }
  }, [moduleStatus])

  return (
    <Container>
      <Segmented
        options={moduleStatusOptions}
        value={moduleStatus}
        onChange={setModuleStatus}
        disabled={isCatalogLoading}
      />
      {isCatalogLoading && <Spin />}
    </Container>
  )
}

export default ModuleUserStatusFilter
