import styled from 'styled-components'
import { Dropdown as AntDropdown } from 'antd'

export const Dropdown = styled(AntDropdown)`
  &.device-selector-btn {
    background-color: ${(props) =>
      localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray4 : props.theme.colors.blue1} !important;
    color: ${(props) => props.theme.colors.gray10};
    border: none;
  }
`

export const MenuItem = styled.div`
  .ant-dropdown-menu-item {
    padding: 0px;
  }

  .device-menu-item {
    min-width: 200px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
    padding: 4px 0px;

    .icon {
      width: 24px;
    }

    .device-info {
      width: 100%;

      .device-name {
        font-weight: 700;
        line-height: 16px;
        margin: 0px;

        .status {
          width: 10px;
          height: 10px;
          display: inline-block;
          border-radius: 50%;
          margin-left: 4px;

          &.started {
            background-color: #52c41a;
          }

          &.starting,
          &.stopping,
          &.restarting {
            background-color: #faad14;
          }

          &.stopped {
            background-color: #ff4d4f;
          }
        }
      }

      .device-ip {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        display: inline-block;
        margin: 0px;
        cursor: pointer;
      }
    }

    .device-actions {
      display: flex;
      gap: 4px;

      > .ant-btn {
        &.ant-btn-primary {
          &.ant-btn-circle {
            color: ${(props) => props.theme.colors.blue6};
          }
        }
      }
    }
  }

  &.is-selected {
  }

  &.is-last {
    .device-menu-item {
      border: none;
    }
  }
`
