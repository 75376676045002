import styled from 'styled-components'

export const MenuContainer = styled.div`
  max-width: 210px;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.geekblue1 : props.theme.colors.gray1};
  position: relative;
  box-shadow: 0px 2px 8px 0px
    ${(props) => (localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray7 : props.theme.colors.gray13)}26;
  z-index: 2;

  .ant-menu {
    border-inline-end: none !important;

    .ant-menu-item {
      .ant-menu-item-icon {
        path {
          fill: ${(props) => props.theme.colors.text};
        }
      }

      &.ant-menu-item-selected {
        .ant-menu-item-icon {
          path {
            fill: ${(props) =>
              localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray13 : props.theme.colors.blue6};
          }
        }
      }

      &.ant-menu-item-disabled {
        .ant-menu-item-icon {
          path {
            fill: ${(props) => props.theme.colors.disabledText};
          }
        }
      }
    }
  }
`
