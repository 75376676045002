import { useMemo } from 'react'
import parse from 'html-react-parser'
import { Image } from 'antd'
import {
  Info24Regular,
  Lightbulb24Regular,
  ShieldError24Regular,
  Warning24Regular,
  ErrorCircle24Regular,
} from '@fluentui/react-icons'
import CodeBlock from '@/components/CodeBlock'
import CustomReplacement from '../CustomReplacement'
import HTMLBlock from '@/components/HTMLBlock'

const getIconType = (type) => {
  switch (type) {
    case 'note':
      return <Info24Regular />
    case 'tip':
      return <Lightbulb24Regular />
    case 'important':
      return <ShieldError24Regular />
    case 'warning':
      return <Warning24Regular />
    case 'caution':
      return <ErrorCircle24Regular />
    default:
      return <></>
  }
}

const Replacement = ({ contentHtml, customClass }) => {
  let parsed

  if (!contentHtml) return <div className={customClass} />

  // eslint-disable-next-line react-hooks/rules-of-hooks
  parsed = useMemo(
    () =>
      parse(contentHtml, {
        trim: true,
        replace: (domNode) => {
          if (domNode.type === 'tag' && domNode.name === 'a') {
            if (domNode?.attribs) {
              domNode.attribs['target'] = '_blank'
            }
            return domNode
          }

          // block replacements
          if (domNode.type === 'tag' && domNode.name === 'p' && domNode?.children?.length === 1) {
            const childNode = domNode.children[0]

            // custom image
            if (childNode.type === 'tag' && childNode.name === 'img') {
              return (
                <Image
                  src={childNode.attribs.src}
                  preview={{
                    src: childNode.attribs.src,
                  }}
                />
              )
            }

            // custom replacements
            if (childNode.type === 'text') {
              const text = childNode.data
              // searches for "{{...}}" patterns
              const regExp = /{{([^{}]*?)}}/g
              const matches = text.match(regExp)

              if (matches?.length) {
                return <CustomReplacement matches={matches} inputText={text} />
              }
            }
          }

          // custom code component replacements
          if (
            domNode.type === 'tag' &&
            domNode.name === 'pre' &&
            domNode?.children?.[0]?.attribs?.class?.includes('language-')
          ) {
            const code = domNode?.children?.[0]?.children?.[0]?.data
            const language = domNode?.children?.[0]?.attribs?.class?.split('-')?.[1]

            // searches for "{{...}}" patterns
            const regExp = /{{([^{}]*?)}}/g
            const matches = code.match(regExp)

            if (matches?.length) {
              return (
                <CodeBlock language={language}>
                  <CustomReplacement matches={matches} inputText={code} />
                </CodeBlock>
              )
            }
          }

          // custom blockquote replacements
          if (domNode.type === 'tag' && domNode.name === 'blockquote') {
            const typeData = domNode?.children?.[1]?.children?.[0]?.data

            // searches for "[!...]" patterns
            const regExp = /\[!([^[]*?)\]/g
            const matches = typeData?.match(regExp)

            if (matches?.length) {
              const type = matches?.[0]?.replace('[!', '')?.replace(']', '')?.toLowerCase()
              const capitalizedType = type?.charAt(0)?.toUpperCase() + type?.slice(1)
              const content = typeData?.replace(regExp, '')

              return (
                <blockquote className={`blockquote-${type}`}>
                  <p className="pre-content">
                    {getIconType(type)} {capitalizedType}
                  </p>

                  <p>{content}</p>
                </blockquote>
              )
            }
          }

          // inline replacements
          if (domNode.type === 'text') {
            const text = domNode.data
            // searches for "{{...}}" patterns
            const regExp = /{{([^{}]*?)}}/g
            const matches = text.match(regExp)

            if (matches?.length) {
              return <CustomReplacement matches={matches} inputText={text} />
            }
          }
        },
      }),
    [contentHtml],
  )

  return (
    <div className={customClass}>
      <HTMLBlock content={parsed} />
    </div>
  )
}

export default Replacement
