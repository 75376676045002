import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import prism from '@/utils/prism'

const CodeBlock = ({ language, children }) => {
  const [isLoading, setIsLoading] = useState(true)

  const { userProfile } = useSelector((state) => state.users)
  const { currentModule: module } = useSelector((state) => state.modules)
  const { currentPlayground: playground } = useSelector((state) => state.playgrounds)
  const { currentDataSource: dataSource } = useSelector((state) => state.dataSources)
  const { currentLab: lab, currentDevice: device, currentView } = useSelector((state) => state.labs)

  useEffect(() => {
    setIsLoading(true)

    setTimeout(() => {
      setIsLoading(false)

      // execute when DOM is ready
      setTimeout(() => prism.highlightAll(), 0)
    }, 0)
  }, [userProfile, module, playground, dataSource, lab, device, currentView])

  return (
    <pre className={`language-${language}`}>
      {isLoading ? <></> : <code className={`language-${language}`}>{children}</code>}
    </pre>
  )
}

export default CodeBlock
