import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Badge, Tabs, Tooltip } from 'antd'
import { CloseOutlined, FormOutlined } from '@ant-design/icons'
import ModuleTab from './components/ModuleTab'
import LabDebugTab from './components/LabDebugTab'
import { getModuleToolbar } from '@/store/modules/actions'
import { fetchLabDebug } from '@/store/labs/actions'
import { Drawer, OpenButton } from './styles'

const ModuleAdminToolbar = () => {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [tab, changeTab] = useState('module')

  const { currentModule, toolbar } = useSelector((state) => state.modules)
  const { currentLab } = useSelector((state) => state.labs)
  const { userProfile } = useSelector((state) => state.users)

  const shouldShowContentToolbar = userProfile?.permissions?.includes('content.view_content_toolbar')
  const canDebugLab = userProfile?.permissions?.includes('labs.debug_lab')

  const tabItems = [
    {
      key: 'module',
      label: 'Project',
      children: <ModuleTab />,
    },
    ...(canDebugLab
      ? [
          {
            key: 'lab',
            label: 'Lab debug',
            children: <LabDebugTab />,
          },
        ]
      : []),
  ]

  const handleChangeTab = (key) => {
    changeTab(key)
  }

  useEffect(() => {
    if (!shouldShowContentToolbar || !currentModule?.id || !userProfile) return

    dispatch(getModuleToolbar(currentModule?.id))
  }, [currentModule?.id, userProfile]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!canDebugLab || !currentLab || !currentModule?.id || !userProfile || !open) return

    dispatch(fetchLabDebug(currentModule?.lab_id, userProfile?.id))
  }, [currentLab, currentModule?.id, userProfile, open]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!shouldShowContentToolbar) return <></>

  return (
    <>
      <OpenButton>
        <Tooltip title={'Open Admin Settings'} placement={'left'}>
          <Badge count={toolbar?.errors?.length} offset={[-40, 0]}>
            <Button
              className="open-admin-settings-button"
              type="primary"
              size={'large'}
              icon={<FormOutlined />}
              onClick={() => setOpen(true)}
              loading={!toolbar}
            />
          </Badge>
        </Tooltip>
      </OpenButton>

      <Drawer
        width="700px"
        title="Admin Settings"
        placement={'right'}
        closeIcon={<CloseOutlined />}
        onClose={() => setOpen(false)}
        open={open}
        key={'admin-settings'}
      >
        <Tabs defaultActiveKey={tab} items={tabItems} onChange={handleChangeTab} disabled={true} />
      </Drawer>
    </>
  )
}

export default ModuleAdminToolbar
