import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    padding: 34px 42px;

    .ant-modal-body {
      display: flex;
      justify-content: center;

      .content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;

        .header {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 18px;

          .icon {
            font-size: 68px;

            svg path {
              fill: ${(props) => props.theme.colors.gold5};
            }
          }

          .title {
            max-width: 360px;
            color: ${(props) => props.theme.colors.gray11};
            font-size: 20px;
            line-height: 28px;
            margin: 0px;
          }
        }

        .actions {
          display: flex;
          gap: 12px;
          margin-top: 30px;

          .cta-button {
          }
        }
      }
    }
  }
`
