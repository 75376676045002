import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    padding: 34px 42px;

    .ant-modal-body {
      display: flex;
      justify-content: center;

      .content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;

        .header {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 18px;

          .icon {
            font-size: 68px;

            svg path {
              fill: ${(props) => props.theme.colors.gold5};
            }
          }

          .title {
            max-width: 360px;
            color: ${(props) => props.theme.colors.gray11};
            font-size: 20px;
            line-height: 28px;
            margin: 0px;
          }

          .pro-info {
            width: calc(100% - 20px);
            background: linear-gradient(
              87deg,
              ${(props) => props.theme.colors.base.gray10} 20%,
              ${(props) => props.theme.colors.base.blue5} 130%
            );
            display: flex;
            align-items: center;
            gap: 6px;
            border-radius: 2px;
            padding: 8px 12px;

            .pro-badge {
              width: 17px;
            }

            .pro-simple-text {
              color: ${(props) => props.theme.colors.gray3};
              font-size: 13px;
              margin: 0px;

              .upgrade-link {
                color: ${(props) => props.theme.colors.gray3};
                font-weight: 500;
                white-space: nowrap;
              }
            }
          }
        }

        .actions {
          display: flex;
          gap: 12px;
          margin-top: 30px;

          .cta-button {
          }
        }
      }
    }
  }
`
