import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tag } from 'antd'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import SkillBullet from '@/pages/SkillTrackPage/components/SkillBullet'
import SkillTrackCard from '@/pages/DashboardPage/components/SkillTrackCard'
import skillTrackComingSoonImg from '@/assets/images/skill-track-coming-soon.png'
import { getSkillTrack, createSkillTrackUpvote } from '@/store/skillTracks/actions'
import { Container } from './styles'

const SkillTrackModal = ({ selectedSkillTrack, onClose }) => {
  const dispatch = useDispatch()

  const { currentSkillTrack: extraSkillTrackData } = useSelector((state) => state.skillTracks)

  const [skillTrack, setSkillTrack] = useState({ ...selectedSkillTrack })
  const [isLoading, setIsLoading] = useState(false)
  const [isUpvoted, setIsUpvoted] = useState(false)

  const isDraft = skillTrack?.status === 'draft'

  const sendUpvote = async () => {
    setIsLoading(true)
    await dispatch(createSkillTrackUpvote(skillTrack?.id))
    setIsLoading(false)
    setIsUpvoted(true)
    showToast('Thanks! We will keep you posted')
  }

  useEffect(() => {
    setSkillTrack(selectedSkillTrack)
    if (!selectedSkillTrack || selectedSkillTrack?.skills?.find((s) => s?.user_status)) return
    dispatch(getSkillTrack(selectedSkillTrack?.id))
  }, [selectedSkillTrack]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (skillTrack?.id !== extraSkillTrackData?.id) return
    setSkillTrack(extraSkillTrackData)
  }, [extraSkillTrackData]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!skillTrack) return <></>

  return (
    <Container
      open={!!selectedSkillTrack}
      onCancel={() => {
        onClose()
        setIsUpvoted(false)
      }}
      footer={null}
      width={620}
      className="no-padding"
      centered
      destroyOnClose
    >
      <SkillTrackCard skillTrack={skillTrack} disableClick />

      <div className="content">
        {!isDraft ? (
          !!skillTrack?.skills?.length && (
            <div className="skills-info">
              {skillTrack?.skills?.map((s) => (
                <div className="skill-row" key={s.id}>
                  <SkillBullet skill={s} />
                  <p className={`skill-name ${s?.status === 'coming_soon' && 'coming-soon'}`}>{s.name}</p>
                </div>
              ))}
            </div>
          )
        ) : (
          <div className="draft-info">
            <p className="title">This track is in the queue to be developed later this year.</p>
            <p className="text">
              If you are interested in it, please give a thumbs up below to promote its development. Tracks with higher
              interest from learners will be developed sooner.
            </p>

            {isUpvoted ? (
              <p className="title">Thanks! We will keep you posted 😉</p>
            ) : (
              <Button type="primary" loading={isLoading} onClick={sendUpvote}>
                👍 I'm interested
              </Button>
            )}
          </div>
        )}

        <div className="skill-track-details">
          {!isDraft ? (
            <>
              <div className="skill-track-info">
                <Tag className="tag">Total projects: {skillTrack?.total_modules}</Tag>

                <p className="description">{skillTrack?.description}</p>
              </div>

              <Link to={`/skill-track/${skillTrack?.id}`}>
                <Button type="primary">Explore track</Button>
              </Link>
            </>
          ) : (
            <img src={skillTrackComingSoonImg} alt={skillTrack?.name} />
          )}
        </div>
      </div>
    </Container>
  )
}

export default SkillTrackModal
