import Replacement from '../Replacement'
import { Container } from './styles'

const ModulePageContent = ({ pageContent, showTitle }) => {
  const contentHtml = pageContent?.content_html

  return (
    <Container id="module-page-content-container">
      {showTitle && <h4 className="title">{pageContent.name}</h4>}

      <div className="module-content">
        <Replacement contentHtml={contentHtml} />
      </div>
    </Container>
  )
}

export default ModulePageContent
