import { useSelector } from 'react-redux'
import { InstantSearch, Configure, SearchBox, Breadcrumb } from 'react-instantsearch'
import { SearchOutlined } from '@ant-design/icons'
import { algoliaSearchClient } from '@/utils/algolia'
import SearchResults from './components/SearchResults'
import Filters from './components/Filters'
import TaskFilter from './components/TaskFilter'
import searchParameters from './searchParameters'
import { Container } from './styles'

const DataSourcesSearch = () => {
  const { userProfile } = useSelector((state) => state.users)
  const canViewDraftDataSources = userProfile?.permissions?.includes('playgrounds.view_draft_data_source')

  const auxSearchParams = {
    ...searchParameters,
    filters: canViewDraftDataSources ? '' : "status:'published'",
  }

  return (
    <InstantSearch
      searchClient={algoliaSearchClient}
      indexName={import.meta.env.REACT_APP_ALGOLIA_DATASOURCES_INDEX}
      // FIXME: be ready for next Algolia update
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure {...auxSearchParams} />

      <Container className="datasources-search-container">
        <div className="main-content">
          <div className="search-box">
            <SearchOutlined />
            <SearchBox placeholder="Search dataset..." />
          </div>

          <div className="results">
            <Breadcrumb
              attributes={['tasks.lvl0', 'tasks.lvl1', 'tasks.lvl2']}
              translations={{
                rootElementText: 'All tasks',
              }}
            />

            <SearchResults />
          </div>
        </div>

        <div className="filters-container">
          <TaskFilter />
          <Filters />
        </div>
      </Container>
    </InstantSearch>
  )
}

export default DataSourcesSearch
