import VerticalModuleCard from '../VerticalModuleCard'
import { Container } from './styles'

const RelatedModulesContainerLoading = () => {
  return (
    <Container>
      <div className="row">
        <VerticalModuleCard isLoading />
        <VerticalModuleCard isLoading />
        <VerticalModuleCard isLoading />
      </div>
    </Container>
  )
}

export default RelatedModulesContainerLoading
