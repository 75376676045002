import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  max-width: 720px;
  width: 100%;

  > .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;

    > .header {
      background-color: ${(props) => props.theme.colors.gray1};
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 22px 24px;

      .image-container {
        min-width: 80px;
        min-height: 80px;
        background: linear-gradient(
          60deg,
          ${(props) => props.theme.colors.base.gray8},
          ${(props) => props.theme.colors.base.cyan7},
          ${(props) => props.theme.colors.base.cyan4}
        );
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        transition: 0.3s all;

        .main-icon {
          width: 32px;
          height: 32px;
          color: ${(props) => props.theme.colors.base.gray1};
          font-size: 32px;
        }

        ${(props) =>
          props.$playgroundBackgroundImage &&
          css`
            background-image: url(${(props) => props.$playgroundBackgroundImage});
            background-size: cover;
            background-position: center;
            object-fit: cover;

            .main-icon {
              display: none;
            }
          `}

        &:hover {
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);

          .upload-playground-image-button {
            display: flex;
          }
        }
      }

      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .title-container {
          display: flex;
          align-items: center;
          gap: 6px;

          .icon {
            min-width: 20px;
            color: ${(props) => props.theme.colors.geekblue5};
          }

          .title-row {
            width: 100%;

            .title,
            .easy-edit-wrapper,
            .easy-edit-wrapper * {
              color: ${(props) => props.theme.colors.text};
              font-size: 20px;
              line-height: 28px;
              font-weight: 700;
              margin: 0px;
            }

            .easy-edit-inline-wrapper {
              input {
                font-size: 20px;
                font-weight: 700;
              }
            }
          }
        }

        .description-container {
          .description-row {
            .description,
            .easy-edit-wrapper,
            .easy-edit-wrapper * {
              color: ${(props) => props.theme.colors.gray8};
              font-size: 14px;
              line-height: 22px;
              white-space: pre-wrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              margin: 0px;
            }

            .easy-edit-inline-wrapper {
              gap: 12px;

              textarea {
                min-height: 32px;
              }
            }
          }
        }
      }
    }

    .configuration {
      background-color: ${(props) => props.theme.colors.gray1};
      display: flex;
      justify-content: space-between;
      gap: 12px;
      overflow: auto;
      padding: 22px 24px;

      .section-title {
        font-weight: 500;
        margin-bottom: 12px;
      }

      .playground-overview {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .overview-section {
          .jumpbox-card-container {
            height: initial;
            min-height: initial;
          }

          .datasource-card-container {
            min-height: 102px;
            border: 1px solid ${(props) => props.theme.colors.geekblue2};
            cursor: default;
            padding: 12px 18px;

            &:hover {
              box-shadow: none;
            }

            .icon-container {
              min-width: 42px;
              width: 42px;
              min-height: 42px;
              height: 42px;
              align-self: flex-start;
              margin-top: 4px;

              .theme-icon {
                width: 24px;
                height: 24px;
                font-size: 24px;
              }
            }
          }

          .no-data-source-content {
            border-radius: 11px;
            border: 1px solid ${(props) => props.theme.colors.geekblue2};
            display: flex;
            align-items: center;
            gap: 14px;
            padding: 12px 18px;

            .image {
              width: 78px;
              height: 78px;
              opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.8 : 1)};
            }

            .title {
              margin: 0px;
            }

            .description {
              color: ${(props) => props.theme.colors.gray8};
            }
          }
        }
      }

      .playground-settings {
        width: 50%;

        .settings-container {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }
    }
  }

  @media ${device.mdDown} {
    > .content {
      > .header {
        .image-container {
          min-width: 54px;
          max-width: 54px;
          min-height: 54px;
          max-height: 54px;

          .main-icon {
            width: 24px;
            height: 24px;
            font-size: 24px;
          }
        }
      }

      .configuration {
        flex-direction: column;
        align-items: center;
        padding: 20px;

        .playground-overview,
        .playground-settings {
          width: 100%;
        }
      }
    }
  }
`
