import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .ant-segmented-item-label {
    padding: 0 8px;
  }
`
