import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const getInvite = async (inviteId) => {
  const route = `invites/${inviteId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postInviteAccept = async (inviteId) => {
  const route = `invites/${inviteId}/accept`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { getInvite, postInviteAccept }
