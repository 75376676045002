import styled from 'styled-components'

export const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.gray4};
  padding-top: 16px;
  margin-top: 28px;

  .title {
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 6px;
    transition: 0.2s all;

    > span {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray10};
    }

    &:hover {
      color: ${(props) => props.theme.colors.gray8};

      > span {
        border-bottom: 1px solid ${(props) => props.theme.colors.gray8};
      }
    }
  }

  .text {
    color: ${(props) => props.theme.colors.gray8};
    font-size: 11px;
    line-height: 18px;
    margin: 0px;
  }
`
