import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  featuredDataSources: null,
  relatedDataSources: null,
  currentDataSource: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'dataSources',
  initialState,
  reducers: {
    SET_DATA_SOURCES_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    FEATURED_DATA_SOURCES_UPDATED: (state, { payload }) => {
      state.featuredDataSources = payload
      state.error = null
    },
    RELATED_DATA_SOURCES_UPDATED: (state, { payload }) => {
      state.relatedDataSources = payload
      state.error = null
    },
    DATA_SOURCE_UPDATED: (state, { payload }) => {
      state.currentDataSource = payload
      state.error = null
    },
    RESET_DATA_SOURCES_STATE: () => initialState,
    SET_DATA_SOURCES_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
  },
})

export const {
  SET_DATA_SOURCES_LOADING,
  FEATURED_DATA_SOURCES_UPDATED,
  RELATED_DATA_SOURCES_UPDATED,
  DATA_SOURCE_UPDATED,
  RESET_DATA_SOURCES_STATE,
  SET_DATA_SOURCES_ERROR,
} = slice.actions
export default slice.reducer
