import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: [],
  recommendedSkillTracks: [],
  inProgressSkillTracks: [],
  currentSkillTrack: null,
  isLoading: false,
  isCurrentSkillTrackLoading: false,
  isRecommendedSkillTracksLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'skillTracks',
  initialState,
  reducers: {
    SET_SKILL_TRACKS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    SET_CURRENT_SKILL_TRACK_LOADING: (state, { payload }) => {
      state.isCurrentSkillTrackLoading = payload
    },
    SET_RECOMMENDED_SKILL_TRACKS_LOADING: (state, { payload }) => {
      state.isRecommendedSkillTracksLoading = payload
    },
    SKILL_TRACKS_UPDATED: (state, { payload }) => {
      state.items = payload
      state.isLoading = false
      state.error = null
    },
    RECOMMENDED_SKILL_TRACKS_UPDATED: (state, { payload }) => {
      state.recommendedSkillTracks = payload
      state.isRecommendedSkillTracksLoading = false
      state.error = null
    },
    IN_PROGRESS_SKILL_TRACKS_UPDATED: (state, { payload }) => {
      state.inProgressSkillTracks = payload
      state.isRecommendedSkillTracksLoading = false
      state.error = null
    },
    SKILL_TRACK_UPDATED: (state, { payload }) => {
      state.currentSkillTrack = payload
      state.isCurrentSkillTrackLoading = false
      state.error = null
    },
    RESET_SKILL_TRACKS_STATE: () => initialState,
    SET_SKILL_TRACK_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
      state.isCurrentSkillTrackLoading = false
      state.isRecommendedSkillTracksLoading = false
    },
  },
})

export const {
  SET_SKILL_TRACKS_LOADING,
  SET_CURRENT_SKILL_TRACK_LOADING,
  SET_RECOMMENDED_SKILL_TRACKS_LOADING,
  SKILL_TRACKS_UPDATED,
  RECOMMENDED_SKILL_TRACKS_UPDATED,
  IN_PROGRESS_SKILL_TRACKS_UPDATED,
  SKILL_TRACK_UPDATED,
  RESET_SKILL_TRACKS_STATE,
  SET_SKILL_TRACK_ERROR,
} = slice.actions
export default slice.reducer
