import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Input, Rate } from 'antd'
import Button from '@/components/Button'
import { toggleFeedbackModal } from '@/store/playgrounds/actions'
import { createUserFeedback } from '@/store/users/actions'
import Camping from '@/assets/images/camping.gif'
import { Modal } from './styles'

const ReportIssueModal = () => {
  const dispatch = useDispatch()

  const { isFeedbackModalOpen } = useSelector((state) => state.playgrounds)

  const [animationKey, setAnimationKey] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [rating, setRating] = useState()
  const [comments, setComments] = useState()
  const [isSent, setIsSent] = useState(false)

  const handleClose = () => {
    setRating(null)
    setComments(null)

    dispatch(toggleFeedbackModal(false))
  }

  const handleSubmit = async () => {
    const data = {
      content_type: 'playgrounds',
      rating,
      comments,
    }

    setIsLoading(true)
    const res = await dispatch(createUserFeedback(data))

    if (res.id) {
      setIsSent(true)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    setAnimationKey(Math.random())
  }, [])

  return (
    <Modal
      open={isFeedbackModalOpen}
      onCancel={handleClose}
      footer={null}
      destroyOnClose
      className="playground-feedback-modal"
    >
      <div key={animationKey} className="rate-content">
        {!isSent ? (
          <>
            <h4 className="title">Rate your experience using Playgrounds</h4>

            <Rate className="rate" value={rating} onChange={setRating} disabled={isLoading} />

            <div className="comments">
              <Input.TextArea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                placeholder="Share your thoughts"
                autoSize={{ minRows: 4, maxRows: 4 }}
                disabled={isLoading}
              />
            </div>

            <div className="actions">
              <Button
                type="secondary"
                onClick={handleSubmit}
                loading={isLoading}
                disabled={!comments || !rating || isLoading}
              >
                Send
              </Button>
            </div>
          </>
        ) : (
          <>
            <img className="sent-image" src={Camping} alt="Thank you for providing your feedback" />
            <h5 className="title">Thank you for providing your feedback</h5>
            <p className="text" style={{ marginBottom: 16 }}>
              Your input helps us improve DataWars.
            </p>

            <Button type="secondary" onClick={handleClose}>
              Continue
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ReportIssueModal
