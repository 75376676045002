import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  display: flex;
  justify-content: center;

  > .content {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px 90px;
  }

  .pre-title {
    color: ${(props) => props.theme.colors.gray8};
    margin: 0px 0px 12px;
  }

  .skill-track-content {
    background-color: ${(props) => props.theme.colors.geekblue1};
    display: flex;
    border-radius: 8px;
    padding: 4px;

    .skills-container {
      height: calc(100vh - 376px);
      max-width: 380px;
      min-width: 380px;
      background-color: ${(props) => props.theme.colors.gray1};
      border-radius: 8px;
      overflow: auto;
      padding: 10px;

      .skill-row {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        overflow: hidden;
        border-radius: 8px;
        padding: 10px;
        margin-bottom: 4px;
        transition: 0.3s all;
        cursor: pointer;

        .skill-name {
          width: 100%;
          font-weight: 500;
          line-height: 20px;
          margin: 0px;
        }

        .select-btn {
          min-width: 30px;
          width: 30px;
          height: 30px;
          background-color: ${(props) => props.theme.colors.gray1};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transform: translateX(-42px);
          transition: 0.3s all;

          svg {
            color: ${(props) => props.theme.colors.geekblue4};
          }
        }

        &.selected,
        &:hover {
          background-color: ${(props) => props.theme.colors.geekblue1};

          .select-btn {
            opacity: 1;
            transform: translateX(0px);
          }
        }

        &.coming-soon {
          cursor: not-allowed;

          .skill-name {
            color: ${(props) => props.theme.colors.gray7};
          }

          &:hover {
            background-color: transparent;

            .select-btn {
              display: none;
            }
          }
        }
      }
    }

    .skill-content {
      height: calc(100vh - 376px);
      width: 100%;
      overflow: auto;
      padding: 20px;

      .skill-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .mobile-menu {
          display: none;
          cursor: pointer;

          > span {
            display: flex;
            align-items: center;
            gap: 12px;

            svg {
              color: ${(props) => props.theme.colors.gray7};
              font-size: 18px;
              transition: 0.3s all;
            }

            &:hover {
              svg {
                color: ${(props) => props.theme.colors.gray8};
                transform: translateY(2px);
              }
            }
          }
        }

        .right-section {
          display: flex;
          align-items: center;
          gap: 6px;

          .module-info-container {
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            gap: 8px;

            .module-info {
              width: 74px;
              color: ${(props) => props.theme.colors.gray10};
              display: flex;
              align-items: center;
              gap: 4px;
              cursor: default;

              .module-type {
                color: ${(props) => props.theme.colors.gray10};
                background-color: ${(props) => props.theme.colors.gray1};
                cursor: default;

                img {
                  width: 16px;
                  filter: brightness(${() => (localStorage.getItem('dw-theme') === 'dark' ? 10 : 1)});
                }
              }
            }
          }

          .skill-assessment-btn {
            background: linear-gradient(
              87.03deg,
              ${(props) => props.theme.colors.gray10} 21.87%,
              rgba(59, 124, 249, 0.93) 136.76%
            );
            border: none;
          }
        }
      }

      .skill-modules-container {
        max-height: calc(100vh - 376px - 88px);
        overflow: auto;

        .skill-section {
          margin: 0px 0px 16px 0px;

          .title {
            color: ${(props) => props.theme.colors.gray7};
            display: flex;
            gap: 6px;
            margin-bottom: 10px;

            .highlight {
              color: ${(props) => props.theme.colors.gray10};
              font-weight: 700;
            }
          }

          .modules-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 14px;

            .module-container {
              height: 100%;
              min-width: calc(50% - 7px);
              flex: 0 1 calc(50% - 7px);
              background: ${(props) => props.theme.colors.gray1};
              border-radius: 15px;

              &.is-assessment {
                flex: 1;
              }
            }
          }
        }
      }
    }
  }

  @media ${device.xxlDown} {
    > .content {
      padding: 20px;
    }
  }

  @media ${device.xlDown} {
    .skill-track-content {
      .skills-container {
        max-width: 200px;
        min-width: 200px;

        .skill-row {
          height: 52px;

          .skill-name {
            font-size: 13px;
            line-height: 18px;
          }

          > div,
          .select-btn {
            display: none;
          }
        }
      }

      .skill-content {
        .skill-assessment-btn {
          display: none;
        }

        .skill-modules-container {
          .skill-section {
            .modules-container {
              .module-container {
                min-width: 100%;
                flex: 0 1 1;
              }
            }
          }
        }
      }
    }
  }

  @media ${device.lgDown} {
    .skill-track-content {
      .skill-content {
        .skill-modules-container {
          height: initial;
          display: initial;
        }
      }
    }
  }

  @media ${device.mdDown} {
    > .content {
      padding: 12px;
    }

    .pre-title {
      display: none;
    }

    .skill-track-content {
      .skills-container {
        display: none;
      }

      .skill-content {
        .skill-header {
          .web-menu,
          .module-info-container {
            display: none;
          }

          .mobile-menu {
            display: block;
          }
        }
      }
    }
  }
`
