import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Steps } from 'antd'
import { togglePublicProfileModal } from '@/store/users/actions'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'
import Step4 from './components/Step4'
import Step5 from './components/Step5'
import { Modal } from './styles'

const PublicProfileModal = () => {
  const dispatch = useDispatch()

  const { userProfile, isPublicProfileModalOpen } = useSelector((state) => state.users)

  const [step, setStep] = useState(1)

  useEffect(() => {
    if (!userProfile?.['first_name'] || !userProfile?.['last_name'] || !userProfile?.['declared_location']) {
      return setStep(1)
    }

    if (!userProfile?.['occupation']) {
      return setStep(2)
    }

    if (!userProfile?.['company'] || !userProfile?.['role'] || !userProfile?.['secondary_email']) {
      return setStep(3)
    }

    if (!userProfile?.['bio'] || !userProfile?.['linkedin_url']) {
      return setStep(4)
    }

    return setStep(1)
  }, [isPublicProfileModalOpen])

  const renderStep = () => {
    if (step === 1) return <Step1 onNext={() => setStep(2)} />
    if (step === 2) return <Step2 onPrev={() => setStep(1)} onNext={() => setStep(3)} />
    if (step === 3) return <Step3 onPrev={() => setStep(2)} onNext={() => setStep(4)} />
    if (step === 4) return <Step4 onPrev={() => setStep(3)} onNext={() => setStep(5)} />
    if (step === 5) return <Step5 onPrev={() => setStep(4)} />
  }

  return (
    <Modal
      open={isPublicProfileModalOpen}
      onCancel={() => dispatch(togglePublicProfileModal(false))}
      destroyOnClose
      footer={null}
      className="public-profile-modal"
    >
      <div className="steps-container">
        {step < 5 && <Steps size="small" current={step - 1} items={[{}, {}, {}, {}]} />}
      </div>

      {renderStep()}
    </Modal>
  )
}

export default PublicProfileModal
