import { Skeleton } from 'antd'
import { Container } from './styles'

const Loading = () => {
  return (
    <Container>
      <div className="row">
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
      </div>
      <div className="row">
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
      </div>
      <div className="row">
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
      </div>
    </Container>
  )
}

export default Loading
