import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .fade-in-mask {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .jumpboxes-section {
    width: 100%;
    align-items: flex-start;
  }

  @media ${device.mdDown} {
    height: calc(100% - 64px);
  }
`
