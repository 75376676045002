import { useSelector, useDispatch } from 'react-redux'
import { Menu } from 'antd'
import { TextDescription24Regular, ArrowRotateCounterclockwise24Regular, Comment24Regular } from '@fluentui/react-icons'
import { selectInfoSideDrawerItem } from '@/store/playgrounds/actions'
import { MenuContainer } from './styles'

const SiderMenu = () => {
  const dispatch = useDispatch()

  const { theme } = useSelector((state) => state.app)
  const { currentPlayground: playground, infoSideDrawer } = useSelector((state) => state.playgrounds)

  const isMemberCopy = playground?.metadata?.is_student_copy
  const hasComments = playground?.user_status?.comments?.length > 0

  const items = [
    { label: 'Description', key: 'description', icon: <TextDescription24Regular /> },
    {
      label: isMemberCopy ? 'Submissions' : 'Version history',
      key: 'version-history',
      icon: <ArrowRotateCounterclockwise24Regular />,
    },
    ...(hasComments
      ? [
          {
            label: 'Comments',
            key: 'comments',
            icon: <Comment24Regular />,
          },
        ]
      : []),
  ]

  return (
    <MenuContainer className="sider-menu">
      <Menu
        mode="inline"
        theme={theme === 'dark' ? 'dark' : 'light'}
        items={items}
        selectable={!!infoSideDrawer}
        selectedKeys={[infoSideDrawer]}
        onClick={({ key }) => dispatch(selectInfoSideDrawerItem(key))}
      />
    </MenuContainer>
  )
}

export default SiderMenu
