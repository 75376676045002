import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  display: flex;
  flex-direction: column;
  transition: 0.3s all;

  &:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  }

  .main-content {
    display: flex;
    gap: 22px;
    padding: 24px 24px 16px;

    .image-container {
      min-width: 80px;
      max-width: 80px;
      min-height: 80px;
      max-height: 80px;
      background: linear-gradient(
        60deg,
        ${(props) => props.theme.colors.base.gray8},
        ${(props) => props.theme.colors.base.cyan7},
        ${(props) => props.theme.colors.base.cyan4}
      );
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      .main-icon {
        width: 32px;
        height: 32px;
        color: ${(props) => props.theme.colors.base.gray1};
        font-size: 32px;
      }

      ${(props) =>
        props.$playgroundBackgroundImage &&
        css`
          background-image: url(${(props) => props.$playgroundBackgroundImage});
          background-size: cover;
          background-position: center;
          object-fit: cover;

          .main-icon {
            display: none;
          }
        `}
    }

    > .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 6px;

      .header {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 4px;

        .title-container {
          .title {
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 0px;
          }
        }

        .actions {
          display: flex;
          align-items: center;

          .open-options-button {
            color: ${(props) => props.theme.colors.gray10};
            border-radius: 50% !important;
            padding: 4px;

            .icon {
              width: 22px;
            }
          }
        }
      }

      .description,
      .description * {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0px;
      }
    }
  }

  .bottom-info {
    border-top: 1px solid ${(props) => props.theme.colors.gray3};
    display: flex;
    justify-content: space-between;
    gap: 4px;
    padding: 10px 24px;

    .left,
    .right {
      color: ${(props) => props.theme.colors.gray7};
      display: flex;
      align-items: center;

      .divider {
        background-color: ${(props) => props.theme.colors.gray3};
        height: 50%;
        width: 2px;
        margin: 8px;
      }
    }

    .left {
      gap: 16px;
    }

    .right {
      gap: 8px;
    }

    .data-source-icon,
    .language-icon,
    .interface-icon {
      width: 22px;
      pointer-events: initial;
    }

    .published-info {
      color: ${(props) => props.theme.colors.gray6};
      margin: 0px;
    }
  }

  @media ${device.smDown} {
    .main-content {
      gap: 14px;
      padding: 24px 16px 16px;

      .image-container {
        min-width: 54px;
        max-width: 54px;
        min-height: 54px;
        max-height: 54px;

        .main-icon {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }

      > .content {
        gap: 2px;

        .header {
          .title-container {
            .title {
              font-size: 16px;
              line-height: 22px;
            }
          }

          .actions {
            margin-right: -10px;
          }
        }

        .description,
        .description * {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }

    .bottom-info {
      padding: 10px 16px;

      .left {
        gap: 8px;
      }

      .data-source-icon,
      .language-icon,
      .interface-icon {
        width: 18px;
        pointer-events: initial;
      }
    }
  }
`
