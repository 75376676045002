import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  position: relative;

  .results-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    list-style: none;
    padding: 0px;
    margin: 0px;

    .datasource-card-container {
      min-height: 144px;
    }

    &.is-loading {
      .ant-skeleton {
        margin: 0px;

        .ant-skeleton-paragraph {
          margin: 0px;

          li {
            height: 146px;
            width: 100% !important;
          }
        }
      }
    }
  }

  .no-results-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;

    & > img {
      user-select: none;
      pointer-events: none;
    }

    .title {
      margin-bottom: 6px;
    }

    .text {
      color: ${(props) => props.theme.colors.gray7};
      font-weight: 500;
    }
  }

  .pagination-box {
    background: linear-gradient(0deg, ${(props) => props.theme.colors.gray1} 40%, transparent 100%);
    width: 100%;
    /* position: sticky; */
    bottom: 0px;
    padding: 20px 0px 0px;
    z-index: 1;

    &.ais-Pagination {
      .ais-Pagination-list {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        padding: 0px;
        margin: 0px;

        .ais-Pagination-item + .ais-Pagination-item {
          margin-left: 8px;
        }

        .ais-Pagination-item {
          font-weight: 500;

          .ais-Pagination-link {
            color: ${(props) => props.theme.colors.gray10};
            display: block;
            border-radius: 5px;
            padding: 6px 11px;
            transition: background-color 0.2s ease-out;
            cursor: pointer;

            &:focus,
            &:hover {
              color: ${(props) => props.theme.colors.gray10};
              background-color: ${(props) => props.theme.colors.gray4};
            }
          }

          &.ais-Pagination-item--nextPage,
          &.ais-Pagination-item--previousPage {
            font-size: 24px;
            line-height: 22px;
            font-weight: 300;
          }

          &.ais-Pagination-item--disabled .ais-Pagination-link {
            color: ${(props) => props.theme.colors.gray5};
            cursor: not-allowed;

            &:focus,
            &:hover {
              background-color: transparent;
            }
          }

          &.ais-Pagination-item--selected .ais-Pagination-link {
            color: ${(props) => props.theme.colors.geekblue5};
            border: 1px solid ${(props) => props.theme.colors.geekblue5};
            font-weight: 700;

            &:focus,
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
`
