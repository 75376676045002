import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  color: ${(props) => props.theme.colors.text};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0px;
  transition: 0.3s all;

  &:hover {
    background: ${(props) => props.theme.colors.gray6}30;
  }

  .easy-edit-wrapper {
    > * {
      margin: 0px;
    }
  }

  .easy-edit-inline-wrapper {
    background: ${(props) => props.theme.colors.gray6}40;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .easy-edit-component-wrapper {
      width: 100%;

      input,
      select,
      textarea {
        width: 100%;
        font-size: 14px;
        border: none;
        outline: none;
        padding: 4px;
        margin: 2px;
      }

      textarea {
        min-height: 100px;
        resize: vertical;
      }

      input,
      select {
        width: calc(100% - 12px);
      }

      .easy-edit-textarea {
        font-family: inherit;
      }
    }

    .easy-edit-button-wrapper {
      display: flex;
      white-space: nowrap;

      .easy-edit-button {
        cursor: pointer;

        &:hover {
          background: ${(props) => props.theme.colors.gray6}90;
        }
      }
    }
  }

  .easy-edit-hover-on {
    font-style: normal;
  }

  @media ${device.smDown} {
    background: #fff;

    .easy-edit-wrapper {
      pointer-events: none;
    }
  }
`
