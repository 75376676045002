import { useNavigate } from 'react-router-dom'
// import { useRouteError } from 'react-router-dom'
import { isDataWarsHostName } from '@/helpers/env'
import Layout1 from '@/layouts/Layout1'
import ErrorCard from './components/ErrorCard'
import { Container } from './styles'

const ErrorPage = () => {
  const navigate = useNavigate()
  // const error = useRouteError()

  const refreshPage = () => {
    window.location.reload()
  }

  const goToDashboard = () => {
    navigate('/', { replace: true })
  }

  return (
    <Layout1 navbar>
      <Container>
        <ErrorCard
          title={'An unexpected error has occurred.'}
          // text={error.statusText || error.message}
          primaryButtonText={'Refresh this page'}
          primaryButtonLinkTo={null}
          primaryButtonOnClick={refreshPage}
          bottomText={isDataWarsHostName ? 'or go to Dashboard' : null}
          bottomOnClick={isDataWarsHostName ? goToDashboard : null}
        />
      </Container>
    </Layout1>
  )
}

export default ErrorPage
