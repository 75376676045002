import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tabs } from 'antd'
import DataSourceDetailContent from '../DataSourceDetailContent'
import NoDataSource from '@/assets/images/playgrounds/datasources/no-data-source.svg'
import { getDataSource } from '@/store/dataSources/actions'
import { Container } from './styles'

const DataSourceDetail = ({ isCustomProject }) => {
  const dispatch = useDispatch()

  const { wizard } = useSelector((state) => state.playgrounds)
  const { currentDataSource, isLoading: isDataSourceLoading } = useSelector((state) => state.dataSources)
  const preDataSource = wizard?.preDataSource

  const items = [
    {
      key: '1',
      label: 'Overview',
      children: (
        <div className="tab-content">
          <DataSourceDetailContent dataSource={currentDataSource} isLoading={isDataSourceLoading} />
        </div>
      ),
    },
    // {
    //   key: '2',
    //   label: 'Created with this dataset',
    // },
  ]

  useEffect(() => {
    if (!preDataSource?.id) return
    dispatch(getDataSource(preDataSource?.id))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="datasource-detail">
      <div className="content">
        {preDataSource?.id ? (
          <Tabs defaultActiveKey="1" items={items} onChange={() => {}} />
        ) : (
          <div className="no-data-source-content">
            <div className="content">
              <img className="image" src={NoDataSource} alt="No dataset selected" />
              <h4 className="title">No dataset selected</h4>
              <p className="description">
                Your {isCustomProject ? 'project' : 'playground'} won't have any access to external datasets, it'll be
                just the compute environment.
              </p>
              <p className="description">This can't be changed after creation.</p>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default DataSourceDetail
