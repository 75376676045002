import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 10px 16px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.base.gray4};
    padding-bottom: 16px;

    .image-container {
    }

    .title-container {
      .title {
        color: ${(props) => props.theme.colors.base.gray10};
        font-weight: 700;
        margin: 0px;
      }
    }
  }

  .user-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.base.gray4};
    padding-bottom: 16px;

    .item {
      display: flex;
      align-items: center;
      gap: 4px;

      .icon {
        min-width: 20px;
        color: ${(props) => props.theme.colors.base.gray6};
      }

      .label {
        color: ${(props) => props.theme.colors.base.gray10};
        margin: 0px;
      }

      .flag-image {
        width: 18px;
        margin-left: 2px;
      }
    }
  }

  .actions {
    width: 100%;
  }
`
