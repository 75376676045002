import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import PublicProfileTag from './components/PublicProfileTag'
import DiscordBanner from './components/DiscordBanner'
// import PublicProfileBanner from './components/PublicProfileBanner'
import ValidateEmailBanner from './components/ValidateEmailBanner'
import { capitalize } from '@/utils/functions'
import { Container } from './styles'

const DashboardHeader = () => {
  const { userProfile, emailValidationCodeIsValidated } = useSelector((state) => state.users)
  const isEmailValidated = userProfile?.email_validated_at || emailValidationCodeIsValidated
  // const showFeedbackBox = isEmailValidated && dayjs().diff(dayjs(isEmailValidated), 'day') >= 3

  const getInfoBoxes = () => {
    let infoBoxes = []

    if (!isEmailValidated) {
      const emailValidationInfoBox = (
        <SwiperSlide key="validate-email-box">
          <ValidateEmailBanner />
        </SwiperSlide>
      )
      infoBoxes.push(emailValidationInfoBox)
    }

    const discordInfoBanner = (
      <SwiperSlide key="discord-info-banner">
        <DiscordBanner />
      </SwiperSlide>
    )
    infoBoxes.push(discordInfoBanner)

    // const publicProfileBanner = (
    //   <SwiperSlide key="public-profile-banner">
    //     <PublicProfileBanner />
    //   </SwiperSlide>
    // )
    // if (isEmailValidated) {
    //   if (publicProfileCompletionPerc === 100) {
    //     infoBoxes.push(publicProfileBanner)
    //   } else {
    //     infoBoxes.unshift(publicProfileBanner)
    //   }
    // }

    // if (showFeedbackBox) {
    //   const feedbackInfoBox = (
    //     <SwiperSlide key="feedback-info-box">
    //       <a className="info-box-container" href="https://forms.gle/Jt57iHh9YUn8nRNs5" target="_blank" rel="noreferrer">
    //         <div className="info-box highlight">
    //           <SoundOutlined className="icon-container" />

    //           <div className="content">
    //             <p className="text">
    //               <b>Thank you for joining our beta program.</b>
    //               <br />
    //               Please take 1 minute to <u>provide feedback</u> about your experience using DataWars.
    //             </p>
    //           </div>
    //         </div>
    //       </a>
    //     </SwiperSlide>
    //   )
    //   infoBoxes.push(feedbackInfoBox)
    // }

    return infoBoxes
  }

  return (
    <Container>
      <div className="user-info">
        <div className="title-container">
          <h3 className="title">👋 Hey, {capitalize(userProfile?.first_name)}!</h3>

          {isEmailValidated && <PublicProfileTag />}
        </div>

        <p className="subtitle">
          You're enrolled in the{' '}
          <a className="link" href="#career-skill-tracks">
            Data Scientist with Python
          </a>{' '}
          career.
        </p>
      </div>

      <Swiper
        className="banner-container"
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
        spaceBetween={4}
      >
        {getInfoBoxes()}
      </Swiper>
    </Container>
  )
}

export default DashboardHeader
