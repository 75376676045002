import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.geekblue3};
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px 14px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);

    .icon {
      transform: translate(2px, 0px);
    }
  }

  &.success {
    background-color: ${(props) => props.theme.colors.cyan2};
  }

  .ant-progress {
    width: 72px;
    height: 0px;

    .ant-progress-outer {
      height: initial !important;
      display: flex;
      padding-inline-end: 0px;

      .ant-progress-bg {
        border-radius: 0px;
      }
    }

    .ant-progress-text {
      display: none;
    }
  }

  .text {
    font-size: 13px;
    line-height: 20px;
    white-space: nowrap;
    margin: 0px;

    .highlight {
      font-weight: 700;
    }
  }

  .icon {
    transition: 0.3s all;

    svg {
      fill: ${(props) =>
        localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.base.geekblue2 : props.theme.colors.geekblue6};
    }

    &.success svg {
      fill: ${(props) => props.theme.colors.cyan5};
    }
  }
`
