import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 26px 30px;

    .module-card-loading-container {
      margin-bottom: 48px;
    }

    > .title {
      color: ${(props) => props.theme.colors.gray8};
      margin-bottom: 20px;
    }

    .subtitle {
      color: ${(props) => props.theme.colors.gray8};
      font-weight: 400;
      margin: -20px 0px 16px;
    }

    .text {
      color: ${(props) => props.theme.colors.gray7};
      margin: 0px;
    }

    .related-modules-container {
      cursor: grab;
    }

    .new-user-main-block {
      .content {
        padding: 0px;
      }
    }
  }

  @media ${device.mdDown} {
    .content {
      overflow: hidden;

      > .title,
      > .subtitle {
        text-align: center;
      }

      .related-modules-container {
        margin: 0px -10px;

        .swiper {
          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
        }
      }
    }
  }
`
