import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Skeleton, Tabs } from 'antd'
import { BuildOutlined } from '@ant-design/icons'
import FadeIn from '@/components/FadeIn'
import Button from '@/components/Button'
import PlaygroundItem from '../PlaygroundItem'
import { getPlaygrounds } from '@/store/playgrounds/actions'
import Logo from '@/assets/images/logo-sm-white.svg?react'
import { Container } from './styles'

const TabContent = ({ status, items, isLoading }) => {
  if (isLoading || items === null)
    return (
      <div className="playgrounds-container">
        <FadeIn>
          <div className="loading-container">
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          </div>
        </FadeIn>
      </div>
    )

  if (!items?.length)
    return (
      <div className="playgrounds-container">
        <FadeIn>
          <div className="no-content-container">
            <div className="icon-container">
              <Logo className="icon" />
            </div>

            <div className="content">
              {status === 'all' ? (
                <h3 className="title">Get started with Playgrounds!</h3>
              ) : (
                <h3 className="title">You don't have {status} Playgrounds</h3>
              )}

              <p className="description">
                {status === 'all' && (
                  <>
                    Use playgrounds to explore datasets and practice your Data Science skills in a free roaming
                    environment.
                  </>
                )}

                {status === 'published' && (
                  <>
                    Published playgrounds allow you to show your work to the world.
                    <br />
                    Get started by creating a playground and showcasing it to the world.
                  </>
                )}

                {status === 'archived' && (
                  <>
                    Archived playgrounds are hidden to the outside world.
                    <br />
                    You can always unarchive them later.
                  </>
                )}
              </p>

              <Link className="action-button" to={'/playgrounds?tab=datasets'}>
                <Button type="primary" size="large">
                  Create New Playground
                </Button>
              </Link>
            </div>
          </div>
        </FadeIn>
      </div>
    )

  return (
    <FadeIn>
      <div className="playgrounds-container">
        {items?.map((p) => (
          <PlaygroundItem key={p?.id} playground={p} />
        ))}
      </div>
    </FadeIn>
  )
}

const MyPlaygroundsTab = () => {
  const dispatch = useDispatch()

  const { myPlaygrounds, publishedPlaygrounds, archivedPlaygrounds, isLoading } = useSelector(
    (state) => state.playgrounds,
  )

  const [tab, changeTab] = useState('all')

  const tabItems = [
    {
      key: 'all',
      label: 'All',
      children: <TabContent status={'all'} items={myPlaygrounds} isLoading={isLoading} />,
      disabled: isLoading,
    },
    {
      key: 'published',
      label: 'Published',
      children: <TabContent status={'published'} items={publishedPlaygrounds} isLoading={isLoading} />,
      disabled: isLoading || !publishedPlaygrounds?.length,
    },
    {
      key: 'archived',
      label: 'Archived',
      children: <TabContent status={'archived'} items={archivedPlaygrounds} isLoading={isLoading} />,
      disabled: isLoading || !archivedPlaygrounds?.length,
    },
  ]

  const handleChangeTab = (key) => {
    changeTab(key)
  }

  const handleLoad = async (status) => {
    dispatch(getPlaygrounds({ user: 'me', ...(status !== 'all' ? { status } : {}) }))
  }

  useEffect(() => {
    if (myPlaygrounds !== null) return

    handleLoad()
  }, [myPlaygrounds]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (publishedPlaygrounds !== null) return

    handleLoad('published')
  }, [publishedPlaygrounds]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (archivedPlaygrounds !== null) return

    handleLoad('archived')
  }, [archivedPlaygrounds]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="my-playgrounds-tab">
      <FadeIn>
        <div className="playgrounds-content">
          <div className="header">
            <h5 className="section-title">
              <BuildOutlined />
              My Playgrounds
            </h5>
          </div>

          <Tabs defaultActiveKey={tab} items={tabItems} onChange={handleChangeTab} disabled={true} />
        </div>
      </FadeIn>
    </Container>
  )
}

export default MyPlaygroundsTab
