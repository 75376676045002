// Mobile devices – 320px — 480px
// iPads, Tablets – 481px — 768px
// Small screens, laptops – 769px — 1024px
// Desktops, large screens – 1025px — 1200px
// Extra large screens, TV – 1201px, and more

export const sizes = {
  // device: 320px
  xs: '480px', // device: 490px
  sm: '576px', // device: 600px
  md: '768px', // device: 800px
  lg: '992px', // device: 1080px
  xl: '1200px', // device: 1280px
  xxl: '1400px', // device: 1480px
  xxxl: '1600px', // device: 1650px
}

export const device = {
  xsDown: `only screen and (max-width: ${sizes.xs})`,
  smDown: `only screen and (max-width: ${sizes.sm})`,
  mdDown: `only screen and (max-width: ${sizes.md})`,
  lgDown: `only screen and (max-width: ${sizes.lg})`,
  xlDown: `only screen and (max-width: ${sizes.xl})`,
  xxlDown: `only screen and (max-width: ${sizes.xxl})`,
  xxxlDown: `only screen and (max-width: ${sizes.xxxl})`,

  xsUp: `only screen and (min-width: ${sizes.xs})`,
  smUp: `only screen and (min-width: ${sizes.sm})`,
  mdUp: `only screen and (min-width: ${sizes.md})`,
  lgUp: `only screen and (min-width: ${sizes.lg})`,
  xlUp: `only screen and (min-width: ${sizes.xl})`,
  xxlUp: `only screen and (min-width: ${sizes.xxl})`,
  xxxlUp: `only screen and (min-width: ${sizes.xxxl})`,
}

export const isDown = (size) => {
  return window?.innerWidth < parseInt(sizes?.[size]?.replace('px', '') || 0)
}
