import styled from 'styled-components'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    overflow: hidden;
    padding: 0px;

    .ant-modal-close {
      width: 40px;
      height: 40px;
      background-color: ${(props) => props.theme.colors.gray4};
      border-radius: 50%;
      top: 18px;
      right: 18px;

      .ant-modal-close-x svg {
        color: ${(props) => props.theme.colors.gray11};
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.gray5};
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      > .header-image {
        width: 100%;
        height: auto;
      }

      > .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 10px 38px 20px;

        .title-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;

          .title {
            font-weight: 700;
            margin: 0px 0px 10px;
          }

          .subtitle {
            color: ${(props) => props.theme.colors.gray9};
            margin: 0px;
          }
        }

        .sharing-content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .info-text {
            max-width: 300px;
            color: ${(props) => props.theme.colors.gray8};
            text-align: center;
            margin: 0px;
          }

          .sharing-box {
            padding: 10px;

            .title {
              margin: 8px 0px;
            }
          }
        }

        .rating-content {
          width: 340px;
          background-color: ${(props) => props.theme.colors.geekblue1};
          display: flex;
          align-items: center;
          gap: 8px;
          border-radius: 20px;
          padding: 8px 32px;

          .text {
            font-weight: 400;
            margin: 0px 0px 4px;
          }

          .rate {
            margin: 8px 0px;

            svg {
              font-size: 20px;
            }
          }
        }

        .next-up-block {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          border-top: 1px solid ${(props) => props.theme.colors.gray4};
          padding-top: 20px;

          > .content {
            .text {
              color: ${(props) => props.theme.colors.gray8};
              margin: 0px;
            }
          }

          .link {
            .next-module-btn {
            }
          }
        }
      }
    }
  }

  @media ${device.lgDown} {
    display: none;
  }
`
