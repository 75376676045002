import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 5px;
  padding: 24px;
  animation: 1s ${fadeInAnimation};

  .account-info {
    display: flex;
    align-items: center;
    gap: 10px;

    .account-image-container {
      max-width: 32px;
      min-width: 32px;
      max-height: 32px;
      min-height: 32px;
      background: linear-gradient(86deg, #39476d 8.49%, #4150d0 67.75%, rgba(179, 114, 244, 0.62) 97.13%);
      border-radius: 7px;
      overflow: hidden;

      .account-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
    }

    .name {
      margin: 0px;
    }
  }

  .custom-projects-container {
    margin: 24px 0px;
  }
`
