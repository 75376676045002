import styled from 'styled-components'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'

export const Container = styled(AntModal)`
  .ant-modal-content {
    padding: 0px;

    .ant-modal-close {
      color: ${(props) => props.theme.colors.gray1};
    }
  }

  .ant-modal-body {
    .skill-track-card {
      width: calc(100% - 40px);
      height: auto;

      .skill-track-body {
        border: none;

        .skill-track-info {
          max-width: 410px;

          .title {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }

    .content {
      background-color: ${(props) => props.theme.colors.gray1};
      min-height: 180px;
      display: flex;
      gap: 20px;
      padding: 30px 20px;

      .skills-info {
        min-width: 35%;
        max-width: 45%;
        height: fit-content;
        border: 2px solid ${(props) => props.theme.colors.geekblue1};
        border-radius: 8px;
        padding: 12px 16px;

        .skill-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          overflow: hidden;
          margin-bottom: 16px;

          &:last-child {
            margin: 0px;
          }

          .skill-name {
            width: 100%;
            font-size: 12px;
            line-height: 20px;
            margin: 0px;

            &.coming-soon {
              color: ${(props) => props.theme.colors.gray6};
            }
          }
        }
      }

      .draft-info {
        max-width: 260px;
        margin-left: 40px;

        .title {
          font-weight: 500;
        }

        .text {
          color: ${(props) => props.theme.colors.gray7};
          font-size: 12px;
          line-height: 20px;
        }
      }

      .skill-track-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .skill-track-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .tag {
            background-color: ${(props) => props.theme.colors.geekblue2};
          }

          .description {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 12px;
            line-height: 20px;
            margin-top: 10px;
          }
        }

        img {
          margin-right: 40px;
        }
      }
    }
  }

  @media ${device.mdDown} {
    .ant-modal-body {
      .skill-track-card {
        width: calc(100% - 60px);
      }

      .content {
        flex-direction: column;

        .skills-info {
          display: none;
        }

        .draft-info {
          text-align: center;
          margin: 0px auto;
        }

        .skill-track-details {
          img {
            display: none;
          }
        }
      }
    }
  }
`
