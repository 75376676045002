import { SET_LEARNING_AREA_LOADING, LEARNING_AREAS_UPDATED, SET_LEARNING_AREA_ERROR } from '@/store/learningAreas'
import { fetchLearningAreas } from '@/services/learningAreas'

const getLearningAreas = () => async (dispatch, getState) => {
  const { items } = getState().learningAreas
  if (items?.length) return

  try {
    dispatch(SET_LEARNING_AREA_LOADING(true))

    const learningAreas = await fetchLearningAreas()
    dispatch(LEARNING_AREAS_UPDATED(learningAreas.results))
  } catch (error) {
    const { message } = error
    dispatch(SET_LEARNING_AREA_ERROR(message))
  }
}

export { getLearningAreas }
