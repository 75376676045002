import {
  SaveOutlined,
  RocketOutlined,
  AppstoreOutlined,
  LockOutlined,
  ClockCircleOutlined,
  BuildOutlined,
} from '@ant-design/icons'

const proFeatures = [
  {
    name: 'Save your Lab state',
    description: 'Your lab changes are safe. Save your progress for later.',
    icon: <SaveOutlined />,
  },
  {
    name: 'Boosted Labs',
    description: 'Start your labs with extra CPU and RAM.',
    icon: <RocketOutlined />,
  },
  {
    name: 'Unlimited concurrent Labs',
    description: 'Start multiple labs simultaneously.',
    icon: <AppstoreOutlined />,
  },
  {
    name: 'Early Access to new projects',
    description: 'Try our newest projects before anybody else with exclusive Early Access.',
    icon: <ClockCircleOutlined />,
  },
  {
    name: 'Playgrounds',
    description: 'Enjoy multiple Data Science Scenarios with fun datasets.',
    icon: <BuildOutlined />,
  },
  {
    name: 'Support our mission',
    description: 'Help us democratize access to Data Science education.',
    icon: <LockOutlined />,
  },
]

export default proFeatures
