const proPrices = {
  year: {
    month: 20,
    monthDecimals: 75,
    year: 249,
    yearDecimals: 0,
  },
  month: {
    month: 39,
    monthDecimals: 0,
  },
}

export default proPrices
