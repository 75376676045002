import EasyEdit from 'react-easy-edit'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Container } from './styles'

const RenderH3 = ({ value }) => <h3>{value}</h3>
const RenderH4 = ({ value }) => <h4>{value}</h4>
const RenderH5 = ({ value }) => <h5>{value}</h5>
const RenderH6 = ({ value }) => <h6>{value}</h6>
const RenderP = ({ value }) => <p>{value}</p>

const InlineEdit = ({
  className = '',
  type = 'text',
  options = [],
  value = '',
  onSave = (value) => console.log(value),
  placeholder = '',
  renderAs = 'p',
  editMode = false,
}) => {
  const getRenderComponent = () => {
    if (renderAs === 'h3') return <RenderH3 />
    if (renderAs === 'h4') return <RenderH4 />
    if (renderAs === 'h5') return <RenderH5 />
    if (renderAs === 'h6') return <RenderH6 />
    if (renderAs === 'p') return <RenderP />
    return <RenderP />
  }
  const renderComponent = getRenderComponent(renderAs)

  return (
    <Container className={className}>
      <EasyEdit
        type={type}
        options={options}
        value={value}
        placeholder={placeholder}
        onSave={onSave}
        onCancel={() => {}}
        saveButtonLabel={<CheckOutlined />}
        cancelButtonLabel={<CloseOutlined />}
        displayComponent={renderComponent}
        editMode={editMode}
      />
    </Container>
  )
}

export default InlineEdit
