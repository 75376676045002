import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: radial-gradient(
    141.24% 83.78% at 122.4% 90%,
    rgba(84, 121, 247, 0.5) 20%,
    rgba(172, 143, 255, 0.5) 45%,
    ${(props) => props.theme.colors.gray3} 90%
  );
  padding: 30px 24px 0px;

  .fade-in-mask {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .playgrounds-content {
    width: 100%;
    height: 100%;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .section-title {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .ant-tabs {
      height: calc(100% - 34px);

      .ant-tabs-nav {
        margin: 0px 10px 6px;
      }

      .ant-tabs-content-holder {
        overflow: auto;
      }
    }

    .playgrounds-container {
      height: 100%;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 10px;

      .subtitle {
        margin: 0px;
      }

      .no-content-container {
        width: 100%;
        background-color: #ecebff;
        display: flex;
        align-items: center;
        gap: 30px;
        border-radius: 20px;
        padding: 40px 30px;

        .icon-container {
          width: 140px;
          height: 140px;
          background: linear-gradient(130deg, #3c276a 20%, ${(props) => props.theme.colors.base.geekblue5} 220%);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;

          .icon {
            height: 64px;
            width: 64px;
          }
        }

        > .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .title {
            font-size: 30px;
            font-weight: 700;
            margin: 0px;
          }

          .description {
            max-width: 460px;
            color: ${(props) => props.theme.colors.gray8};
            margin: 0px;
          }

          .action-button {
            margin-top: 10px;
          }
        }
      }
    }

    .loading-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .ant-skeleton {
        margin: 0px;

        .ant-skeleton-paragraph {
          margin: 0px;

          li {
            height: 167px;
            width: 100% !important;
          }
        }
      }
    }
  }

  @media ${device.mdDown} {
    height: calc(100% - 64px);
    width: 100%;
  }

  @media ${device.smDown} {
    padding: 20px 0px 0px;

    .playgrounds-content {
      > .header {
        padding-left: 20px;
      }

      .ant-tabs {
        .ant-tabs-nav {
          padding-left: 20px;
          margin: 0px;
        }
      }

      .playgrounds-container {
        padding: 10px 0px;
      }
    }
  }
`
