import Button from '@/components/Button'
import EmailVerificationDone from '@/assets/images/email-verification-done.svg'

const ValidationDone = ({ handleClose }) => {
  return (
    <div className="content">
      <img src={EmailVerificationDone} alt="Email verification done" />

      <h2>Your account has been verified.</h2>

      <Button type="primary" size="large" onClick={handleClose}>
        Continue
      </Button>
    </div>
  )
}

export default ValidationDone
