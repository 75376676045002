import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, FloatButton, Tooltip, Spin, Skeleton } from 'antd'
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  ExpandAltOutlined,
  DeleteOutlined,
  SendOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import HTMLBlock from '@/components/HTMLBlock'
import {
  createModuleConversation,
  getModuleConversation,
  addUserMessageToModuleConversation,
  updateModuleConversation,
  removeModuleConversation,
} from '@/store/modules/actions'
import Sparkle from '@/assets/images/icons/sparkle.svg?react'

import { Container } from './styles'

const Chat = () => {
  const dispatch = useDispatch()

  const { currentModule: module, moduleConversation, error } = useSelector((state) => state.modules)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMessage, setIsLoadingMessage] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [message, setMessage] = useState('')

  const conversationId = module?.user_status?.chat_conversation || moduleConversation?.id

  const getConversation = async () => {
    if (!conversationId || (moduleConversation && moduleConversation?.id === conversationId)) {
      return
    }

    setIsLoading(true)
    await dispatch(getModuleConversation(module?.id, conversationId))
    setIsLoading(false)
  }

  const renderMessages = () => {
    if (!moduleConversation?.messages?.length) {
      return (
        <div className="info-box">
          <p className="title">I'm Trooper DataWars' AI Assistant</p>
          <p className="text">Based on ChatGPT and adapted for this particular project, I can answer questions like:</p>
        </div>
      )
    }

    return moduleConversation?.messages?.map((m, i) => {
      const isUser = m?.role === 'user'

      return (
        <div key={i} className="message-container">
          <div className={`message ${isUser ? 'request' : 'response'}`}>
            {isUser ? m?.content : <HTMLBlock content={m?.content_html} />}
          </div>
        </div>
      )
    })
  }

  const sendMessage = async () => {
    if (!message) return

    setIsLoadingMessage(true)

    let newConversationId
    if (!conversationId) {
      newConversationId = await dispatch(createModuleConversation(module?.id))
    }

    await dispatch(addUserMessageToModuleConversation(message))
    setMessage('')

    const auxConversationId = conversationId || newConversationId
    await dispatch(updateModuleConversation(module?.id, auxConversationId, message))

    setIsLoadingMessage(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage(message)
    }
  }

  const handleClearConversation = () => {
    Modal.confirm({
      title: 'Reset conversation',
      content: 'Are you sure you want to reset the current conversation?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, reset',
      cancelText: 'Keep chatting',
      onOk: () => dispatch(removeModuleConversation(module?.id, conversationId)),
      okButtonProps: {
        danger: true,
        type: 'primary',
        disabled: isLoading || isLoadingMessage,
        loading: isLoading || isLoadingMessage,
      },
    })
  }

  useEffect(() => {
    setTimeout(() => {
      if (!isOpen) return

      const container = document.getElementById('chat-body')

      if (container) {
        container.scrollTop = container.scrollHeight
      }
    })
  }, [isOpen, moduleConversation?.messages])

  useEffect(() => {
    if (!isOpen || !conversationId) return

    getConversation()
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container $isExpanded={isExpanded}>
      <FloatButton.Group
        className="chat-open-icon"
        trigger="click"
        shape="square"
        description={
          <span className="chat-open-btn">
            {isOpen ? <CloseOutlined /> : <Sparkle />} <span className="btn-title">Ask DataWars AI</span>
          </span>
        }
        open={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="chat-container">
          <div className="chat-header">
            <Sparkle className="chat-icon" />

            <h5 className="title">Ask DataWars AI</h5>

            <div className="actions">
              {!!moduleConversation?.messages?.length && (
                <Tooltip title="Reset Conversation">
                  <DeleteOutlined className="clear-icon" onClick={handleClearConversation} />
                </Tooltip>
              )}

              <Tooltip title={isExpanded ? 'Collapse' : 'Expand'}>
                <ExpandAltOutlined className="expand-icon" onClick={() => setIsExpanded(!isExpanded)} />
              </Tooltip>

              <Tooltip title={'Close'}>
                <CloseOutlined className="close-icon" onClick={() => setIsOpen(false)} />
              </Tooltip>
            </div>
          </div>

          <div id="chat-body" className="chat-body">
            <div className="messages">
              {isLoading && <Skeleton className="chat-loading" active title={false} paragraph={{ rows: 3 }} />}
              {!isLoading && renderMessages()}

              {isLoadingMessage && (
                <div className="message-container">
                  <div className="message response loading">
                    <Spin indicator={<LoadingOutlined spin />} />
                  </div>
                </div>
              )}

              {error && (
                <div className="message-container">
                  <div className="message error">{error}</div>
                </div>
              )}
            </div>
          </div>

          <div className="chat-actions">
            {!(isLoading || isLoadingMessage || moduleConversation) && (
              <div className="example-questions">
                <div className="question-box">
                  <p>What's the syntax to join two dataframes in Pandas?</p>
                </div>

                <div className="question-box">
                  <p>What's the syntax to create a stacked bar plot in matplotlib?</p>
                </div>

                <div className="question-box">
                  <p>What's the syntax to perform cross validation with scikit learn?</p>
                </div>
              </div>
            )}

            <div className="send-box">
              <textarea
                className="input"
                rows="1"
                placeholder="Send a message..."
                disabled={isLoading || isLoadingMessage}
                value={message}
                onChange={(evt) => setMessage(evt.target.value)}
                onKeyDown={handleKeyDown}
              />

              <SendOutlined
                className={`send-btn ${isLoading || isLoadingMessage ? 'disabled' : ''}`}
                onClick={sendMessage}
              />
            </div>
          </div>
        </div>
      </FloatButton.Group>
    </Container>
  )
}

export default Chat
