import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Skeleton, Tag } from 'antd'
import { isDataWarsHostName } from '@/helpers/env'
import getIconByUrl from '@/utils/getIconByUrl'
import Replacement from '@/pages/ModulePage/components/Replacement'
import DataSourceCard from '@/pages/PlaygroundsPage/components/DataSourceCard'
import ProLabel from '@/components/ProLabel'
import NoDataSource from '@/assets/images/playgrounds/datasources/no-data-source.svg'
import { getRelatedDataSources } from '@/store/dataSources/actions'
import { Container } from './styles'

const DataSourceDetailContent = ({ dataSource, isLoading }) => {
  const dispatch = useDispatch()

  const [typeIcon, setTypeIcon] = useState(null)

  const { relatedDataSources, error } = useSelector((state) => state.dataSources)

  useEffect(() => {
    if (!dataSource) return

    getIconByUrl({
      iconUrl: dataSource?.subtype?.logo_url || 'ant-FileTextOutlined',
      className: 'type-icon',
      onReady: setTypeIcon,
    })

    if (isDataWarsHostName) {
      dispatch(getRelatedDataSources(dataSource?.theme?.value))
    }
  }, [dataSource]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return (
      <Container className="datasource-detail-content">
        <Skeleton active title={false} paragraph={{ rows: 8 }} />
        <Skeleton active title={false} paragraph={{ rows: 8 }} />
        <Skeleton active title={false} paragraph={{ rows: 8 }} />
      </Container>
    )
  }

  return (
    <Container className="datasource-detail-content">
      {dataSource ? (
        <>
          <div className="header">
            <h4 className="title">
              {typeIcon}
              {dataSource?.name}
            </h4>

            {dataSource?.is_pro && isDataWarsHostName && <ProLabel />}
          </div>

          <div className="tags-container">
            {dataSource?.is_beta && <Tag className="tag beta">Beta</Tag>}

            {dataSource?.theme && <Tag className="tag">{dataSource?.theme?.value}</Tag>}
            {dataSource?.subtype && <Tag className="tag">{dataSource?.subtype?.value}</Tag>}
            {(dataSource?.format || dataSource?.engine) && (
              <Tag className="tag">{dataSource?.format?.value || dataSource?.engine?.value}</Tag>
            )}
          </div>

          {dataSource?.description_html && (
            <div className="description-content">
              <Replacement contentHtml={dataSource?.description_html} />
            </div>
          )}

          {!!relatedDataSources?.length && (
            <div className="related-data-sources">
              <h5 className="title">Related datasets</h5>

              <div className="data-sources-container">
                {relatedDataSources?.slice(0, 4)?.map((ds) => (
                  <DataSourceCard key={ds?.objectID} dataSource={ds} />
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="no-data-source-content">
          <img className="image" src={NoDataSource} alt="No dataset selected" />

          <div className="title-container">
            <h5 className="title">
              {error ? 'Problem ocurred while loading dataset. Please try again.' : 'No dataset selected.'}
            </h5>
          </div>
        </div>
      )}
    </Container>
  )
}

export default DataSourceDetailContent
