import { css } from 'styled-components'

export const SwiperStyle = css`
  .swiper {
    .swiper-slide {
      width: auto;
    }

    .swiper-button-prev,
    .swiper-button-next {
      height: 100%;
      width: 80px;
      color: ${(props) => props.theme.colors.base.gray1};
      top: 0px;
      margin-top: 0px;

      &::after {
        font-size: 32px;
        transition: 0.3s all;
      }
    }

    .swiper-button-prev {
      background: linear-gradient(90deg, ${(props) => props.theme.colors.base.gray13}cc, transparent);
      left: 0px;

      &:hover {
        background: linear-gradient(90deg, ${(props) => props.theme.colors.base.gray13}e6, transparent);

        &::after {
          transform: translate(-4px, 0px);
        }
      }
    }

    .swiper-button-next {
      background: linear-gradient(-90deg, ${(props) => props.theme.colors.base.gray13}cc, transparent);
      right: 0px;

      &:hover {
        background: linear-gradient(-90deg, ${(props) => props.theme.colors.base.gray13}e6, transparent);

        &::after {
          transform: translate(4px, 0px);
        }
      }
    }

    .swiper-pagination {
      display: block;
      position: initial;

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background: ${(props) => props.theme.colors.gray8};

        &.swiper-pagination-bullet-active {
          background: ${(props) => props.theme.colors.gray7};
        }
      }
    }

    &.white {
      .swiper-button-prev,
      .swiper-button-next {
        color: ${(props) => props.theme.colors.gray11};
      }

      .swiper-button-prev {
        background: linear-gradient(90deg, ${(props) => props.theme.colors.gray1}cc, transparent);

        &:hover {
          background: linear-gradient(90deg, ${(props) => props.theme.colors.gray1}e6, transparent);
        }
      }

      .swiper-button-next {
        background: linear-gradient(-90deg, ${(props) => props.theme.colors.gray1}cc, transparent);

        &:hover {
          background: linear-gradient(-90deg, ${(props) => props.theme.colors.gray1}e6, transparent);
        }
      }
    }

    &.blue1 {
      .swiper-button-prev,
      .swiper-button-next {
        color: ${(props) => props.theme.colors.gray11};
      }

      .swiper-button-prev {
        background: linear-gradient(90deg, ${(props) => props.theme.colors.geekblue1}cc, transparent);

        &:hover {
          background: linear-gradient(90deg, ${(props) => props.theme.colors.geekblue1}e6, transparent);
        }
      }

      .swiper-button-next {
        background: linear-gradient(-90deg, ${(props) => props.theme.colors.geekblue1}cc, transparent);

        &:hover {
          background: linear-gradient(-90deg, ${(props) => props.theme.colors.geekblue1}e6, transparent);
        }
      }
    }

    .swiper-button-disabled {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      .swiper-button-prev,
      .swiper-button-next {
        width: 46px;

        &::after {
          font-size: 18px;
        }
      }
    }
  }
`
