import { useSelector, useDispatch } from 'react-redux'
import SendCode from './components/SendCode'
import ValidateCode from './components/ValidateCode'
import ValidationDone from './components/ValidationDone'
import { toggleEmailVerificationModal } from '@/store/users/actions'
import { Modal } from './styles'

const EmailVerificationModal = () => {
  const dispatch = useDispatch()

  const {
    isAuthenticated,
    userProfile,
    isEmailVerificationModalOpen,
    emailValidationCodeIsSent,
    emailValidationCodeIsValidated,
  } = useSelector((state) => state.users)
  const requireEmailValidated = userProfile?.organization?.config?.launch_labs_requires_email_validated

  if (!isAuthenticated || userProfile?.email_validated_at || !requireEmailValidated) {
    return <></>
  }

  return (
    <Modal
      open={isEmailVerificationModalOpen}
      onCancel={() => dispatch(toggleEmailVerificationModal(false))}
      keyboard={false}
      maskClosable={false}
      footer={null}
      className={`email-verification-modal ${emailValidationCodeIsValidated && 'done'}`}
    >
      {emailValidationCodeIsValidated ? (
        <ValidationDone handleClose={() => dispatch(toggleEmailVerificationModal(false))} />
      ) : emailValidationCodeIsSent ? (
        <ValidateCode />
      ) : (
        <SendCode />
      )}
    </Modal>
  )
}

export default EmailVerificationModal
