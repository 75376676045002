import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  position: relative;
  margin-bottom: 48px;

  .swiper {
    height: 100%;
    margin: -12px;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-slide {
      height: auto;
      padding: 12px;

      > div {
        height: 100%;
        cursor: grab;
      }
    }
  }

  @media ${device.mdDown} {
    .swiper {
      .swiper-slide {
        padding: 12px 12px 48px;
      }

      .swiper-pagination {
        margin-top: -18px;
        padding-bottom: 16px;
      }

      .swiper-button-prev {
        left: 40px;
      }

      .swiper-button-next {
        right: 40px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        height: 32px;
        width: 32px;
        background: ${(props) => props.theme.colors.gray10};
        display: flex;
        bottom: 0px;
        top: auto;
        border-radius: 50%;
        padding: 20px;
        margin-bottom: 6px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      }
    }
  }
`
