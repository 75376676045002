import { Skeleton } from 'antd'
import { Container } from './styles'

const LabLoading = () => {
  return (
    <Container>
      <Skeleton className="title" active title={false} paragraph={{ rows: 2 }} />

      <Skeleton className="image" active title={false} paragraph={{ rows: 1 }} />

      <Skeleton className="description" active title={false} paragraph={{ rows: 4 }} />
    </Container>
  )
}

export default LabLoading
