import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 90px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0px 24px;
  box-shadow: 0px 2px 8px 0px
    ${(props) => (localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray7 : props.theme.colors.gray13)}26;
  z-index: 1;

  .info {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 22px;

    .loading-content {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;

      .title-loading {
        height: 56px;

        .ant-skeleton-paragraph {
          max-width: 460px;
          width: 100% !important;
          margin: 0px;

          li {
            height: 20px;
            margin-top: 6px;
          }
        }
      }

      .mode-loading {
        align-items: right;

        .ant-skeleton-paragraph {
          display: flex;
          justify-content: flex-end;
          margin: 0px;

          li {
            height: 46px;
            max-width: 320px;
            width: 100% !important;
          }
        }
      }
    }

    .header {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;

      .title-container {
        display: flex;
        align-items: center;
        gap: 6px;

        .back-btn {
          align-self: flex-start;

          &.ant-btn {
            height: auto;
            line-height: 1px;
            padding: 3px 4px;
          }

          .icon {
            font-size: 16px;
          }
        }

        .title {
          line-height: 24px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          margin: 0px;
        }
      }

      .subtitle-container {
        display: flex;
        align-items: center;
        gap: 6px;

        .subtitle {
          color: ${(props) => props.theme.colors.gray7};
          font-size: 12px;
          margin: 0px;
        }
      }
    }

    .actions {
      min-height: 42px;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12px;

      .left,
      .right {
        display: flex;
        gap: 16px;

        > .ant-btn {
          display: flex;
          align-items: center;
          gap: 8px;

          > svg {
            width: 18px;
            height: 18px;

            &.publish-icon {
              transform: rotate(180deg);
            }
          }
        }
      }

      .left {
        display: none;
      }

      .action-loading {
        width: 32px;

        .ant-skeleton-paragraph {
          display: flex;
          margin: 0px;

          li {
            height: 32px;
            width: 100% !important;
          }
        }
      }
    }
  }

  .secondary-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;

    .warning-alert {
      border-radius: 10px;
    }

    .mode {
      width: fit-content;
      background: ${(props) => props.theme.colors.gray8};
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      padding: 8px 16px;

      .text {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
        margin: 0px;
      }

      &.preview {
        background: linear-gradient(270deg, #6e88ca 25.07%, #456aca 86.44%);

        .text {
          color: ${(props) => props.theme.colors.base.gray1};
        }
      }

      &.edit {
        background: linear-gradient(270deg, #94f2c1 -8.75%, #72ceb5 86.43%);

        .text {
          color: ${(props) => props.theme.colors.base.gray13};
        }
      }

      &.copy {
        background: linear-gradient(270deg, #ffd83a -8.75%, #fff189 86.43%);

        .text {
          color: ${(props) => props.theme.colors.base.gray13};
        }
      }
    }
  }

  @media ${device.xlDown} {
    .info {
      .actions {
        .left,
        .right {
          > .ant-btn {
            padding: 4px 8px;

            .text {
              display: none;
            }
          }
        }
      }
    }

    .secondary-content {
      .mode {
        .text {
          display: none;
        }
      }
    }
  }

  @media ${device.mdDown} {
    height: auto;
    flex-direction: column;
    gap: 6px;
    padding: 20px 20px 10px;

    .info {
      flex-direction: column;
      gap: 10px;
      padding-top: 0px;

      .header {
        width: 100%;
        justify-content: center;

        .title-container {
          .title {
            -webkit-line-clamp: 2;
          }
        }
      }

      .actions {
        width: 100%;
        border-top: 1px solid ${(props) => props.theme.colors.gray4};
        padding-top: 10px;

        .left {
          display: flex;
        }
      }
    }

    .secondary-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .mode {
        background: transparent;
        padding: 0px;

        &.preview {
          display: none;
        }
      }
    }
  }
`
