import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { presetPalettes, presetDarkPalettes } from '@ant-design/colors'
// import { setAppTheme } from '@/store/app/actions'
import GlobalStyle from './globalStyle'

const learningAreaColors = {
  purple: '#C590FC', // Programming
  green: '#72D4A8', // Data Analysis
  red: '#EE5C66', // Machine Learning
  blue: '#7F8DF1', // Databases
  yellow: '#FFD400', // Math and Statistics
  pink: '#FF97F0', // Visualizations
  orange: '#F7BE95',
}

// $success-color: #2ecc71;
// $success-dark-color: #27ae60;
// $danger-color: #e74c3c;
// $danger-dark-color: #c0392b;
// $warning-color: #f1c40f;
// $warning-dark-color: #f39c12;

const getColorsObject = (color, isDark) => {
  const colorsObject = Object.fromEntries(
    (isDark ? presetDarkPalettes : presetPalettes)[color]
      .map((c, i) => ({ [`${color}${i + 1}`]: c }))
      .map(Object.entries)
      .flat(),
  )

  return colorsObject
}

const baseColors = {
  learningArea: learningAreaColors,
  heading: '#000000E0',
  text: '#000000E0',
  secondaryText: '#000000A6',
  disabledText: '#00000040',
  border: '#D9D9D9FF',
  separator: '#0505050F',
  layoutBg: '#F5F5F5FF',
  ...getColorsObject('red'),
  ...getColorsObject('volcano'),
  ...getColorsObject('orange'),
  ...getColorsObject('gold'),
  ...getColorsObject('yellow'),
  ...getColorsObject('lime'),
  ...getColorsObject('green'),
  ...getColorsObject('cyan'),
  ...getColorsObject('blue'),
  ...getColorsObject('geekblue'),
  ...getColorsObject('purple'),
  ...getColorsObject('magenta'),
  // neutral
  gray1: '#ffffff',
  gray2: '#fafafa',
  gray3: '#f5f5f5',
  gray4: '#f0f0f0',
  gray5: '#d9d9d9',
  gray6: '#bfbfbf',
  gray7: '#8c8c8c',
  gray8: '#595959',
  gray9: '#434343',
  gray10: '#262626',
  gray11: '#1f1f1f',
  gray12: '#141414',
  gray13: '#000000',
}

const lightTheme = {
  colors: {
    base: baseColors,
    ...baseColors,
  },
}

const darkTheme = {
  colors: {
    base: baseColors,
    heading: '#FFFFFFD9',
    text: '#FFFFFFD9',
    secondaryText: '#FFFFFFa6',
    disabledText: '#FFFFFF40',
    border: '#424242FF',
    separator: '#FDFDFD1F',
    layoutBg: '#000000FF',
    ...getColorsObject('red', true),
    ...getColorsObject('volcano', true),
    ...getColorsObject('orange', true),
    ...getColorsObject('gold', true),
    ...getColorsObject('yellow', true),
    ...getColorsObject('lime', true),
    ...getColorsObject('green', true),
    ...getColorsObject('cyan', true),
    ...getColorsObject('blue', true),
    ...getColorsObject('geekblue', true),
    ...getColorsObject('purple', true),
    ...getColorsObject('magenta', true),
    // neutral
    gray1: '#000000',
    gray2: '#141414',
    gray3: '#1f1f1f',
    gray4: '#262626',
    gray5: '#434343',
    gray6: '#595959',
    gray7: '#8c8c8c',
    gray8: '#bfbfbf',
    gray9: '#d9d9d9',
    gray10: '#f0f0f0',
    gray11: '#f5f5f5',
    gray12: '#fafafa',
    gray13: '#ffffff',
  },
}

const Theme = ({ children }) => {
  // const dispatch = useDispatch()

  const { theme } = useSelector((state) => state.app)

  // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
  //   const systemTheme = event.matches ? 'dark' : 'light'
  //   dispatch(setAppTheme(systemTheme))
  // })

  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export default Theme
