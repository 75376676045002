import { useState, useEffect } from 'react'
import { Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { DataPie24Regular } from '@fluentui/react-icons'
import { profilesURL } from '@/helpers/env'
import getIconByUrl from '@/utils/getIconByUrl'
import { capitalize } from '@/utils/functions'
import UserPopover from '@/components/UserPopover'
import ProfilePicture from '@/components/ProfilePicture'
import PlaygroundVote from '@/components/PlaygroundVote'
import { Container } from './styles'

const PlaygroundCard = ({ playground }) => {
  const [mainIcon, setMainIcon] = useState(null)
  const [languageIcon, setLanguageIcon] = useState(null)
  const [interfaceIcon, setInterfaceIcon] = useState(null)

  const lastVersion = playground?.versions?.[playground?.versions?.length - 1]

  useEffect(() => {
    if (!playground) return

    getIconByUrl({
      iconUrl: playground?.data_sources?.[0]?.theme?.logo_url || 'ant-BuildOutlined',
      className: 'main-icon',
      onReady: setMainIcon,
    })

    getIconByUrl({
      iconUrl: playground?.jumpbox?.language?.logo_url || 'ant-FileTextOutlined',
      className: 'language-icon',
      onReady: setLanguageIcon,
    })

    getIconByUrl({
      iconUrl: playground?.jumpbox?.interface?.logo_url || 'ant-FileTextOutlined',
      className: 'interface-icon',
      onReady: setInterfaceIcon,
    })
  }, [playground])

  return (
    <Container className="playground-card-container" $playgroundBackgroundImage={playground?.image_url}>
      <Link to={`${profilesURL}${playground?.user?.username}/playgrounds/${playground?.id}`} target="_blank">
        <>
          <div className="image-container">{mainIcon}</div>

          <div className="playground-card-content">
            <div className="info">
              <div className="icons-container">
                {playground?.data_sources?.[0] && (
                  <Tooltip title={playground?.data_sources?.[0]?.name}>
                    <DataPie24Regular className="data-source-icon" />
                  </Tooltip>
                )}

                <Tooltip title={playground?.jumpbox?.language?.value}>
                  <span>{languageIcon}</span>
                </Tooltip>

                <Tooltip title={playground?.jumpbox?.interface?.value}>
                  <span>{interfaceIcon}</span>
                </Tooltip>
              </div>

              <p className="published-info">Published {dayjs(lastVersion?.created).fromNow()}</p>
            </div>

            <h4 className="title">{playground?.name}</h4>

            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: playground?.description_html,
              }}
            />
          </div>
        </>
      </Link>

      <div className="bottom-info">
        <UserPopover user={playground?.user}>
          <span className="author-info">
            <ProfilePicture
              src={playground?.user?.avatar_url}
              alt={capitalize(playground?.user?.first_name)}
              isPro={playground?.user?.is_pro}
              size="small"
              disableTooltip
            />

            <span className="link">
              {capitalize(playground?.user?.first_name)} {capitalize(playground?.user?.last_name)}
            </span>
          </span>
        </UserPopover>

        <PlaygroundVote playground={playground} />
      </div>
    </Container>
  )
}

export default PlaygroundCard
