import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Spin, Collapse, Tooltip } from 'antd'
import JsonView from '@uiw/react-json-view'
import { githubLightTheme } from '@uiw/react-json-view/githubLight'
import { githubDarkTheme } from '@uiw/react-json-view/githubDark'
import Blob from '@/components/Blob'
import DjangoIcon from '@/assets/images/icons/django.svg?react'
import { Container } from './styles'

const LabDebugTab = () => {
  const { theme } = useSelector((state) => state.app)
  const { labDebug, isLabDebugLoading, isLabLoading } = useSelector((state) => state.labs)

  const collapseItems = labDebug?.lab_sessions?.map((s, index) => ({
    key: index + 1,
    label: (
      <>
        <Blob text={s?.status} color={s?.status === 'allocated' ? 'green' : 'gray'} size="small" addPulse />{' '}
        {dayjs(s?.created).format('LLL')}
      </>
    ),
    children: (
      <div key={s?.id} className="lab-stats">
        <div className="lab-stat is-row">
          <p className="title">ID:</p>
          <p className="subtitle">{s?.id}</p>

          <Tooltip title={'View in Django Admin'}>
            <a className="link" href={s?.django_admin_url} target="_blank" rel="noreferrer">
              <DjangoIcon />
            </a>
          </Tooltip>
        </div>

        <div className="lab-stat is-row">
          <p className="title">Instance:</p>
          <p className="subtitle">
            {s?.container_instance?.domain} ({s?.container_instance?.ip})
          </p>
        </div>

        <div className="lab-stat is-row">
          <p className="title">Network:</p>
          <p className="subtitle">{s?.docker_network_name}</p>
        </div>

        <div className="lab-stat">
          <p className="title">Devices:</p>

          <ul className="list">
            {s?.devices?.map((d) => {
              const lastHistory = d?.history_human?.slice(-1)[0]

              return (
                <li key={d?.device_id} className="list-item">
                  <p className="subtitle">
                    <Blob text={d?.status} color={d?.status === 'started' ? 'green' : 'gray'} size="small" addPulse />{' '}
                    {d?.device_name}
                  </p>

                  <div className="lab-stat is-row">
                    <p className="title">Container ID:</p>
                    <p className="subtitle">{lastHistory?.docker_container_id}</p>
                  </div>

                  <div className="lab-stat is-row">
                    <p className="title">Image:</p>
                    <p className="subtitle">{lastHistory?.docker_image}</p>
                  </div>

                  {d?.views && (
                    <>
                      <div className="lab-stat is-row">
                        <p className="title">Views:</p>
                      </div>
                      <div className="lab-stat">
                        <ul className="list">
                          {Object.keys(d?.views)?.map((key) => {
                            const views = d?.views?.[key]

                            return views?.map((v) => (
                              <li key={v?.url} className="list-item">
                                <p className="subtitle">
                                  {v?.name} ({key}):{' '}
                                  <a href={v?.url} target="_blank">
                                    {v?.url}
                                  </a>
                                </p>
                              </li>
                            ))
                          })}
                        </ul>
                      </div>
                    </>
                  )}

                  <div className="lab-stat is-row">
                    <p className="title">Command:</p>
                    <p className="subtitle">
                      <code>{d?.command}</code>
                    </p>
                  </div>

                  <div className="lab-stat">
                    <p className="title">History:</p>

                    <JsonView value={d?.history_human} style={theme === 'dark' ? githubDarkTheme : githubLightTheme} />
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    ),
  }))

  if (isLabDebugLoading || isLabLoading) {
    return (
      <Container className="lab-debug-tab">
        <Spin />
      </Container>
    )
  }

  return (
    <Container className="lab-debug-tab">
      <div className="lab-info">
        <h5 className="title">Lab details</h5>

        <div className="lab-stats">
          <div className="lab-stat is-row">
            <p className="title">ID:</p>
            <p className="subtitle">{labDebug?.lab?.id}</p>

            <Tooltip title={'View in Django Admin'} overlayClassName={'on-modal'}>
              <a className="link" href={labDebug?.lab?.django_admin_url} target="_blank" rel="noreferrer">
                <DjangoIcon />
              </a>
            </Tooltip>
          </div>

          <div className="lab-stat">
            <p className="title">Devices:</p>

            <ul className="list">
              {labDebug?.lab?.devices?.map((d) => (
                <li key={d?.id} className="list-item">
                  <p className="subtitle">{d?.name}</p>

                  <Tooltip title={'View in Django Admin'}>
                    <a className="link" href={d?.django_admin_url} target="_blank" rel="noreferrer">
                      <DjangoIcon />
                    </a>
                  </Tooltip>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="lab-session-info">
        <h5 className="title">Lab sessions</h5>

        <Collapse items={collapseItems} defaultActiveKey={['1']} ghost />
      </div>
    </Container>
  )
}

export default LabDebugTab
