import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  .title {
    margin: 0px;
  }

  .modules-container {
    position: relative;
    display: flex;
    gap: 20px;
    overflow: auto;

    .swiper {
      width: 100%;
      margin: 0px;
    }
  }

  @media ${device.mdDown} {
    padding: 0px 10px;

    > .title {
      font-size: 24px;
      line-height: 34px;
      text-align: center;
    }

    > .ant-tabs-nav-wrap {
      justify-content: center;
    }
  }

  @media ${device.smDown} {
    .modules-container {
      .swiper {
        .swiper-slide {
          width: 100%;
        }
      }
    }
  }
`
