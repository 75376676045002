import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Area } from '@ant-design/plots'
import StreakIcon from '@/assets/images/streak.svg?react'
import StreakEmptyIcon from '@/assets/images/streak-empty.svg?react'
import NoActivity from '@/assets/images/no-activity.gif'
import { Container } from './styles'

const ActivityInfo = () => {
  const { theme } = useSelector((state) => state.app)
  const { stats, isStatsLoading } = useSelector((state) => state.users)
  const hasActivity = stats?.activities && Object.values(stats?.activities)?.some((v) => v !== 0)

  const [chartConfig, setChartConfig] = useState({ height: 180 })

  useEffect(() => {
    const activityChartConfig = {
      data: stats?.activities
        ? Object.entries(stats.activities).map((e) => ({
            Date: dayjs(e[0]).format('MMM D'),
            activities: e[1],
          }))
        : [],
      height: 180,
      marginTop: 5,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 20,
      xField: 'Date',
      yField: 'activities',
      shapeField: 'smooth',
      axis: {
        x: {
          tickLength: 10,
          tickCount: 10,
          tickLineWidth: 0,
          labelAutoRotate: false,
          labelAutoHide: true,
          labelFill: localStorage.getItem('dw-theme') === 'dark' ? '#ffffff' : '#000000',
        },
        y: {
          tickFilter: (t) => {
            if (Number.isInteger(t)) return true
            return false
          },
          tickLength: 10,
          tickCount: 3,
          tickLineWidth: 0,
          grid: true,
          gridLineDash: 'line',
          gridStrokeOpacity: 0.1,
          labelFill: localStorage.getItem('dw-theme') === 'dark' ? '#ffffff' : '#000000',
        },
      },
      animate: {
        enter: {
          type: 'pathIn',
        },
      },
      interaction: {
        tooltip: true,
      },
      line: {
        style: {
          stroke: '#1890ff',
          strokeWidth: 2,
        },
        shapeField: 'smooth',
        tooltip: true,
      },
      style: {
        maxWidth: 30,
        fill: `l(270) 0:#ffffff00 1:#7ec2f3`,
      },
    }

    if (stats?.activities) {
      setChartConfig(activityChartConfig)
    }
  }, [theme, stats?.activities])

  return (
    <Container>
      <div className="streak-box">
        <h4 className="title">Current streak</h4>

        <p className="text">Complete an activity every day to build your streak.</p>

        <div className="streak-info">
          <div className="current-streak">
            <span>
              {isStatsLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} /> : stats?.streak}
            </span>

            {stats?.streak ? <StreakIcon /> : <StreakEmptyIcon />}
          </div>

          <div className="week-days-streak">
            {stats?.current_week &&
              Object.entries(stats.current_week)?.map((e) => {
                const isToday = dayjs().format('YYYY-MM-DD') === dayjs(e[0]).format('YYYY-MM-DD')

                return (
                  <h5 className={`day-streak ${isToday ? 'current-day' : ''} ${e[1] ? 'active' : ''}`} key={e[0]}>
                    {dayjs(e[0]).format('dd')}
                  </h5>
                )
              })}
          </div>
        </div>
      </div>

      <div className="activity-box">
        <h4 className="title">Your activity</h4>

        {isStatsLoading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
        ) : hasActivity ? (
          chartConfig ? (
            <Area className="chart" {...chartConfig} />
          ) : (
            <></>
          )
        ) : (
          <>
            <img src={NoActivity} alt="" />
            <p className="text">There is no activity this month. You can start by exploring our catalog.</p>
          </>
        )}
      </div>

      <div className="stats-box">
        <div className="stat-container">
          <div className="stat-info">
            <span className="title">Projects done</span>
            <h3 className="value">{stats?.completed?.labs || '—'}</h3>
          </div>
        </div>

        <div className="stat-container">
          <div className="stat-info">
            <span className="title">Skills acquired</span>
            <h3 className="value">{stats?.completed?.skills || '—'}</h3>
          </div>
        </div>

        <div className="stat-container">
          <div className="stat-info">
            <span className="title">Skill tracks completed</span>
            <h3 className="value">{stats?.completed?.skill_tracks || '—'}</h3>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ActivityInfo
