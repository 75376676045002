import styled from 'styled-components'

export const Container = styled.div`
  > button {
    background: linear-gradient(253deg, #484886 44.48%, #5479f7 76.52%, #89d2c2 110%) !important;
    color: #fff !important;
    display: flex;
    gap: 4px;
    align-items: center;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

    .hightlight {
      font-weight: 700;
    }

    .pro-badge {
      width: 16px;
    }
  }
`
