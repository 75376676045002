import styled from 'styled-components'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'

export const Modal = styled(AntModal)`
  padding: 14px;

  * {
    box-sizing: border-box;
  }

  .ant-modal-content {
    height: 100%;
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray4} !important;
    display: flex;
    justify-content: center;
    padding: 48px 24px;

    .ant-modal-body {
      height: 100%;
      max-width: 1200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  @media ${device.xlDown} {
    .ant-modal-content {
      padding: 48px 16px 16px;
    }
  }
`
