import Layout1 from '@/layouts/Layout1'
import WizardContent from '@/pages/PlaygroundsPage/components/WizardModal/components/WizardContent'
import { Container } from './styles'

const CustomProjectsCreationPage = () => {
  return (
    <Layout1 navbar>
      <Container>
        <div className="custom-projects-creation-page-container">
          <WizardContent isCustomProject={true} />
        </div>
      </Container>
    </Layout1>
  )
}

export default CustomProjectsCreationPage
