import { SET_ISSUES_LOADING, ISSUES_UPDATED, SET_ISSUES_ERROR, RESET_ISSUES_STATE } from '@/store/issues'
import { fetchIssues, postIssue } from '@/services/issues'
import { showToast } from '@/utils/toast'

const getIssues = (params) => async (dispatch) => {
  try {
    dispatch(SET_ISSUES_LOADING(true))

    const issuesData = await fetchIssues(params)

    dispatch(ISSUES_UPDATED(issuesData))
  } catch (error) {
    const { message } = error
    dispatch(SET_ISSUES_ERROR(message))
  }
}

const createIssue = (data, cb) => async (dispatch) => {
  try {
    await postIssue(data)
    showToast('Your report was submitted')

    if (cb) {
      cb()
    }
  } catch (error) {
    showToast(error.message, 'error')
  }
}

const resetIssuesState = () => (dispatch) => {
  dispatch(RESET_ISSUES_STATE())
}

export { getIssues, createIssue, resetIssuesState }
