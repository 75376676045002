import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  user-select: none;

  .loading-container {
    position: absolute;
    text-align: center;
  }

  > iframe {
    border: 0px;
  }
`
