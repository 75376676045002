import { useSelector, useDispatch } from 'react-redux'
import Replacement from '@/pages/ModulePage/components/Replacement'
import { toggleCustomReportIssueModal } from '@/store/app/actions'
import { Modal } from './styles'

const CustomReportIssueModal = () => {
  const dispatch = useDispatch()

  const { isCustomReportIssueModalOpen } = useSelector((state) => state.app)
  const { currentAccount } = useSelector((state) => state.accounts)

  const enableCustomReportIssue = currentAccount?.config?.browse?.report_issue
  const customReportIssueButtonText = currentAccount?.whitelabel_config?.report_issue_button_text || 'Report an issue'
  const customReportIssueContent = currentAccount?.whitelabel_config?.report_issue_modal_content_html

  const cleanup = () => {
    dispatch(toggleCustomReportIssueModal())
  }

  if (!enableCustomReportIssue) return null

  return (
    <Modal
      title={customReportIssueButtonText}
      open={isCustomReportIssueModalOpen}
      onCancel={cleanup}
      footer={null}
      destroyOnClose
    >
      <Replacement contentHtml={customReportIssueContent} />
    </Modal>
  )
}

export default CustomReportIssueModal
