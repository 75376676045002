import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: calc(100% - 210px);
  height: 100%;
  display: flex;
  margin: 0 auto;

  .fade-in-mask {
    width: 100%;
    display: flex;
  }

  .main-container {
    width: calc(100% - 400px);
    background-color: ${(props) => props.theme.colors.gray3};
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    padding: 30px 24px;

    > .content {
      width: 100%;
    }
  }

  @media ${device.xlDown} {
    .main-container {
      width: calc(100% - 240px);
    }
  }

  @media ${device.lgDown} {
    width: 100%;
    height: calc(100% - 64px);
  }

  @media ${device.mdDown} {
    .main-container {
      width: 100%;
      padding: 20px 0px;

      .my-playgrounds-section {
        > .fade-in-mask > .header {
          padding-left: 20px;
        }
      }
    }

    .jumpboxes-section {
      display: none;
    }
  }
`
