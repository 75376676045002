import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import CustomProjectCard from '../CustomProjectCard'
import { fetchCustomProjects } from '@/store/customProjects/actions'
import { Container } from './styles'

const CustomProjectsBlock = () => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { customProjectsData, isLoading } = useSelector((state) => state.customProjects)

  const memberAccount = userProfile?.accounts?.filter((a) => a?.role === 'member')?.[0]

  const handleLoad = () => {
    dispatch(fetchCustomProjects({ account_id: memberAccount?.id }))
  }

  useEffect(() => {
    handleLoad()
  }, [])

  if (!memberAccount || !customProjectsData?.length) return <></>

  return (
    <Container className="custom-projects-block">
      <div className="account-info">
        <div className="account-image-container">
          {memberAccount?.image_url && (
            <img className="account-image" src={memberAccount?.image_url} alt={memberAccount?.name} />
          )}
        </div>

        <h5 className="name">{memberAccount?.name} assignments</h5>
      </div>

      <div className="custom-projects-container">
        <Swiper
          className="white custom-project-swiper-container"
          spaceBetween={12}
          modules={[Navigation]}
          navigation
          slidesPerView={'auto'}
        >
          {customProjectsData?.map((customProject) => (
            <SwiperSlide key={customProject?.id}>
              <CustomProjectCard accountId={memberAccount?.id} customProject={customProject} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  )
}

export default CustomProjectsBlock
