import styled, { css, keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.$isBanner ? 'flex-end' : 'center')};
  padding: 40px;
  animation: 1s ${fadeInAnimation};

  .share-card {
    width: 510px;
    background-color: ${(props) =>
      localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.geekblue3 : props.theme.colors.geekblue2};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    border-radius: 8px 8px 0px 0px;
    padding: 12px 12px 26px;
    margin-bottom: -14px;

    .text {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 12px;
      margin: 0px;

      .icon {
        font-size: 24px;
      }
    }

    .share-btn {
      padding: 4px 10px;
    }
  }

  .card {
    width: 510px;
    max-height: 100%;
    min-height: 420px;
    background: ${(props) => props.theme.colors.gray1};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 8px;
    padding: 36px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    .module-image {
      min-width: 120px;
      width: 120px;
      height: auto;
      opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
      image-rendering: auto;
      pointer-events: none;
    }

    .content {
      max-width: 1040px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 26px;
      align-self: center;
    }

    .header-bg {
      width: 100%;
      user-select: none;

      @media ${device.lgDown} {
        display: none;
      }
    }

    .lab-top-banner {
      width: 100%;
      background-color: ${(props) => props.theme.colors.gray8};
      color: ${(props) => props.theme.colors.gray2};
      font-size: 14px;
      line-height: 22px;
      border-radius: 6px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      align-self: self-start;
      text-align: center;
      padding: 4px 10px;

      > a {
        color: ${(props) => props.theme.colors.gray2};
        font-weight: 700;
        text-decoration: underline;
      }
    }

    .early-access-banner {
      background: linear-gradient(
        84deg,
        ${(props) => props.theme.colors.base.gray10} 24.3%,
        #5479f7 67.59%,
        #89d2c2 99.33%
      );
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 5px 10px;

      .pro-badge {
        width: 16px;
      }

      > span {
        color: ${(props) => props.theme.colors.base.gray1};
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .module-header {
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: 24px;

      .module-header-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 6px;
        overflow: auto;
        position: relative;

        .pre-title {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 4px 0px;

          .la-bullet {
            min-width: 12px;
            width: 12px;
            height: 12px;
            background-color: ${(props) => props.theme.colors.green1};
            display: inline-block;
            border-radius: 50%;
          }

          .text {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 12px;
            line-height: 16px;
            margin: 0px;
          }

          .link {
            color: ${(props) => props.theme.colors.gray7};
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .module-title {
          color: ${(props) => props.theme.colors.gray11};
          flex: 1;
          margin: 0px 0px 6px;
        }

        .module-info {
          display: flex;
          gap: 10px;

          .tags-container {
            max-width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;
            overflow: auto;

            .tag {
              background-color: ${(props) => props.theme.colors.base.gray7};
              color: ${(props) => props.theme.colors.base.gray1};
              text-transform: capitalize;
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              gap: 6px;
              margin: 0px;

              > img {
                width: initial;
              }

              &.module-type {
                color: ${(props) => props.theme.colors.base.gray1};
                background-color: ${(props) => props.theme.colors.base.gray10};
              }

              &.is-draft {
                color: ${(props) => props.theme.colors.base.gray1};
                background-color: ${(props) => props.theme.colors.base.volcano6};
              }

              &.is-beta {
                color: ${(props) => props.theme.colors.base.gray1};
                background-color: ${(props) => props.theme.colors.base.volcano6};
              }

              &.is-archived {
                color: ${(props) => props.theme.colors.base.gray1};
                background-color: ${(props) => props.theme.colors.base.volcano6};
              }

              &.difficulty-easy {
                color: ${(props) => props.theme.colors.base.gray9};
                background-color: ${(props) => props.theme.colors.base.green2};
              }

              &.difficulty-medium {
                color: ${(props) => props.theme.colors.base.gray9};
                background-color: ${(props) => props.theme.colors.base.gold3};
              }

              &.difficulty-hard {
                color: ${(props) => props.theme.colors.base.gray9};
                background-color: ${(props) => props.theme.colors.base.red2};
              }
            }
          }

          .rating {
            color: ${(props) => props.theme.colors.gray8};
            display: flex;
            align-items: center;
            gap: 4px;
            font-weight: 500;
            padding-left: 10px;
            box-shadow: 1px 0px 0px 0px ${(props) => props.theme.colors.gray4} inset;

            .subtext {
              color: ${(props) => props.theme.colors.gray8};
              margin: 0px;
            }

            .icon {
              color: ${(props) => props.theme.colors.yellow6};
              font-size: 18px;
            }
          }
        }
      }
    }

    .multiple-projects-limit-block {
      border-radius: 20px;
      background: ${(props) => props.theme.colors.gray4};
      color: ${(props) => props.theme.colors.gray8};
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      white-space: initial;
      padding: 12px;
      margin: 0px;

      > a {
        color: ${(props) => props.theme.colors.gray8};
        font-weight: 600;
        text-decoration: underline;
      }
    }

    .module-description {
      .description {
        max-height: 90px;
        color: ${(props) => props.theme.colors.gray8};
        overflow: auto;
        margin: 0px;
      }
    }

    .bottom-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 26px;

      .module-settings {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .settings-block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          .setting-box {
            height: 42px;
            background-color: ${(props) => props.theme.colors.geekblue1};
            color: ${(props) => props.theme.colors.gray10};
            display: flex;
            align-items: center;
            gap: 6px;
            white-space: nowrap;
            border-radius: 6px;
            padding: 10px;

            .icon {
              width: 20px;
              font-size: 20px;

              path {
                fill: ${(props) => props.theme.colors.gray10};
              }
            }

            .text {
            }
          }
        }

        .instance-block {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > .ant-select {
            width: 100%;
          }
        }
      }

      .bottom-options {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .author-info {
          color: ${(props) => props.theme.colors.gray8};
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          display: flex;
          align-items: center;
        }

        .main-row {
          position: relative;
          display: flex;
          align-items: flex-end;
          gap: 20px;

          .last-checkpoint-date {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 12px;
            position: absolute;
            top: -24px;
            right: 0px;
            white-space: nowrap;
            margin-bottom: 4px;
          }

          #launch-lab-btn {
          }

          .resume-lab-container {
            position: relative;

            #resume-lab-btn {
              width: 100%;
            }
          }
        }

        .running-labs {
          width: 100%;

          .info-text {
            color: ${(props) => props.theme.colors.base.gray8};
            margin: 10px 0px 6px;
          }
        }
      }
    }
  }

  ${(props) =>
    props.$isBanner &&
    css`
      position: sticky;
      bottom: 0px;
      padding: 0px;
      align-items: flex-end;

      .share-card {
        width: 100%;
        padding: 12px 40px 26px;

        .text {
          gap: 8px;
          font-size: 14px;
        }

        .share-btn {
          padding: 4px 15px;
        }
      }

      .card {
        width: 100%;
        max-height: initial;
        min-height: auto;
        background: linear-gradient(180deg, ${props.theme.colors.blue1} 0%, ${props.theme.colors.gray1} 40%);
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 24px;
        box-shadow: 0px -6px 19px 1px rgba(42, 55, 101, 0.22);

        .content {
          gap: 6px;
        }

        .lab-top-banner {
          width: initial;
          display: inline-block;
        }

        .early-access-banner {
          align-self: flex-start;
          border-radius: 10px;
          padding: 3px 10px;
        }

        .module-header {
          .module-header-content {
            .module-title {
              margin: 0px 0px 10px;
            }

            .module-info {
              position: absolute;
              top: 0px;
              right: 0px;
            }
          }
        }

        .multiple-projects-limit-block {
          background: transparent;
          text-align: left;
          padding: 0px;
          margin-top: -10px;
        }

        .allocated-modules-container {
          padding: 10px;

          .allocated-module {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            .module-name {
              margin: 0px;
            }
          }
        }

        .module-description {
          display: none;
        }

        .bottom-content {
          flex-direction: row;

          .module-settings {
            max-width: 50%;
            justify-content: flex-start;
          }

          .bottom-options {
            justify-content: flex-end;

            .author-info {
              display: none;
            }
          }
        }
      }

      @media ${(props) => (props.$hasLab ? device.xxlDown : device.mdDown)} {
        .card {
          .bottom-content {
            flex-direction: column;
            gap: 20px;

            .module-settings {
              max-width: 100%;
              justify-content: space-between;
            }

            .bottom-options {
              margin-top: 20px;
            }
          }
        }

        ${!props.$hasLab &&
        css`
          .card {
            align-items: flex-start;

            .bottom-content {
              .bottom-options {
                margin-top: 0px;
              }
            }
          }
        `}
      }
    `}
`
