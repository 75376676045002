import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import proFeatures from '@/constants/proFeatures'
import BannerBg from '@/assets/images/plans/pro-features-banner-bg.png'
import { Container } from './styles'

const ProFeaturesBanner = () => {
  const generateSlides = () => {
    return proFeatures.map((f, i) => (
      <SwiperSlide key={i}>
        <span className="icon">{f.icon}</span>
        <p className="feature-name">{f.name}</p>
        {f.comingSoon && <span className="coming-soon-tag">Coming soon</span>}
      </SwiperSlide>
    ))
  }

  return (
    <Container>
      <img className="banner-bg" src={BannerBg} alt="Update to PRO" />

      <h4 className="title">Switch to Pro Plan today and save up to 50% off</h4>

      <Swiper
        direction={'vertical'}
        loop={true}
        allowTouchMove={false}
        autoplay={{
          delay: 800,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {generateSlides()}
      </Swiper>
    </Container>
  )
}
export default ProFeaturesBanner
