import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray1};

  .catalog-header {
    min-height: 172px;
    width: 100%;
    background: radial-gradient(
      77.57% 293.89% at 46.42% 114.25%,
      ${(props) => props.theme.colors.base.gray10} 28.53%,
      rgba(59, 124, 249, 0.93) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;

    > .content {
      max-width: 1440px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 0px 20px;
    }

    .title {
      color: ${(props) => props.theme.colors.base.gray1};
      margin-bottom: 20px;
    }

    .search-box {
      width: 100%;
      max-width: 540px;
      background-color: ${(props) => props.theme.colors.gray1};
      border: 1px solid ${(props) => props.theme.colors.base.gray9};
      border-radius: 2px;
      position: relative;
      display: flex;
      align-items: center;

      svg {
        color: ${(props) => props.theme.colors.gray13}40;
        font-size: 16px;
        position: absolute;
        top: 6px;
        left: 12px;
      }

      .ais-SearchBox {
        width: 100%;

        input {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          border: 0px;
          outline: 0px;
          padding: 6px 24px 6px 38px;
        }

        .ais-SearchBox-loadingIndicator {
          width: 40px;
          height: 100%;
          position: absolute;
        }

        .ais-SearchBox-submit,
        .ais-SearchBox-reset {
          display: none;
        }
      }
    }
  }

  .catalog-body {
    height: calc(100vh - 172px - 64px);
    background-color: ${(props) =>
      localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.geekblue1 : props.theme.colors.gray1};
    display: flex;
    justify-content: center;

    > .content {
      max-width: 1440px;
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      padding: 10px 20px;
    }
  }

  @media ${device.lgDown} {
    .catalog-body {
      > .content {
        flex-direction: column;
      }
    }
  }
`
