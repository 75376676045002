import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import playground1 from '@/assets/images/playgrounds/playgrounds-header-1.png'
import playground2 from '@/assets/images/playgrounds/playgrounds-header-2.png'
import playground3 from '@/assets/images/playgrounds/playgrounds-header-3.png'
import { Container } from './styles'

const NoPlaygroundsHeader = () => {
  const items = [
    {
      title: 'Configure your Playground',
      description: 'Choose between Python, R and Julia. And hundreds of datasets',
      image: playground1,
    },
    {
      title: 'Play and analyze your data',
      description: 'Playgrounds are a free roaming environment where you can play freely with the dataset selected.',
      image: playground2,
    },
    {
      title: 'Share your work with the world',
      description: 'Publish your playground and showcase your work to the world',
      image: playground3,
    },
  ]

  const pagination = {
    clickable: true,
    renderBullet: (index, className) => {
      return '<span class="' + className + '">' + (index <= 9 ? `0${index + 1}` : index + 1) + '</span>'
    },
  }

  return (
    <Container className="no-playgrounds-header">
      <h3 className="title">Welcome to Playgrounds</h3>

      <Swiper
        spaceBetween={40}
        modules={[Autoplay, Navigation, Pagination]}
        navigation
        pagination={pagination}
        slidesPerView={'auto'}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
      >
        {items?.map((item, i) => (
          <SwiperSlide key={i}>
            <div key={i} className="item">
              <div className="title-container">
                <h3 className="title">{item?.title}</h3>
                <p className="description">{item?.description}</p>
              </div>

              <img className={`image image-${i}`} src={item?.image} alt={item?.title} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  )
}

export default NoPlaygroundsHeader
