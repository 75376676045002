import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  gptTasks: null,
  gptRuns: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'gpt',
  initialState,
  reducers: {
    SET_GPT_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    GPT_TASKS_UPDATED: (state, { payload }) => {
      state.gptTasks = payload
      state.error = null
    },
    GPT_RUNS_UPDATED: (state, { payload }) => {
      state.gptRuns = payload
      state.isLoading = false
      state.error = null
    },
    SET_GPT_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
    RESET_GPT_STATE: () => initialState,
  },
})

export const { SET_GPT_LOADING, GPT_TASKS_UPDATED, GPT_RUNS_UPDATED, SET_GPT_ERROR, RESET_GPT_STATE } = slice.actions
export default slice.reducer
