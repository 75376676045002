import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentVoucher: null,
  error: null,
}

const slice = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    VOUCHER_UPDATED: (state, { payload }) => {
      state.currentVoucher = payload
      state.error = null
    },
    SET_VOUCHER_ERROR: (state, { payload }) => {
      state.error = payload
    },
    RESET_VOUCHERS_STATE: () => initialState,
  },
})

export const { VOUCHER_UPDATED, SET_VOUCHER_ERROR, RESET_VOUCHERS_STATE } = slice.actions
export default slice.reducer
